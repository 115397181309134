import React, { useEffect, useState } from "react";
import { DatePicker, Table, Button, Form, Card } from "antd";
import { formatDate } from "../../../../utils/DateFormat";
import { doGet } from "../../../../API/apis";
import ViewReferralNotes from "../../PatientSummary/Referal/ReferralReport/ViewReferralNotes";
import { useOutletContext } from "react-router-dom";
import { CSVLink } from "react-csv";
import Notification from "../../../../components/Notification/Notification";
import FormatDate from "../../../../components/FormatDate/FormatDate";

const { RangePicker } = DatePicker;

const Referral = () => {
  const [loading, setLoading] = useState(false);
  const [referralList, setReferralList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [view, setView] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
    useOutletContext();

  const showReferral = (record) => {
    setView(true);
    setViewRecord(record);
  };

  const closeReferral = () => {
    setView(false);
    setViewRecord(null);
  };

  const transformedData =(records)=>{
    if (!records || !Array.isArray(records)) {
      return [];
    }
    return records.map((record) => ({
      "ID": record?.id,
      "Patient Name": record?.patient_name,
      "Date of Birth": record?.dob,
      "Patient MRN": record?.patient_mrn,
      "Referral ID": record?.referral_id,
      "Referring Provider": record?.referral_from_name,
      "Referring Provider Speciality": record?.referral_from_speciality,
      "Referring Provider Phone": record?.referral_from_phone,
      "Referring Provider Fax": record?.referral_from_fax,
      "Referring Provider Email": record?.referral_from_email,
      "Referral To Phone": record?.referral_to_phone,
      "Referral To Full Name": record?.referral_to_full_name,
      "Referral To Email": record?.referral_to_email,
      "Referral To Address": record?.referral_to_address,
      "Referral To Operating Hours": record?.referral_to_operating_hours,
      "Referral To Speciality": record?.referral_to_speciality,
      "Referral To Fax": record?.referral_to_fax,
      "Start Date": record?.start_date,
      "End Date": record?.end_date,
      "Authorization Code": record?.auth_code,
      "Patient Insurance Information": record?.patient_insurance_info,
      "Insurance Type": record?.insurance_type,
      "Insurance Group": record?.insurance_group,
      "Insurance Company": record?.insurance_company,
      "Insurance Plan": record?.insurance_plan,
      "Insurance Policy": record?.insurance_policy,
      "Instructions": record?.instructions,
      "Reason for Referral": record?.reason_for_referral,
      "Patient Diagnostic Code": record?.patient_diagnostic_code,
      "Clinic Notes": record?.clinic_notes,
      "Other Notes": record?.other_notes,
      "Status": record?.status,
      "Date Time": record?.date_time,
      "Added By": record?.added_by,
      "Clinic": record?.clinic,
      "Patient": record?.patient,
    }));
  }

  const coloumn = [
    {
      key: 1,
      title: "#",
      width: "5%",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: "Referral_ID",
      title: "Referral ID",
      dataIndex: "referral_id",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <Button type="link" onClick={() => showReferral(record)}>
          {text}
        </Button>
      ),
    },
    {
      key: "PatientName",
      title: "Patient Name",
      dataIndex: "patient_name",
      align: "center",
    },
    {
      key: "DOB",
      title: "DOB",
      dataIndex: "dob",
      align: "center",
      render: (text) => text && <FormatDate date={text}/>,
    },
    {
      key: 2,
      title: "Ref. Date",
      align: "center",
      dataIndex: "date_time",
      render: (text) =>
        text && formatDate(text, officeDateFormat, officeTimezone),
    },

    {
      key: 3,
      title: "Reason",
      dataIndex: "reason_for_referral",
    },
    {
      key: 4,
      title: "Referral From",
      align: "center",
      dataIndex: "referral_from_name",
    },
    {
      key: 5,
      title: "Referral To",
      align: "center",
      dataIndex: "referral_to_full_name",
    },
    {
      key: 6,
      title: "Speciality",
      align: "center",
      dataIndex: "referral_to_speciality",
    },
    {
      key: 8,
      title: "Expiry Date",
      align: "center",
      dataIndex: "end_date",
      render: (text) => text && <FormatDate date={text}/>,
    },
  ];

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getReferralList();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getReferralList = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/referral-data/?start_date=${startDate}&end_date=${endDate}`
        );

        if (response.status === 200) {
          setReferralList(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getReferralList();
      setSearchClicked(false);
    }
  }, [searchClicked]);

  return (
    <div>
      <h1 className="font-bold text-xl">Referral List </h1>
      <Card className="shadow-class mb-4 mt-4">
        <Form layout="vertical">
          <div className="flex items-center">
            <Form.Item label="Select Date Range">
              <RangePicker
                size="medium"
                onChange={handleDateRangeChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button type="primary" className="ml-2 mt-2" onClick={handleSearch}>
              Search
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setReferralList([])}>
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {referralList?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformedData(referralList)}
                filename={"Referring_provider.csv"}
                className="csv-link"
              >
                Download CSV
              </CSVLink>
            </Button>
          )}
        </div>
        <Table
          loading={loading}
          columns={coloumn}
          dataSource={referralList}
          size="small"
          scroll={{
            x: 1500,
          }}
        />
      </Card>

      <ViewReferralNotes
        open={view}
        onClose={closeReferral}
        data={viewRecord}
      />
    </div>
  );
};

export default Referral;
