import React, { useEffect, useState } from "react";
import { Table, Dropdown, Modal, Tooltip, Typography, Button } from "antd";
import { doDelete, doPatch } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import ViewOrderedLabs from "./ViewOrderedLabs";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import LabPrescription from "../LabPrescription/LabPrescription";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Mark as Completed",
  },
  {
    key: 4,
    label: "Print",
  },
  {
    key: 5,
    label: "Delete",
  },
];

const OrderedLabs = () => {
  const { id } = useParams();
  const [viewData, setViewData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labDrawer, setLabDrawer] = useState(false);
  const [labData, setLabData] = useState(null);

  const {
    orderedLabs,
    getLabOrders,
    patientBasicInfo,
    getPatientData,
    officeSetting,
    getOfficeSettings,
  } = useStateGlobalContext();

  const columns = [
    {
      title: "#",
      render: (_, record, index) => index + 1,
      align: "center",
      width: "5%",
    },
    {
      title: "Lab Order ID",
      dataIndex: "lab_id",
      key: "lab_id",
      width: "15%",
      render: (text) => (
        <Tooltip title="Click to copy Lab Order ID">
          <Typography.Text
            className="cursor-pointer text-[#1890ff]"
            onClick={() => {
              navigator.clipboard.writeText(text);
              Notification.success("Lab Order ID copied to clipboard");
            }}
          >
            {text}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Ordered Labs",
      dataIndex: "lab_name",
      key: "name",
      render: (labs) => {
        // Check if the labs array has any entries
        if (labs && labs.length > 0) {
          // Initialize an empty array to store lab names
          const labNames = [];

          // Iterate over the first three lab objects in the array or all labs if less than three
          for (let i = 0; i < Math.min(labs.length, 3); i++) {
            const lab = labs[i];

            // Check if the lab object has the "lab_name" property
            if (lab.lab && lab.lab.lab_name) {
              // Add the lab name to the labNames array
              labNames.push(lab.lab.lab_name);
            } else if (lab.lab_name) {
              // For cases where lab name is directly present in the array
              labNames.push(lab.lab_name);
            }
          }

          // Add three dots at the end if there are more than three items
          if (labs.length > 3) {
            return labNames.join(", ") + "...";
          } else {
            return labNames.join(", ");
          }
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "Order Type",
      dataIndex: "lab_id",
      key: "lab_id",
      width: "10%",
      render: (lab_id) =>
        lab_id.includes("LAB_EX") ? (
          <span className="bg-green-600 px-2 py-[2px] text-white text-xs rounded-md">
            External
          </span>
        ) : (
        <span className="bg-[#3484F0] px-2 py-[2px] text-white text-xs rounded-md">
            In-House
          </span>
        ),
    },
    {
      title: "Ordered By",
      dataIndex: "added_by",
      key: "added_by",
      align: "center",
      width: "10%",
      render: (text) => (
        <span title={text}>
          {text.length > 10 ? text.slice(0, 15) + "..." : text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date_added",
      key: "date_added",
      width: "10%",
      align: "center",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      render: (status) =>
        status === "Completed" ? (
          <span className="bg-[#1890ff] px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      fixed: "right",
      align: "center",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Completed"
            ? items.filter((item) => [1, 3, 4].includes(item.key))
            : items;
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handlePrint(record);
                  } else if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 5) {
                    handleDelete(record);
                  } else if (item.key === 2) {
                    handleStatus(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handlePrint = (record) => {
    setLabDrawer(true);
    setLabData(record);
  };

  const onClose = () => {
    setLabDrawer(false);
  };

  const handleStatus = async (record) => {
    const response = await doPatch(
      `/labs/images/complete-ordered-labs/${id}/${record.id}/`,
      { status: "Completed" }
    );
    if (response.status === 200) {
      Notification.success("Status udpated Successfully");
      getLabOrders(id);
    }
  };

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteLab(record.lab_id);
      },
    });
  };

  const deleteLab = async (recordId) => {
    try {
      const response = await doDelete(
        `/labs/images/delete-ordered-labs/${id}/${recordId}/`
      );
      if (response.status === 200) {
        getLabOrders(id);
        Notification.success("Lab deleted successfully");
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getLabOrders(id),
          getPatientData(id),
          getOfficeSettings(),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <p className="font-semibold mb-4 text-[0.9vw]">Ordered Labs</p>
      <Table
        columns={columns}
        dataSource={orderedLabs}
        size="small"
        loading={loading}
        scroll={{
          x: 1500,
        }}
      />

      <Modal
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <ViewOrderedLabs orderedLabData={viewData} />
      </Modal>

      <LabPrescription
        visible={labDrawer}
        rxData={labData}
        onClose={onClose}
        officeData={officeSetting}
        ptData={patientBasicInfo}
      />
    </div>
  );
};

export default OrderedLabs;
