const localStorage = typeof window !== 'undefined' ? window?.localStorage : null;

// const timeStamps = () => { return Math.floor(new Date().getTime() / 1000) };

// const jwtParse = (query) => { //keys = ["exp", "sessionTime"]
//     let access_token = localStorage?.getItem("_") ? JSON?.parse(atob(localStorage?.getItem("_")))?.access_token : null;
//     if (access_token) {
//         var base64Url = access_token.split('.')[1];
//         var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//         var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
//             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//         }).join(''));
//         if (Array.isArray(query)) {
//             let response;
//             query.forEach((key) => {
//                 response = { ...response, [key]: JSON.parse(jsonPayload)?.[key] }
//             })
//             return response;
//         } else {
//             return JSON.parse(jsonPayload)?.[query]
//         }

//     }
// };

const setSession = (values) => {
    localStorage.setItem("_", btoa(JSON.stringify({ ...getSession(), ...values })));
};

const getSession = (query) => { // keys = ["token", "refreshToken", "user"]
    if (Array.isArray(query)) {
        let response = {};
        query?.forEach((key) => {
            response[key] = localStorage?.getItem("_") ? JSON.parse(atob(localStorage?.getItem("_")))?.[key] : null;
            // response[key] = localStorage?.getItem("_") ? JSON.parse(localStorage?.getItem("_"))?.[key] : null;
        })
        return response;
    } else if(typeof query === "string"){
        return localStorage?.getItem("_") && JSON?.parse(atob(localStorage?.getItem("_")))?.[query]
        // return localStorage?.getItem("_") && JSON.parse(localStorage?.getItem("_"))?.[query]
    }else{
        return localStorage?.getItem("_") && JSON?.parse(atob(localStorage?.getItem("_")))
    }
};

const clearSession = (check = true) => {
    localStorage.clear();
    check && window.location.assign("/")
};

// const refreshToken = (isReload) => {
//     const { postAPI } = httpServices();
//     postAPI({ endPoint: "refresh-token", requestData: { refreshToken: getSession("refresh_token") } }).then(
//         ({ responseCode, data }) => {
//             if (responseCode === 200) {
//                 setSession({ ...data?.keycloakToken, company: data?.company, companyKey: data?.companyKey });
//                 isReload && window.location.assign("/home");
//             } else {
//                 clearSession();
//             }
//             return { responseCode }
//         }
//     );
// };


export { setSession, getSession, clearSession };