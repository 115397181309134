import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  TimePicker,
  Row,
  Col,
  Checkbox,
  Select,
  AutoComplete,
} from "antd";
import {
  phoneValidator,
  zipValidator,
  validateClinicName,
  emailValidator,
  validateDecimalNumber,
} from "../../../../utils/validator";
import TimezoneSelector from "./TimezoneSelector/TimezoneSelector";
import { doPost, doPatch } from "../../../../API/apis";
import dayjs from "dayjs";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import OfficeLogo from "./OfficeLogo";
import Notification from "../../../../components/Notification/Notification";
import Loader from "../../../../components/Loader/Loader";
import BasicButton from "../../../../components/BasicButton/BasicButton";
import CurrencySelector from "./CurrencySelector/CurrencySelector";
const { TextArea } = Input;
const { Option } = Select;
const format = "HH:mm";

document.title = "Office Setting | American EMR";

function Basic_information() {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const { officeSetting, getOfficeSettings } = useStateGlobalContext();



  useEffect(()=>{
    officeSetting?.clinic ? setEdit(true): setEdit(false)
  },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOfficeSettings();

        const operatingHours = officeSetting?.operating_hrs[0];
        const formattedStartTime = dayjs(
          operatingHours?.start_time,
          "HH:mm"
        )?.format("HH:mm");
        const formattedEndTime = dayjs(
          operatingHours?.end_time,
          "HH:mm"
        )?.format("HH:mm");

        form.setFieldsValue({
          facility_name: officeSetting.facility_name[0],
          clinic_name: officeSetting.facility_name[1],
          facility_type: officeSetting?.speciality_type[0],
          speciality_type_visible: officeSetting?.speciality_type[1],
          address: officeSetting?.facility_address[0],
          address_visible: officeSetting?.facility_address[1],
          city: officeSetting?.city[0],
          city_visible: officeSetting?.city[1],
          country: officeSetting?.country[0],
          country_visible: officeSetting?.country[1],
          phone_number: officeSetting?.office_phone[0],
          phone_number_visible: officeSetting?.office_phone[1],
          state: officeSetting?.state[0],
          state_visible: officeSetting?.state[1],
          zip_code: officeSetting?.zipcode[0],
          zip_code_visible: officeSetting?.zipcode[1],
          fax_number: officeSetting?.office_fax[0],
          fax_visible: officeSetting?.office_fax[1],
          business_email: officeSetting?.buisness_email[0],
          business_email_visible: officeSetting?.buisness_email[1],
          website: officeSetting?.website,
          additional_data_1: officeSetting?.additional_basic_data_1[0],
          additional_data_1_visible: officeSetting?.additional_basic_data_1[1],
          additional_data_2: officeSetting?.additional_basic_data_2[0],
          additional_data_2_visible: officeSetting?.additional_basic_data_2[1],
          additional_data_3: officeSetting?.additional_basic_data_3[0],
          additional_data_3_visible: officeSetting?.additional_basic_data_3[1],
          startTime:
            (formattedStartTime && dayjs(formattedStartTime, "HH:mm")) || "",
          endTime: dayjs(formattedEndTime, "HH:mm") || "",
          timezone: officeSetting?.facility_time_zone,
          date_format: officeSetting?.date_format,
          selected_unit: officeSetting?.selected_unit,
          time_format: officeSetting?.time_format,
          currency: officeSetting?.local_currency,
          local_tax_rate: officeSetting?.local_tax_rate,
        });

        setIsModified(false);
        setLoading(false);
      } catch (error) {
        Notification.error("Failed to fetch office settings");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onFinish = async (values) => {
    const { startTime, endTime, selected_unit } = values;
    const startTimeString = startTime?.format("HH:mm");
    const endTimeString = endTime?.format("HH:mm");

    const finalCurrency = values.currency || "USD";
    const data = {
      facility_name: [values.facility_name, values.clinic_name],
      speciality_type: [values.facility_type, values.speciality_type_visible],
      facility_address: [values.address, values.address_visible],
      city: [values.city, values.city_visible],
      state: [values.state, values.state_visible],
      country: [values.country, values.country_visible],
      zipcode: [values.zip_code, values.zip_code_visible],
      office_fax: [values.fax_number, values?.fax_visible],
      website: values.website,
      office_phone: [values.phone_number, values.phone_number_visible],
      office_phone: [values.phone_number, values.phone_number_visible],
      buisness_email: [values?.business_email, values?.business_email_visible],
      additional_basic_data_1: [
        values.additional_data_1,
        values.additional_data_1_visible,
      ],
      additional_basic_data_2: [
        values.additional_data_2,
        values.additional_data_2_visible,
      ],
      additional_basic_data_3: [
        values.additional_data_3,
        values.additional_data_3_visible,
      ],
      operating_hrs: [{ start_time: startTimeString, end_time: endTimeString }],
      facility_time_zone: values.timezone,
      date_format: values.date_format,
      time_format: values.time_format,
      local_currency: finalCurrency,
      selected_unit,
      local_tax_rate: values.local_tax_rate,
    };
    setSaving(true);
    try {
      const edit = officeSetting && officeSetting?.clinic;
      const endpoint = edit
        ? `/user/office-settings/update/?q=basic/&update=true`
        : `/user/office-settings/?q=basic/`;
      const response = await (edit ? doPatch : doPost)(
        endpoint,
        data
      );
      if (response.status === 200 || response.status === 201) {
        const successMessage = isModified
          ? "Basic Office Information Updated Successfully"
          : "Basic Office Information Saved Successfully";
        Notification.success(successMessage);
        setIsModified(false);
        getOfficeSettings();
        setSaving(false);
      } else {
        Notification.error("Something went wrong error");
      }
    } catch (error) {
      Notification.warning("Something went wrong", error);
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    getOfficeSettings();
  };

  const handleFormChange = () => {
    setIsModified(true);
  };

  const formRules = {
    requiredRule: [{ required: true, message: "This field is required" }],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex">
      <Form
        layout="vertical"
        style={{ maxWidth: 550, width: 550 }}
        name="basic-info"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        {[
          {
            label: "Practice Display Name",
            name: "clinic_name",
            tooltip: "Name will appear on the header and Print Invoices",
            component: (
              <Input
                size="medium"
                placeholder="Enter Clinic Name"
                maxLength={50}
              />
            ),
            rules: [{ validator: validateClinicName }],
            visibleCheckbox: null,
          },
          {
            label: "Legal Facility Name",
            name: "facility_name",
            tooltip:
              "This is one time action. Name cannot be changed later. Facility initials will be used on MRN, Inv and other reports",
            component: (
              <Input
                size="medium"
                placeholder="Enter Facility name"
                disabled={officeSetting?.facility_name[0]}
                maxLength={100}
              />
            ),
            rules: [
              { required: true, message: "This field is required" },
              { validator: validateClinicName },
            ],
            visibleCheckbox: null,
          },
          {
            label: "Speciality type",
            name: "facility_type",
            component: (
              <Input
                size="medium"
                placeholder="Enter Speciality Type"
                maxLength={30}
              />
            ),
            rules: formRules.requiredRule,
            visibleCheckbox: "speciality_type_visible",
          },
          {
            label: "Address",
            name: "address",
            component: (
              <TextArea
                size="medium"
                rows={4}
                showCount
                maxLength={300}
                placeholder="Enter Facility Address"
              />
            ),
            rules: [{ required: true, message: "This field is required" }],
            visibleCheckbox: "address_visible",
          },
          {
            label: "City",
            name: "city",
            component: (
              <Input size="medium" placeholder="Enter City" maxLength={30} />
            ),
            rules: formRules.requiredRule,
            visibleCheckbox: "city_visible",
          },
          {
            label: "State / Province",
            name: "state",
            component: (
              <Input
                size="medium"
                placeholder="Enter State/ Province"
                maxLength={30}
              />
            ),
            visibleCheckbox: "state_visible",
          },
          {
            label: "Zip Code / Postal Code",
            name: "zip_code",
            component: (
              <Input
                size="medium"
                placeholder="Enter Zip Code / Postal Code"
                maxLength={30}
              />
            ),
            rules: [{ validator: zipValidator }],
            visibleCheckbox: "zip_code_visible",
          },
          {
            label: "Country",
            name: "country",
            component: (
              <Input size="medium" placeholder="Enter Country" maxLength={30} />
            ),
            rules: formRules.requiredRule,
            visibleCheckbox: "country_visible",
          },
          {
            label: "Office Phone Number",
            name: "phone_number",
            component: <Input size="medium" placeholder="Enter Phone Number" />,
            rules: [
              { required: true, message: "Please enter Phone Number" },
              { validator: phoneValidator },
            ],
            visibleCheckbox: "phone_number_visible",
          },
          {
            label: "Fax Number",
            name: "fax_number",
            component: (
              <Input
                size="medium"
                placeholder="Enter Fax Number"
                maxLength={30}
              />
            ),
            visibleCheckbox: "fax_visible",
          },
          {
            label: "Business Email",
            name: "business_email",
            component: (
              <Input size="medium" placeholder="Email" maxLength={30} />
            ),
            rules: [{ validator: emailValidator }],
            visibleCheckbox: "business_email_visible",
          },
          {
            label: "Business Website",
            name: "website",
            component: (
              <Input
                size="medium"
                placeholder="Enter Website"
                maxLength={100}
              />
            ),
            // visibleCheckbox: "website_visible",
          },
          {
            label: "Additional Data 1",
            name: "additional_data_1",
            component: (
              <Input
                size="medium"
                placeholder="Enter Additional Data 1"
                maxLength={50}
              />
            ),
            visibleCheckbox: "additional_data_1_visible",
          },
          {
            label: "Additional Data 2",
            name: "additional_data_2",
            component: (
              <Input
                size="medium"
                placeholder="Enter Additional Data 2"
                maxLength={50}
              />
            ),
            visibleCheckbox: "additional_data_2_visible",
          },
          {
            label: "Additional Data 3",
            name: "additional_data_3",
            component: (
              <Input
                size="medium"
                placeholder="Enter Additional Data 3"
                maxLength={50}
              />
            ),
            visibleCheckbox: "additional_data_3_visible",
          },
        ].map(
          (
            { label, name, component, rules, tooltip, visibleCheckbox },
            index
          ) => (
            <Row gutter={16} key={index}>
              <Col span={16}>
                <Form.Item
                  label={label}
                  name={name}
                  rules={rules}
                  tooltip={tooltip}
                >
                  {component}
                </Form.Item>
              </Col>
              {visibleCheckbox && (
                <Col span={8}>
                  <Form.Item name={visibleCheckbox} valuePropName="checked">
                    <Checkbox className="mt-9" defaultChecked={false} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )
        )}

        <h1 className="text-sm mb-2 font-semibold"> Office hours</h1>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true, message: "Please Enter Start Time" }]}
            >
              <TimePicker use12Hours={false} format={format} />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[{ required: true, message: "Please Enter Closing Time" }]}
            >
              <TimePicker use12Hours={false} format={format} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Timezone"
              name="timezone"
              rules={[{ required: true, message: "Please Choose Timezone" }]}
            >
              <TimezoneSelector
                defaultValue={officeSetting && officeSetting?.facility_time_zone}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Time Format"
              name="time_format"
              rules={[{ required: true, message: "Please Choose Time Format" }]}
            >
              <Select placeholder="Time Format" style={{ borderRadius: "6px" }}>
                <Option value="h12">AM/ PM</Option>
                <Option value="h23">24 Hour</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Date Format" name="date_format">
              <Select placeholder="Date Format" style={{ borderRadius: "6px" }}>
                <Option value="en-GB">DD/MM/YYYY</Option>
                <Option value="en-US">MM/DD/YYYY</Option>
                <Option value="zh-CN">YYYY/MM/DD</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Currency" name="currency">
              <CurrencySelector/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Select Units"
              name="selected_unit"
              rules={[{ required: true, message: "Please Choose Units" }]}
            >
              <Select placeholder="Select units">
                <Option value="us">US System</Option>
                <Option value="metric">Metric System</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Local Tax Rate"
              name="local_tax_rate"
              rules={[
                { required: true, message: "Please Enter Local Tax Rate" },
                {validator: validateDecimalNumber},
              ]}
            >
              <Input size="medium" placeholder="Enter Local Tax Rate" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-start mb-10 mt-4">
          <Button className="mr-2" onClick={handleCancel}>
            {isModified ? "Cancel Changes" : "Cancel"}
          </Button>
          <BasicButton loading={saving} saving={!edit} title={edit ? "Update" : "Save"}/>
        </div>
      </Form>

      <OfficeLogo />
    </div>
  );
}

export default Basic_information;