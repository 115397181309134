import React from "react";
import { NumberFormatter } from "../../../../../utils/validator";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function ViewOutstandingBill({ outstandingBillData }) {

  return (
    <div>
      <p>
        <span className="font-medium">Added By: </span>
        {outstandingBillData?.added_by}
      </p>
      <p>
        <span className="font-medium">Date Added: </span>
        <FormatDateTime date={outstandingBillData?.date_time} type="datetime"/>
      </p>

      <div className="mt-3 bg-slate-100 p-3">
        <p>
          <span className="font-medium">Invoice ID: </span>
          {outstandingBillData?.invoice_id}
        </p>
        <p>
          <span className="font-medium">Description: </span>
          {outstandingBillData?.description}
        </p>
        <p>
          <span className="font-medium">Outstanding Amount: </span>
          <NumberFormatter
            value={outstandingBillData?.outstanding_amount}
            options={{ style: "decimal" }}
          />
        </p>
      </div>
      <p>
        <span className="font-medium">Status: </span>
        {outstandingBillData?.status}
      </p>

      <p>
        <span className="font-medium">Marked as Paid By: </span>
        {outstandingBillData?.mark_as_paid_by}
      </p>
      <p>
        <span className="font-medium">Paid Date: </span>
        {outstandingBillData?.mark_as_paid_by_date_datetime && <FormatDateTime date={outstandingBillData?.mark_as_paid_by_date_datetime} type="datetime"/>}
      </p>
    </div>
  );
}

export default ViewOutstandingBill;
