import { Divider, Drawer, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import ViewTemplate from "../../../Templates/components/ViewTemplate";


const {  Title } = Typography;
const VisitPad = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(true)
  const { officeSetting, patientBasicInfo ,providerDetails , getProviderDetails } = useStateGlobalContext();
  const full_name = `${patientBasicInfo?.first_name || ''} ${patientBasicInfo?.middle_name ? patientBasicInfo.middle_name + ' ' : ''}${patientBasicInfo?.last_name || ''}`.trim();


  useEffect(() => {
    if (data?.provider_id) {
      getProviderDetails(data?.provider_id);
      setLoading(false)
    }
  }, [data?.provider_id]);

  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
 
  return (
    <Drawer
      title={full_name}
      placement="bottom"
      height="100vh"
      footer={null}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        zIndex:999,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer"
    >
      <div className="invoices-print">
        <div className="invoice-container">
          <button className="guest-button" onClick={handlePrint}>
            Print Document
          </button>
          <div className="invoice-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Patient Encounter Notes {data?.status === 'Cancelled' && <span className="font-medium text-red-500">({data.status})</span>}</h2>
            <Header
              officeInfo={officeSetting}
              patientInfo={patientBasicInfo}
              date={data?.date_time}
              providerDetails={providerDetails}
            />
            <Divider />
            <Title className="text-center mt-4" level={5}>
              Encounter Notes
            </Title>
            <ViewTemplate template={data?.encounter_details}/>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default VisitPad;
