import React, { useState, useEffect } from "react";
import logo from "../../../assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import Notification from "../../../components/Notification/Notification";

const Verify_OTP = () => {
  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const {appLogoData, getAppLogo} = useStateGlobalContext()
  // const isTimerOver = remainingTime === 0;
  const navigate = useNavigate();
  const { id } = useParams();

  const onFinish = (values) => {
    setLoading(true);
    const result = axios
      .post(`${process.env.REACT_APP_API_URL}/user/verify-OTP/`, {
        otp: values.otp,
        user_id: id,
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          Notification.success("OTP verified successfully");
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Notification.error("OTP is incorrect");
        }
      });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleResetOTP = () => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/user/resend-OTP/${id}/`).then((res) => {
        console.log("res", res);
        Notification.success("OTP sent successfully");
        setRemainingTime(60);
      });
    } catch (err) {
      Notification.error("Something went wrong");
      console.log("err", err.response.data.detail);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(()=>{
    getAppLogo()
  },[])

  return (
    <div className="flex justify-center  h-screen">


      {/* <div className="absolute top-6 left-6">
          <img src={appLogoData?.icon || logo} alt="Logo" className="w-[200px]" />
        </div> */}

      {/* Right Side */}
      <div className="flex flex-col items-center justify-center">
      <div className="mb-10">
          <img src={appLogoData?.icon || logo} alt="Logo" className="w-[300px]" />
        </div>
        <Form
          layout="vertical"
          className="w-[400px]"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h1 className="text-xl mb-3 text-center">Verify OTP</h1>
          <Form.Item
            label="OTP"
            name="otp"
            rules={[
              {
                required: true,
                message: "Please enter your OTP sent on your email",
              },
            ]}
          >
            <Input placeholder="Enter OTP" />
          </Form.Item>

          <Button
            size="large"
            className="bg-[#3484F0] text-[#fff] w-full mb-2"
            type="primary"
            htmlType="submit"
          >
            Verify OTP
          </Button>
          <p>
            {remainingTime > 0 ? `Resend OTP in ${remainingTime} seconds` : ""}
          </p>
          <div className="text-center mt-2">
              <Button onClick={handleResetOTP} size="small">
                Resend OTP
              </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Verify_OTP;
