import React, { useState, useEffect } from "react";
import { Table, DatePicker, Button, Form, Card, Tooltip } from "antd";
import { doGet } from "../../../../../API/apis";
import { CSVLink } from "react-csv";
import ProductsDrawer from "../../../PatientSummary/InvoiceAndBilling/Invoices/component/Product/ProductsDrawer";
import { useOutletContext } from "react-router-dom";
import { formatDate, formatTime } from "../../../../../utils/DateFormat";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { GetColumnSearchProps } from "../../../../../components/TableSearch/TableSearch";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const { RangePicker } = DatePicker;

const showProducts = (products) => {
  if (!products || products.length === 0) {
    return null;
  }
  
  const productNames = products.map(product => product.items).join(', ');
  return productNames;
};

const showQuantity = (products) => {
  if (!products || products.length === 0) {
    return null;
  }
  
  const productQuantity = products.map(product => product.quantity).join(', ');
  return productQuantity;
};

const showUnitPrice = (products) => {
  if (!products || products.length === 0) {
    return null;
  }
  
  const productQuantity = products.map(product => product?.unit_price).join(', ');
  return productQuantity;
};

const showPrice = (products) => {
  if (!products || products.length === 0) {
    return null;
  }
  
  const productQuantity = products.map(product => product?.price).join(', ');
  return productQuantity;
};






const ProductReports = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [visible, setVisible] = useState(false)
  const [viewData, setViewData] = useState([])
  const [officeDateFormat, officeTimeFormat, officeTimezone] = useOutletContext()
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  
const transformedData = (records)=>{
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => ({
    // ID: record?.id,
    "First Name": record?.patient_first_name,
    "Middle Name": record?.patient_middle_name,
    "Last Name": record?.patient_last_name,
    "MRN Number": record.mrn_number,
    "Products": showProducts(record?.product_details),
    "Quantity": showQuantity(record?.product_details),
    "Unit Price": showUnitPrice(record?.product_details),
    "Sub Total": showPrice(record?.product_details),
    "Added By": record?.added_by,
    "Issue Date": formatDate(record?.issue_date, officeDateFormat, officeTimezone),
    "Issue Time": formatTime(record?.issue_date, officeTimeFormat, officeTimezone),
    "Notes": record?.notes,
    "Grand Total": record?.total_price,
    "Payment Method": record?.payment_method,
    "Status": record?.status,
  }));
}


  const coloumn = [
    {
      key: 1,
      title: "#",
      align:'center',
      render: (_, record, index) => index + 1,
    },
    {
      key: 2,
      title: "Invoice ID",
      align:'center',
      dataIndex:'invoice_id',
      ...GetColumnSearchProps('invoice_id'),
      render: (text, record)=> <Button type="link" onClick={()=>viewInvoiceDetail(record)}> {text}</Button>
    },
    {
      key: 3,
      title: "Patient Name",
      align:'center',
      render: (_, record) => (
        <span>
          {record?.patient_first_name} {record?.patient_middle_name ? record?.patient_middle_name : " "}{" "}
          {record?.patient_last_name}
        </span>
      ),
    },
    {
      key: 4,
      title: "Products",
      dataIndex:'product_details',
      render: (text)=> showProducts(text)
    },
    {
      key: 10,
      title: "Quantity",
      dataIndex:'product_details',
      render: (text)=> showQuantity(text)
    },
    {
      key: 5,
      title:'Total Price',
      align:'center',
      dataIndex:'total_price',
    },
    {
      key: 6,
      title:'Added By',
      align:'center',
      dataIndex:'added_by'
    },
    {
      key:"date_time",
      title:"Date",
      align:'center',
      dataIndex:"issue_date",
      render:(text)=> (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      )
    },
    {
      key: 7,
      title:'Notes',
      dataIndex:'notes'
    },
    {
      key: 8,
      title:'Payment Method',
      align:'center',
      dataIndex:'payment_method'
    },
    {
      key:9,
      title:'Status',
      align:'center',
      dataIndex:'status'
    },
  ];

  const viewInvoiceDetail = (record)=>{
    setVisible(true)
    setViewData(record)
  }

  const onClose = ()=>{
    setVisible(false)
  }

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getInventoryReports();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getInventoryReports= async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/product-invoice/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getInventoryReports()
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <Card className="shadow-class mb-4 mt-4">
        <Form layout="vertical">
          <div className="flex items-center">
            <Form.Item label="Select Date Range">
              <RangePicker
                size="medium"
                onChange={handleDateRangeChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button type="primary" className="ml-2 mt-2" onClick={handleSearch}>
              Search
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setData([])}>
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {role !== 'nurse' && role !=='doctor' && data?.length > 0 && <Button type="primary">
            <CSVLink
              data={transformedData(data)}
              filename={"Inventory-reports.csv"}
              className="csv-link"
            >
              Download Report
            </CSVLink>
          </Button>}
        </div>
        <Table
          loading={loading}
          columns={coloumn}
          dataSource={data}
          size="small"
          scroll={{
            x: 1500,
          }}
          pagination={false}
        />
      </Card> 

      <ProductsDrawer visible={visible} data={viewData} onClose={onClose}/>
    </div>
  );
};

export default ProductReports;
