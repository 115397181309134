import { Drawer, Table } from "antd";
import React, { useRef } from "react";
import "./prescriptionpad.css";
import { useReactToPrint } from "react-to-print";
import ClinicInformation from "../../../../components/PrescriptionsPad/ClinicInformation";

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Product Name",
    dataIndex: "items",
  },
  {
    key: 3,
    title: "Quanity",
    dataIndex: "quantity",
  },
  {
    key: 4,
    title: "Price",
    dataIndex: "price",
  },
];

const PrescriptionPad = ({ visible, onClose, rxData, clinic_information }) => {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  return (
    <Drawer
      title={rxData?.patient_name}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      placement="bottom"
      height="100vh"
      className="prescription-drawer"
    >
      <div className="prescription-print">
        <div className="guest-container h-[90vh] w-[800px]" ref={contentRef}>
          <button onClick={handlePrint} className="guest-button">
            Print Document
          </button>
          <div className="guest-header">
          <h2 className="text-center mt-4 mb-4">Invoice</h2>
          <div className="flex justify-between">
            <div>
              <h3>Clinic Information</h3>
              {ClinicInformation(clinic_information)}
            </div>
            <div>
              <h3>Customer Information </h3>
            </div>
          </div>
          </div>
          <hr className="border-1 mb-2" />
          <div className="print-content">
            <h4>Patient Information</h4>
            <p className="mb-2">
              <span className="font-medium">Name:</span> {rxData?.patient_name}
            </p>
            <p className="mb-2">
              <span className="font-medium">Status:</span> {rxData?.status}
            </p>
            <p className="font-bold">Medication</p>
            <Table
              className="mt-2 mb-10"
              size="small"
              columns={coloumn}
              dataSource={rxData?.product_details}
              pagination={false}
            />

            <div className="flex flex-col items-end p-2 prescription-total">
              <p className="mb-2">
                <span className="font-medium mr-4">Tax: </span>
                {rxData?.price}
              </p>
              <p className="mb-2">
                <span className="font-medium mr-4">Tax: </span>
                {rxData?.discount}%
              </p>
              <p className="mb-2">
                <span className="font-medium mr-4">Tax: </span>
                {rxData?.tax}
              </p>
              <p>
                <span className="font-medium mr-4">Grand Total: </span>{" "}
                {rxData?.grand_total}
              </p>
            </div>
            <p className="guest-notes font-medium">Notes:</p>
            <p>{rxData?.notes}</p>

            <div className="flex justify-end flex-col items-end mt-10 signature">
              <p>_______________________________</p>
              <h3>Signature</h3>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PrescriptionPad;
