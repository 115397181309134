import React, { useState, useEffect } from 'react';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const FullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(document.fullscreenElement !== null);

  const toggleFullScreen = () => {
      if (isFullScreen) {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.webkitExitFullscreen) { // Safari-specific
              document.webkitExitFullscreen();
          }
      } else if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Safari-specific
          document.documentElement.webkitRequestFullscreen(); 
      } else {
          alert("Fullscreen is not supported on this browser.");
      }
  };

  useEffect(() => {
      const onFullScreenChange = () => {
          setIsFullScreen(document.fullscreenElement !== null || document.webkitFullscreenElement !== null); // Check for both
      };

      document.addEventListener('fullscreenchange', onFullScreenChange);
      document.addEventListener('webkitfullscreenchange', onFullScreenChange); // Safari-specific

      return () => {
          document.removeEventListener('fullscreenchange', onFullScreenChange);
          document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
      };
  }, []);

    return (
        <div className="flex items-center p-2 rounded-full">
            {isFullScreen ? (
                <FullscreenExitOutlined
                    className="p-2 rounded-full"
                    style={{ fontSize: "20px", color: "#1890ff" }}
                    onClick={toggleFullScreen}
                />
            ) : (
                <FullscreenOutlined
                    className="p-2 rounded-full"
                    style={{ fontSize: "20px", color: "#1890ff" }}
                    onClick={toggleFullScreen}
                />
            )}
        </div>
    );
};

export default FullScreen;
