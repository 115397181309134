import React, { useEffect, useState } from "react";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
import { Button, Modal, Input, Form, Typography } from "antd";
import { doPatch } from "../../../../../API/apis";
import Notification from "../../../../../components/Notification/Notification";

const {Paragraph} = Typography

const Credentials = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { patientBasicInfo, getPatientData } = useStateGlobalContext();



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handlePasswordChange = async (values) => {
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Password Changed Successfully");
        await getPatientData(id);
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async (id) => {
      setLoading(true);
      await getPatientData(id);
      setLoading(false);
    };

    fetchData(id);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <h1 className="text-[1.125rem] font-semibold">Credentials</h1>
      <div>
        <div>
          Login ID: <Paragraph copyable>{patientBasicInfo?.mrn_number}</Paragraph>
        </div>
        <div>
          Password:{" "}
          <span>
              <Paragraph>{patientBasicInfo?.password.replace(/./g, "*")}</Paragraph>
          </span>
        </div>
        <Button className="mt-2" type="primary" onClick={showModal}>
          Reset Password
        </Button>
        <Modal
          title="Reset Password"
          width={376}
          open={isModalVisible}
          closeIcon={
            <Button size="small" className="app-close-icon" shape="round">Close</Button>
          }
          footer={null}
          onCancel={handleCancel}
        >
          <Form layout="vertical" form={form} onFinish={handlePasswordChange}>
            <Form.Item name="password" label="New Temporary Password">
              <Input.Password type="password" />
            </Form.Item>
            <div className="flex justify-end">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" className="ml-2" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Credentials;
