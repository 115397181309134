import React, { useState} from "react";
import { Button, Form, Input, Col, Row, Radio } from "antd";
import { doPost } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { validateDecimalNumber } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";



function IdealWeightCalculator({ onSave, onCancel , metric }) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [idealWeight, setIdealWeight] = useState(null);

  const handleCancel = ()=>{
    onCancel()
    form.resetFields()
    setIdealWeight(null)
  }


  const calculateIdealWeight = () => {
    const height = form.getFieldValue("height");
    const gender = form.getFieldValue("gender");

    // Robinson formula
    let heightInInches;
    let idealWeightInKg;
    if (metric) {
      heightInInches = parseFloat(height) * 0.393701;
      if (gender === "male") {
        idealWeightInKg = 52 + 1.9 * (heightInInches - 60);
      } else {
        idealWeightInKg = 49 + 1.7 * (heightInInches - 60);
      }
    } else {
      heightInInches = height;
      if (gender === "male") {
        idealWeightInKg = 52 + 1.9 * (heightInInches - 60);
      } else {
        idealWeightInKg = 49 + 1.7 * (heightInInches - 60);
      }
    }
    setIdealWeight(
      metric
        ? idealWeightInKg.toFixed(2)
        : (idealWeightInKg * 2.20462).toFixed(2)
    );
  };

  const handleIdealWeight = async (values) => {
    const { height, gender } = values;
    const data = {
      idealweight_height: height,
      idealweight_gender: gender,
      idealweight: idealWeight,
    };
    try {
      const response = await doPost(
        `/patient/add-ideal-weight/${id}/`,
        data,
      );
      if (response.status === 201) {
        Notification.success("Ideal Weight Added Successfully");
        onSave();
        form.resetFields();
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleIdealWeight}>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please select your gender!" }]}
        >
          <Radio.Group defaultChecked="male">
            <Radio value="male">Male</Radio>
            <Radio value="female">Female</Radio>
          </Radio.Group>
        </Form.Item>
        <Row gutter={16} className="mt-[-1rem]">
          <Col span={12}>
            <Form.Item
              name="height"
              label={metric ? "Height(cm)" : "Height(in)"}
              className="mt-2"
              rules={[
                { required: true, message: "Please Enter height!" },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Height" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                className="mt-[2.3rem]"
                onClick={calculateIdealWeight}
              >
                Calculate
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {idealWeight !== null && (
          <p className="mt-[-0.2rem] font-semibold">
            Patient's ideal weight is {idealWeight} {metric ? "Kg" : "lb"}.
          </p>
        )}
        <div className="flex justify-end">
          <Button onClick={handleCancel}> Cancel </Button>
          <Button
            type={!idealWeight ? "default" : "primary"}
            htmlType="submit"
            className="ml-2"
            disabled={idealWeight === null}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default IdealWeightCalculator;
