import { Drawer, Table} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import InvoiceHeader from "../../../../../../../../components/InvoiceHeader/InvoiceHeader";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import "./serviceInvoicepad.css";
import Notification from "../../../../../../../../components/Notification/Notification";
import InvoiceFooter from "../../InvoiceFooter/InvoiceFooter";
import { NumberFormatter } from "../../../../../../../../utils/validator";

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Service Name",
    dataIndex: "service_name",
  },
  {
    key: 3,
    title: "QTY",
    render: () => <span>1</span>,
  },
  {
    key: 4,
    title: "Unit Price",
    dataIndex: "price",
    align:'center',
    render: (text)=> <NumberFormatter value={text}/>
  },
  {
    key: 5,
    title: "Total Price",
    dataIndex: "price",
    align:'center',
    render: (text)=> <NumberFormatter value={text}/>
  },
];

const ServiceInvoicePad = ({ visible, onClose, rxData }) => {
  const { id } = useParams();
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    officeSetting,
    getOfficeSettings,
    patientBasicInfo,
    getPatientData,
    billingInformation,
    getBillingInformation,
  } = useStateGlobalContext();



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getOfficeSettings(),
          getPatientData(id),
          getBillingInformation(),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <Drawer
      title={rxData?.patient_name}
      placement="bottom"
      height="100vh"
      footer={null}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer"
    >
      <div className="invoices-print">
        <div className="invoice-container">
          <button className="guest-button" onClick={handlePrint}>
            Print Document
          </button>
          <div className="invoice-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Invoice</h2>
            <InvoiceHeader
              officeInformation={officeSetting}
              patientInformation={patientBasicInfo}
              rxData={rxData}
              billingInformation={billingInformation}
            />
            <Table
              columns={coloumn}
              size="small"
              dataSource={[rxData]}
              pagination={false}
            />
            <div className="flex justify-between mt-4">
              <div></div>
              <InvoiceFooter subTotal={rxData?.price} discount={rxData?.discount} discountAmount={rxData?.discounted_amount} tax={rxData?.tax} grandTotal={rxData?.grand_total_amount}/>
            </div>


          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ServiceInvoicePad;
