import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useStateGlobalContext } from '../../contexts/GlobalContext';

// Extend dayjs with advancedFormat
dayjs.extend(advancedFormat);

const FormatDate = ({ date }) => {
  const { officeSetting } = useStateGlobalContext();

  if (!date) return null;

  // Determine the date format from office settings
  const officeDateFormat = officeSetting?.date_format === 'en-US' 
    ? 'MM/DD/YYYY' 
    : officeSetting?.date_format === 'en-GB' 
      ? 'DD/MM/YYYY' 
      : 'YYYY/MM/DD';

  const formattedDate = dayjs(date).format(officeDateFormat);

  return <>{formattedDate}</>;
};

export default FormatDate;
