import { Button, Modal } from "antd";
import React from "react";
import { formatDate } from "../../../../../utils/DateFormat";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const ViewReferralNotes = ({ open, onClose, data }) => {
  return (
    <Modal
      title={data?.referral_id}
      open={open}
      onCancel={onClose}
      footer={null}
      width={750}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
    >
        <p>Added By: {data?.added_by}</p>
        <p>Date Added: <FormatDateTime date={data?.date_time} type="datetime"/></p>
        <p>Status: {data?.status}</p>

        <div className="bg-gray-200 p-4">
            <p>Start Date: {data?.start_date && <FormatDate date={data?.start_date}/>}</p>
            <p>Expiry Date: {data?.end_date && <FormatDate date={data?.end_date}/>}</p>
            <p>Authoriztion Code: {data?.auth_code}</p>

            <hr className="my-2 bg-grey-400"/>
            <h4>Referring From:</h4>
            <p>Name: {data?.referral_from_name}</p>
            <p>Speciality: {data?.referral_from_speciality}</p>
            <p>Phone: {data?.referral_from_phone}</p>
            <p>Fax: {data?.referral_from_fax}</p>
            <p>Email: {data?.referral_from_email}</p>

            <hr className="my-2 bg-grey-400"/>
            <h4>Referring To:</h4>
            <p>Name: {data?.referral_to_full_name}</p>
            <p>Facility Name: {data?.referral_to_facility_name}</p>
            <p>Speciality: {data?.referral_to_speciality}</p>
            <p>Phone: {data?.referral_to_phone}</p>
            <p>Fax: {data?.referral_to_fax}</p>
            <p>Email: {data?.referral_to_email}</p>
            <p>Address: {data?.referral_to_address}</p>
            <p>Opening Hours: {data?.referral_to_operating_hours}</p>

            <hr className="my-2 bg-grey-400"/>
            <h4>Patient Insurance Information:</h4>
            <p>Type: {data?.insurance_type}</p>
            <p>Company: {data?.insruance_company}</p>
            <p>Plan: {data?.insurance_plan}</p>
            <p>Group: {data?.insurance_group}</p>
            <p>Policy: {data?.insurance_policy}</p>

            <hr className="my-2 bg-grey-400"/>
            <h4>Clinical Notes:</h4>
            <p>Referral For Reason: {data?.reason_for_referral}</p>
            <p>Patient Diagnosis: {data?.patient_diagnostic_code}</p>
            <p>Clinical Notes: {data?.instructions}</p>
            <hr className="my-2 bg-grey-400"/>
            <h4>Internal Notes:</h4>
            <p>{data?.other_notes}</p>
        </div>
    </Modal>
  );
};

export default ViewReferralNotes;
