import React from "react";
import { Modal, Form, Input, Button} from "antd";
import { validatePassword } from "../../utils/validator";
import { doPost } from "../../API/apis";
import { clearSession, setSession } from "../../customHooks/index";
import Notification from "../Notification/Notification";

const ChangePasswordModal = ({ open, onClose, showCancel, showLogout }) => {
  const [form] = Form.useForm();

  const onCancel = () => {
    onClose();
    setSession({ changePassword: false });
    form.resetFields();
  };

  const handleChangePassword = async (values) => {
    const data = {
      old_password: values.current_password,
      new_password: values.new_password,
    };
    try {
      const response = await doPost(`/user/change-password/`, data);
      if (response.status === 200) {
        Notification.success("Password Updated Successfully");
        onCancel();
      } else {
        Notification.error("Current Password is Incorrect");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Change Password"
      open={open}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
      width={376}
      maskClosable={false}
      closable={showCancel ? true : false}
      onCancel={onClose}
    >
      <p className="mb-2">For Security Reason New Password is required after 90 days</p>
      <Form layout="vertical" form={form} onFinish={handleChangePassword}>
        <Form.Item
          name="current_password"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please enter your current password!",
            },
          ]}
        >
          <Input.Password placeholder="Current Password" maxLength={30} />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="New Password"
          rules={[
            { validator: validatePassword },
            { required: true, message: "Please enter your new password!" },
          ]}
        >
          <Input.Password placeholder="New Password" maxLength={30} autoComplete="false"/>
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Confirm New Password"
          dependencies={["new_password"]}
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <div className={`flex ${showLogout ? 'justify-between' : (showCancel ? 'justify-end' : 'justify-center')}`}>
          {showLogout && <Button onClick={()=>clearSession()}>Logout</Button>}
          <div>
          {showCancel && <Button onClick={onCancel} className="mr-2">Cancel</Button>}
          <Button type="primary" htmlType="submit" className={`w-${showCancel ? 'auto' : 'full'}`}>
            Save
          </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
