import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";


const radioOptions = [ 
    { label: "Self-Pay", value:'Self-Pay' },
    { label: "Membership" , value: 'Membership'},
    { label: "Insurance", value:'Insurance' },
  ];

const PaymentPreference = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false)
  const { patientBasicInfo, getPatientData, loading } = useStateGlobalContext();

  const {
    payment_services,
    payment_services_notes
  } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {

        form.setFieldsValue({
          payment_services,
          payment_services_notes
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = ()=>{
    setEdit(true)
  }

  const onEditCancel = ()=>{
    setEdit(false)
  }

  const handleUpdate = async (values)=>{
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id); 
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
      <h1 className="text-[1.125rem] font-bold mb-4">Payment Services</h1>
      {edit ? <Button onClick={onEditCancel}>Close</Button> : <Button onClick={onEdit}>Edit</Button> }
      </div>

      { edit ? (
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <Form.Item label="Payment Preference" name='payment_services'>
            <Radio.Group
              defaultValue="a"
              className="payment-method"
              buttonStyle="solid"
            >
              {radioOptions.map((option, index) => (
                <Radio.Button key={index} value={option.value}>
                  <span className="flex flex-row justify-center  items-center gap-2">
                    <p className="text-lg">
                      {option.label}
                    </p>
                  </span>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>



        <Form.Item label="Notes" name="payment_services_notes">
          <Input.TextArea placeholder="Notes..." maxLength={300} showCount/>
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={onEditCancel}>Cancel Changes</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Save Changes
          </Button>
        </div>
      </Form>
      ) : (
      <div className="view-wrapper">
        <LabelDisplay title="Payment Method" description={payment_services}/>
        <LabelDisplay title="Notes" description={payment_services_notes} className="notes"/>
      </div>
      )}
    </div>
  );
};

export default PaymentPreference
