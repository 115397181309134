import React, { useState, useEffect } from "react";
import { Table, DatePicker, Button, Form, Card } from "antd";
import { doGet } from "../../../../../API/apis";
import { CSVLink } from "react-csv";
import Notification from "../../../../../components/Notification/Notification";

const { RangePicker } = DatePicker;
const coloumn = [
  {
    key: "id",
    title: "#",
    align:'center',
    render: (_, record, index) => index + 1,
  },
  {
    key: 1,
    title: "MRN Number",
    align:'center',
    dataIndex:'mrn_number'
  },
  {
    key: 2,
    title: "Patient Name",
    dataIndex:"first_name",
    // ...GetColumnSearchProps('first_name'),

    render: (_, record) => (
      <span>
        {record?.first_name} {record?.middle_name ? record?.middle_name : " "}{" "}
        {record?.last_name}
      </span>
    ),
  },
  {
    key: 3,
    title: "Total Visits",
    align:'center',
    dataIndex:'total_visits',
    sorter: (a, b) => a.total_visits - b.total_visits,
    showSorterTooltip : false
  },
];


const transformedData = (records)=>{
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => ({
    // ID: record?.id,
    "First Name": record?.first_name,
    "Middle Name": record?.middle_name,
    "Last Name": record?.last_name,
    "MRN Number": record.mrn_number,
    "Total Visits": record?.total_visits,
  }));
}

const MostVisited = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getMostVisits();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getMostVisits= async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/most-visits/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          const mostVisits = response.data.sort((a, b) => {
            return b.total_visits - a.total_visits;
          });
          setData(mostVisits);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getMostVisits()
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <h1 className="font-bold text-lg mb-2">Most Visits Report </h1>
      <Card className="shadow-class mb-4 mt-4">
        <Form layout="vertical">
          <div className="flex items-center">
            <Form.Item label="Select Date Range">
              <RangePicker
                size="medium"
                onChange={handleDateRangeChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button type="primary" className="ml-2 mt-2" onClick={handleSearch}>
              Search
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setData([])}>
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {data?.length > 0 && <Button  type="primary">
            <CSVLink
              data={transformedData(data)}
              filename={"Most-visits-report.csv"}
              className="csv-link"
            >
              Download Report
            </CSVLink>
          </Button>}
        </div>
        <Table
          loading={loading}
          columns={coloumn}
          dataSource={data}
          size="small"
          scroll={{
            x: 1500,
          }}
        />
      </Card>
    </div>
  );
};

export default MostVisited;
