import { Button, Modal } from 'antd';
import React from 'react';
import FormatDateTime from '../../../../../../components/FormatDate/FormatDateTime';
import FormatDate from '../../../../../../components/FormatDate/FormatDate';
import LabelDisplay from '../../../../../../components/LabelDisplay/LabelDisplay';
import { NumberFormatter } from '../../../../../../utils/validator';

const InsuranceClaimsView = ({ visible, data, onClose }) => {
  return (
    <Modal
      title="Insurance Claim"
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <LabelDisplay title="Added By" description={data?.added_by} />
      <LabelDisplay title="Date Added" description={<FormatDateTime date={data?.added_date} type="datetime" />} />
      <LabelDisplay title="Status" description={data?.status} />
      <div className="bg-gray-100 p-4 mt-2">
        <LabelDisplay title="Insurance Type" description={data?.insurance_type} />
        <LabelDisplay title="Insurance Company Name" description={data?.insurance_company_name} />
        <LabelDisplay title="Primary Insurer Full Name" description={data?.primary_account_full_name} />
        <LabelDisplay title="Member ID" description={data?.members_id} />
        <LabelDisplay title="Claim Date" description={<FormatDate date={data?.claim_date} />} />
        <LabelDisplay title="Claim Tracking Number" description={data?.claim_tracking_number} />
        <LabelDisplay title="Claim Officer Full Name" description={data?.claim_officer_full_name} />
        <LabelDisplay title="Claim Officer Phone" description={data?.claim_officer_phone_number} />
        <LabelDisplay title="Claim Officer Fax" description={data?.claim_officer_fax} />
        <LabelDisplay title="Claim Officer Email" description={data?.claim_officer_email} />
        <LabelDisplay title="Service Type" description={data?.service_description} />
        <LabelDisplay title="Patient Copay" description={<NumberFormatter value={data?.patient_copay}/>} />
        <LabelDisplay title="Service Provider" description={data?.service_provider} />
        <LabelDisplay title="Procedure Codes" description={data?.procedure_codes} />
        <LabelDisplay title="Diagnosis Code" description={data?.diagnosis_code} />
        <LabelDisplay title="Billed To Insurance" description={<NumberFormatter value={data?.billed}/>} />
        <LabelDisplay title="Payment Received" description={<NumberFormatter value={data?.paid}/>} />
        <LabelDisplay title="Outstanding" description={<NumberFormatter value={data?.outstanding}/>} />
        <LabelDisplay title="Notes" description={data?.notes} />
      </div>
      <LabelDisplay title="Status Changed By" description={data?.status_changed_by} className="mt-4" />
      <LabelDisplay title="Status Changed Date" description={data?.status_change_date && <FormatDateTime date={data?.status_change_date} type="datetime" />} />
    </Modal>
  );
};

export default InsuranceClaimsView;
