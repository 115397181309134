import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Drawer,
  Form,
  Input,
  Modal,
  Dropdown,
  Card,
  Tooltip,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { doPatch, doGet, doPost, doDelete } from "../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import ViewOutstandingBill from "./ViewOutstandingBill";
import {  NumberFormatter,  validatePrice } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Mark as Paid",
  },
  {
    key: 4,
    label: "Delete",
  },
];



const OutstandingBills = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [outstandingBillView, setOutstandingBillView] = useState(false);
  const { patientBasicInfo, getOutstandingBillInfo } = useStateGlobalContext();
  const {
    first_name,
    middle_name,
    last_name,
  } = patientBasicInfo || {};

  const fullName = `${first_name} ${
    middle_name ? middle_name : ""
  } ${last_name}`;


  const columns = [
    {
      title: "#",
      key: "#",
      align:'center',
      width:'5%',
      render : (text, record, index)=> index + 1 
    },
    {
      title: "Invoice ID#",
      dataIndex: "invoice_id",
      key: "invoice_id",
      align: "center",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },

    {
      title: "Amount",
      dataIndex: "outstanding_amount",
      key: "outstanding_amount",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (text) => (
        <>
          <NumberFormatter value={text} options={{ style: "decimal" }} />
        </>
      ),
    },
    {
      title: "Created By",
      dataIndex: "added_by",
      key: "added_by",
      align: "center",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Created Date",
      dataIndex: "date_time",
      align: "center",
      width: "10%",
      key: "date_time",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (text) => {
        let bgColor =""
        let textColor= ""
        switch(text){
          case "Paid":
            bgColor="bg-blue-200"
            textColor="text-blue-500"
            break;
          case "unpaid":
            bgColor="bg-gray-200"
            textColor="text-gray-500"
            break;
          default:
            bgColor="bg-grau-200"
            textColor="text-gray-500"
            break;
        }
        return(
          <span className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}>
              {text ==='unpaid'? "Unpaid" : text}
          </span>
        )
      }
    },
    {
      title: "Action",
      key: "action",
      width: "7%",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Paid"
            ? items.filter((item) => [1].includes(item.key))
            : items.filter((item) => [1, 2, 3, 4].includes(item.key));
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handleDelete(record.id);
                  } else if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleStatus(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setEditMode(false)
    form.resetFields();
  };

  const handleView = (record) => {
    setSelectedItem(record);
    setOutstandingBillView(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteOutstandingBill(record);
      },
    });
  };

  const deleteOutstandingBill = async (record) => {
    try {
      const response = await doDelete(
        `/bill/outstanding-bill-delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Outstanding Bill deleted successfully");
        getOutstandingBills();
        getOutstandingBillInfo(id)
        onClose()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({
      invoice_id: record?.invoice_id,
      description: record?.description,
      outstanding_amount: record?.outstanding_amount,
    });
    setVisible(true);
  };

  const handleStatus = async (record) => {
    let data = {
      status: "Paid",
    };
    const response = await doPatch(
      `/bill/outstanding-bill-update/${id}/${record.id}/`,
      data
    );
    if (response.status === 200) {
      Notification.success("Status udpated Successfully");
      getOutstandingBills();
      getOutstandingBillInfo(id)
    }
  };

  const handleOutstandingBill = async (values) => {
    setIsSubmitting(true)
    const data = {
      invoice_id: values.invoice_id,
      description: values.description,
      outstanding_amount: values.outstanding_amount,
    };
    const url = editMode
      ? `/bill/outstanding-bill-update/${id}/${selectedItem?.id}/`
      : `/bill/outstanding-bill-add/${id}/`;
    try {
      const response = await (editMode ? doPatch(url, data) : doPost(url, data));
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode ? "Outstanding Bill updated successfully" : "Outstanding Bill Created Successfully";
        Notification.success(successMessage);
        getOutstandingBills();
        getOutstandingBillInfo(id);
        onClose();
      }
    } catch (error) {
      Notification.error("Failed to handle outstanding bill");
    } finally{
      setIsSubmitting(false)
    }
  };

  const getOutstandingBills = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/bill/outstanding-bill-get/${id}/`
      );
      if (response.status === 200) {
        setData(response?.data.reverse());
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false)
    }
  };



  useEffect(() => {
    getOutstandingBills();
    getOutstandingBillInfo(id)
    // getPatientData(id);
  }, [id]);

  return (
    <Card className="shadow-sm">
      <div className="flex justify-between items-center mb-2">
        <h1>Outstanding Bills</h1>
        <Button type="primary" onClick={showDrawer}>
          Create Bill
        </Button>
      </div>
      <Table loading={loading} columns={columns} dataSource={data} size="small" pagination={false}/>
      <Drawer
        title={editMode? "Update OUtstanding Bill" : "New Outstanding Bill"}
        placement="right"
        onClose={onClose}
        open={visible}
        width="578px"
        maskClosable={false}
        closeIcon={
          <Button size="small" className="drawer-close-icon" shape="round">Close</Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleOutstandingBill}>
          <div className="border border-slate-200 rounded-lg p-4 shadow-sm">
            <p>
              <span className="font-medium">Bill to: </span>
              <span>{fullName}</span>
            </p>
            <Form.Item name="invoice_id" label="Invoice ID"
              rules={[{
                required:true,
                message:"Please Enter Invoice ID"
              }]}
            >
              <Input placeholder="Enter Invoice ID" maxLength={30} />
            </Form.Item>
            <Form.Item name="description" label="Description"
              rules={[{
                required:true,
                message:'Please Enter Description'
              }]}
            >
              <Input.TextArea
                placeholder="Enter description"
                maxLength={300}
                rows={4}
                showCount
              />
            </Form.Item>
            <Form.Item
              name="outstanding_amount"
              label="Amount"
              rules={[
                {
                  validator: validatePrice,
                },
                {
                  required: true,
                  message: "Please Enter Amount",
                },
              ]}
            >
              <Input placeholder="Enter Amount" maxLength={10} />
            </Form.Item>
          </div>
          <div className="mt-3 flex justify-between">
          <div>
            {editMode && (<Button  className="danger__button" onClick={()=> handleDelete(selectedItem?.id)}>
              Delete
            </Button>)}
            </div>
            <div>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={isSubmitting}>
            {isSubmitting
                ? editMode
                  ? "Updating..."
                  : "Saving..."
                : editMode
                ? "Update"
                : "Save"}
            </Button>
            </div>
          </div>
        </Form>
      </Drawer>
      <Modal
        title="Outstanding Bill"
        open={outstandingBillView}
        footer={null}
        onCancel={() => {
          setOutstandingBillView(false);
        }}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        <ViewOutstandingBill outstandingBillData={selectedItem} />
      </Modal>
    </Card>
  );
};

export default OutstandingBills;
