export const ageCalculator = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age -= 1;
  return age;
};


export const yearCalculator = (startDate, endDate = new Date()) => {
  const start_date = new Date(startDate);
  const end_date = endDate ? new Date(endDate) : new Date(); // Use current date if endDate is null

  let calcYears = end_date.getFullYear() - start_date.getFullYear();
  let monthDiff = end_date.getMonth() - start_date.getMonth();
  let dayDiff = end_date.getDate() - start_date.getDate();

  // Adjust for the current day
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    calcYears--;
  }

  return calcYears;
};
