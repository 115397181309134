import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import BasicButton from "../../../../../components/BasicButton/BasicButton";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";

function ImportantNotes() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [importantNotesModalVisible, setImportantNotesModalVisible] = useState(false);
  const {submitLoading, setSubmitLoading} = useStateGlobalContext()

  const handleCancel = () => {
    setVisible(false);
    setEditMode(false);
    setImportantNotesModalVisible(false);
    form.resetFields();
  };

  const openEditModal = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({ important_notes: record.important_notes });
    setVisible(true);
  };

  const openAddModal = () => {
    setVisible(true);
  };

  const openDetailsModal = (record) => {
    setSelectedItem(record);
    setImportantNotesModalVisible(true);
  };

  const fetchImportantNotes = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-important-notes/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImportantNotesSubmit = async (values) => {
    const data = { important_notes: values.important_notes };
    setSubmitLoading(true);
    try {
      const response = editMode
        ? await doPatch(`/patient/update-important-notes/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/patient/add-important-notes/${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode ? "Important Notes updated successfully" : "Important Notes added successfully";
        Notification.success(successMessage);
        fetchImportantNotes();
        handleCancel();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally{
      setSubmitLoading(false)
    }
  };

  const handleDelete = async (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      className:"custom-modal",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const response = await doDelete(`/patient/delete-important-notes/${id}/${record}/`);
          if (response.status === 200) {
            Notification.success("Important Notes deleted successfully");
            fetchImportantNotes();
          } else {
            Notification.error("Something went wrong");
          }
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      },
    });
  };

  useEffect(() => {
    fetchImportantNotes();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px] max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Important Notes (Internal)" />
          <Button type="primary" onClick={openAddModal} size="small" shape="round">
            Add <PlusOutlined />
          </Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px] overflow-auto mt-1">
            <ul>
              {data.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span onClick={() => openDetailsModal(item)} title={item.important_notes}>
                    {index + 1}. {item.important_notes.slice(0, 26)}
                    {item.important_notes.length > 26 ? "..." : ""}
                  </span>
                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <EditOutlined className="text-[#1890ff] mr-2" onClick={() => openEditModal(item)} />
                      <DeleteOutlined className="text-[#1890ff]" onClick={() => handleDelete(item.id)} />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No Important Notes</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Important Notes (Internal)"
        open={visible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<Button size="small" className="app-close-icon" shape="round">Close</Button>}
        maskClosable={false}
        width={650}
      >
        <Form layout="vertical" form={form} onFinish={handleImportantNotesSubmit}>
          <Form.Item name="important_notes" label="Important Notes">
            <Input.TextArea rows={4} maxLength={500} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <BasicButton loading={submitLoading} saving={!editMode} title={editMode ? "Update" : "Save"}/>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Important Notes"
        open={importantNotesModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={650}
        closeIcon={<Button size="small" className="app-close-icon" shape="round">Close</Button>}
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Description: </span>
              <span>{selectedItem.important_notes}</span>
            </p>
            <p>
              <span className="font-medium">Added By: </span>
              <span>{selectedItem.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date: </span>
              <FormatDateTime date={selectedItem.date_time} type="datetime" />
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ImportantNotes;
