import { Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import RxImage from "../../../../../assets/Rx1.png";
import "./prescription.css";
import { useReactToPrint } from "react-to-print";
import { ageCalculator } from "../../../../../utils/ageCalculator";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import providerInfo from "../../../../../components/PrescriptionsPad/providerInfo";
import { useParams } from "react-router-dom";
import PreferredItem from "../../../../../components/PrescriptionsPad/PreferredItem";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";

const PrescriptionPad = ({
  visible,
  rxData,
  officeData,
  ptData,
  onClose,
}) => {
  const { id } = useParams();
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const {
    providerDetails,
    getProviderDetails,
    preferredPharmacy: preferredData,
    getPreferredPharmacy,
  } = useStateGlobalContext();

  useEffect(() => {
    if (rxData?.provider_id) {
      getProviderDetails(rxData?.provider_id);
      setLoading(false);
    }
  }, [rxData?.provider_id]);

  useEffect(() => {
    if (id) {
      getPreferredPharmacy(id);
    }
  }, [id]);

  const fullName = `${ptData?.first_name} ${
    ptData?.middle_name ? ptData?.middle_name : ""
  } ${ptData?.last_name}`;

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <Drawer
      title={fullName}
      open={visible}
      placement="bottom"
      height="100vh"
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      onClose={onClose}
      className="prescription-drawer"
    >
      <div className='"prescription-pres-print max-w-[900px]'>
        <div className="prescription-container">
          <button onClick={handlePrint} className="guest-button">
            Print Document
          </button>
          <div className="print-pres-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Prescription</h2>
            <div className="pres-header">
              <div className="clinic-info w-[33%]">
                <h4>Clinic Information</h4>
                {ClinicInformation(officeData)}
              </div>
              <div className="pharmacy-info w-[33%]">
                <h4>Pharmacy Information </h4>
                {PreferredItem(preferredData)}
                <div style={{ opacity: 0.5 }}>
                  <img
                    src={RxImage}
                    alt="Prescription-Rx"
                    className="flex h-[90px]"
                  />
                </div>
              </div>
            </div>
            <hr className="print-divider" />

            <div className="flex justify-between">
              <div className="patient-info">
                <h4>Patient Information</h4>
                <p>
                  <span>Name: </span>
                  {fullName}
                </p>
                <p>
                  <span>DOB: </span>
                  <FormatDate date={ptData?.date_of_birth}/>
                </p>
                <p>
                  <span>Age: </span>
                  {ageCalculator(ptData?.date_of_birth)} y/o
                </p>
                <p>
                  <span>Gender: </span>
                  {ptData?.gender}
                </p>
                <p>
                  <span>Address: </span>
                  {ptData && ptData?.current_address_line_1}
                </p>
                <p>
                  <span>Phone #: </span>
                  {ptData && ptData?.patient_phone}
                </p>
              </div>
              <div>
                <p>
                  <span className="font-medium">Prescription Date: </span>
                  {rxData && <FormatDateTime date={rxData?.date_time} type="datetime"/>}
                </p>
              </div>
            </div>
            <hr className="print-divider" />
            {rxData?.prescriptions?.map((med, idx) => (
              <div key={idx} className="prescription-details">
                <p>
                  <span className="font-medium">{idx + 1}: Medicine:</span>{" "}
                  {med?.searched_medicine}
                </p>
                <p>
                  <span className="font-medium">Instructions: </span>{" "}
                  {med?.instructions}
                </p>
                <p>
                  <span className="font-medium">Quantity: </span>{" "}
                  {med?.dispense_quantity}
                </p>
                <p>
                  <span className="font-medium">Refills: </span> {med?.refills}
                </p>
                <p>
                  <span className="font-medium">Dispense as written: </span>{" "}
                  {med?.dispense_as_written ? "Yes" : "No"}{" "}
                </p>
                <p>
                  <span className="font-medium">Start Date: </span>{" "}
                  {med?.start_date && <FormatDate date={med?.start_date}/>}
                </p>
                <p>
                  <span className="font-medium">Stop Date: </span>{" "}
                  {med?.end_date && <FormatDate date={med?.end_date}/>}
                </p>
                <hr className="print-divider" />
              </div>
            ))}

            <div className="flex justify-between">
              {providerInfo(providerDetails)}
              <div className="flex items-end">
                <div>
                  <p>_______________________________</p>
                  <h3 className="flex justify-end">Signature</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PrescriptionPad;
