import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Avatar, Button, Breadcrumb } from "antd";
import SummaryTabs from "./MedicalCards/PatientTabs";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { ageCalculator } from "../../../utils/ageCalculator";
import { birthDateFormat } from "../../../utils/DateFormat";
import BuyService from "./BuyService/BuyService";
import Loader from "../../../components/Loader/Loader";
import AddToWatingRoom from "./AddToWaitingRoom/AddToWatingRoom";
import ProfileAppointment from "../../../components/ProfileAppointment/ProfileAppointment";
import BuyProducts from "../../../components/BuyProducts/BuyProducts";
import FaceSheet from "../../../components/FaceSheet/FaceSheet";
import FormatDateTime from "../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../components/FormatDate/FormatDate";

function PatientSummary() {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calenderVisible, setCalenderVisible] = useState(false);
  const [waitingListModal, setWaitingListModal] = useState(false);
  const [buyProductsVisible, setBuyProductsVisible] = useState(false);
  const [faceSheet, setFaceSheet] = useState(false);
  const { patientBasicInfo, getPatientData, patientAllergies, getAllergies } =
    useStateGlobalContext();


  // const openCalenderModal = () => {
  //   setCalenderVisible(true);
  // };

  const closeCalender = () => {
    setCalenderVisible(false);
  };

  const openModal = () => {
    setVisible(true);
  };

  const openWaitingListModal = () => {
    setWaitingListModal(true);
  };

  const closeWaitingListModal = () => {
    setWaitingListModal(false);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const openBuyProductsModal = () => {
    setBuyProductsVisible(true);
  };

  const closeBuyProductsModal = () => {
    setBuyProductsVisible(false);
  };

  const openFaceSheet = () => {
    setFaceSheet(true);
  };

  const hideFaceSheet = () => {
    setFaceSheet(false);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await getPatientData(id);
      await getAllergies(id);
      setLoading(false);
    }

    fetchData();
  }, [id]);

  const {
    mrn_number,
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    sex,
    other_Gender,
    gender,
    employment,
    other_Employment_Status,
    marital_status,
    patient_phone,
    patient_email,
    other_Martial_Status,
    profile_picture,
    languages,
  } = patientBasicInfo || {};

  const fullName = `${first_name} ${
    middle_name ? middle_name : ""
  } ${last_name}`;

  const DisplayAllergies = ({ allergies }) => {
    const displayedAllergies = allergies
      ?.slice(0, 4)
      ?.map((allergy) => allergy?.allergy_value);
    const remainingAllergies = allergies?.length - 4;

    return (
      <span>
        {displayedAllergies?.join(", ")}
        {remainingAllergies > 0 ? ` ...and ${remainingAllergies} more` : ""}
      </span>
    );
  };

  if (loading) {
    return <Loader />;
  }
  document.title = "Patient Summary | American EMR";
  return (
    <div className="max-w-[1800px] mx-auto p-4">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/main" className="font-semibold">
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-semibold">
          <Link to="/main/charts">Charts</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item className="font-semibold">
          {first_name} {middle_name} {last_name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-col  md:flex-row md:items-center bg-[#C4dBFA] px-2 py-4 rounded-lg">
        <>
          <div className="md:mr-4 md:mb-0">
            <Avatar
              className="w-20 h-20 flex items-center"
              src={profile_picture}
            >
              {!profile_picture && patientBasicInfo?.first_name[0]}
            </Avatar>
          </div>
          <div className="w-full md:flex md:justify-between">
            <Row
              gutter={[16, 16]}
              className="flex flex-col items-start md:flex-row md:justify-evenly w-full"
            >
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={5}
                className="flex flex-col justify-evenly mb-4"
              >
                <p>
                  <span className="font-medium">Name: </span>
                  <span>
                    {first_name} {middle_name} {last_name}
                  </span>
                </p>
                <p>
                  <span className="font-medium">Marital Status: </span>
                  <span>{marital_status}</span>
                </p>
                <p>
                  <span className="font-medium">MRN: </span>
                  <span>{mrn_number}</span>
                </p>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={5}
                className="flex flex-col items-start justify-evenly mb-4"
              >
                <p>
                  <span className="font-medium">Gender: </span>
                  <span className="mr-2">
                    {sex === "other" ? other_Gender : gender}
                  </span>
                  |
                  <span className="ml-2 mr-2">
                    {ageCalculator(date_of_birth)} y/o
                  </span>
                  |
                  <span className="ml-2"><FormatDate date={date_of_birth}/></span>
                </p>
                <p>
                  <span className="font-medium">Employment Status: </span>
                  {employment === "other"
                    ? other_Employment_Status
                    : employment}
                </p>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={5}
                className="flex flex-col justify-evenly mb-4"
              >
                <p>
                  <span className="font-medium">Phone: </span>
                  {patient_phone}
                </p>
                <p>
                  <span className="font-medium">Email: </span>
                  <span title={patient_email}>
                    {patient_email?.length > 20 ? patient_email?.slice(0, 20) + "..." : patient_email}
                  </span>
                </p>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={5}
                className="flex flex-col justify-evenly mb-4"
              >
                <p>
                  <span className="font-medium">Language: </span>
                  <span>{languages}</span>
                </p>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={4}
                className="flex flex-col justify-evenly items-end"
              >
                <p>
                  <span className="font-medium">Allergies: </span>
                  <span className="text-red-600 font-medium">
                    <DisplayAllergies allergies={patientAllergies} />{" "}
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </>
      </div>

      <div className="flex flex-col md:flex-row md:justify-end mt-10">
        <Button
          className="mb-2 md:mb-0 md:ml-4"
          type="primary"
          shape="round"
          size="medium"
          onClick={openFaceSheet}
        >
          FaceSheet
        </Button>
        {/* <Button
          className="mb-2 md:mb-0 md:ml-4"
          type="primary"
          shape="round"
          size="medium"
          onClick={openCalenderModal}
        >
          Book Appointment
        </Button> */}
        <Button
          className="mb-2 md:mb-0 md:ml-4"
          type="primary"
          shape="round"
          size="medium"
          onClick={openModal}
        >
          Buy Services
        </Button>
        <Button
          className="mb-2 md:mb-0 md:ml-4"
          type="primary"
          shape="round"
          size="medium"
          onClick={openBuyProductsModal}
        >
          Buy Products
        </Button>
        <Button
          className="mb-2 md:mb-0 md:ml-4 waiting-room-button"
          shape="round"
          size="medium"
          onClick={openWaitingListModal}
        >
          Add to Waiting Room
        </Button>
      </div>
      <div className="mt-4">
        <SummaryTabs />
      </div>

      <ProfileAppointment
        visible={calenderVisible}
        onClose={closeCalender}
        firstName={first_name}
        middleName={middle_name}
        lastName={last_name}
        MRN={mrn_number}
        patientID={id}
        dob={date_of_birth}
        contact={patient_phone}
        gender={sex === "other" ? other_Gender : gender}
      />

      <BuyService
        visible={visible}
        onClose={closeModal}
        fullName={fullName}
        date_of_birth={date_of_birth}
      />
      <AddToWatingRoom
        visible={waitingListModal}
        onClose={closeWaitingListModal}
        fullName={fullName}
        date_of_birth={date_of_birth}
      />

      <BuyProducts
        visible={buyProductsVisible}
        onClose={closeBuyProductsModal}
        fullName={fullName}
        mrn_number={mrn_number}
        date_of_birth={date_of_birth}
      />

      <FaceSheet visible={faceSheet} onClose={hideFaceSheet} />
    </div>
  );
}

export default PatientSummary;
