import React, { useEffect } from "react";
import { Modal, Form, Input, Button, AutoComplete } from "antd";
import { useParams } from "react-router-dom";
import { doPost } from "../../../../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { useStateInvoicesContext } from "../../../../../../../../contexts/InvoiceContext";
import Notification from "../../../../../../../../components/Notification/Notification";
import { useSearchServices } from "../../../../../../../../API/apiUtils";
import { validatePrice } from "../../../../../../../../utils/validator";

const CustomInvoice1 = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { patientBasicInfo, getPatientData, getInvoices } =
    useStateGlobalContext();
  const { options: serviceTypeOptions, handleSearchServices } =
    useSearchServices();
  const { getUnpaidInvoicesCount } = useStateInvoicesContext();

  const { first_name, middle_name, last_name } = patientBasicInfo || {};

  const fullName = `${first_name || ""} ${middle_name || ""} ${
    last_name || ""
  }`;

  const handleCustomInvoice = async (values) => {
    const data = {
      patient_name: fullName,
      service_name: values.service_name,
      service_type: values?.service_type,
      price: Number(values.price),
    };
    try {
      const response = await doPost(`/invoice/invoice-add/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Invoice Created Successfully");
        getUnpaidInvoicesCount(id);
        getInvoices(id);
        handleCancel();
      }
    } catch (error) {
      Notification.warning("Something Went Wrong");
    }
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  useEffect(() => {
    getPatientData(id);
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title="Create Custom Invoice"
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form form={form} onFinish={handleCustomInvoice} layout="vertical">
        <Form.Item
          label="Service Name"
          name="service_name"
          rules={[
            {
              required: true,
              message: "Please Enter Service Name",
            },
          ]}
        >
          <AutoComplete
            onSearch={handleSearchServices}
            options={serviceTypeOptions}
            placeholder="Search Service Type"
            maxLength={100}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Service Type" name="service_type">
          <Input placeholder="Service Type" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
              message: "Please Enter Price",
            },
            {
              validator: validatePrice
            }
          ]}
        >
          <Input placeholder="price" maxLength={10} />
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Create Custom Invoice
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CustomInvoice1;
