import React from "react";
import { Avatar, Button, Modal, Tag } from "antd";
import Loader from '../../components/Loader/Loader'

const ScheduleEntry = ({ day, schedule, note, loading }) => {
  const [startTime, endTime, isAvailable] = schedule || ["N/A", "N/A", false];

  return (
    <>
      <p>
        {day}: {startTime} - {endTime}
        <Tag
          className="rounded-lg ml-2"
          color={isAvailable ? "#87d068" : "#f87171"}
        >
          {isAvailable ? "Available" : "Not Available"}
        </Tag>
      </p>
      <p className="ml-5">Notes: {note || "N/A"}</p>
    </>
  );
};

const StaffRecord = ({ show, onClose, data, loading }) => {
  const contact = data?.personel_settings_info[0]?.contact || {};
  const workSchedule = data?.work_schedule_info[0] || {};
  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <Modal
      title="Weekly Schedule"
      open={show}
      footer={null}
      onCancel={onClose}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      {loading ? (
        <Loader/>
      ) : (
        <div>
          <div className="flex items-center">
            <Avatar
              size={"large"}
              src={
                data && data?.personel_settings_info[0]?.user_profile_picture
              }
            >
              {!data?.personel_settings_info[0]?.user_profile_picture &&
                data &&
                getInitial(data.firstname)}
            </Avatar>
            <p
              className="ml-2 p-2 font-semibold"
              style={{ borderLeft: "2px solid #c4c8cf" }}
            >
              {data && data?.firstname}{" "}
              {data &&
                data?.personel_settings_info[0]?.middle_name &&
                data?.personel_settings_info[0]?.middle_name}{" "}
              {data && data?.lastname}
            </p>
          </div>
          <div style={{ border: "1px solid #c4c8cf" }} className="mt-2 mb-2" />
          <div className="gap-4 mb-4 staff-schedule">
            <ScheduleEntry
              day="Monday"
              schedule={workSchedule.monday}
              note={workSchedule.monday_note}
            />
            <ScheduleEntry
              day="Tuesday"
              schedule={workSchedule.tuesday}
              note={workSchedule.tuesday_note}
            />
            <ScheduleEntry
              day="Wednesday"
              schedule={workSchedule.wednesday}
              note={workSchedule.wednesday_note}
            />
            <ScheduleEntry
              day="Thursday"
              schedule={workSchedule.thursday}
              note={workSchedule.thursday_note}
            />
            <ScheduleEntry
              day="Friday"
              schedule={workSchedule.friday}
              note={workSchedule.friday_note}
            />
            <ScheduleEntry
              day="Saturday"
              schedule={workSchedule.saturday}
              note={workSchedule.saturday_note}
            />
            <ScheduleEntry
              day="Sunday"
              schedule={workSchedule.sunday}
              note={workSchedule.sunday_note}
            />
          </div>

          <p className="text-md font-medium mb-2">Personal Information</p>
          <div className="gap-4 mb-4">
            <p>Mobile Number: {contact.phone_number || "N/A"}</p>
            <p>Work Number: {contact.work_phone || "N/A"}</p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default StaffRecord;
