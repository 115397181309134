import React from "react";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import ViewTemplate from "../../Templates/components/ViewTemplate";

function ViewNotes({ viewGeneralNotes }) {

  return (
    <div>
      <p>
        <span className="font-medium">Created By: </span>
        <span>{viewGeneralNotes?.created_by}</span>
      </p>
      <p>
        <span className="font-medium">Date: </span>
        <FormatDateTime date={viewGeneralNotes?.date_time} type="datetime"/>
      </p>
      <p>
        <span className="font-medium">Signed By: </span>
        <span>{viewGeneralNotes?.signed_by}</span>
      </p>
      <p>
        <span className="font-medium">Signed Date: </span>
        {viewGeneralNotes?.signed_date && <FormatDateTime date={viewGeneralNotes?.signed_date} type="datetime"/>}
      </p>
      <p>
        <span className="font-medium">Status: </span>
        <span>{viewGeneralNotes?.status}</span>
      </p>
      <div className="mt-3 bg-slate-100 p-3 h-[500px] overflow-auto">
        <p>
          <span className="font-medium">Service Title: </span>
          <span>{viewGeneralNotes?.service_type}</span>
        </p>
        <p>
          <span className="font-medium">Description: </span>
          <span>{viewGeneralNotes?.description}</span>
        </p>
        <p>
          <span className="font-medium">Reason: </span>
          <span>{viewGeneralNotes?.reason}</span>
        </p>
        <p>
          <span className="font-medium">Notes: </span>
        </p>
        <ViewTemplate template={viewGeneralNotes?.notes}/>
      </div>
    </div>
  );
}

export default ViewNotes;
