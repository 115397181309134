import React, { useEffect } from "react";
import { Form, Input, Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import { doPost, doPatch } from "../../../../../API/apis";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function AccountDetails({ visible, onClose, record , edit ,getbankAccountInfo , view, onCloseView}) {
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (edit && record) {
        form.setFieldsValue({
          name: record?.name,
          bank_name: record?.bank_name,
          account_number: record?.bank_number,
          routing: record?.routing,
          zipcode: record?.zipcode,
          notes: record?.notes,
        });
    }
}, [edit, record, form]);


  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  const handlePaymentMethod = async (values)=>{
    const data = {
      name: values.name,
      bank_name: values.bank_name,
      bank_number: values.account_number,
      routing: values.routing,
      zipcode: values.zipcode,
      notes: values.notes,
    };
    const url = edit
    ? `/invoice/account-details-update/${id}/${record?.id}/`
    : `/invoice/account-details-add/${id}/`
    try {
      const response = await (edit ? doPatch(url, data) : doPost(url, data));
      if (response.status === 201 || response.status === 200) {
        const successMessage = edit
          ? "Bank Account Details Updated Successfully"
          : "Bank Account Details Added Successfully";
        Notification.success(successMessage);
        getbankAccountInfo(id)
        handleCancel();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  }

  return (
    <>
    <Modal
      open={visible}
      title={edit ? "Update Bank Account Details" : "Add New Bank Account Details"}
      footer={null}
      onCancel={handleCancel}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={handlePaymentMethod}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please Enter Full Name on Bank Account",
            },
          ]}
        >
          <Input
            placeholder="Full Name on Bank Account"
            autoComplete="off"
            maxLength={30}
          />
        </Form.Item>
        <Form.Item
          name="bank_name"
          label="Bank Name"
          rules={[{ required: true, message: "Please Enter Bank Name" }]}
        >
          <Input placeholder="Bank Name" maxLength={30} />
        </Form.Item>
        <Form.Item
          name="account_number"
          label="Account Number"
          rules={[{ required: true, message: "Please Enter Account Number" }]}
        >
          <Input placeholder="Account Number" maxLength={30} />
        </Form.Item>

        <Form.Item name="routing" label="Routing">
          <Input placeholder="Routing Number" maxLength={30} />
        </Form.Item>

        <Form.Item name="zipcode" label="Billing Zip / Postal Code">
          <Input placeholder="Zip / Postal Code" maxLength={30} />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input.TextArea placeholder="Notes" showCount maxLength={300} />
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            {edit ? "Update": "Save"}
          </Button>
        </div>
      </Form>
    </Modal>

    {view && (
                <Modal open={view} title="Debit / Credit Card Details" footer={null} onCancel={onCloseView}
                closeIcon={
                  <Button size="small" className="app-close-icon" shape="round">
                    Close
                  </Button>
                }
                >
                    <p> <span className="font-medium">Account Holder Name:</span> {record?.name}</p>
                    <p> <span className="font-medium">Bank Name:</span> {record?.bank_name}</p>
                    <p> <span className="font-medium">Account Number:</span> {record?.bank_number}</p>
                    <p> <span className="font-medium">Routing:</span> {record?.routing}</p>
                    <p> <span className="font-medium">Billing Zip / Postal Code:</span> {record?.zipcode}</p>
                    <p> <span className="font-medium">Notes:</span> {record?.notes}</p>
                    <p> <span className="font-medium">Added By:</span> {record?.added_by}</p>
                    <p> <span className="font-medium">Added Date:</span> <FormatDateTime date={record?.date_time}/></p>
                </Modal>
            )}

    </>
  );
}

export default AccountDetails;
