import React, { useState } from "react";
import {  Tabs } from "antd";
import CreditCardInfo from "./PaymentMethod/CreditCardInfo";
import BankTransferInfo from "./PaymentMethod/BankTransferInfo";
import MonthlyInvoiceInfo from "./PaymentMethod/MonthlyInvoiceInfo";

const TabPane = [
  {
    key: "1",
    label: "Credit Card",
    content: <CreditCardInfo/>,
  },
  {
    key: "2",
    label: "Bank Transfer",
    content: <BankTransferInfo/>,
  },
  {
    key: "3",
    label: "Monthly Invoice",
    content: <MonthlyInvoiceInfo/>,
  },
];

const PaymentMethodTabs = () => {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <>
     <p className="text-lg font-semibold mb-4">Billing Information</p>
    <Tabs
      activeKey={activeKey}
      type="card"
      size="small"
      onTabClick={handleTabChange}
    >
      {TabPane.map((tab) => (
        <Tabs.TabPane key={tab.key} tab={tab.label}>
          {activeKey === tab.key && tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
    </>
  );
};

export default PaymentMethodTabs;
