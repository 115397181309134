import React, {  useState , useEffect} from "react";
import { Modal, Tabs, Table, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {  doGet } from "../../../../../API/apis";
import {  useParams } from "react-router-dom";
import ViewGraph from "./ViewGraph";
import BasicVitals from './BasicVitals'
import BMICalculator from './BMICalculator'
import BodyFatCalculator from './BodyFatCalculator'
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import IdealWeightCalculator from "./IdealWeightCalculator";
import MedicalCardsTitle from "../MedicalCardsTitle";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";



const { TabPane } = Tabs;
const TABLE_WIDTH = 800;


const CustomModal = ({ title, open, onCancel, data, columns, loading }) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
      footer={null}
      width={TABLE_WIDTH}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Table" key="1">
          <Table
            loading={loading}
            size="small"
            dataSource={data}
            columns={columns}
            scroll={{ x: TABLE_WIDTH }}
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Graph" key="2">
           <ViewGraph data={data} loading={loading} title={title}/>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

function VitalSigns() {
  
  const { id } = useParams();
  const [BMIData, setBMIData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bodyFatData, setBodyFatData] = useState([]);
  const [idealWeightData, setIdealWeightData] = useState([]);
  const [basicVitalsData, setBasicVitalsData] = useState([]);
  const [isBMIModalVisible, setIsBMIModalVisible] = useState(false);
  const { officeSetting, getOfficeSettings } = useStateGlobalContext()
  const [isBasicModalVisible, setIsBasicModalVisible] = useState(false);
  const [isBodyFatModalVisible, setIsBodyFatModalVisible] = useState(false);
  const [isIdealWeightModalVisible, setIsIdealWeightModalVisible] = useState(false);

  const columns = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => <FormatDateTime date={text} type="datetime"/>
    },
    {
      title: "Blood Pressure",
      dataIndex: "blood_pressure",
      key: "blood_pressure",
      align: 'center'
    },
    {
      title: "Body Temperature",
      dataIndex: "body_temp",
      key: "body_temp",
      align: 'center',
      render : (temperature) =>{
        return ( 
          temperature >= 100 && temperature  <=110
          ? <p className="text-red-500">{temperature } F</p>
          : temperature  >= 90 && temperature  <=100
          ? <p className="text-green-500">{temperature } F</p>
          : temperature >= 30 && temperature < 40
          ? <p className="text-green-500">{temperature } C </p>
          : temperature >= 40 && temperature <= 45
          ? <p className="text-red-500">{temperature } C </p>
          : <p>{temperature }</p>
        )
      }
    },
    {
      title:"Height",
       align: 'center',
      dataIndex:"height",
      key:"height"
    },
    {
      title:"Weight",
       align: 'center',
      dataIndex:'weight' ,
      key:'weight'
    },
    {
      title: "Heart Rate",
      dataIndex: "heart_rate",
      key: "heart_rate",
      align: 'center'
    },
    {
      title: "O2 Saturation",
      dataIndex: "oxygen",
      key: "oxygen",
      align: 'center',
      render: (oxygen)=> oxygen ? (oxygen >= 95 ? <p className="text-green-500">{oxygen}%</p> : 
      oxygen < 95 && oxygen > 92 ? <p className="text-yellow-400">{oxygen}%</p> :
      oxygen < 93 ? <p className="text-red-500">{oxygen}%</p> : <p>{oxygen}%</p>): ""
    },

    {
      title: "Respiratory Rate",
      dataIndex: "resp_rate",
      key: "resp_rate",
      align: 'center'
    },
    {
      title: "Pain Scale",
      dataIndex: "pain_scale",
      key: "pain_scale",
      align: 'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ];
  
  const columns1 = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render: (text) => <FormatDateTime date={text} type="datetime"/>
    },
    {
      title: "Height",
      dataIndex: "BMI_height",
      key: "BMI_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "BMI_weight",
      key: "BMI_weight",
      align: 'center'
    },
    {
      title: "BMI",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300">{text }</p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">{text }</p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">{text }</p>
          : text >= 30 
          ?  <p className="text-red-300">{text }</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: "BMI Category",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300"> Underweight </p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">Normal Weight </p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">Overweight</p>
          : text >= 30 
          ?  <p className="text-red-300">Obesity</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const columns2 = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => <FormatDateTime date={text} type="datetime"/>
    },
  
    {
      title: "Height",
      dataIndex: "idealweight_height",
      key: "idealweight_height",
      align:'center'
    },
    {
    title: 'Gender',
    dataIndex: 'idealweight_gender',
    key:'idealweight_gender',
    align:'center'
    },
    {
      title: "Ideal Weight",
      dataIndex: "idealweight",
      key: "idealweight",
      align:'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const column3 = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => <FormatDateTime date={text} type="datetime"/>
    },
    {
      title: "Age",
      dataIndex: "bodyfat_waist",
      key: "bodyfat_waist",
    },
    {
      title: 'Gender',
      dataIndex: 'bodyfat_neck',
      key: 'bodyfat_neck'
    },
    {
      title: "Height",
      dataIndex: "bodyfat_height",
      key: "bodyfat_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "bodyfat_weight",
      key: "bodyfat_weight",
      align: 'center'
    },
    {
      title: 'Body Fat Percentage',
      dataIndex: 'bodyfat',
      key: 'bodyfat',
      align: 'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]



  const handleVitalSigns = () => setVisible(true);

  useEffect(()=>{
    getOfficeSettings()
  },[])

  const metric = officeSetting?.selected_unit  === 'metric' ? true : false

  //Get Basic Vitals
  const handleBasicModal = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-vitals/${id}/`);
      if (response.status === 200) {
        setBasicVitalsData(response.data.reverse());
        setIsBasicModalVisible(true);
      } 
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  };

  //Get BMI
  const handleBMIModal = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-bmi/${id}/`);
      if (response.status === 200) {
        setBMIData(response.data.reverse());
        setIsBMIModalVisible(true);
      } 
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  };

  //get Ideal Weight
  const handleIdealWeightModal = async () => {
    setLoading(true);
    try {

      const response = await doGet(`/patient/get-ideal-weight/${id}/`);
      if (response.status === 200) {
        setIdealWeightData(response.data.reverse());
        setIsIdealWeightModalVisible(true);
      } 
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  };
  


  //Get Body Fat
  const getBodyFat = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-bodyfat/${id}/`);
      if (response.status === 200) {
        setBodyFatData(response.data.reverse());
        setIsBodyFatModalVisible(true);
        setLoading(false);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  };


  const handleCancel = () => {
    setVisible(false);
  };

  const handleSave = ()=>{
    setVisible(false)
  }

  const items = [
    {
      key: '1',
      label: 'Basic Vitals',
      children: <BasicVitals onSave = {handleSave} onCancel={handleCancel} metric={metric}/>,
    },
    {
      key: '2',
      label: 'BMI Calculator',
      children: <BMICalculator onSave = {handleSave} onCancel={handleCancel} metric={metric}/>,
    },
    {
      key: '3',
      label: 'Body Fat Calculator',
      children: <BodyFatCalculator onSave = {handleSave} onCancel={handleCancel} metric={metric}/>,
    },
    {
      key: '4',
      label: 'Ideal Weight Calculator',
      children: <IdealWeightCalculator onSave={handleSave} onCancel={handleCancel} metric={metric}/>,
    },
  ];

 
  return (
    <div>
    <div className="shadow-class p-4 border rounded-md h-[180px]  max-h-[180px]">
      <div className="flex items-center justify-between">
        <MedicalCardsTitle title='Vital Signs'/>
        <Button type="primary" size="small" shape='round' onClick={handleVitalSigns}>Add <PlusOutlined  /></Button>
      </div>
      <div className="flex flex-col align-middle leading-8">
        <div className="flex justify-between">
          <p
            className="text-[#1890ff] cursor-pointer"
            onClick={handleBasicModal}
          >
            Basic Vitals
          </p>
          <p className="text-[#1890ff] cursor-pointer" onClick={getBodyFat}>
            Body Fat Calculator
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-[#1890ff] cursor-pointer" onClick={handleBMIModal}>
            BMI Calculator
          </p>
          <p
            className="text-[#1890ff] cursor-pointer"
            onClick={handleIdealWeightModal}
          >
            Ideal Weight Calculator
          </p>
        </div>
      </div>

      <Modal
        title="Vital Signs"
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        footer={null}
        width={750}
      >
        <Tabs defaultActiveKey="1" items={items}/>
      </Modal>

      <CustomModal
          title="Basic Vitals"
          open={isBasicModalVisible}
          onCancel={() => setIsBasicModalVisible(false)}
          data={basicVitalsData}
          columns={columns}
          loading={loading}
        />

        <CustomModal
          title="BMI"
          open={isBMIModalVisible}
          onCancel={() => setIsBMIModalVisible(false)}
          data={BMIData}
          columns={columns1}
          loading={loading}
        />

        <CustomModal
          title="Body Fat Percentage"
          open={isBodyFatModalVisible}
          onCancel={() => setIsBodyFatModalVisible(false)}
          data={bodyFatData}
          columns={column3}
          loading={loading}
        />

        <CustomModal
          title="Ideal Weight"
          open={isIdealWeightModalVisible}
          onCancel={() => setIsIdealWeightModalVisible(false)}
          data={idealWeightData}
          columns={columns2}
          loading={loading}
        />
    </div>
    </div>
  );
} 

export default VitalSigns;