import axios from "axios";
import { getSession } from "../customHooks/index";

const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const defaultHeaders = {
  "Content-Type": "application/json",
}

const sendRequest = async (method, url, data, headers = {}) => {
  const apiUrl = API_Path + url;

  try {
    const response = await axios({ method, url: apiUrl, data, headers: { Authorization: `Bearer ${getSession("token")}`, ...headers } });
    return { status: response?.status, data: response?.data };
  } catch (error) {
    return { status: error?.response?.status, data: error?.response?.data };
  }
};

export const doPost = async (url, body, headers) => sendRequest('post', url, body, { ...defaultHeaders, ...headers });
export const doGet = async (url, headers) => sendRequest('get', url, null, headers);
export const doPatch = async (url, body, headers) => sendRequest('patch', url, body, headers);
export const doDelete = async (url, headers) => sendRequest('delete', url, null, headers);


// export const doPost = async (url, body, headers) => {
//   const apiUrl = API_Path + url;
//   const response = await axios.post(apiUrl, body, { headers: { Authorization: `Bearer ${getSession("token")}`,...defaultHeaders, ...headers } })
//     .then((res) => {
//       return { status: res?.status, data: res?.data };
//     })
//     .catch((error) => {
//       return { status: error?.response?.status, data: error?.response?.data };
//     });
//   return response;
// };

// export const doGet = async (url, headers) => {
//   const apiUrl = API_Path + url;
//   const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${getSession("token")}`, ...headers } })
//     .then((res) => {
//       return { status: res?.status, data: res?.data };
//     })
//     .catch((error) => {
//       return { status: error?.response?.status, data: error?.response?.data };
//     });
//   return response;
// };

// export const doPatch = async (url, body, headers) => {
//   const apiUrl = API_Path + url;
//   const response = await axios.patch(apiUrl, body, { headers: { Authorization: `Bearer ${getSession("token")}`, ...headers } })
//     .then((res) => {
//       return { status: res.status, data: res.data };
//     })
//     .catch((error) => {
//       return { status: error.response.status, data: error.response.data };
//     });
//   return response;
// };

// export const doDelete = async (url, headers) => {
//   const apiUrl = API_Path + url;
//   const response = await axios.delete(apiUrl, { headers: { Authorization: `Bearer ${getSession("token")}`, ...headers } })
//     .then((res) => {
//       return { status: res.status, data: res.data };
//     })
//     .catch((error) => {
//       return { status: error.response.status, data: error.response.data };
//     });
//   return response;
// };
