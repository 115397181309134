import React, { useEffect, useMemo, useState } from "react";
import InvoicesCards from "./InvoicesCards";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { InvoiceContextProvider } from "../../../../contexts/InvoiceContext";

const { Content } = Layout;

const menuItems = [
  { label: "Invoices", key: "service-invoices" },
  { label: "Product Invoices", key: "product-bills" },
  { label: "Outstanding Bills", key: "outstanding-bills" },
  { label: "Credit Memo", key: "credit-memo" },
  { label: "Insurance Claims", key: "insurance-claims" },
  { label: "Superbill", key: "superbill" },
  { label: "Payment Method", key: "payment-method" },
];

function InvoiceAndBilling() {
  const navigate = useNavigate();
  const location = useLocation();
  const { officeSetting } = useStateGlobalContext();
  
  const officeDateFormat = officeSetting?.date_format;
  const officeTimeFormat = officeSetting?.time_format;
  const officeTimezone = officeSetting?.facility_time_zone;
  
  const activeMenuItem = useMemo(() => menuItems.find(item => location.pathname.includes(item.key)), [location.pathname]);
  useEffect(() => {
    if (!activeMenuItem) {
      navigate("service-invoices");
    }
  }, []);

  const menuItemsMemo = useMemo(() => menuItems, []); 

  return (
    <div>
      <InvoiceContextProvider>
        <div className="mt-2 mb-2">
          <InvoicesCards />
        </div>
        <Layout style={{ backgroundColor: "transparent" }}>
          <Menu
            onClick={({ key }) => navigate(key)}
            mode="horizontal"
            selectedKeys={activeMenuItem ? [activeMenuItem.key] : []}
            items={menuItemsMemo}
            className="summary-menu mb-4 bg-white font-medium tracking-[0.2px]"
          />
          <Content
            className="layout"
            style={{ backgroundColor: "white" }}
          >
            <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
          </Content>
        </Layout>
      </InvoiceContextProvider>
    </div>
  );
}

export default InvoiceAndBilling;
