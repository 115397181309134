import React, { useEffect, useState, useCallback } from "react";
import { Table, Dropdown, Modal, Spin, Row, Col, Card, Tooltip, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { doDelete, doPatch } from "../../../../API/apis";
import {  useParams } from "react-router-dom";
import PrescriptionItem from "./PrescriptionItem";
import HistoricalMedication from "./HistoricalMedication";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import ViewPrescription from "./ViewPrescription";
import CurrentMedications from "./CurrentMedications";
import Loader from "../../../../components/Loader/Loader";
import PrescriptionPad from "./component/PrescriptionPad";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";

const items = [
  { key: 1, label: "View" },
  { key: 2, label: "Dispense" },
  { key: 3, label: "Print" },
  { key: 5, label: "Delete" },
];

const AllMedications = () => {
  const { id } = useParams();
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [prescriptionDrawer, setPrescriptionDrawer] = useState(false);
  const { getPrescriptions, prescriptions, patientBasicInfo, getPatientData, officeSetting, getOfficeSettings } = useStateGlobalContext();

  const columns = [
    {
      title: "Rx ID",
      dataIndex: "prescription_code",
      key: "prescription_code",
      render: (text) => (
        <span title={text}>
          {text.length > 10 ? text.slice(0, 10) + "..." : text}
        </span>
      ),
    },
    {
      title: "Medication",
      dataIndex: "prescriptions",
      key: "prescriptions",
      render: (prescriptions) => (
        <span>
          {prescriptions.length <= 1 ? (
            prescriptions.map((prescription, index) => (
              <span key={prescription.key} title={prescription?.searched_medicine}>
                {index > 0 ? ", " : ""}
                {prescription?.searched_medicine?.length > 10
                  ? prescription?.searched_medicine?.slice(0, 10) + "..."
                  : prescription?.searched_medicine}
              </span>
            ))
          ) : (
            <>
              <span>
                {prescriptions[0]?.searched_medicine?.length > 10
                  ? prescriptions[0]?.searched_medicine?.slice(0, 10) + "..."
                  : prescriptions[0]?.searched_medicine}
                ,{" "}
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Added By",
      dataIndex: "added_by",
      key: "added_by",
      align: "center",
      render: (text) => (
        <span title={text}>
          {text.length > 10 ? text.slice(0, 10) + "..." : text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      align: "center",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ), 
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      align: "center",
      render: (text) => {
        const bgColor = text === "New" ? "bg-green-600" : text === "Dispense" ? "bg-[#3484F0]" : "bg-gray-400";
        return <span className={`${bgColor} px-1 py-[2px] text-white text-xs rounded-md`}>{text}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: "10%",
      render: (text, record) => {
        const filteredItems = items.filter((item) => {
          // Remove "Dispense" option if the current status is already "Dispense"
          return !(item.key === 2 && record.status === "Dispense");
        });
    
        return (
          <Dropdown
            menu={{
              items: filteredItems.map((item) => ({
                ...item,
                onClick: () => {
                  switch (item.key) {
                    case 1:
                      handleView(record);
                      break;
                    case 2:
                      handleStatus(record, "Dispense");
                      break;
                    case 3:
                      handlePrint(record);
                      break;
                    case 5:
                      handleDelete(record);
                      break;
                    default:
                      break;
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
    
  ];

  const handleView = useCallback((record) => {
    setViewData(record);
    setView(true);
  }, []);

  const handleStatus = async (record, status) => {
    const response = await doPatch(`/prescriptions/update/${id}/${record.id}/`, { status });
    if (response.status === 200) {
      Notification.success("Status updated successfully");
      getPrescriptions(id);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => deletePrescription(record.id),
    });
  };

  const deletePrescription = async (recordId) => {
    try {
      const response = await doDelete(`/prescriptions/delete/${id}/${recordId}/`);
      if (response.status === 200) {
        Notification.success("Prescription deleted successfully");
        getPrescriptions(id);
      }
    } catch (error) {
      Notification.error("Failed to delete prescription");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getPrescriptions(id);
        await getPatientData(id);
        await getOfficeSettings();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handlePrint = (record) => {
    setPrescriptionDrawer(true);
    setPrescriptionData(record);
  };

  const onClose = () => {
    setPrescriptionDrawer(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xl={12} lg={24} md={24} sm={24}>
          <Card className="shadow-class">
            <h1 className="font-semibold cards__heading">Prescribed Medications</h1>
            <div style={{ minHeight: '150px', maxHeight: "250px", overflowY: "scroll" }}>
              {prescriptions?.length === 0 ? (
                <p>No medication added.</p>
              ) : (
                prescriptions?.map((item) => (
                  <PrescriptionItem
                    key={item.id}
                    prescriptions={item?.prescriptions}
                    showButton={true}
                  />
                ))
              )}
            </div>
            <CurrentMedications />
          </Card>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24}>
          <Card className="shadow-class h-[420px]">
            <h1 className="font-semibold cards__heading">Digital Prescriptions</h1>
            <Table
              columns={columns}
              dataSource={prescriptions}
              pagination={false}
              loading={loading}
              size="small"
              className="mt-2"
              scroll={{ x: 700, y: 370 }}
            />
          </Card>

          <Modal
             closeIcon={<Button size="small" className="app-close-icon" shape="round">Close</Button>}
            title={viewData?.prescription_code}
            open={view}
            footer={null}
            bodyStyle={{ maxHeight: "10%", overflowY: "auto" }}
            onCancel={() => setView(false)}
            maskClosable={false}
            width={650}
          >
            <ViewPrescription
              viewPrescription={viewData}
            />
          </Modal>
        </Col>
      </Row>
      <HistoricalMedication />
      <PrescriptionPad
        visible={prescriptionDrawer}
        rxData={prescriptionData}
        officeData={officeSetting}
        ptData={patientBasicInfo}
        onClose={onClose}
      />
    </div>
  );
};

export default AllMedications;
