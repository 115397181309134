
import Loader from '../../../../../components/Loader/Loader'
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function ViewOrderedLabs({ orderedLabData  }) {
  console.log(orderedLabData, "TEST")

  if(!orderedLabData){
    return <Loader/>
  }
  return (
    <div>
      <p>
        <span className="font-medium">Added By: </span>
        {orderedLabData?.added_by}
      </p>
      <p>
        <span className="font-medium">Date Added: </span>
       <FormatDateTime date={orderedLabData?.date_added} type="datetime"/>
      </p>

      <p>
        <span className="font-medium">Lab Type: </span>
        {orderedLabData?.lab_id?.includes("LAB_EX") ? "External" : "In-House"}
      </p>
      <p>
        <span className="font-medium">Lab Order ID: </span>
        {orderedLabData?.lab_id}
      </p>

      {orderedLabData && orderedLabData?.lab_name?.map((lab_item, index) => (
        <div key={index} className="mt-3 bg-slate-100 p-3">
          <p>
            <span className="font-medium">Lab: </span>
            {lab_item?.lab?.lab_name
              ? lab_item?.lab?.lab_name
              : lab_item?.lab_name}
          </p>
          {lab_item?.lab_instructions && (
            <p>
              <span className="font-medium">Instructions: </span>
              {lab_item?.lab_instructions}
            </p>
          )}
          {lab_item?.lab_diagnosis && (
            <p>
              <span className="font-medium">Diagnosis: </span>
              {lab_item?.lab_diagnosis}
            </p>
          )}
          {lab_item?.lab_type && (
            <p>
              <span className="font-medium">Type: </span>
              {lab_item?.lab_type}
            </p>
          )}
          {lab_item?.lab_group && (
            <p>
              <span className="font-medium">Group: </span>
              {lab_item?.lab_group}
            </p>
          )}
          {lab_item?.lab_price && (
            <p>
              <span className="font-medium">Price: </span>
              {lab_item?.lab_price}
            </p>
          )}
        </div>
      ))}
      <p>
        <span className="font-medium">Status: </span>
        {orderedLabData?.status}
      </p>
      <p>
        <span className="font-medium">Completed By: </span>
        {orderedLabData?.completed_by}
      </p>
      <p>
        <span className="font-medium">Completed Date: </span>
        {orderedLabData?.completed_date && (
          <FormatDateTime date={orderedLabData?.completed_date} type="datetime"/>
        )}
      </p>
    </div>
  );
}

export default ViewOrderedLabs;
