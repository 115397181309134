// Notification.js
import { message } from 'antd';

const Notification = {
  success: (msg) => {
    message.success(msg, 3);
  },
  error: (msg) => {
    message.error(msg, 3);
  },
  info: (msg) => {
    message.info(msg, 3);
  },
  warning: (msg) => {
    message.warning(msg, 3);
  },
};

export default Notification;
