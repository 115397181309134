import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Typography,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { doGet, doPost, doPatch, doDelete } from "../../../../../API/apis";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";

const ImplantedDevice = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [copied, setCopied] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gmdnTerm, setgmdnTerm] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [implantedData, setImplantedData] = useState(null);
  const [productCode, setProductCode] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [implantedDeviceModal, setImplantedDeviceModal] = useState(false);

  const copyToClipboard = () => {
    let textToCopy = `MRI Safety Status: ${data?.MRISafetyStatus}\n`;
    textToCopy += `Brand Name: ${data?.brandName}\n`;
    textToCopy += `Catalog Number: ${data?.catalogNumber}\n`;
    textToCopy += `Company Name: ${data?.companyName}\n`;
    textToCopy += `Device Description: ${data?.deviceDescription}\n`;
    textToCopy += `Device Publish Date: ${data?.devicePublishDate}\n`;
    textToCopy += `Version Model Number: ${data?.versionModelNumber}\n`;
    textToCopy += `Device HCTP: ${
      data?.deviceHCTP.toString() === "true" ? "Yes" : "No"
    }\n`;
    textToCopy += `Device Kit: ${
      data?.deviceKit.toString() === "true" ? "Yes" : "No"
    }\n`;
    textToCopy += `Product Code: ${productCode?.productCode}\n`;
    textToCopy += `Device Class: ${productCode?.deviceClass}\n`;
    textToCopy += `Device Name: ${productCode?.deviceName}\n`;
    textToCopy += `Regulation Number: ${productCode?.regulationNumber}\n`;
    textToCopy += `GMDN Terms: ${gmdnTerm?.gmdnPTName}\n`;
    textToCopy += `GMDN Definition: ${gmdnTerm?.gmdnPTDefinition}\n`;
    textToCopy += `Device Packaged as Sterilize: ${
      data?.sterilization?.deviceSterile?.toString() === "true" ? "Yes" : "No"
    }\n`;
    textToCopy += `Sterilization Prior to Use: ${
      data?.sterilization?.sterilizationPriorToUse?.toString() === "true"
        ? "Yes"
        : "No"
    }\n`;
    textToCopy += `Device Issuing Agency: ${data?.identifiers?.identifier[0]?.deviceIdIssuingAgency}\n`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000); // Reset copied state to false after 2 seconds
      })
      .catch((error) => console.error("Copy to clipboard failed: ", error));
  };

  const openModal = () => {
    setVisible(true);
  };

  const disabledDate = (current) => {
    return current && current > new Date();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleItemClick = (record) => {
    setImplantedDeviceModal(true);
    setSelectedItem(record);
  };

  const handleCancel = () => {
    setVisible(false);
    setEdit(false);
    setImplantedDeviceModal(false);
    form.resetFields();
    setData(null);
  };

  const handleEdit = (record) => {
    setEdit(true);
    setSelectedItem(record);
    form.setFieldsValue({
      udi: record.udi,
      implanted_date: dayjs(record?.implanted_date),
      implanted_device_active: record?.active_status,
      implanted_device_notes: record?.notes,
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteImplantedDevice(record);
      },
    });
  };

  const deleteImplantedDevice = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-implantable-device/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Implanted Device Record Deleted Successfully");
        getImplantedDevice();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const verifyDevice = async () => {
    try {
      const udi = form.getFieldValue("udi");
      setVerifyLoading(true);
      const response = await doGet(
        `/patient/verify-implantable-device/${udi}/`
      );
      if (response.status === 200) {
        setData(response?.data?.gudid?.device);
        setProductCode(response?.data?.productCodes[0]);
        setgmdnTerm(response?.data?.gudid?.device?.gmdnTerms.gmdn[0]);
      } else {
        Notification.error("No record found");
        setData(null);
      }
    } catch (error) {
      Notification.error("Error occurred while fetching data");
      setData(null);
    } finally {
      setVerifyLoading(false);
    }
  };

  const handleImpalntedDevice = async (value) => {
    const data = {
      udi: value.udi,
      implanted_date: value.implanted_date,
      active_status: value.implanted_device_active,
      notes: value.implanted_device_notes,
    };
    const endpoint = edit
      ? `/patient/update-implantable-device/${id}/${selectedItem.id}/`
      : `/patient/add-implantable-device/${id}/`;
    try {
      const response = edit
        ? await doPatch(endpoint, data)
        : await doPost(endpoint, data);

      if (response.status === 200 || response.status === 201) {
        const successMessage = edit
          ? "Implanted Device Information updated successfully"
          : "Implanted Device Information added successfully";

        Notification.success(successMessage);
        setVisible(false);
        form.resetFields();
        setData(null);
        setProductCode(null);
        setgmdnTerm(null);
        getImplantedDevice();
      } else {
        console.log("Error:", response.data);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const getImplantedDevice = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-implantable-device/${id}/`);
      if (response.status === 200) {
        setImplantedData(response?.data.reverse());
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getImplantedDevice();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px] max-h-[180px]">
        <div className="flex items-center justify-between">
          {/* <h1 className="font-semibold cards__heading">Implanted Devices</h1> */}
          <MedicalCardsTitle title="Implanted Devices" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px] overflow-auto mt-1">
            {implantedData && implantedData.length > 0 ? (
              <ul>
                {implantedData.map((item, index) => (
                  <li
                    key={item.id}
                    className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                    onMouseEnter={() => setHoveredItemId(item.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                  >
                    <span
                      onClick={() => {
                        handleItemClick(item);
                      }}
                    >
                      {index + 1}. {item?.udi?.slice(0, 26)}
                      {item?.udi?.length > 26 ? "..." : ""}
                    </span>
                    {hoveredItemId === item.id && (
                      <div className="flex items-center ml-3">
                        <EditOutlined
                          className="text-[#1890ff] mr-2"
                          onClick={() => {
                            handleEdit(item);
                          }}
                        />
                        <DeleteOutlined
                          className="text-[#1890ff]"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="flex justify-between items-center">
                <span>No Implanted Device Added</span>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        title={edit ? "Update Implanted Device" : "Add Implanted Device"}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        maskClosable={false}
        width={500}
      >
        <Form
          layout="vertical"
          name="implanted-device"
          form={form}
          onFinish={handleImpalntedDevice}
        >
          <Typography.Paragraph>
            Enter and Verify a unique Device identifier to populate data from
            the Global UDI Database
          </Typography.Paragraph>
          <div className="flex">
            <Form.Item
              name="udi"
              label="Unique Device Identifier"
              className="w-[240px]"
              rules={[
                {
                  required: true,
                  message: "Please Enter UDI Number",
                },
              ]}
            >
              <Input maxLength={100} placeholder="UDI" />
            </Form.Item>
            <Button
              className="mt-[1.9rem] ml-1"
              onClick={verifyDevice}
              disabled={form.getFieldValue("udi") === null}
            >
              Verify
            </Button>
          </div>
          <div className="flex">
            <Form.Item
              name="implanted_date"
              label="Implanted Date"
              rules={[
                {
                  required: true,
                  message: "Please Enter Implanted Date",
                },
              ]}
            >
              <BaseDatePicker
                value={edit ? selectedItem?.implanted_date : selectedDate}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item
              name="implanted_device_active"
              className="ml-4 mt-[1.9rem]"
              valuePropName="checked"
            >
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </div>
          <Form.Item label="Notes" name="implanted_device_notes">
            <Input.TextArea
              placeholder="Notes"
              rows={4}
              maxLength={2000}
              showCount
            />
          </Form.Item>
          {data && (
            <Button
              className="flex"
              onClick={copyToClipboard}
              type="link"
              size="small"
            >
              {copied ? "Copied!" : "Copy to Clipboard"}
            </Button>
          )}

          {verifyLoading ? (
            <div className="flex items-center justify-center">
              <Spin />
            </div>
          ) : (
            data && (
              <div className="border-solid border-2 rounded-md border-white mb-2 p-3 bg-slate-600 h-[200px] overflow-y-auto">
                <p className="text-white">
                  <span className="font-medium mr-1">MRI Safety Status:</span>
                  {data?.MRISafetyStatus}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Brand Name:</span>
                  {data?.brandName}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Catalog Number:</span>
                  {data?.catalogNumber}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Company Name:</span>
                  {data?.companyName}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Description:</span>
                  {data?.deviceDescription}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Publish Date:</span>
                  {data?.devicePublishDate}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">
                    Version Model Number:
                  </span>
                  {data?.versionModelNumber}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device HCTP:</span>
                  {data?.deviceHCTP.toString() === "true" ? "Yes" : "No"}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Kit:</span>
                  {data?.deviceKit.toString() === "true" ? "Yes" : "No"}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Product Code:</span>
                  {productCode?.productCode}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Class:</span>
                  {productCode?.deviceClass}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Name:</span>
                  {productCode?.deviceName}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">Device Name:</span>
                  {productCode?.regulationNumber}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">GMDN Terms:</span>
                  {gmdnTerm?.gmdnPTName}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">GMDN Definition:</span>
                  {gmdnTerm?.gmdnPTDefinition}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">
                    Device Packaged as Sterilize:
                  </span>
                  {data?.sterilization?.deviceSterile?.toString() === "true"
                    ? "Yes"
                    : "No"}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">
                    Sterilization Prior to Use:
                  </span>
                  {data?.sterilization?.sterilizationPriorToUse?.toString() ===
                  "true"
                    ? "Yes"
                    : "No"}
                </p>
                <p className="text-white">
                  <span className="font-medium mr-1">
                    Device Issuing Agency:
                  </span>
                  {data?.identifiers?.identifier[0]?.deviceIdIssuingAgency}
                </p>
              </div>
            )
          )}

          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              {edit ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Implanted Device Information"
        open={implantedDeviceModal}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
      >
        {selectedItem && (
          <>
          <LabelDisplay title="Implanted Date" description={<FormatDate date={selectedItem?.implanted_date}/>} />
          <LabelDisplay title="UDI" description={selectedItem?.udi} />
          <LabelDisplay title="Status" description={selectedItem?.active_status.toString()} />
          <LabelDisplay title="Notes" description={selectedItem?.notes} />
          <LabelDisplay title="Added By" description={selectedItem?.added_by} />
          <LabelDisplay title="Date" description={<FormatDateTime date={selectedItem?.date_time} type="datetime"/>} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default ImplantedDevice;
