import React from 'react';
import { Spin } from 'antd';
import { NumberFormatter } from '../../../../../utils/validator';

function CardComponent({ title, value, loading, noShowCurrency}) {
  const formattedValue = isNaN(value) ? value : (
    <NumberFormatter
      value={value}
      options={{ style: 'decimal' }}
    />
  );

  return (
    <div className="w-1/6 flex flex-col items-center py-5">
      <p className='font-medium'>{title}</p>
      {loading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <p className='text-lg font-semibold'>{value === null ? 0 : noShowCurrency ? value : formattedValue}</p>
      )}
    </div>
  );
}

export default CardComponent;
