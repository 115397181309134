import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input, Form, Row, Col,  AutoComplete, DatePicker, TimePicker, Tooltip , Avatar, Checkbox} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { birthDateFormat } from "../../utils/DateFormat";
import { doPost , doPatch } from "../../API/apis";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import dayjs from "dayjs";
import { useSearchServices, useSearch } from "../../API/apiUtils";
import Notification from "../Notification/Notification";

const { Option } = Select;
// const format = "h:mm a";



  const SUCCESS_MESSAGES = {
    update: "Appointment Updated Successfully",
    add: "Appointment Created Successfully",
  };

  
const CalenderModal = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const [edit, setEdit] = useState(false)
    const [startTime, setStartTime] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [clientDetails, setClientDetails] = useState([]);
    const { providers, getProviders } = useStateGlobalContext();
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [appointmentTime, setAppointmentTime] = useState(null); 
    const [isNotRegistered, setIsNotRegistered] = useState(false);
    const [appointmentWith, setAppointmentWith ] = useState(null)
    const [selectedProvider, setSelectedDProvider] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);


    useEffect(()=>{
        getProviders()
    }, [])

    const handleCancel = () => {
        onClose()
        setSelectedAppointment(null);
        setTimeout(() => {
          setEdit(false);
          form.resetFields();
        }, 300);
      };

      const handleFormValuesChange = (changedValues, allValues) => {
        if (
          "appointment_date" in changedValues ||
          "start_time" in changedValues ||
          "duration" in changedValues ||
          "appointment_with" in changedValues
        ) {
          const selectedDate = allValues["appointment_date"];
          const provider = allValues["appointment_with"]
          const selectedStartTime =
            allValues["start_time"] || dayjs().startOf("day");
          const selectedDuration = allValues["duration"] || 15;
    
          const formattedDate = dayjs(selectedDate).format("MM-DD-YYYY");
          const formattedStartTime = selectedStartTime.format("h:mm a");
          const formattedEndTime = selectedStartTime
            .add(selectedDuration, "minutes")
            .format("h:mm a");
    
          setStartTime(formattedStartTime);
          setAppointmentDate(formattedDate);
          setAppointmentWith(provider)
          setAppointmentTime(`${formattedStartTime} - ${formattedEndTime}`);
        }
      };

      const { options: serviceOptions, handleSearchServices } = useSearchServices();
      const { nameOptions, handleSearch } = useSearch();


      const onSelect = (value, option) => {
        const selectedClient = nameOptions.find((item) => item.value === value);
        if (selectedClient) {
          setClientDetails({
            patient_id: option?.id,
            first_name: option?.first_name,
            last_name: option?.last_name,
            patient_MRN: option?.mrn_number,
            dob: option?.date_of_birth,
            contact: option?.phone,
            gender: option?.gender,
          });
        }
      };
      const patient_name = clientDetails.first_name + " " + clientDetails.last_name;



      const handleProviderSearch = (value) => {
        const filteredSuggestions = providers?.filter((suggestion) =>
          suggestion.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      };
    
      const handleSelect = (value, option) => {
        const selectedProviderID = option.key;
        const selectedProviderName = option.children;
        setSelectedDProvider({
          id: selectedProviderID,
          name: selectedProviderName,
        });
      };


      const notRegistered = (e) => {
        const notRegistered = e.target.checked;
        setIsNotRegistered(notRegistered);
      };


      
  const handleAppoinmentSchedule = async (values) => {
    const {
      first_name,
      last_name,
      phone,
      appointment_date,
      start_time,
      duration,
      service,
      reason_for_visit,
    } = values;
    const data = {
      patient_id: clientDetails?.patient_id,
      first_name: clientDetails?.first_name || first_name,
      last_name: clientDetails?.last_name || last_name,
      patient_MRN: clientDetails?.patient_MRN,
      gender: clientDetails?.gender,
      dob: clientDetails?.dob,
      contact: clientDetails?.contact || phone,
      appointment_date: dayjs(appointment_date).format("YYYY-MM-DD"),
      time: dayjs(start_time).format("HH:mm:ss"),
      duration: duration || 15,
      service: service,
      appointment_with: selectedProvider?.name,
      provider_id: selectedProvider?.id,
      visit_reason: reason_for_visit,
      current_status: edit ? "Rescheduled" : "Scheduled",
    };
    try {
      const response = edit
        ? await doPatch(
            `/appointments/update/${selectedAppointment}/`,
            data
          )
        : await doPost("/appointments/make/", data);
      console.log(response, 'Appointment Response')
      if (response.status === 201 || response.status === 200) {
        Notification.success(edit ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add)
        handleCancel();
      } else if (response.status === 406) {
        Notification.warning("Provider not availble in this duration");
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  return (
    <>
      <Modal
        open={visible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        maskClosable={false}
        onCancel={onClose}
        footer={null}
        width={680}
        className="appointment_modal"
      >
        <div className="appointment_box">
          <div className="left">
            <Avatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
            <p style={{ color: "white" }}>
              {clientDetails?.first_name ? patient_name : "Searched Patient"}
            </p>
            <hr width="80%" />
            <h5>Selected Date and Time</h5>
            <p>{appointmentDate ? birthDateFormat(appointmentDate) : "Appointment Date"}</p>
            <p>{appointmentTime || "Appointment Time"}</p>
            <hr width="80%" />
            <h5>Appointment With</h5>
            <p> {appointmentWith || "Provider Name"}</p>
          </div>
          <Form
            layout="vertical"
            form={form}
            onValuesChange={handleFormValuesChange}
            // onFinish={handleOk}
            onFinish={handleAppoinmentSchedule}
            className="appointment-form"
          >
            <div className="right-header">
              <p className="mb-4"> Book an Appointment </p>
              {/* <Button
                type="primary"
                size="small"
                onClick={() => navigate(`/main/register_patient/`)}
              >
                Add New Patient
              </Button> */}
            </div>
            <Form.Item name="patient_name" label="Search Existing Patients">
              <AutoComplete
                showSearch
                options={nameOptions}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearch}
                placeholder="Search Patient"
              />
            </Form.Item>
            <Form.Item>
              <Checkbox onChange={notRegistered}>
                New Unregistered Patient or Client
                <Tooltip title="Check the box if the Patient or Client is not registered">
                  <QuestionCircleOutlined className="tooltip__icon" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
            {isNotRegistered && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="first_name" label="First Name">
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="last_name" label="Last Name">
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="phone" label="Phone">
                  <Input placeholder="Phone" maxLength={20} />
                </Form.Item>
              </>
            )}
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="appointment_date"
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Appointment Date",
                    },
                  ]}
                >
                  <DatePicker format="MM-DD-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="start_time" label="Start Time">
                  <TimePicker use12Hours format="h:mm a" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="duration" label="Duration">
                  <Select placeholder="Duration" defaultValue="15">
                    <Option value="15"> 15 minutes</Option>
                    <Option value="30"> 30 minutes</Option>
                    <Option value="45"> 45 minutes</Option>
                    <Option value="60"> 60 minutes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="service"
                  label="Choose Service"
                  rules={[{ required: true, message: "Service is Required" }]}
                >
                  <AutoComplete
                    options={serviceOptions}
                    allowClear={true}
                    // onSelect={onSelect}
                    onSearch={handleSearchServices}
                    placeholder="Search Service"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="appointment_with"
                  label="Provider"
                  rules={[{ required: true, message: "Provider is Required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search for doctors"
                    style={{ width: 200 }}
                    onSearch={handleProviderSearch}
                    onSelect={handleSelect}
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    {suggestions?.map((doctor) => (
                      <Option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="reason_for_visit" label="Reason For Visit">
              <Input.TextArea
                placeholder="Reason for visit"
                maxLength={300}
                showCount
              />
            </Form.Item>
            <div className="flex justify-between mt-[1.4rem]">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Book Appointment
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CalenderModal;
