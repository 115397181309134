import React, { useEffect, useState, useCallback, useMemo } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { dateFormat } from "../../../../utils/DateFormat";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Select,
  Dropdown,
  Button,
  Modal,
  Col,
  Card,
  Tooltip,
} from "antd";
import { doPatch, doGet } from "../../../../API/apis";
import { ageCalculator } from "../../../../utils/ageCalculator";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { SlRefresh } from "react-icons/sl";
import Notification from "../../../../components/Notification/Notification";
import { GetColumnSearchProps } from "../../../../components/TableSearch/TableSearch";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import { getWaitingRoomColumn } from "../../../../components/TableColumns/TableColumns";

const { confirm } = Modal;
const { Option } = Select;

const waitingRoomStatus = [
  {
    key: 1,
    label: "In Waiting Room",
  },
  {
    key: 2,
    label: "In Exam Room",
  },
  {
    key: 3,
    label: "Ready for Provider",
  },
  {
    key: 4,
    label: "With Provider",
  },
  {
    key: 5,
    label: "Ready for Lab Work",
  },
  {
    key: 6,
    label: "Ready for Imaging",
  },
  {
    key: 7,
    label: "Ready for Check Out",
  },
  {
    key: 8,
    label: "Check Out",
  },
];

const WaitingRoom = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [waitingList, setWaitingList] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { providers, getProviders, officeSetting, getOfficeSettings } =
    useStateGlobalContext();


  const handleViewAllWRP = ()=>{
    navigate('/main/waiting-room')
  }

  useEffect(() => {
    getProviders();
    getOfficeSettings();
  }, []);

  const rooms = useMemo(() => officeSetting?.exam_rooms, [officeSetting]);


  const coloumn2 = [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "4%",
      key: "serial_number",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Check in Date",
      ellipsis: true,
      align: "center",
      width: "8%",
      dataIndex: "checkin_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Patient Name",
      ellipsis: true,
      width: "10%",
      dataIndex: "patient",
      ...GetColumnSearchProps("patient"),
      render: (_, record) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            navigate(`/main/charts/patient/${record?.patient_id}/`);
          }}
        >
          {record?.patient}
        </Button>
      ),
    },
    {
      key: 3,
      title: "Age",
      ellipsis: true,
      align: "center",
      dataIndex: "dob",
      width: "6%",
      render: (text, record) => {
        const age = ageCalculator(text);
        const dobFormatted = <FormatDate date={record?.dob} />;
        return (
          <Tooltip title={dobFormatted}>
            <span>{`${age} y/o`}</span>
          </Tooltip>
        );
      }
    },    
    {
      key: 4,
      title: "Reason for Visit",
      ellipsis: true,
      width: "15%",
      dataIndex: "reason_of_visit",
    },
    {
      key: 5,
      title: "Service Name",
      ellipsis: true,
      width: "10%",
      dataIndex: "service_name",
      ...GetColumnSearchProps("service_name"),
    },
    {
      key: 6,
      title: "Provider",
      ellipsis: true,
      width: "15%",
      align: "center",
      dataIndex: "provider",
      render: (_, record) => (
        <Select
          style={{ width: 150 }}
          onChange={(value) => handleProviderChange(value, record)}
          value={record.provider}
        >
          {providers?.map((provider) => (
            <Option key={provider.id} value={`${provider?.name}`}>
              {provider.name}
            </Option>
          ))}
        </Select>
      ),
      filters: providers?.map((provider) => ({
        text: provider.name,
        value: `${provider.name}`,
      })),
      onFilter: (value, record) => record.provider === value,
    },
    {
      key: 7,
      title: "Room",
      ellipsis: true,
      width: "10%",
      align: "center",
      dataIndex: "room",
      render: (_, record) => (
        <Select
          style={{ width: 120 }}
          onChange={(value) => handleRoomChange(value, record)}
          value={record.room}
        >
          {Object.keys(rooms).map((room) => (
            <Option key={room} value={room}>
              {rooms[room]}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      key: 8,
      title: "Status",
      ellipsis: true,
      width: "17%",
      dataIndex: "status",
      render: (text, record) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "In Waiting Room":
            bgColor = "bg-blue-400";
            textColor = "text-white";
            break;
          case "In Exam Room":
            bgColor = "bg-blue-700";
            textColor = "text-white";
            break;
          case "Ready for Provider":
            bgColor = "bg-green-400";
            textColor = "text-white";
            break;
          case "With Provider":
            bgColor = "bg-green-600";
            textColor = "text-white";
            break;
          case "Ready for Lab Work":
            bgColor = "bg-red-400";
            textColor = "text-white";
            break;
          case "Ready for Imaging":
            bgColor = "bg-yellow-400";
            textColor = "text-white";
            break;
          case "Ready for Check Out":
            bgColor = "bg-gray-400";
            textColor = "text-white";
            break;
          case "Checked Out":
            bgColor = "bg-gray-200";
            textColor = "text-gray-500";
            break;
          default:
            bgColor = "bg-gray-200";
        }

        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
            title={<FormatDateTime date={record?.status_updated_on} type="datetime"/>}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: 9,
      title: "Action",
      align: "center",
      fixed: "right",
      width: "5%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: waitingRoomStatus.map((item) => ({
              ...item,
              onClick: () => {
                if (
                  item.key === 1 ||
                  item.key === 2 ||
                  item.key === 3 ||
                  item.key === 4 ||
                  item.key === 5 ||
                  item.key === 6 ||
                  item.key === 7
                ) {
                  handleWaitingRoomStatus(record, item.key);
                } else if (item.key === 8) {
                  handleCheckout(record, item.key);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleCheckout = (record, itemKey) => {
    confirm({
      title: `Checkout for ${record?.patient}?`,
      icon: null,
      okText: "Check Out",
      onOk() {
        handleWaitingRoomStatus(record, itemKey);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleProviderChange = useCallback(async (value, record) => {
    setSelectedProvider(value);
    const data = {
      provider: value,
    };
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Provider Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleRoomChange = useCallback(async (value, record) => {
    setSelectedRoom(value);
    const data = {
      room: value,
    };
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Room Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleWaitingRoomStatus = useCallback(async (record, key) => {
    let data = {};
    switch (key) {
      case 1:
        data = {
          status: "In Waiting Room",
        };
        break;
      case 2:
        data = {
          status: "In Exam Room",
        };
        break;
      case 3:
        data = {
          status: "Ready for Provider",
        };
        break;
      case 4:
        data = {
          status: "With Provider",
        };
        break;
      case 5:
        data = {
          status: "Ready for Lab Work",
        };
        break;
      case 6:
        data = {
          status: "Ready for Imaging",
        };
        break;
      case 7:
        data = {
          status: "Ready for Check Out",
        };
        break;
      case 8:
        data = {
          status: "Checked Out",
        };
        break;
      default:
        data = {
          status: "In Waiting Room",
        };
    }
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Status updated Successfully");
        getWaitingList();
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  }, []);

  const getWaitingList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await doGet("/waiting-room/get/");
      if (response.status === 200) {
        const sortedTasks = response.data.sort(
          (a, b) => new Date(b.checkin_time) - new Date(a.checkin_time)
        );
        setWaitingList(sortedTasks);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  }, []);



  useEffect(() => {
    getWaitingList();
  }, [getWaitingList]);

  return (
    <Col xl={24}>
      <Card className="mt-4 shadow-sm mb-10" >
        <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-lg">Waiting Room </h1>
        <div>
        <Tooltip title="Refresh" onClick={getWaitingList}>
              <SlRefresh size={16} className="cursor-pointer" />
            </Tooltip>
        <Button className="ml-4" onClick={handleViewAllWRP}>View All</Button>
        </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          loading={loading}
          columns={coloumn2}
          dataSource={waitingList}
          size="small"
          pagination={false}
          scroll={{
            x: 1550,
            y: 230,
          }}
        />
      </Card>
    </Col>
  );
};

export default WaitingRoom;
