import React, { createContext, useContext, useState } from "react";
import { doGet } from "../API/apis";
import Notification from "../components/Notification/Notification";

const InvoiceContext = createContext();
export const InvoiceContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [totalInvoice, setTotalInvoice] = useState([]);
    const [visits, setTotalVisits] = useState([])
    const [unpaidInvoicesCount, setUnpaidInvoicesCount] = useState([])

  const handleApiError = (error) => {
    Notification.error(error);
    console.error(error);
  };

  

  const getTotalServiceInvoice = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/invoice/total-invoice-amount-get/${id}/`);
      if (response.status === 200) {
        setTotalInvoice(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUnpaidInvoicesCount = async (id) => {
    setLoading(true)
    try{
      const response = await doGet(`/invoice/clinic-unpaid-invoice-count/${id}/`);
      if (response?.status === 200) {
        setUnpaidInvoicesCount(response?.data?.unpaid_count);
      }
    }catch(error){
      handleApiError(error)
    } finally{
      setLoading(false)
    }
  }

  const getTotalVisits = async (id) => {
    try{
      const response = await doGet(`/visits/total-encounters/${id}/`);
      if (response.status === 200) {
        setTotalVisits(response.data);
      }
    } catch(error){
      handleApiError(error)
    }
    finally{
      setLoading(false)
    }
  };


 

  return (
    <InvoiceContext.Provider
      value={{
        loading,
        setLoading,
        totalInvoice,
        getTotalServiceInvoice,
        unpaidInvoicesCount,
        getUnpaidInvoicesCount,
        visits,
        getTotalVisits
        }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
export const useStateInvoicesContext = () => useContext(InvoiceContext);
