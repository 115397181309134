import React from 'react'
import Notification from '../Notification/Notification';
import { AutoComplete, Radio, Space, Tooltip } from 'antd';
import { BsBank, BsCardChecklist, BsCashCoin, BsFillCreditCard2BackFill } from 'react-icons/bs';
import { useSearchProduct } from '../../API/apiUtils';

const radioOptions = [
  { label: "CARD/POS", icon: <BsFillCreditCard2BackFill />, value: "card" },
  { label: "CASH", icon: <BsCashCoin />, value: "cash" },
  { label: "BANK WIRE", icon: <BsBank />, value: "bank" },
  { label: "CHECK", icon: <BsCardChecklist />, value: "check" },
];

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      Notification.success('Copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  export const CopyableText = ({ text, className="" }) => {
    return (
      <Tooltip title="Click to copy">
        <span
          onClick={() => copyToClipboard(text)}
          className={`${className} cursor-pointer`}
        >
          {text}
        </span>
      </Tooltip>
    );
  };

  export const RadioGroup = ({ value, onChange, ...rest }) => {
    return (
      <Radio.Group value={value} onChange={onChange} {...rest}   className="radio-tile-group" buttonStyle="solid">
        {radioOptions.map((option, index) => (
          <Radio.Button key={index} value={option.value}>
            <span className="flex flex-row justify-center items-center gap-2">
              {option.icon && <span>{option.icon}</span>}
              <p>{option.label}</p>
            </span>
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  };

  export const SearchProduct = ({ options, onSelect, onSearch }) => {
  
    return (
      <AutoComplete
        options={options}
        allowClear={true}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder="Search Products"
      />
    );
  };

  export const invoiceStatus = [
    { key: 1, label: "Paid" },
    { key: 2, label: "unpaid" },
    { key: 3, label: "Cancelled" },
  ];

  export const encounter_Status = [
    { key: 1, label: "Draft" },
    { key: 2, label: "Signed" },
    { key: 3, label: "Cancelled" },
  ];

  export const RaceRadioGroup = ({ defaultValue = "notAsked", onChange }) => {
    return (
      <Radio.Group className="mb-4" defaultValue={defaultValue} onChange={onChange}>
        <Space direction="vertical">
          <Radio value="americanIndian">American Indian or Alaska Native</Radio>
          <Radio value="asian">Asian</Radio>
          <Radio value="black">Black or African American</Radio>
          <Radio value="nativeHawaiian">Native Hawaiian or Other Pacific Islander</Radio>
          <Radio value="white">White</Radio>
          <Radio value="other">Other</Radio>
          <Radio value="decline">Decline to specify</Radio>
          <Radio value="notAsked">Provider didn't ask</Radio>
        </Space>
      </Radio.Group>
    );
  };

  export const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };