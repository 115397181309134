import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, DatePicker, Form, Input, Modal} from "antd";
import { doPatch, doPost } from "../../../../../API/apis";
import dayjs from "dayjs";
import Notification from "../../../../../components/Notification/Notification";
import { isValidCardNumber } from "../../../../../utils/validator";
import LableDisplay from '../../../../../components/LabelDisplay/LabelDisplay'

const { Item } = Form;

const CreditDebit = ({
  visible,
  onClose,
  edit,
  record,
  getPaymentCardInfo,
  view,
  onCloseView,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const monthFormat = "MM/YY";

  useEffect(() => {
    if (edit && record) {
      form.setFieldsValue({
        card_type: record.card_type,
        full_name: record.card_holder_name,
        card_number: record.card_number,
        expiry_date: dayjs(record?.exp_date, monthFormat),
        security_code: record.cvv,
        zip_postal_code: record.zipcode,
        notes: record.notes,
      });
    }
  }, [edit, record, form]);

  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  const handlePaymentMethod = async (values) => {
    const selectedDate = values.expiry_date;
    const currentDate = dayjs();
  
    if (selectedDate.isBefore(currentDate, 'month')) {
      Notification.error("The card has already expired. Please enter a valid expiry date.");
      return;
    }
  
    const data = {
      card_type: values.card_type,
      card_holder_name: values.full_name,
      card_number: values.card_number,
      issue_date: values.issue_date,
      exp_date: values.expiry_date.format(monthFormat),
      cvv: values.security_code,
      zipcode: values.zip_postal_code,
      notes: values.notes,
    };
  
    try {
      const response = edit
        ? await doPatch(
            `/invoice/payment-method-update/${id}/${record?.id}/`,
            data
          )
        : await doPost(`/invoice/payment-method-add/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        const successMessage = edit
          ? "Payment Method Updated Successfully"
          : "Payment Method Added Successfully";
        Notification.success(successMessage);
        getPaymentCardInfo();
        handleCancel();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };
  

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        title={
          edit
            ? "Update Credit / Debit Card Details"
            : "Credit / Debit Card Details"
        }
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={handlePaymentMethod}>
          <Item
            label="Card Type"
            name="card_type"
            rules={[{ required: true, message: "Please enter Card Type" }]}
          >
            <Input placeholder="Card Type" />
          </Item>
          <Item
            label="Card Holder Name"
            name="full_name"
            rules={[
              { required: true, message: "Please enter Card Holder Name" },
            ]}
          >
            <Input placeholder="Card Holder Name" maxLength={30}/>
          </Item>
          <Item
            label="Card Number"
            name="card_number"
            rules={[{ required: true, message: "Please enter Card Number" },
              {
                validator: isValidCardNumber,
              },
            ]}
      
          >
            <Input placeholder="Card Number" maxLength={20}/>
          </Item>
          <div className="flex gap-4">
            <Item
              label="CVV"
              name="security_code"
              rules={[{ required: true, message: "Please enter CVV" }]}
            >
              <Input placeholder="Security Code" maxLength={4}/>
            </Item>
            <Item
              label="Expiry MM/YY"
              name="expiry_date"
              rules={[
                { required: true, message: "Please Select Valid Thru Date" },
              ]}
            >
              <DatePicker picker="month"  placeholder="MM/YY" format={monthFormat} />
            </Item>
          </div>
          <Item label="Billing Zip Code / Postal Code" name="zip_postal_code">
            <Input placeholder="Billing Zip Code / Postal Code" maxLength={30}/>
          </Item>
          <Item label="Notes" name="notes">
            <Input.TextArea placeholder="Notes" showCount maxLength={300} />
          </Item>

          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" className="ml-2" htmlType="submit">
              {edit ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      {view && (
        <Modal
          open={view}
          title="Debit / Credit Card Details"
          footer={null}
          onCancel={onCloseView}
          closeIcon={
            <Button size="small" className="app-close-icon" shape="round">
              Close
            </Button>
          }
        >
          <LableDisplay title={"Card Type"} description={record?.card_type}/>
          <LableDisplay title={"Card Holder Name"} description={record?.card_holder_name}/>
          <LableDisplay title={"Card Number"} description={record?.card_number}/>
          <LableDisplay title={"Security Code"} description={record?.cvv}/>
          <LableDisplay title={"Expiry Date"} description={record?.exp_date}/>
          <LableDisplay title={"Zip / Postal Code"} description={record?.zipcode}/>
          <LableDisplay title={"Notes"} description={record?.notes}/>
        </Modal>
      )}
    </>
  );
};

export default CreditDebit;
