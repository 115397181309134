import React, { useState, useEffect } from "react";
import { Table, DatePicker, Button, Card, Form } from "antd";
import { doGet } from "../../../../../API/apis";
import { CSVLink } from "react-csv";
import { formatDate, formatTime } from "../../../../../utils/DateFormat";
import ViewExternalLab from "../../../PatientSummary/Labs/component/ViewExternalLab";
import { useOutletContext } from "react-router-dom";
import { GetColumnSearchProps } from "../../../../../components/TableSearch/TableSearch";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const { RangePicker } = DatePicker;


const extractLab = (labData) => {
  return labData?.map((lab) => lab.lab_name).join(", ");
};

const extractServiceType = (labData) => {
  return labData?.map((lab) => lab.service_type).join(", ");
};

const extractServiceGroup = (labData) => {
  return labData?.map((lab) => lab.service_group).join(", ");
};


const ExternalLabReports = () => {
  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [visible, setVisible] = useState(false)
  const [viewLabData ,setViewLabData] = useState([])
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
  useOutletContext()
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const transformedData = (records) => {
    if (!records || !Array.isArray(records)) {
      return [];
    }
  
    return records.map((record) => ({
      ID: record?.id,
      "LAB ID": record?.externalUID,
      "First Name": record?.patient_first_name,
      "Middle Name": record?.patient_middle_name,
      "Last Name": record?.patient_last_name,
      "Lab Name": extractLab(record?.lab_name),
      "Serive Type": extractServiceType(record?.lab_name),
      "Service Group": extractServiceGroup(record?.lab_name),
      "Added By": record?.added_by,
      "Issue Date": formatDate(record?.date_added, officeDateFormat, officeTimezone),
      "Issue Time": formatTime(record?.date_added, officeTimeFormat, officeTimezone)
    }));
  };

  const viewExternalLab = (record)=>{
    setVisible(true)
    setViewLabData(record)
  }

  const onClose = ()=>{
    setVisible(false)
  }

  const coloumn = [
    {
      key:"#",
      title:"#",
      align:'center',
      render : (_, __, index) => index + 1
    },
    {
      key: 7,
      title: "LAB ID",
      align:'center',
      width:'15%',
      dataIndex:'externalUID',
      ...GetColumnSearchProps('externalUID'),
      render: (text, record)=> <Button type="link" onClick={()=> viewExternalLab(record)}>{text}</Button>,
     
    },
    {
      key: "name",
      title: "Patient Full Name",
      align:'center',
      render: (_, record) => (
        <span>
          {record?.patient_first_name} {record?.patient_middle_name ? record?.patient_middle_name : " "}{" "}
          {record?.patient_last_name}
        </span>
      ),
    },
    {
      key: 1,
      title: "Lab Name",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.lab_name).join(", ")}</span>
      ),
    },
  
    {
      key: 3,
      title: "Service Type",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.service_type).join(", ")}</span>
      ),
    },
    {
      key: 5,
      title: "Service Group",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.service_group).join(", ")}</span>
      ),
    },
    {
      key: 4,
      title: "Date",
      dataIndex: "date_added",
      render: (text) => <FormatDateTime date={text} type="date"/>,
    },
    {
      key: 6,
      title: "Time",
      dataIndex: "date_added",
      render: (text) => <FormatDateTime date={text} type="time"/>,
    },
  ];

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getPatientList();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getPatientList = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/external-labs-reports/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setPatientList(response.data.reverse());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatientList();
  }, [searchClicked]);

  return (
    <div>
      <Card className="shadow-class mb-4 mt-4">
        <Form layout="vertical">
          <div className="flex items-center">
            <Form.Item label="Select Date Range for External">
              <RangePicker
                size="medium"
                onChange={handleDateRangeChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button type="primary" className="ml-2 mt-2" onClick={handleSearch}>
              Search
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setPatientList([])}>
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class" loading={loading}>
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {role !== 'nurse' && role !=='doctor' && patientList?.length > 0 && (
            <Button  type="primary">
              <CSVLink
                data={transformedData(patientList)}
                filename={"External_Labs_Record.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        {patientList && <Table size="small" dataSource={patientList} columns={coloumn} pagination={false}/>}
      </Card>

      <ViewExternalLab visible={visible} onClose={onClose} rxData={viewLabData}/>
    </div>
  );
};

export default ExternalLabReports;
