import { DateTime } from "luxon";

export const formatTime = (date, officeTimeFormat, officeTimezone) => {
  return  DateTime.fromISO(date, { zone: 'UTC' }).setZone(officeTimezone).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: officeTimeFormat });
};



export const formatDate = (date, format, officeTimezone)=>{
  return DateTime.fromISO(date, { zone: 'UTC' }).setZone(officeTimezone).setLocale(format).toLocaleString()
}

export const formatDateTime = (date, dateFormat, timeFormat, officeTimezone) => {
  const formattedDate = formatDate(date, dateFormat, officeTimezone);
  const formattedTime = formatTime(date, timeFormat, officeTimezone);
  return `${formattedDate}, ${formattedTime}`;
};


export const formattedTime = (dateTime, officeTimeFormat, officeTimezone) => {
  return DateTime.fromJSDate(dateTime, { zone: officeTimezone }).toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: officeTimeFormat,
    timeZone: officeTimezone
  });
};



export const dateTimeFormat = (date_time) => {
  const dateObj = new Date(date_time);

  const localDate = dateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const localTime = dateObj.toLocaleTimeString();
  const localDateTime = `${localDate} ${localTime}`;
  return localDateTime;
};



export const birthDateFormat = (dateString) => {
  // Create a Date object from the provided dateString
  const date = new Date(dateString);
  
  const dob = date?.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
  
  return dob;
};


export const dateFormat = (date_time, format ="en-US") => {
  let officeDateFormat = format ==="YYYY/MM/DD"? 'zn-ch' : format ==='MM/DD/YYYY' ? 'en-US' : 'en-GB'
  const dateObj = new Date(date_time);

  const localDate = dateObj.toLocaleDateString(officeDateFormat, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return localDate;
};


export const monthYearFormat = (date_time) =>{
  const dateObj = new Date(date_time);

  const localDate = dateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
  });
  return localDate;
}


export const timeFormat = (date_time) => {
  const dateObj = new Date(date_time);

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const localTime = dateObj.toLocaleTimeString(undefined, options);
  return localTime;
};




export const convertToAMPM =(time24) => {
  var arr = time24.split(":");
  var hours = parseInt(arr[0], 10);
  var minutes = parseInt(arr[1], 10);
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in AM/PM
  var minutesStr = minutes < 10 ? '0' + minutes : minutes;
  var time12 = hours + ':' + minutesStr + ' ' + ampm;
  return time12;
}