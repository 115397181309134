import React, { useEffect, useState } from "react";
import { Card, Col, Form, Select, Input, Button } from "antd";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { doPatch, doPost } from "../../../../../API/apis";
import { discountValidator } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";

const { Option } = Select;
const { Item } = Form;

const Discounts = () => {
  const [numDiscounts, setNumDiscounts] = useState(1);
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState({});
  const { officeSetting, getOfficeSettings } = useStateGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getOfficeSettings(); 
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (officeSetting && officeSetting.discounts) {
      setDiscountData(officeSetting.discounts);
      setNumDiscounts(Object.keys(officeSetting.discounts).length);
    }
  }, [officeSetting]);


  const handleNumDiscountChange = (value) => {
    const updatedDiscountData = { ...discountData };
    const currentNumDiscounts = Object.keys(discountData).length;
  
    if (value > currentNumDiscounts) {
      // Add new discounts
      for (let i = currentNumDiscounts + 1; i <= value; i++) {
        updatedDiscountData[`discount${i}`] = { name: "", value: "" };
      }
    } else if (value < currentNumDiscounts) {
      // Remove last discounts
      for (let i = currentNumDiscounts; i > value; i--) {
        delete updatedDiscountData[`discount${i}`];
      }
    }
  
    setNumDiscounts(value);
    setDiscountData(updatedDiscountData);
  };
  

  const handleDiscountChange = (index, key, value) => {

    if (key === "value") {
      const discountPattern = /^([0-9]|[1-9][0-9]|100)$/;
      if (!discountPattern.test(value) && value !== "") {
        Notification.error("Discount value should be a whole number between 0 and 100.");
        return;
      }
    }
  
    setDiscountData((prevData) => ({
      ...prevData,
      [`discount${index + 1}`]: {
        ...prevData[`discount${index + 1}`],
        [key]: value,  
      },
    }));
  };
  
  
  
  

  const handleAddDiscount = async () => {
    const data = {
      discounts: discountData,
    };

    try {
      const office_ID = officeSetting?.id;
      const response = office_ID
        ? await doPatch("/user/office-settings/update/?q=basic/&update=true", data)
        : await doPost("/user/office-settings/?q=basic/", data);
      if (response.status === 200) {
        Notification.success("Discounts Added Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const discountInputs = [];
  for (let i = 0; i < numDiscounts; i++) {
    discountInputs.push(
      <div key={i} className="flex justify-between">
        <Item label={`Discount Name ${i + 1}`}>
          <Input
            value={discountData[`discount${i + 1}`]?.name || ""}
            placeholder={`Discount Name`}
            onChange={(e) => handleDiscountChange(i, "name", e.target.value)}
            maxLength={30}
          />
        </Item> 
        <Item label={`Discount value`} 
        // name={`discount${i + 1}`} 
        rules={[{ validator: discountValidator }]}>
          <Input
            type="number"
            style={{ width: "100px" }}
            value={discountData[`discount${i + 1}`]?.value || ""}
            placeholder={`Discount value %`}
            onChange={(e) => handleDiscountChange(i, "value", e.target.value)}
            maxLength={3}
            max={100}
            suffix="%"
          />
        </Item>
      </div>
    );
  }

  return (
    <Col span={8} className="ml-4">
      <Card className="shadow-class" loading={loading}>
        <Form layout="vertical">
          <Form.Item label="Number of discounts">
            <Select value={numDiscounts} onChange={handleNumDiscountChange}>
              {Array.from({ length: 99 }, (_, index) => index + 1).map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {discountInputs}
        </Form>
        <Button type="primary" onClick={handleAddDiscount}>
          Save
        </Button>
      </Card>
    </Col>
  );
};

export default Discounts;
