import React, { useState } from 'react';
import { Tabs } from 'antd';
import ProductReports from './ProductReports';
import GuestCheckoutReports from './GuestCheckoutReports';

const { TabPane } = Tabs;

const InventoryReports = () => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={handleTabChange} size='large' type="card">
        <TabPane tab="Product Reports" key="1">
          <ProductReports/>
        </TabPane>
        <TabPane tab="Guest Checkout Reports" key="2">
          <GuestCheckoutReports/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default InventoryReports;