import React, { useEffect , useState} from "react";
import { Table, Col , message, Card} from "antd";
import { doGet } from "../../../../API/apis";
import Notification from "../../../../components/Notification/Notification";



const OutOfStock = () => {
const [loading, setLoading] = useState(false);
const [lowInStockProducts, setLowInStockProducts] = useState(null);

const coloumns = [
    {
      title: "Brand Name",
      dataIndex: "medicine_name",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
  ];

  const getLowInStockProuducts = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/inventory/low-stock/`);
      if (response.status === 200) {
        setLowInStockProducts(response?.data?.low_stock);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally{
      setLoading(false)
    }
  };

  useEffect(()=>{
    getLowInStockProuducts()
  }, [])

  return (
    <Col xl={12}>
    <Card>
    <h1 className="mb-2"> Low In Stock </h1>
    <Table
      columns={coloumns}
      dataSource={lowInStockProducts}
      loading={loading}
      size="small"
      pagination={false}
      scroll={{
        y: 200,
      }}
    />
    </Card>
    </Col>
  );
};

export default OutOfStock;
