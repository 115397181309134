import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import { Modal, Input, Form, Select, AutoComplete, DatePicker, Row, Col, TimePicker, Button , Avatar} from "antd";
import { birthDateFormat } from "../../utils/DateFormat";
import { doGet, doPost } from "../../API/apis";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import Notification from "../Notification/Notification";

const {Option} = Select


const ProfileAppointment = ({ visible, onClose, firstName , middleName, lastName , MRN , patientID , dob, contact , gender}) => {
    const [form] = Form.useForm()
    const [startTime, setStartTime] = useState(null);
    const [options, setOptions] = useState(null)
    const [suggestions, setSuggestions] = useState([]);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [appointmentTime, setAppointmentTime] = useState(null);
    const [selectedProvider, setSelectedDProvider] = useState(null);
    const [appointmentWith, setAppointmentWith ] = useState(null)
    const {  providers, getProviders } = useStateGlobalContext();

    useEffect(()=>{
        getProviders()
    },[])

    const handleFormValuesChange = (changedValues, allValues) => {
        if (
          "appointment_date" in changedValues ||
          "start_time" in changedValues ||
          "duration" in changedValues ||
          "appointment_with" in changedValues
        ) {
          const selectedDate = allValues["appointment_date"];
          const provider = allValues["appointment_with"]
          const selectedStartTime =
            allValues["start_time"] || dayjs().startOf("day");
          const selectedDuration = allValues["duration"] || 15;
    
          const formattedDate = dayjs(selectedDate).format("MM-DD-YYYY");
          const formattedStartTime = selectedStartTime.format("h:mm a");
          const formattedEndTime = selectedStartTime
            .add(selectedDuration, "minutes")
            .format("h:mm a");
    
          setStartTime(formattedStartTime);
          setAppointmentDate(formattedDate);
          setAppointmentWith(provider)
          setAppointmentTime(`${formattedStartTime} - ${formattedEndTime}`);
        }
      };

//search services

const handleSearchServices = async (value) => {
    setOptions([]);

    if (value.length > 2) {
      try {
        const response = await doGet(
          `/services/search-services/?q=${value}`
        );
        if (response.status === 200) {
          const filteredOptions = response.data?.map((service) => ({
            id: service?.id,
            value: service?.service_names,
            label: <span>{service?.service_names}</span>,
          }));
          setOptions(filteredOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  //Provider Search Function

  const handleProviderSearch = (value) => {
    const filteredSuggestions = providers?.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSelect = (value, option) => {
    const selectedProviderID = option.key;
    const selectedProviderName = option.children;
    setSelectedDProvider({
      id: selectedProviderID,
      name: selectedProviderName,
    });
  };

  const handleCancel = ()=>{
    onClose()
    form.resetFields()
    setSelectedDProvider(null)
    setAppointmentWith(null)
    setAppointmentDate(null)
    setAppointmentTime(null)
  }


  //BOok Appointment
  const handleAppoinmentSchedule = async (values) => {
    const {
      appointment_date,
      start_time,
      duration,
      service,
      reason_for_visit,
    } = values;
    const data = {
      patient_id: patientID,
      first_name: firstName,
      last_name: lastName,
      patient_MRN: MRN,
      gender: gender,
      dob: dob,
      contact: contact,
      appointment_date: dayjs(appointment_date).format("YYYY-MM-DD"),
      time: dayjs(start_time).format("HH:mm:ss"),
      duration: duration || 15,
      service: service,
      appointment_with: selectedProvider?.name,
      provider_id: selectedProvider?.id,
      visit_reason: reason_for_visit,
      current_status: "Scheduled",
    };
    try {
      const response = await doPost("/appointments/make/", data);
      if (response.status === 201) {
        Notification.success("Appointment Created Successfully")
        handleCancel();
      } 
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };
  

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        width={680}
        className="appointment_modal"
        footer={null}
      >
        <div className="appointment_box">
          <div className="left">
          <Avatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
            <p>Name: {firstName + " " + lastName}</p>
            <p>DOB: {birthDateFormat(dob)}</p>
            <p>Gender: {gender}</p>
            <p>Contact: {contact}</p>
            <hr width="80%" />
            <h5>Selected Date and Time</h5>
            <p>{appointmentDate ? birthDateFormat(appointmentDate) : "Appointment Date"}</p>
            <p>{appointmentTime || "Appointment Time"}</p>

            <hr width='80%'/>
            <h5> Appointment With: </h5>
            <p> {appointmentWith || "Provider Name"}</p>
          </div>
          <div className="right">
            <Form form={form} layout="vertical" className="mt-4 p-4" onValuesChange={handleFormValuesChange} onFinish={handleAppoinmentSchedule}>
              <p className="font-semibold mb-2">Book Appointment </p>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="appointment_date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Appointment Date",
                      },
                    ]}
                  >
                    <DatePicker format="MM-DD-YYYY" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="start_time" label="Start Time">
                    <TimePicker use12Hours format="h:mm a" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="duration" label="Duration">
                    <Select placeholder="Duration" defaultValue="15">
                      <Option value="15"> 15 minutes</Option>
                      <Option value="30"> 30 minutes</Option>
                      <Option value="45"> 45 minutes</Option>
                      <Option value="60"> 60 minutes</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="service"
                  label="Choose Service"
                  rules={[{ required: true, message: "Service is Required" }]}
                >
                  <AutoComplete
                    options={options}
                    allowClear={true}
                    // onSelect={onSelect}
                    onSearch={handleSearchServices}
                    placeholder="Search Service"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="appointment_with"
                  label="Provider"
                  rules={[{ required: true, message: "Provider is Required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search for doctors"
                    style={{ width: 200 }}
                    onSearch={handleProviderSearch}
                    onSelect={handleSelect}
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    {suggestions?.map((doctor) => (
                      <Option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="reason_for_visit" label="Reason For Visit">
              <Input.TextArea
                placeholder="Reason for visit"
                maxLength={300}
                showCount
              />
            </Form.Item>

            <div className="flex justify-end">
                <Button onClick={handleCancel}>
                    Cancel
                </Button>
                <Button type='primary' htmlType="submit" className="ml-2">
                    Book Appointment
                </Button>
            </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAppointment;
