import React, { useEffect, useState, useCallback, useMemo } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Table, Select, Dropdown, Button, Modal, Col, Tooltip } from "antd";
import { doPatch, doGet, doDelete } from "../../../API/apis";
import { ageCalculator } from "../../../utils/ageCalculator";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { GetColumnSearchProps } from "../../../components/TableSearch/TableSearch";
import { SlRefresh } from "react-icons/sl";
import Notification from "../../../components/Notification/Notification";
import FormatDateTime from "../../../components/FormatDate/FormatDateTime";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../components/FormatDate/FormatDate";

const { Option } = Select;

const waitingRoomStatus = [
  {
    key: 1,
    label: "In Waiting Room",
  },
  {
    key: 2,
    label: "In Exam Room",
  },
  {
    key: 3,
    label: "Ready for Provider",
  },
  {
    key: 4,
    label: "With Provider",
  },
  {
    key: 5,
    label: "Ready for Lab Work",
  },
  {
    key: 6,
    label: "Ready for Imaging",
  },
  {
    key: 7,
    label: "Ready for Check Out",
  },
  {
    key: 8,
    label: "Check Out",
  },
];


const WaitingRoom = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [waitingList, setWaitingList] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { providers, officeSetting, profile } = useStateGlobalContext();

  document.title = "Waiting Room | American EMR";

  

  const rooms = useMemo(
    () => officeSetting && officeSetting?.exam_rooms,
    [officeSetting]
  );
  
  const coloumn2 = [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "4%",
      key: "serial_number",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Check in Date",
      ellipsis: true,
      align: "center",
      width: "8%",
      dataIndex: "checkin_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Patient Name",
      ellipsis: true,
      width: "10%",
      dataIndex: "patient",
      ...GetColumnSearchProps("patient"),
      render: (_, record) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            navigate(`/main/charts/patient/${record?.patient_id}/`);
          }}
        >
          {record?.patient}
        </Button>
      ),
    },
    {
      key: 3,
      title: "Age",
      ellipsis: true,
      align: "center",
      dataIndex: "dob",
      width: "6%",
      render: (text, record) => {
        const age = ageCalculator(text);
        const dobFormatted = <FormatDate date={record?.dob} />;
        return (
          <Tooltip title={dobFormatted}>
            <span>{`${age} y/o`}</span>
          </Tooltip>
        );
      }
    },    
    {
      key: 4,
      title: "Reason for Visit",
      ellipsis: true,
      width: "15%",
      dataIndex: "reason_of_visit",
    },
    {
      key: 5,
      title: "Service Name",
      ellipsis: true,
      width: "10%",
      dataIndex: "service_name",
      ...GetColumnSearchProps("service_name"),
    },
    {
      key: 6,
      title: "Provider",
      ellipsis: true,
      width: "15%",
      align: "center",
      dataIndex: "provider",
      render: (_, record) => (
        <Select
          style={{ width: 150 }}
          onChange={(value) => handleProviderChange(value, record)}
          value={record.provider}
        >
          {providers?.map((provider) => (
            <Option key={provider.id} value={`${provider?.name}`}>
              {provider.name}
            </Option>
          ))}
        </Select>
      ),
      filters: providers?.map((provider) => ({
        text: provider.name,
        value: `${provider.name}`,
      })),
      onFilter: (value, record) => record.provider === value,
    },
    {
      key: 7,
      title: "Room",
      ellipsis: true,
      width: "10%",
      align: "center",
      dataIndex: "room",
      render: (_, record) => (
        <Select
          style={{ width: 120 }}
          onChange={(value) => handleRoomChange(value, record)}
          value={record.room}
        >
          {Object.keys(rooms).map((room) => (
            <Option key={room} value={room}>
              {rooms[room]}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      key: 8,
      title: "Status",
      ellipsis: true,
      width: "17%",
      dataIndex: "status",
      render: (text, record) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "In Waiting Room":
            bgColor = "bg-blue-400";
            textColor = "text-white";
            break;
          case "In Exam Room":
            bgColor = "bg-blue-700";
            textColor = "text-white";
            break;
          case "Ready for Provider":
            bgColor = "bg-green-400";
            textColor = "text-white";
            break;
          case "With Provider":
            bgColor = "bg-green-600";
            textColor = "text-white";
            break;
          case "Ready for Lab Work":
            bgColor = "bg-red-400";
            textColor = "text-white";
            break;
          case "Ready for Imaging":
            bgColor = "bg-yellow-400";
            textColor = "text-white";
            break;
          case "Ready for Check Out":
            bgColor = "bg-gray-400";
            textColor = "text-white";
            break;
          case "Checked Out":
            bgColor = "bg-gray-200";
            textColor = "text-gray-500";
            break;
          default:
            bgColor = "bg-gray-200";
        }

        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
            title={<FormatDateTime date={record?.status_updated_on} type="datetime"/>}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: 9,
      title: "Action",
      align: "center",
      fixed: "right",
      width: "5%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: waitingRoomStatus.map((item) => ({
              ...item,
              onClick: () => {
                if (
                  item.key === 1 ||
                  item.key === 2 ||
                  item.key === 3 ||
                  item.key === 4 ||
                  item.key === 5 ||
                  item.key === 6 ||
                  item.key === 7
                ) {
                  handleWaitingRoomStatus(record, item.key);
                } else if (item.key === 8) {
                  handleCheckout(record, item.key);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleCheckout = (record, itemKey) => {
    Modal.confirm({
      title: `Checkout for ${record?.patient}?`,
      icon: null,
      okText: "Check Out",
      className: "custom-modal-checkout-warning",
      onOk() {
        handleWaitingRoomStatus(record, itemKey);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleProviderChange = useCallback(async (value, record) => {
    setSelectedProvider(value);
    try {
      const response = await doPatch(`/waiting-room/update/${record.id}/`, {
        provider: value,
      });
      if (response.status === 201) {
        Notification.success("Provider Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleRoomChange = useCallback(async (value, record) => {
    setSelectedRoom(value);
    const data = {
      room: value,
    };
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Room Changed Successfully");
        getWaitingList();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleWaitingRoomStatus = useCallback(async (record, key) => {
    let data = {};
    switch (key) {
      case 1:
        data = {
          status: "In Waiting Room",
        };
        break;
      case 2:
        data = {
          status: "In Exam Room",
        };
        break;
      case 3:
        data = {
          status: "Ready for Provider",
        };
        break;
      case 4:
        data = {
          status: "With Provider",
        };
        break;
      case 5:
        data = {
          status: "Ready for Lab Work",
        };
        break;
      case 6:
        data = {
          status: "Ready for Imaging",
        };
        break;
      case 7:
        data = {
          status: "Ready for Check Out",
        };
        break;
      case 8:
        data = {
          status: "Checked Out",
        };
        break;
      default:
        data = {
          status: "In Waiting Room",
        };
    }
    try {
      const response = await doPatch(
        `/waiting-room/update/${record.id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Status updated Successfully");
        getWaitingList();
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  }, []);

  const getWaitingList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await doGet("/waiting-room/report-get/");
      if (response.status === 200) {
        const sortedTasks = response.data.sort((a, b) => {
          // Custom sorting logic
          if (a.status === "Checked Out" && b.status !== "Checked Out") {
            return 1; // "Checked Out" status comes after others
          } else if (a.status !== "Checked Out" && b.status === "Checked Out") {
            return -1; // Others come before "Checked Out" status
          }

          // If statuses are the same, sort by checkin_time
          return new Date(b.checkin_time) - new Date(a.checkin_time);
        });

        setWaitingList(sortedTasks);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = useCallback(() => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content:
        "Are you sure you want to Remove All Checked Out Patients Record?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: confirmDelete,
    });
  }, []);

  const confirmDelete = useCallback(async () => {
    try {
      const response = await doDelete("/waiting-room/delete-old-records/");
      if (response.status === 200) {
        Notification.success(
          "All Checked Out Patients Record Deleted Successfully"
        );
        getWaitingList();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  }, [getWaitingList]);

  useEffect(() => {
    getWaitingList();
  }, [getWaitingList]);

  return (
    <Col xl={24}>
      <div className="flex justify-between mb-2">
        <h1 className="font-bold text-lg">Waiting Room </h1>
        <div>
          <Tooltip title="Refresh" onClick={getWaitingList}>
            <SlRefresh size={16} className="cursor-pointer mr-4" />
          </Tooltip>

          {((profile && profile?.user_info[0]?.role === "clinic") ||
            profile?.user_info[0]?.role === "manager" ||
            profile?.role === "manager") && (
            <Button className="danger__button mr-2" onClick={handleDelete}>
              Remove
            </Button>
          )}
          {waitingList && waitingList.length > 0 && (
            <Button>
              <CSVLink
                data={waitingList}
                filename={"Waiting Room.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={coloumn2}
        dataSource={waitingList}
        size="small"
        pagination={{
          defaultPageSize: 15,
          showSizeChanger: true,
          total: waitingList?.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
        scroll={{
          x: 1550,
        }}
      />
    </Col>
  );
};

export default WaitingRoom;
