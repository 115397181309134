import React, { useState, lazy, Suspense } from "react";
import { Tabs } from 'antd';
import Title from '../../../components/Title/Title'
import { ServicesContextProvider } from "../../../contexts/ServicesContext";
const TypesAndGroups = lazy(()=> import('./components/TypesAndGroups'))
const LabList = lazy(()=> import('./components/LabList'))
const ServiceList  = lazy(()=> import('./components/ServiceList')) 
const CptCodes = lazy(()=> import('./components/CptCodes')) 

const tabPanes = [
  { label: "Service List", key: "1", component: <ServiceList /> },
  { label: "Labs & Imaging", key: "2", component: <LabList /> },
  { label: "Types & Groups", key: "3", component: <TypesAndGroups /> },
  { label: "CPT & HCPCS Codes", key: "4", component: <CptCodes /> },
];

function Services() {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabClick = (key) => {
    setActiveKey(key);
  };
  document.title = "Services | American EMR";
  return (
    <ServicesContextProvider>
    <div>
      <Title title='Service Management'/>
      {/* <BreadCrumbs title="American EMR"  breadcrumbItem="Services"/> */}
      <Tabs activeKey={activeKey} defaultActiveKey="1" onTabClick={handleTabClick}>
        {tabPanes.map((pane) => (
          <Tabs.TabPane tab={pane.label} key={pane.key}>
            {activeKey === pane.key && (
              <Suspense>
                {pane.component}
              </Suspense>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
    </ServicesContextProvider>
  );
}

export default Services;
