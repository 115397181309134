import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import logo from '../../../assets/logo.png';
import React, { useEffect, useState } from 'react';
import { useStateGlobalContext } from '../../../contexts/GlobalContext';
import Notification from '../../../components/Notification/Notification';

const Forgot_Password = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { appLogoData, getAppLogo } = useStateGlobalContext();

  const onFinish = async (values) => {
    setLoading(true);
    try{
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/reset-password/`, values);
      if(response?.success){
        form.resetFields();
        Notification.success('Reset link sent successfully');
      }
    }
    catch(error){
      Notification.error("Username doesn't exist")
    }
    finally{
      setLoading(false)
    }
  };


  useEffect(() => {
    getAppLogo();
  }, []);

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="absolute top-6 left-6">
        <img src={appLogoData?.icon || logo} alt="Logo" className="w-[200px]" />
      </div>
      <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <h1 className="text-3xl leading-7 text-[#3484F0] font-extrabold mb-3">Reset your password</h1>
          <p className="text-sm leading-5 mb-2">
            Enter your username and we'll send you a link to reset your password.
          </p>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input placeholder="Enter username" size='large' />
          </Form.Item>

          <Button
            size="large"
            className="bg-[#3484F0] text-[#fff] w-full mb-2"
            type="primary"
            htmlType="submit"
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Send Reset Link'}
          </Button>

          <p className="text-center">
            Already have an account?
            <Link to="/" className="text-[#3484F0]">
              Login
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Forgot_Password;
