import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Table, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { doDelete, doGet, doPatch, doPost } from "../../../API/apis";
import Notification from "../../../components/Notification/Notification";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import ViewTemplate from "./components/ViewTemplate";
import FormatDateTime from "../../../components/FormatDate/FormatDateTime";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Delete",
  },
];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const NotesTemplates = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [viewTemplate, setViewTemplate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editFields, setEditFields] = useState(null);
  const [isFormReady, setIsFormReady] = useState(false);

  const column = [
    {
      key: "#",
      title: "#",
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      key: 1,
      title: "Title",
      dataIndex: "title",
    },
    {
      key: 2,
      title: "Added By",
      dataIndex: "added_by",
    },
    {
      key: 3,
      title: "Added Date",
      dataIndex: "added_date",
      render: (text) => (
        <span>
          <FormatDateTime date={text} type="date" />
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => {
        const availableItems = items;
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleDelete(record.id);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const onClick = () => {
    setVisible(true);
  };

  const handleView = (record) => {
    setViewTemplate(record);
    setView(true);
  };

  const onCancel = () => {
    setVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const handleCreateTemplate = async (values) => {
    setIsSubmitting(true);
    const data = {
      title: values.template_title,
      template: values.template,
    };
    try {
      const response = edit
        ? await doPatch(`/templates/update/${editFields.id}/`, data)
        : await doPost("/templates/add/", data);
      if (response.status === 200) {
        Notification.success("Template created Successfully");
        getTemplates();
        onCancel();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (record) => {
    setEditFields(record);
    setEdit(true);
    setVisible(true);

    setTimeout(() => {
      form.setFieldsValue({
        template_title: record?.title,
        template: record?.template,
      });
      setIsFormReady(true);
    }, 0);
  };

  const getTemplates = async () => {
    setLoading(true);
    try {
      const response = await doGet("/templates/get/");
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleDeleteConfirm(record);
      },
    });
  };

  const handleDeleteConfirm = async (id) => {
    try {
      const response = await doDelete(`/templates/delete/${id}/`);
      if (response.status === 200) {
        Notification.success("Template Deleted Successfully");
        getTemplates();
      }
    } catch {
      Notification.error("Something went wrong");
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  document.title = "Templates | American EMR";
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1>Templates </h1>
        <Button type="primary" onClick={onClick}>
          Create Templates
        </Button>
      </div>

      <Table
        size="small"
        loading={loading}
        columns={column}
        dataSource={data}
        pagination={false}
      />
      <Modal
        title={edit ? "Update Template" : "Create Template"}
        footer={null}
        open={visible}
        width={900}
        onCancel={onCancel}
        maskClosable={false}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={onCancel}
          >
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleCreateTemplate}>
          <Form.Item
            label="Title"
            name="template_title"
            rules={[
              {
                required: true,
                message: "Title is Required",
              },
            ]}
          >
            <Input placeholder="Template Title" />
          </Form.Item>
          <Form.Item
            label="Template"
            name="template"
            rules={[
              {
                required: true,
                message: "Title is Required",
              },
            ]}
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              style={{ height: "300px" }}
            />
          </Form.Item>
          <div className="flex justify-end mt-14">
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              className="ml-2"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting
                ? edit
                  ? "Updating..."
                  : "Saving..."
                : edit
                ? "Update"
                : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        width={850}
        title={viewTemplate?.title}
        open={view}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={() => setView(false)}
          >
            Close
          </Button>
        }
        onCancel={() => setView(false)}
        footer={null}
      >
        <p>
          <b>Title: </b>
          {viewTemplate?.title}
        </p>
        <p>
          <b>Added By:</b> {viewTemplate?.added_by}
        </p>
        <p>
          <b>Date Added:</b>{" "}
          <FormatDateTime date={viewTemplate?.added_date} type="datetime" />
        </p>
        <p>
          <b>Template: </b>
        </p>

        <ViewTemplate template={viewTemplate?.template} />
      </Modal>
    </div>
  );
};

export default NotesTemplates;
