import { Drawer, Table } from "antd";
import React, {  useRef } from "react";
import { useReactToPrint } from "react-to-print";
import InvoiceHeader from "../../../../../../../../components/InvoiceHeader/InvoiceHeader";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { NumberFormatter } from "../../../../../../../../utils/validator";
import InvoiceFooter from '../../../component/InvoiceFooter/InvoiceFooter'
import {calTotal} from '../../../../../../../../utils/validator'

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Lab Name",
    dataIndex:'lab_name'
  },
  {
    key: 3,
    title: "Price",
    align:'center',
    dataIndex:'lab_price',
    render: (text)=> <NumberFormatter value={text} options={{ style: "decimal" }}/>
  },
];

const LabPad = ({ visible, onClose, rxData }) => {
  const contentRef = useRef(null);
  const {officeSetting, patientBasicInfo, billingInformation} = useStateGlobalContext();
  const subTotal = calTotal(rxData?.lab_name)


  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <Drawer
      title={patientBasicInfo?.first_name}
      placement="bottom"
      height="100vh"
      footer={null}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer" 
    >
      <div className="invoices-print max-w-[900px]">
        <div className="invoice-container">
          <button className="guest-button" onClick={handlePrint}>
            Print Document
          </button>
          <div className="invoice-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Invoice</h2>
            <InvoiceHeader officeInformation={officeSetting} patientInformation={patientBasicInfo} rxData={rxData} billingInformation={billingInformation}
            />
            <Table
              columns={coloumn} 
              size="small"
              dataSource={rxData?.lab_name}
              pagination={false}
            />
            <div className="flex justify-between mt-4">
              <div></div>
              <InvoiceFooter subTotal={subTotal}  discount={rxData?.discount} discountAmount={rxData?.discounted_amount} tax={rxData?.tax} grandTotal={rxData?.grand_total}/>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default LabPad;
