import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Card,
  Col,
  Tooltip,
} from "antd";
import {  doPatch } from "../../../../API/apis";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SlRefresh } from "react-icons/sl";
import TasksModal from "../../Tasks/components/TasksModal";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";



const items = [
  {
    key: 1,
    label: "Edit",
  },
  {
    key: 2,
    label: "To Do",
  },
  {
    key: 3,
    label: "In Progress",
  },
  {
    key: 4,
    label: "Need Help!",
  },
  {
    key: 5,
    label: "High Priority",
  },
  {
    key: 6,
    label: "Mark as Completed",
  },
];

const Tasks = () => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  // const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [taskModal, setTaskModal] = useState(false) 
  const { tasks, getTasks } = useStateGlobalContext();

  const showTaskModal = ()=>{
    setTaskModal(true)
  }

  const handleViewAllTasks = () => {
    navigate("/main/tasks");
  };

  const coloumn = [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, __, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Assigned By",
      ellipsis: true,
      dataIndex: "assigned_by",
    },
    {
      key: 2,
      title: "Detail",
      ellipsis: true,
      dataIndex: "detail",
    },
    {
      key: 3,
      title: "Assisged To",
      ellipsis: true,
      dataIndex: "assigned_to",
    },

    {
      key: 7,
      title: "Status",
      ellipsis: true,
      align: "center",
      dataIndex: "status",
      render: (text) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "In Progress":
            bgColor = "bg-blue-200";
            textColor = "text-blue-500";
            break;
          case "Completed":
            bgColor = "bg-green-200";
            textColor = "text-green-500";
            break;
          case "High Priority":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          case "To do":
            bgColor = "bg-yellow-200";
            textColor = "text-yellow-500";
            break;
          case "Need Help!":
            bgColor = "bg-orange-200";
            textColor = "text-orange-500";
            break;
          default:
            bgColor = "bg-yellow-200";
            textColor = "text-yellow-500";
        }
        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: 8,
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: items
              .filter(
                (item) =>
                  !(item.key === 1 && record.status === "Completed") && 
                  !(item.key === 6 && record.status === "Completed")
              )
              .map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) handleUpdate(record);
                  else handleStatus(record, item.key);
                },
              })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
    
  ];

  const handleUpdate = (record) => {
    if (record.status !== "Completed") {
      setEdit(true);
      setSelectedItem(record);
      setTaskModal(true)
    } else {
      Notification.warning("Cannot edit a completed task.");
    }
  };

  const handleStatus = async (record, key) => {
    let data = {};
    switch (key) {
      case 2:
        data = {
          status: "To Do",
        };
        break;
      case 3:
        data = {
          status: "In Progress",
        };
        break;
      case 4:
        data = {
          status: "Need Help!",
        };
        break;
      case 5:
        data = {
          status: "High Priority",
        };
        break;
      case 6:
        data = {
          status: "Completed",
        };
        break;
      default:
        data = {
          status: "To Do",
        };
    }

    try {
      const response = await doPatch(`/tasks/update/${record.id}`, data);
      if (response.status === 200) {
        Notification.success("Status updated Successfully");
        getTasks(true);
      }
    } catch (error) {
      Notification.error("An error occurred while updating status");
    }
  };

 

  const handleCancel = () => {
    setEdit(false);
    setTaskModal(false)
  };

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      await getTasks(true);
      setLoading(false);
    };
    fetchTasks();
  }, []);

  return (
    <Col xl={12}>
      <Card className="mt-4 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-bold text-lg">Tasks</h1>
          <div>
            <Tooltip title="Refresh" onClick={()=> getTasks(true)}>
              <SlRefresh size={16} className="cursor-pointer" />
            </Tooltip>
            <Button className="ml-4 mr-2" onClick={handleViewAllTasks}>
              View All
            </Button>
            <Button onClick={showTaskModal} type="primary">New Task <PlusOutlined /></Button>
          </div>
        </div>
        <Table
          size="small"
          columns={coloumn}
          dataSource={tasks}
          scroll={{ y: 200 }}
          loading={loading}
          pagination={false}
        />

        <TasksModal visible={taskModal} onClose={handleCancel} edit={selectedItem} check={true}/>
      </Card>
    </Col>
  );
};

export default Tasks;
