import { Button, Modal } from 'antd'
import React from 'react'
import { NumberFormatter } from '../../../../../../../../utils/validator'
import FormatDateTime from '../../../../../../../../components/FormatDate/FormatDateTime'
import { CopyableText } from '../../../../../../../../components/GlobalComponents/GlobalComponents'
import LabelDisplay from '../../../../../../../../components/LabelDisplay/LabelDisplay'
import InvoiceFooter from '../../InvoiceFooter/InvoiceFooter'

const ViewInvoice = ({visible, onClose, rxData}) => {
  
  return (
    <Modal
     title={<CopyableText text={rxData?.invoice_id}/>}
     open={visible}
     onCancel={onClose}
     footer={null}
     closeIcon={
      <Button size="small" className="app-close-icon" shape="round">Close</Button>
    }
    >
      <LabelDisplay title="Created By" description={rxData?.created_by}/>
      <LabelDisplay title="Issue Date" description={<FormatDateTime date={rxData?.issue_date} type='datetime'/>}/>
      <LabelDisplay title="Patient Name" description={rxData?.patient_name}/>
      <LabelDisplay title="Invoice ID" description={rxData?.invoice_id}/>
      <LabelDisplay title="Status" description={rxData?.status}/>

      <div className="bg-gray-200 p-2 mt-2 mb-2">
          <LabelDisplay title="Reason for Visit" description={rxData?.visit_reason}/>
          <LabelDisplay title="Service Name" description={rxData?.service_name}/>
          <LabelDisplay title="Service Type" description={rxData?.service_type}/>
          {rxData?.service_group && <LabelDisplay title="Service Group" description={rxData?.service_group}/>}
          <LabelDisplay title="Service Price" description={<NumberFormatter value={rxData?.price}/>}/>
          {rxData?.notes && <LabelDisplay title="Service Notes" description={rxData?.notes}/>}
      </div>


      <LabelDisplay title="Sub Total" description={<NumberFormatter value={rxData?.price} options={{ style: "decimal" }} />}/>
      <LabelDisplay title={`Discount ${rxData?.discount}%`} description={<NumberFormatter value={rxData?.discounted_amount}/>}/>
      <LabelDisplay title="Tax" description={<NumberFormatter value={rxData?.tax} options={{ style: "decimal" }} />}/>
      <LabelDisplay title="Grand Total" description={<NumberFormatter value={rxData?.grand_total_amount} options={{ style: "decimal" }} />}/>
      <LabelDisplay title="Payment Method" description={rxData?.payment_method ? rxData.payment_method.charAt(0).toUpperCase() + rxData.payment_method.slice(1) : ''}/>
      {rxData?.paid_on && <LabelDisplay title="Paid Date" description={<FormatDateTime date={rxData?.paid_on} type='datetime'/>}/>}
    </Modal>
  )
}

export default ViewInvoice
