import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";

const Address = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const { patientBasicInfo, getPatientData } = useStateGlobalContext();

  const {
    current_address_line_1,
    current_address_line_2,
    current_city,
    current_state,
    current_country,
    current_zipcode,
    current_address_notes,
    mailing_address_line_1,
    mailing_address_line_2,
    mailing_city,
    mailing_state,
    mailing_zipcode,
    mailing_country,
    mailing_address_notes,
  } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        form.setFieldsValue({
          current_address_line_1,
          current_address_line_2,
          current_city,
          current_state,
          current_country,
          current_zipcode,
          current_address_notes,
          mailing_address_line_1,
          mailing_address_line_2,
          mailing_city,
          mailing_state,
          mailing_country,
          mailing_zipcode,
          mailing_address_notes,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = () => {
    setEdit(true);
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const handleUpdate = async (values) => {
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id);
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderFormItem = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={12}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (!patientBasicInfo) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
        <h1 className="text-[1.125rem] font-bold mb-4">Address</h1>
        {edit ? (
          <Button onClick={onEditCancel}>Close</Button>
        ) : (
          <Button onClick={onEdit}>Edit</Button>
        )}
      </div>

      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <h3 className="mb-2">Current Address</h3>
          <Form.Item label="Address Line 1" name="current_address_line_1">
            <Input placeholder="Address Line 1" maxLength={50}/>
          </Form.Item>
          <Form.Item label="Address Line 2" name="current_address_line_2">
            <Input placeholder="Address Line 2" maxLength={50}/>
          </Form.Item>

          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "City",
              "current_city",
              <Input placeholder="City" maxLength={50}/>
            )}
            {renderFormItem(
              "State / Province",
              "current_state",
              <Input placeholder="State / Province" maxLength={50}/>
            )}
          </Row>

          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "Zip Code / Postal Code",
              "current_zipcode",
              <Input placeholder="Zip Code / Postal Code" maxLength={50}/>
            )}
            {renderFormItem(
              "Country",
              "current_country",
              <Input placeholder="Country" maxLength={50}/>
            )}
          </Row>

          <Form.Item label="Notes" name="current_address_notes">
            <Input.TextArea
              placeholder="Notes"
              maxLength={300}
              showCount
            />
          </Form.Item>

          <h3 className="mb-2">Mailing Address</h3>
          <Form.Item label="Address Line 1" name="mailing_address_line_1">
            <Input placeholder="Address Line 1" />
          </Form.Item>
          <Form.Item label="Address Line 2" name="mailing_address_line_2">
            <Input placeholder="Address Line 2" />
          </Form.Item>

          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "City",
              "mailing_city",
              <Input placeholder="City" maxLength={50}/>
            )}
            {renderFormItem(
              "State / Province",
              "mailing_state",
              <Input placeholder="State / Province" maxLength={50}/>
            )}
          </Row>

          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "Zip Code / Postal Code",
              "mailing_zipcode",
              <Input placeholder="Zip Code / Postal Code" maxLength={50}/>
            )}
            {renderFormItem(
              "Country",
              "mailing_country",
              <Input placeholder="Country" maxLength={50}/>
            )}
          </Row>

          {/* <Form.Item label="Notes" name="notes">
            <Input.TextArea
              placeholder="Notes"
              maxLength={300}
              showCount
            />
          </Form.Item> */}

          <div className="flex justify-end">
            <Button onClick={onEditCancel}>Cancel Changes</Button>
            <Button type="primary" className="ml-2" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      ) : (
        <div className="view-wrapper">
          <h3 className="mb-4">Current Address </h3>
          <p>Address Line 1: {current_address_line_1}</p>
          <p>Address Line 2: {current_address_line_2}</p>
          <p>City: {current_city}</p>
          <p>State / Province: {current_state}</p>
          <p>Zip Code / Postal Code: {current_zipcode}</p>
          <p>Country: {current_country}</p>
          <p className="notes">Notes: {current_address_notes}</p>

          <h3 className="mb-4">Mailing Address </h3>
          <p>Address Line 1: {mailing_address_line_1}</p>
          <p>Address Line 2: {mailing_address_line_2}</p>
          <p>City: {mailing_city}</p>
          <p>State / Province: {mailing_state}</p>
          <p>Zip Code / Postal Code: {mailing_zipcode}</p>
          <p>Country: {mailing_country}</p>
          {/* <p>Notes: {mailing_address_notes}</p> */}
        </div>
      )}
    </div>
  );
};

export default Address;
