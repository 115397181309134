import React from "react";
import { Button, Modal } from "antd";
import FormatDateTime from "../FormatDate/FormatDateTime";
import { NumberFormatter } from "../../utils/validator";
import LabelDisplay from "../../components/LabelDisplay/LabelDisplay";

const GuestCheckoutView = ({ visible, data, onClose }) => {
  return (
    data && (
      <Modal
        title="Guest Checkout"
        footer={null}
        open={visible}
        onCancel={onClose}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <div>
          <LabelDisplay title="Added By" description={data?.added_by} />
          <LabelDisplay
            title="Added Date"
            description={
              <FormatDateTime date={data?.issue_date} type="datetime" />
            }
          />
          <LabelDisplay title="Patient Name" description={data?.patient_name} />
          <LabelDisplay title="Invoice ID" description={data?.invoice_id} />
          <LabelDisplay title="Status" description={data?.status} />
          {data?.product_details?.map((item) => (
            <div className="bg-gray-200 p-2 mt-2" key={item?.key}>
              <LabelDisplay title="Product Name" description={item?.items} />
              <LabelDisplay title="Quantity" description={item?.quantity} />
              <LabelDisplay
                title="Unit Price"
                description={<NumberFormatter value={item?.unit_price} />}
              />
            </div>
          ))}

          <LabelDisplay
            title="Sub Total"
            description={
              <NumberFormatter
                value={data?.price}
                options={{ style: "decimal" }}
              />
            }
          />
          <LabelDisplay
            title={`Discount ${data?.discount}%`}
            description={<NumberFormatter value={data?.discounted_amount} />}
          />
          <LabelDisplay
            title="Tax"
            description={
              <NumberFormatter
                value={data?.tax}
              />
            }
          />
          <LabelDisplay
            title="Grand Total"
            description={
              <NumberFormatter
                value={data?.grand_total}
              />
            }
          />
        </div>
      </Modal>
    )
  );
};

export default GuestCheckoutView;
