import { Avatar } from 'antd';
import React from 'react';
import './profilecard.css';

const ProfileCard = ({ patientInfo }) => {

  return (
    <div className='flex justify-center items-center'>
      <div className='image-wrapper' >
        <Avatar shape='circle' 
            size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 100,
            }}
        src={patientInfo && patientInfo?.profile_picture} alt='profile' />
      </div>
      <h1 className='text-[1.2rem]'>
        {patientInfo?.first_name} {patientInfo?.middle_name && patientInfo?.middle_name} {patientInfo?.last_name}
      </h1>
    </div>
  );
};

export default ProfileCard;
