import React from "react";
import { Card, Col, Row, Spin } from "antd";
import { AiOutlineDollarCircle, AiOutlineWarning } from "react-icons/ai";
import { NumberFormatter } from "../../../../utils/validator";


const InventoryCard = ({ title, value, showCurrency, loading, cardBackgroundColor, icon, role }) => {

  return (
    <Col xl={8} sm={12} md={role === "doctor" || role === "nurse" ? 8 : 6}>
      <Card
        loading={loading}
        hoverable
        className="shadow-sm"
        style={{ backgroundColor: cardBackgroundColor }}
      >
        <div className="flex items-center justify-between">
          <p className="font-medium text-sm">{title}</p>
          <span>{icon}</span>
        </div>
        {loading ? (
          <Spin />
        ) : (
          <h1 className="font-bold text-xl">
            {!showCurrency ? (
              value
            ) : (
              <NumberFormatter value={value} options={{ style: "decimal" }} />
            )}
          </h1>
        )}
      </Card>
    </Col>
  );
};

const InventoryCards = ({
  expiringSoon,
  lowInStock,
  loading,
  soldItemCount,
  // totalInvRevenue,
  role
}) => {


  return (
    <>
      <Row gutter={[16, 16]}>
        {(role !== "doctor" && role !== "nurse") && (
          <>
            <InventoryCard
              role={role}
              title="Total Item Sales this month"
              value={soldItemCount}
              loading={loading}
              cardBackgroundColor="#b3e5fc"
              icon={<AiOutlineDollarCircle size={24} color="#03A9F5" />}
            />
            {/* <InventoryCard
              role={role}
              title="Total Sales this month"
              value={totalInvRevenue}
              showCurrency={true}
              loading={loading}
              cardBackgroundColor="#86f0a5"
              icon={<AiOutlineDollarCircle size={24} color="#61D180" />}
            /> */}
          </>
        )}

        <InventoryCard
          role={role}
          title="Low in Stock"
          value={lowInStock}
          loading={loading}
          cardBackgroundColor="#fbf5c5"
          icon={<AiOutlineWarning size={24} color="#F0DE3E" title="QTY less than 2" />}
        />
        <InventoryCard
          role={role}
          title="Expiring Soon"
          value={expiringSoon}
          loading={loading}
          cardBackgroundColor="#fbc8c5"
          icon={<AiOutlineWarning size={24} color="#F0483E" title="Product Expiring in 30 Days" />}
        />
      </Row>
    </>
  );
};

export default InventoryCards;
