import React from 'react';
import { formatDate, formatDateTime, formatTime } from '../../utils/DateFormat';
import { useStateGlobalContext } from '../../contexts/GlobalContext';


const FormatDateTime = ({ date, type = 'date' }) => {
  const {officeSetting} = useStateGlobalContext()

  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 

  if (!date) return null;

  const formattedDateTime = {
    date: formatDate(date, officeDateFormat, officeTimezone),
    time: formatTime(date, officeTimeFormat, officeTimezone),
    datetime: formatDateTime(date, officeDateFormat, officeTimeFormat, officeTimezone),
  };

  return <>{formattedDateTime[type]}</>;
};

export default FormatDateTime;
