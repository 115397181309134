import WaitingRoom from "./component/WaitingRoom";
import WelcomeCard from "../../../components/WelcomeCard/WelcomeCard";
import Appointments from "./component/Appointments";
import Tasks from "./component/Tasks";
import { Row } from "antd";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";


function Index() {
  document.title = "Work Dashboard | American EMR";

  const { profile } = useStateGlobalContext();
  const provider_name = profile && `${profile?.user_info[0]?.first_name} ${profile?.user_info[0]?.last_name}`;


  return (
    <div>
      <WelcomeCard
        title={"Work Dashboard"}
        color={"border-[#C4dBFA]"}
        name={`Welcome Back, ${provider_name}`}
        provider_name={provider_name}
        profile_pic={profile?.personel_settings[0]?.user_profile_picture}
        work={true}
      />
      <Row gutter={[16, 16]}>
        <Appointments />
        <Tasks />
      </Row>

      <Row className="mt-2">
        <WaitingRoom />
      </Row>
    </div>
  );
}

export default Index;
