import React, { useState } from 'react';
import { Tabs } from 'antd';
import InhouseLabReports from './Labs/InhouseLabsReports';
import ExternalLabReports from './Labs/ExternalLabReports';

const { TabPane } = Tabs;

const Labs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={handleTabChange} size='large' type="card">
        <TabPane tab="In-house Lab Reports" key="1">
          <InhouseLabReports />
        </TabPane>
        <TabPane tab="External Lab Reports" key="2">
          <ExternalLabReports />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Labs;