import { Card } from "antd";
import React from "react";

const HelpfullInfo = () => {
  return (
    <Card className="p-6 rounded-lg shadow-md">
      <p className="text-lg font-semibold mb-4">Useful Information</p>

      <div className="mb-4">
        <p className="font-medium">Credit Card:</p>
        <p className="text-gray-700">
          For Autopay, your Credit Card information will be securely stored and
          utilized for automated monthly payments, adjusted according to the
          number of users you add each month, please select the{" "}
          <span className="font-semibold">Credit Card</span> tab to enter your
          card information.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-medium">Bank Transfer:</p>
        <p className="text-gray-700">
          Kindly adhere to the provided bank wiring instructions in{" "}
          <span className="font-semibold">Bank Transfer</span> tab and ensure to
          email the receipt to
          <span className="text-blue-500"> support@americanemr.com</span> for
          documentation and email receipt purposes. Please note that you will have a grace period of 7 days from the 1st of each month to initiate transfer.
        </p>
      </div>

      <div className="mb-4">
        <p className="font-medium">Monthly Invoice:</p>
        <p className="text-gray-700">
          You will receive the invoice on the 1st of every month on the email in <span className="font-semibold">Monthly Invoice</span> tab, adjusted based on the number of users added during that
          month. Please note that you will have a grace period of 7 days to
          settle any pending invoices.
        </p>

        <p className="mt-4">For any billing related help, email  support@americanemr.com or call <br/>Customer Service at +1 737 225 8885</p>
      </div>
    </Card>
  );
};

export default HelpfullInfo;
