import { Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useStateGlobalContext } from '../../contexts/GlobalContext';

const SelectProvider = ({ value, onChange ,waitingRoom }) => {
  const [options, setOptions] = useState([]);
  const { providers } = useStateGlobalContext();

  useEffect(() => {
    let initialOptions = providers;
    if (!waitingRoom) {
      const allOption = {
        id: null,
        name: 'Assign to All',
      };
      initialOptions = [allOption, ...providers];
    }
    setOptions(initialOptions);
  }, [providers, waitingRoom]);

  const handleProviderSearch = (searchText) => {
    const filteredOptions = providers?.filter((provider) =>
      provider.name.toLowerCase().includes(searchText.toLowerCase())
    );
    const allOption = {
      id: null,
      name: 'Assign to All',
    };
    setOptions([allOption, ...filteredOptions]);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder="Search Provider"
      onChange={onChange}
      onSearch={handleProviderSearch}
      filterOption={false}
      style={{ width: '100%' }}
    >
      {options.map((option) => (
        <Select.Option key={option.id} value={option.name}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectProvider;
