import React, { useEffect, useState } from "react";
import { DatePicker, Table, Button, Form, Card } from "antd";
import { doGet } from "../../../../../API/apis";
import { formatDate } from "../../../../../utils/DateFormat";
import { CSVLink } from "react-csv";
import Notification from "../../../../../components/Notification/Notification";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const { RangePicker } = DatePicker;

const columns = [
  {
    key: "1",
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    key: "patient_mrn",
    title: "MRN",
    dataIndex: "mrn_number",
  },
  {
    key: "2",
    title: "First Name",
    dataIndex: "first_name",
    ellipsis: true,
  },
  {
    key: "3",
    title: "Middle Name",
    dataIndex: "middle_name",
    ellipsis: true,
  },
  {
    key: "4",
    title: "Last Name",
    dataIndex: "last_name",
    ellipsis: true,
  },
  {
    key: "5",
    title: "DOB",
    dataIndex: "date_of_birth",
    ellipsis: true,
    render: (text) => <span><FormatDate date={text}/></span>,
  },
  {
    key: "6",
    title: "Gender",
    ellipsis: true,
    dataIndex: "gender",
  },
  {
    key: "7",
    title: "Marital Status",
    ellipsis: true,
    dataIndex: "marital_status",
  },
  {
    key: "8",
    title: "Legal Guardian",
    ellipsis: true,
    render: (_, record) => (
      <span>
        {record?.guardian_first_name} {record?.guardian_middle_name}{" "}
        {record?.guardian_last_name}
      </span>
    ),
  },
  {
    key: "9",
    title: "Mobile",
    ellipsis: true,
    dataIndex: "patient_phone",
  },
  {
    key: "10",
    title: "Home",
    ellipsis: true,
    dataIndex: "patient_home",
  },
  {
    key: "11",
    title: "Work",
    ellipsis: true,
    dataIndex: "patient_work",
  },
  {
    key: "12",
    title: "Email",
    ellipsis: true,
    dataIndex: "patient_email",
  },
  {
    key: "13",
    ellipsis: true,
    title: "Address Line 1",
    dataIndex: "current_address_line_1",
  },
  {
    key: "address_line_2",
    ellipsis: true,
    title: "Address Line 2",
    dataIndex: "current_address_line_2",
  },
  {
    key: "14",
    title: "City",
    ellipsis: true,
    dataIndex: "current_city",
  },
  {
    key: "15",
    ellipsis: true,
    title: "State / Province",
    dataIndex: "current_state",
  },
  {
    key: "16",
    title: "Zip / Postal Code",
    ellipsis: true,
    dataIndex: "current_zipcode",
  },
  {
    key: "17",
    title: "Country",
    ellipsis: true,
    dataIndex: "current_country",
  },
  {
    key: "18",
    title: "Payment Services",
    ellipsis: true,
    dataIndex: "payment_services",
  },
  {
    key: "19",
    title: "Note",
    ellipsis: true,
    dataIndex: "address_Note",
  },
];

const PatientList = () => {
  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getPatientList();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getPatientList = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/patient/patients/filter/?from_date=${startDate}&till_date=${endDate}`
        );

        if (response.status === 200) {
          setPatientList(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const transformAppointmentsForCSV = (data) => {
    if (!data) {
      return [];
    }

    return data?.map((patient) => ({
      "First Name": patient?.first_name,
      "Middle Name": patient?.middle_name,
      "Last Name": patient?.last_name,
      "DOB": formatDate(patient?.date_of_birth),
      "Gender": patient?.gender,
      "Height": patient?.height,
      "Sexual Orientation": patient?.sexual_orientation,
      "Marital Status": patient?.marital_status,
      "Guardian First Name": patient?.guardian_first_name,
      "Guardian Middle Name": patient?.guardian_middle_name,
      "Guardian Last Name": patient?.guardian_last_name,
      "Guardian Phone Number": patient?.guardian_phone_number,
      "Guardian Email": patient?.guardian_email,
      "Basic Information Notes": patient?.basic_information_notes,
      "Patient Phone": patient?.patient_phone,
      "Patient Email": patient?.patient_email,
      "Phone Decline": patient?.phone_decline,
      "Email Decline": patient?.email_decline,
      "Patient Home": patient?.patient_home,
      "Patient Work": patient?.patient_work,
      "Extension": patient?.extension,
      "Patient Contact Notes": patient?.patient_contact_notes,
      "Emergency First Name": patient?.emergency_first_name,
      "Emergency Last Name": patient?.emergency_last_name,
      "Emergency Phone Number": patient?.emergency_phone_number,
      "Emergency Relationship": patient?.emergency_relationship,
      "Emergency Notes": patient?.emergency_notes,
      "Current Address Line 1": patient?.current_address_line_1,
      "Current Address Line 2": patient?.current_address_line_2,
      "Current State": patient?.current_state,
      "Current Zipcode": patient?.current_zipcode,
      "Current Country": patient?.current_country,
      "Current Address Notes": patient?.current_address_notes,
      "Current City": patient?.current_city,
      "Mailing Address Line 1": patient?.mailing_address_line_1,
      "Mailing Address Line 2": patient?.mailing_address_line_2,
      "Mailing City": patient?.mailing_city,
      "Mailing State": patient?.mailing_state,
      "Mailing Zipcode": patient?.mailing_zipcode,
      "Mailing Country": patient?.mailing_country,
      "Mailing Address Notes": patient?.mailing_address_notes,
      "Race": patient?.race,
      "Religion": patient?.religion,
      "Ethnicity": patient?.ethnicity,
      "Languages": patient?.languages,
      "Demographics Notes": patient?.demographics_notes,
      "Referral Full Name": patient?.referal_full_name,
      "Referral Speciality": patient?.referal_speciality,
      "Referral Phone": patient?.referal_phone,
      "Referral Email": patient?.referal_email,
      "Referral Fax": patient?.referal_fax,
      "Referral Organization Name": patient?.referal_organization_name,
      "Referral Group Name": patient?.referal_group_name,
      "How Did You Hear About Us": patient?.wdyhau,
      "Referral Notes": patient?.referal_notes,
      "Government ID": patient?.govId,
      "Passport": patient?.passport,
      "License": patient?.license,
      "SSN": patient?.ssn,
      "ID Notes": patient?.id_notes,
      "Profile Picture": patient?.profile_picture,
      "Identification Picture": patient?.identification_picture,
      "Payment Services": patient?.payment_services,
      "Payment Services Notes": patient?.payment_services_notes,
      "MRN Number": patient?.mrn_number,
      "Registration Date": formatDate(patient?.registration_date),
      "Insurance Card": patient?.insurance_card,
      "Employment": patient?.employment,
      "Clinic": patient?.clinic,
      "Doctor": patient?.doctor,
    }));
  };

  useEffect(() => {
    if (searchClicked) {
      getPatientList();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <h1 className="font-bold text-xl">Patients List </h1>
      <Card className="shadow-class mb-4 mt-4">
        <Form layout="vertical">
          <div className="flex items-center">
            <Form.Item label="Select Date Range">
              <RangePicker
                size="medium"
                onChange={handleDateRangeChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button type="primary" className="ml-2 mt-2" onClick={handleSearch}>
              Search
            </Button>
            <Button className="ml-2 mt-2" onClick={()=> setPatientList([])}>
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {patientList?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformAppointmentsForCSV(patientList)}
                filename={"Patient_list.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={patientList}
          size="small"
          scroll={{
            x: "max-content",
          }}
        />
      </Card>
    </div>
  );
};

export default PatientList;
