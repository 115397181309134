import { Modal } from 'antd';

const showConfirmModal = ({ title, content, okText, okType, cancelText, className, onOk }) => {
  Modal.confirm({
    title,
    icon: null,
    content,
    okText,
    okType,
    cancelText,
    className,
    onOk,
  });
};

export default showConfirmModal;
