import React, { useState, lazy, Suspense } from "react";
import { Tabs } from "antd";
import Title from '../../../components/Title/Title';
import Loader from '../../../components/Loader/Loader'
const MembershipDashboard = lazy(() => import('./MembershipDashboard'));
const MembershipPlans = lazy(() => import('./Membership'));

const TabPane = [
  {
    key: "1",
    label: "Dashboard",
    content: <MembershipDashboard />,
  },
  {
    key: "2",
    label: "Plans",
    content: <MembershipPlans />,
  },
];

function MembershipTabs() {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  document.title = "Membership Dashboard | American EMR";

  return (
    <div>
      <Title title="Memberships Management" />
      <Tabs activeKey={activeKey} size="small" onTabClick={handleTabChange}>
        {TabPane.map((tab) => (
          <Tabs.TabPane key={tab.key} tab={tab.label}>
            {activeKey === tab.key && (
              <Suspense fallback={<Loader/>}>
                {tab.content}
              </Suspense>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default MembershipTabs;
