import { Modal, Button, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { doPatch, doPost } from '../../../../API/apis';
import { useStateGlobalContext } from '../../../../contexts/GlobalContext';
import Notification from '../../../../components/Notification/Notification';
import BasicButton from '../../../../components/BasicButton/BasicButton';
import SelectProvider from '../../../../components/SelectProvider/SelectProvider';

const SUCCESS_MESSAGES = {
  update: 'Task Updated Successfully',
  add: 'Task Created Successfully',
};

const TasksModal = ({ visible, onClose, edit, check, selectedItem }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { getTasks } = useStateGlobalContext();

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        detail: selectedItem?.detail,
        assigned_to: selectedItem?.assigned_to,
      });
    }
  }, [edit]);

  const handleTasks = async (values) => {
    const { detail } = values;
    const data = {
      detail,
      assigned_to: values.assigned_to,
    };
    setLoading(true);
    try {
      const response = edit
        ? await doPatch(`/tasks/update/${selectedItem?.id}`, data)
        : await doPost('/tasks/add/', data);
      if (response.status === 201 || response.status === 200) {
        Notification.success(edit ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add);
        getTasks(check);
        handleCancel();
      }
    } catch (error) {
      Notification.error('Something Went Wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTasks(check);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Modal
      title={edit ? 'Update Task' : 'New Task'}
      open={visible}
      footer={null}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} onFinish={handleTasks}>
        <Form.Item
          name="detail"
          label="Details"
          rules={[{ required: true, message: 'Please Enter task Details' }]}
        >
          <Input.TextArea
            placeholder="Task Details"
            showCount
            maxLength={300}
          />
        </Form.Item>
        <Form.Item
          name="assigned_to"
          label="Assigned To"
          rules={[{ required: true, message: 'Please Select any Provider' }]}
        >
          <SelectProvider/>
        </Form.Item>
        <div className="flex justify-end">
          <Button className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <BasicButton saving={!edit} loading={loading} title={edit ? 'Update' : 'Assign'} />
        </div>
      </Form>
    </Modal>
  );
};

export default TasksModal;
