const currencies = [
    {
        "currency": "Afghani",
        "alphabeticCode": "AFN"
    },
    {
        "currency": "Euro",
        "alphabeticCode": "EUR"
    },
    {
        "currency": "Lek",
        "alphabeticCode": "ALL"
    },
    {
        "currency": "Algerian Dinar",
        "alphabeticCode": "DZD"
    },
    {
        "currency": "US Dollar",
        "alphabeticCode": "USD"
    },
    {
        "currency": "Kwanza",
        "alphabeticCode": "AOA"
    },
    {
        "currency": "East Caribbean Dollar",
        "alphabeticCode": "XCD"
    },
    {
        "currency": "Argentine Peso",
        "alphabeticCode": "ARS"
    },
    {
        "currency": "Armenian Dram",
        "alphabeticCode": "AMD"
    },
    {
        "currency": "Aruban Florin",
        "alphabeticCode": "AWG"
    },
    {
        "currency": "Australian Dollar",
        "alphabeticCode": "AUD"
    },
    {
        "currency": "Azerbaijan Manat",
        "alphabeticCode": "AZN"
    },
    {
        "currency": "Bahamian Dollar",
        "alphabeticCode": "BSD"
    },
    {
        "currency": "Bahraini Dinar",
        "alphabeticCode": "BHD"
    },
    {
        "currency": "Taka",
        "alphabeticCode": "BDT"
    },
    {
        "currency": "Barbados Dollar",
        "alphabeticCode": "BBD"
    },
    {
        "currency": "Belarusian Ruble",
        "alphabeticCode": "BYN"
    },
    {
        "currency": "Belize Dollar",
        "alphabeticCode": "BZD"
    },
    {
        "currency": "CFA Franc BCEAO",
        "alphabeticCode": "XOF"
    },
    {
        "currency": "Bermudian Dollar",
        "alphabeticCode": "BMD"
    },
    {
        "currency": "Indian Rupee",
        "alphabeticCode": "INR"
    },
    {
        "currency": "Ngultrum",
        "alphabeticCode": "BTN"
    },
    {
        "currency": "Boliviano",
        "alphabeticCode": "BOB"
    },
    {
        "currency": "Mvdol",
        "alphabeticCode": "BOV"
    },
    {
        "currency": "Convertible Mark",
        "alphabeticCode": "BAM"
    },
    {
        "currency": "Pula",
        "alphabeticCode": "BWP"
    },
    {
        "currency": "Norwegian Krone",
        "alphabeticCode": "NOK"
    },
    {
        "currency": "Brazilian Real",
        "alphabeticCode": "BRL"
    },
    {
        "currency": "Brunei Dollar",
        "alphabeticCode": "BND"
    },
    {
        "currency": "Bulgarian Lev",
        "alphabeticCode": "BGN"
    },
    {
        "currency": "Burundi Franc",
        "alphabeticCode": "BIF"
    },
    {
        "currency": "Cabo Verde Escudo",
        "alphabeticCode": "CVE"
    },
    {
        "currency": "Riel",
        "alphabeticCode": "KHR"
    },
    {
        "currency": "CFA Franc BEAC",
        "alphabeticCode": "XAF"
    },
    {
        "currency": "Canadian Dollar",
        "alphabeticCode": "CAD"
    },
    {
        "currency": "Cayman Islands Dollar",
        "alphabeticCode": "KYD"
    },
    {
        "currency": "Chilean Peso",
        "alphabeticCode": "CLP"
    },
    {
        "currency": "Unidad de Fomento",
        "alphabeticCode": "CLF"
    },
    {
        "currency": "Yuan Renminbi",
        "alphabeticCode": "CNY"
    },
    {
        "currency": "Colombian Peso",
        "alphabeticCode": "COP"
    },
    {
        "currency": "Unidad de Valor Real",
        "alphabeticCode": "COU"
    },
    {
        "currency": "Comorian Franc ",
        "alphabeticCode": "KMF"
    },
    {
        "currency": "Congolese Franc",
        "alphabeticCode": "CDF"
    },
    {
        "currency": "New Zealand Dollar",
        "alphabeticCode": "NZD"
    },
    {
        "currency": "Costa Rican Colon",
        "alphabeticCode": "CRC"
    },
    {
        "currency": "Kuna",
        "alphabeticCode": "HRK"
    },
    {
        "currency": "Cuban Peso",
        "alphabeticCode": "CUP"
    },
    {
        "currency": "Peso Convertible",
        "alphabeticCode": "CUC"
    },
    {
        "currency": "Netherlands Antillean Guilder",
        "alphabeticCode": "ANG"
    },
    {
        "currency": "Czech Koruna",
        "alphabeticCode": "CZK"
    },
    {
        "currency": "Danish Krone",
        "alphabeticCode": "DKK"
    },
    {
        "currency": "Djibouti Franc",
        "alphabeticCode": "DJF"
    },
    {
        "currency": "Dominican Peso",
        "alphabeticCode": "DOP"
    },
    {
        "currency": "Egyptian Pound",
        "alphabeticCode": "EGP"
    },
    {
        "currency": "El Salvador Colon",
        "alphabeticCode": "SVC"
    },
    {
        "currency": "Nakfa",
        "alphabeticCode": "ERN"
    },
    {
        "currency": "Lilangeni",
        "alphabeticCode": "SZL"
    },
    {
        "currency": "Ethiopian Birr",
        "alphabeticCode": "ETB"
    },
    {
        "currency": "Falkland Islands Pound",
        "alphabeticCode": "FKP"
    },
    {
        "currency": "Fiji Dollar",
        "alphabeticCode": "FJD"
    },
    {
        "currency": "CFP Franc",
        "alphabeticCode": "XPF"
    },
    {
        "currency": "Dalasi",
        "alphabeticCode": "GMD"
    },
    {
        "currency": "Lari",
        "alphabeticCode": "GEL"
    },
    {
        "currency": "Ghana Cedi",
        "alphabeticCode": "GHS"
    },
    {
        "currency": "Gibraltar Pound",
        "alphabeticCode": "GIP"
    },
    {
        "currency": "Quetzal",
        "alphabeticCode": "GTQ"
    },
    {
        "currency": "Pound Sterling",
        "alphabeticCode": "GBP"
    },
    {
        "currency": "Guinean Franc",
        "alphabeticCode": "GNF"
    },
    {
        "currency": "Guyana Dollar",
        "alphabeticCode": "GYD"
    },
    {
        "currency": "Gourde",
        "alphabeticCode": "HTG"
    },
    {
        "currency": "Lempira",
        "alphabeticCode": "HNL"
    },
    {
        "currency": "Hong Kong Dollar",
        "alphabeticCode": "HKD"
    },
    {
        "currency": "Forint",
        "alphabeticCode": "HUF"
    },
    {
        "currency": "Iceland Krona",
        "alphabeticCode": "ISK"
    },
    {
        "currency": "Rupiah",
        "alphabeticCode": "IDR"
    },
    {
        "currency": "SDR (Special Drawing Right)",
        "alphabeticCode": "XDR"
    },
    {
        "currency": "Iranian Rial",
        "alphabeticCode": "IRR"
    },
    {
        "currency": "Iraqi Dinar",
        "alphabeticCode": "IQD"
    },
    {
        "currency": "New Israeli Sheqel",
        "alphabeticCode": "ILS"
    },
    {
        "currency": "Jamaican Dollar",
        "alphabeticCode": "JMD"
    },
    {
        "currency": "Yen",
        "alphabeticCode": "JPY"
    },
    {
        "currency": "Jordanian Dinar",
        "alphabeticCode": "JOD"
    },
    {
        "currency": "Tenge",
        "alphabeticCode": "KZT"
    },
    {
        "currency": "Kenyan Shilling",
        "alphabeticCode": "KES"
    },
    {
        "currency": "North Korean Won",
        "alphabeticCode": "KPW"
    },
    {
        "currency": "Won",
        "alphabeticCode": "KRW"
    },
    {
        "currency": "Kuwaiti Dinar",
        "alphabeticCode": "KWD"
    },
    {
        "currency": "Som",
        "alphabeticCode": "KGS"
    },
    {
        "currency": "Lao Kip",
        "alphabeticCode": "LAK"
    },
    {
        "currency": "Lebanese Pound",
        "alphabeticCode": "LBP"
    },
    {
        "currency": "Loti",
        "alphabeticCode": "LSL"
    },
    {
        "currency": "Rand",
        "alphabeticCode": "ZAR"
    },
    {
        "currency": "Liberian Dollar",
        "alphabeticCode": "LRD"
    },
    {
        "currency": "Libyan Dinar",
        "alphabeticCode": "LYD"
    },
    {
        "currency": "Swiss Franc",
        "alphabeticCode": "CHF"
    },
    {
        "currency": "Pataca",
        "alphabeticCode": "MOP"
    },
    {
        "currency": "Denar",
        "alphabeticCode": "MKD"
    },
    {
        "currency": "Malagasy Ariary",
        "alphabeticCode": "MGA"
    },
    {
        "currency": "Malawi Kwacha",
        "alphabeticCode": "MWK"
    },
    {
        "currency": "Malaysian Ringgit",
        "alphabeticCode": "MYR"
    },
    {
        "currency": "Rufiyaa",
        "alphabeticCode": "MVR"
    },
    {
        "currency": "Ouguiya",
        "alphabeticCode": "MRU"
    },
    {
        "currency": "Mauritius Rupee",
        "alphabeticCode": "MUR"
    },
    {
        "currency": "ADB Unit of Account",
        "alphabeticCode": "XUA"
    },
    {
        "currency": "Mexican Peso",
        "alphabeticCode": "MXN"
    },
    {
        "currency": "Mexican Unidad de Inversion (UDI)",
        "alphabeticCode": "MXV"
    },
    {
        "currency": "Moldovan Leu",
        "alphabeticCode": "MDL"
    },
    {
        "currency": "Tugrik",
        "alphabeticCode": "MNT"
    },
    {
        "currency": "Moroccan Dirham",
        "alphabeticCode": "MAD"
    },
    {
        "currency": "Mozambique Metical",
        "alphabeticCode": "MZN"
    },
    {
        "currency": "Kyat",
        "alphabeticCode": "MMK"
    },
    {
        "currency": "Namibia Dollar",
        "alphabeticCode": "NAD"
    },
    {
        "currency": "Nepalese Rupee",
        "alphabeticCode": "NPR"
    },
    {
        "currency": "Cordoba Oro",
        "alphabeticCode": "NIO"
    },
    {
        "currency": "Naira",
        "alphabeticCode": "NGN"
    },
    {
        "currency": "Rial Omani",
        "alphabeticCode": "OMR"
    },
    {
        "currency": "Pakistan Rupee",
        "alphabeticCode": "PKR"
    },
    {
        "currency": "Balboa",
        "alphabeticCode": "PAB"
    },
    {
        "currency": "Kina",
        "alphabeticCode": "PGK"
    },
    {
        "currency": "Guarani",
        "alphabeticCode": "PYG"
    },
    {
        "currency": "Sol",
        "alphabeticCode": "PEN"
    },
    {
        "currency": "Philippine Peso",
        "alphabeticCode": "PHP"
    },
    {
        "currency": "Zloty",
        "alphabeticCode": "PLN"
    },
    {
        "currency": "Qatari Rial",
        "alphabeticCode": "QAR"
    },
    {
        "currency": "Romanian Leu",
        "alphabeticCode": "RON"
    },
    {
        "currency": "Russian Ruble",
        "alphabeticCode": "RUB"
    },
    {
        "currency": "Rwanda Franc",
        "alphabeticCode": "RWF"
    },
    {
        "currency": "Saint Helena Pound",
        "alphabeticCode": "SHP"
    },
    {
        "currency": "Tala",
        "alphabeticCode": "WST"
    },
    {
        "currency": "Dobra",
        "alphabeticCode": "STN"
    },
    {
        "currency": "Saudi Riyal",
        "alphabeticCode": "SAR"
    },
    {
        "currency": "Serbian Dinar",
        "alphabeticCode": "RSD"
    },
    {
        "currency": "Seychelles Rupee",
        "alphabeticCode": "SCR"
    },
    {
        "currency": "Leone",
        "alphabeticCode": "SLL"
    },
    {
        "currency": "Singapore Dollar",
        "alphabeticCode": "SGD"
    },
    {
        "currency": "Sucre",
        "alphabeticCode": "XSU"
    },
    {
        "currency": "Solomon Islands Dollar",
        "alphabeticCode": "SBD"
    },
    {
        "currency": "Somali Shilling",
        "alphabeticCode": "SOS"
    },
    {
        "currency": "South Sudanese Pound",
        "alphabeticCode": "SSP"
    },
    {
        "currency": "Sri Lanka Rupee",
        "alphabeticCode": "LKR"
    },
    {
        "currency": "Sudanese Pound",
        "alphabeticCode": "SDG"
    },
    {
        "currency": "Surinam Dollar",
        "alphabeticCode": "SRD"
    },
    {
        "currency": "Swedish Krona",
        "alphabeticCode": "SEK"
    },
    {
        "currency": "WIR Euro",
        "alphabeticCode": "CHE"
    },
    {
        "currency": "WIR Franc",
        "alphabeticCode": "CHW"
    },
    {
        "currency": "Syrian Pound",
        "alphabeticCode": "SYP"
    },
    {
        "currency": "New Taiwan Dollar",
        "alphabeticCode": "TWD"
    },
    {
        "currency": "Somoni",
        "alphabeticCode": "TJS"
    },
    {
        "currency": "Tanzanian Shilling",
        "alphabeticCode": "TZS"
    },
    {
        "currency": "Baht",
        "alphabeticCode": "THB"
    },
    {
        "currency": "Pa'anga",
        "alphabeticCode": "TOP"
    },
    {
        "currency": "Trinidad and Tobago Dollar",
        "alphabeticCode": "TTD"
    },
    {
        "currency": "Tunisian Dinar",
        "alphabeticCode": "TND"
    },
    {
        "currency": "Turkish Lira",
        "alphabeticCode": "TRY"
    },
    {
        "currency": "Turkmenistan New Manat",
        "alphabeticCode": "TMT"
    },
    {
        "currency": "Uganda Shilling",
        "alphabeticCode": "UGX"
    },
    {
        "currency": "Hryvnia",
        "alphabeticCode": "UAH"
    },
    {
        "currency": "UAE Dirham",
        "alphabeticCode": "AED"
    },
    {
        "currency": "US Dollar (Next day)",
        "alphabeticCode": "USN"
    },
    {
        "currency": "Peso Uruguayo",
        "alphabeticCode": "UYU"
    },
    {
        "currency": "Uruguay Peso en Unidades Indexadas (UI)",
        "alphabeticCode": "UYI"
    },
    {
        "currency": "Unidad Previsional",
        "alphabeticCode": "UYW"
    },
    {
        "currency": "Uzbekistan Sum",
        "alphabeticCode": "UZS"
    },
    {
        "currency": "Vatu",
        "alphabeticCode": "VUV"
    },
    {
        "currency": "Bol\u00c3\u00advar Soberano",
        "alphabeticCode": "VES"
    },
    {
        "currency": "Dong",
        "alphabeticCode": "VND"
    },
    {
        "currency": "Yemeni Rial",
        "alphabeticCode": "YER"
    },
    {
        "currency": "Zambian Kwacha",
        "alphabeticCode": "ZMW"
    },
    {
        "currency": "Zimbabwe Dollar",
        "alphabeticCode": "ZWL"
    },
    {
        "currency": "Bond Markets Unit European Composite Unit (EURCO)",
        "alphabeticCode": "XBA"
    },
    {
        "currency": "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
        "alphabeticCode": "XBB"
    },
    {
        "currency": "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
        "alphabeticCode": "XBC"
    },
    {
        "currency": "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
        "alphabeticCode": "XBD"
    },
    {
        "currency": "Codes specifically reserved for testing purposes",
        "alphabeticCode": "XTS"
    },
    {
        "currency": "The codes assigned for transactions where no currency is involved",
        "alphabeticCode": "XXX"
    },
    {
        "currency": "Gold",
        "alphabeticCode": "XAU"
    },
    {
        "currency": "Palladium",
        "alphabeticCode": "XPD"
    },
    {
        "currency": "Platinum",
        "alphabeticCode": "XPT"
    },
    {
        "currency": "Silver",
        "alphabeticCode": "XAG"
    },
    {
        "currency": "Afghani",
        "alphabeticCode": "AFA"
    },
    {
        "currency": "Markka",
        "alphabeticCode": "FIM"
    },
    {
        "currency": "Old Lek",
        "alphabeticCode": "ALK"
    },
    {
        "currency": "Andorran Peseta",
        "alphabeticCode": "ADP"
    },
    {
        "currency": "Spanish Peseta",
        "alphabeticCode": "ESP"
    },
    {
        "currency": "French Franc",
        "alphabeticCode": "FRF"
    },
    {
        "currency": "Kwanza",
        "alphabeticCode": "AOK"
    },
    {
        "currency": "New Kwanza",
        "alphabeticCode": "AON"
    },
    {
        "currency": "Kwanza Reajustado",
        "alphabeticCode": "AOR"
    },
    {
        "currency": "Austral",
        "alphabeticCode": "ARA"
    },
    {
        "currency": "Peso Argentino",
        "alphabeticCode": "ARP"
    },
    {
        "currency": "Peso",
        "alphabeticCode": "ARY"
    },
    {
        "currency": "Russian Ruble",
        "alphabeticCode": "RUR"
    },
    {
        "currency": "Schilling",
        "alphabeticCode": "ATS"
    },
    {
        "currency": "Azerbaijan Manat",
        "alphabeticCode": "AYM"
    },
    {
        "currency": "Azerbaijanian Manat",
        "alphabeticCode": "AZM"
    },
    {
        "currency": "Belarusian Ruble",
        "alphabeticCode": "BYB"
    },
    {
        "currency": "Belarusian Ruble",
        "alphabeticCode": "BYR"
    },
    {
        "currency": "Convertible Franc",
        "alphabeticCode": "BEC"
    },
    {
        "currency": "Belgian Franc",
        "alphabeticCode": "BEF"
    },
    {
        "currency": "Financial Franc",
        "alphabeticCode": "BEL"
    },
    {
        "currency": "Peso boliviano",
        "alphabeticCode": "BOP"
    },
    {
        "currency": "Dinar",
        "alphabeticCode": "BAD"
    },
    {
        "currency": "Cruzeiro",
        "alphabeticCode": "BRB"
    },
    {
        "currency": "Cruzado",
        "alphabeticCode": "BRC"
    },
    {
        "currency": "Cruzeiro",
        "alphabeticCode": "BRE"
    },
    {
        "currency": "New Cruzado",
        "alphabeticCode": "BRN"
    },
    {
        "currency": "Cruzeiro Real",
        "alphabeticCode": "BRR"
    },
    {
        "currency": "Lev A/52",
        "alphabeticCode": "BGJ"
    },
    {
        "currency": "Lev A/62",
        "alphabeticCode": "BGK"
    },
    {
        "currency": "Lev",
        "alphabeticCode": "BGL"
    },
    {
        "currency": "Kyat",
        "alphabeticCode": "BUK"
    },
    {
        "currency": "Croatian Dinar",
        "alphabeticCode": "HRD"
    },
    {
        "currency": "Cyprus Pound",
        "alphabeticCode": "CYP"
    },
    {
        "currency": "Krona A/53",
        "alphabeticCode": "CSJ"
    },
    {
        "currency": "Koruna",
        "alphabeticCode": "CSK"
    },
    {
        "currency": "Sucre",
        "alphabeticCode": "ECS"
    },
    {
        "currency": "Unidad de Valor Constante (UVC)",
        "alphabeticCode": "ECV"
    },
    {
        "currency": "Ekwele",
        "alphabeticCode": "GQE"
    },
    {
        "currency": "Kroon",
        "alphabeticCode": "EEK"
    },
    {
        "currency": "European Currency Unit (E.C.U)",
        "alphabeticCode": "XEU"
    },
    {
        "currency": "Georgian Coupon",
        "alphabeticCode": "GEK"
    },
    {
        "currency": "Mark der DDR",
        "alphabeticCode": "DDM"
    },
    {
        "currency": "Deutsche Mark",
        "alphabeticCode": "DEM"
    },
    {
        "currency": "Cedi",
        "alphabeticCode": "GHC"
    },
    {
        "currency": "Ghana Cedi",
        "alphabeticCode": "GHP"
    },
    {
        "currency": "Drachma",
        "alphabeticCode": "GRD"
    },
    {
        "currency": "Syli",
        "alphabeticCode": "GNE"
    },
    {
        "currency": "Syli",
        "alphabeticCode": "GNS"
    },
    {
        "currency": "Guinea Escudo",
        "alphabeticCode": "GWE"
    },
    {
        "currency": "Guinea-Bissau Peso",
        "alphabeticCode": "GWP"
    },
    {
        "currency": "Italian Lira",
        "alphabeticCode": "ITL"
    },
    {
        "currency": "Old Krona",
        "alphabeticCode": "ISJ"
    },
    {
        "currency": "Irish Pound",
        "alphabeticCode": "IEP"
    },
    {
        "currency": "Pound",
        "alphabeticCode": "ILP"
    },
    {
        "currency": "Old Shekel",
        "alphabeticCode": "ILR"
    },
    {
        "currency": "Pathet Lao Kip",
        "alphabeticCode": "LAJ"
    },
    {
        "currency": "Latvian Lats",
        "alphabeticCode": "LVL"
    },
    {
        "currency": "Latvian Ruble",
        "alphabeticCode": "LVR"
    },
    {
        "currency": "Loti",
        "alphabeticCode": "LSM"
    },
    {
        "currency": "Financial Rand",
        "alphabeticCode": "ZAL"
    },
    {
        "currency": "Lithuanian Litas",
        "alphabeticCode": "LTL"
    },
    {
        "currency": "Talonas",
        "alphabeticCode": "LTT"
    },
    {
        "currency": "Luxembourg Convertible Franc",
        "alphabeticCode": "LUC"
    },
    {
        "currency": "Luxembourg Franc",
        "alphabeticCode": "LUF"
    },
    {
        "currency": "Luxembourg Financial Franc",
        "alphabeticCode": "LUL"
    },
    {
        "currency": "Malagasy Franc",
        "alphabeticCode": "MGF"
    },
    {
        "currency": "Maldive Rupee",
        "alphabeticCode": "MVQ"
    },
    {
        "currency": "Mali Franc",
        "alphabeticCode": "MLF"
    },
    {
        "currency": "Maltese Lira",
        "alphabeticCode": "MTL"
    },
    {
        "currency": "Maltese Pound",
        "alphabeticCode": "MTP"
    },
    {
        "currency": "Ouguiya",
        "alphabeticCode": "MRO"
    },
    {
        "currency": "Mexican Peso",
        "alphabeticCode": "MXP"
    },
    {
        "currency": "Mozambique Escudo",
        "alphabeticCode": "MZE"
    },
    {
        "currency": "Mozambique Metical",
        "alphabeticCode": "MZM"
    },
    {
        "currency": "Netherlands Guilder",
        "alphabeticCode": "NLG"
    },
    {
        "currency": "Cordoba",
        "alphabeticCode": "NIC"
    },
    {
        "currency": "Sol",
        "alphabeticCode": "PEH"
    },
    {
        "currency": "Inti",
        "alphabeticCode": "PEI"
    },
    {
        "currency": "Sol",
        "alphabeticCode": "PES"
    },
    {
        "currency": "Zloty",
        "alphabeticCode": "PLZ"
    },
    {
        "currency": "Portuguese Escudo",
        "alphabeticCode": "PTE"
    },
    {
        "currency": "Leu A/52",
        "alphabeticCode": "ROK"
    },
    {
        "currency": "Old Leu",
        "alphabeticCode": "ROL"
    },
    {
        "currency": "Dobra",
        "alphabeticCode": "STD"
    },
    {
        "currency": "Serbian Dinar",
        "alphabeticCode": "CSD"
    },
    {
        "currency": "Slovak Koruna",
        "alphabeticCode": "SKK"
    },
    {
        "currency": "Tolar",
        "alphabeticCode": "SIT"
    },
    {
        "currency": "Rhodesian Dollar",
        "alphabeticCode": "RHD"
    },
    {
        "currency": "Spanish Peseta",
        "alphabeticCode": "ESA"
    },
    {
        "currency": "\"A\" Account (convertible Peseta Account)",
        "alphabeticCode": "ESB"
    },
    {
        "currency": "Sudanese Dinar",
        "alphabeticCode": "SDD"
    },
    {
        "currency": "Sudanese Pound",
        "alphabeticCode": "SDP"
    },
    {
        "currency": "Surinam Guilder",
        "alphabeticCode": "SRG"
    },
    {
        "currency": "WIR Franc (for electronic)",
        "alphabeticCode": "CHC"
    },
    {
        "currency": "Tajik Ruble",
        "alphabeticCode": "TJR"
    },
    {
        "currency": "Timor Escudo",
        "alphabeticCode": "TPE"
    },
    {
        "currency": "Old Turkish Lira",
        "alphabeticCode": "TRL"
    },
    {
        "currency": "Turkmenistan Manat",
        "alphabeticCode": "TMM"
    },
    {
        "currency": "Uganda Shilling",
        "alphabeticCode": "UGS"
    },
    {
        "currency": "Old Shilling",
        "alphabeticCode": "UGW"
    },
    {
        "currency": "Karbovanet",
        "alphabeticCode": "UAK"
    },
    {
        "currency": "Rouble",
        "alphabeticCode": "SUR"
    },
    {
        "currency": "US Dollar (Same day)",
        "alphabeticCode": "USS"
    },
    {
        "currency": "Old Uruguay Peso",
        "alphabeticCode": "UYN"
    },
    {
        "currency": "Uruguayan Peso",
        "alphabeticCode": "UYP"
    },
    {
        "currency": "Bolivar",
        "alphabeticCode": "VEB"
    },
    {
        "currency": "Bolivar Fuerte",
        "alphabeticCode": "VEF"
    },
    {
        "currency": "Old Dong",
        "alphabeticCode": "VNC"
    },
    {
        "currency": "Yemeni Dinar",
        "alphabeticCode": "YDD"
    },
    {
        "currency": "New Yugoslavian Dinar",
        "alphabeticCode": "YUD"
    },
    {
        "currency": "New Dinar",
        "alphabeticCode": "YUM"
    },
    {
        "currency": "Yugoslavian Dinar",
        "alphabeticCode": "YUN"
    },
    {
        "currency": "New Zaire",
        "alphabeticCode": "ZRN"
    },
    {
        "currency": "Zaire",
        "alphabeticCode": "ZRZ"
    },
    {
        "currency": "Zambian Kwacha",
        "alphabeticCode": "ZMK"
    },
    {
        "currency": "Rhodesian Dollar",
        "alphabeticCode": "ZWC"
    },
    {
        "currency": "Zimbabwe Dollar (old)",
        "alphabeticCode": "ZWD"
    },
    {
        "currency": "Zimbabwe Dollar (new)",
        "alphabeticCode": "ZWN"
    },
    {
        "currency": "Zimbabwe Dollar",
        "alphabeticCode": "ZWR"
    },
    {
        "currency": "Gold-Franc",
        "alphabeticCode": "XFO"
    },
    {
        "currency": "RINET Funds Code",
        "alphabeticCode": "XRE"
    },
    {
        "currency": "UIC-Franc",
        "alphabeticCode": "XFU"
    }
]

export default currencies