import React, { useEffect, useState } from "react";
import {  Button, Spin } from "antd";
import {  doGet } from "../../../../../API/apis";
import { useNavigate, useParams } from "react-router-dom";
import PrescriptionItem from "../../../PatientSummary/Prescription/PrescriptionItem";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";



function PatientMedication() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNavigate = ()=>{
    navigate(`/main/charts/patient/${id}/medications`);
  }


  const getPrescriptions = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/prescriptions/get/${id}/`);
      if (response.status === 200) {
        setData(response?.data.reverse());
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrescriptions();
  }, []);


  
  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
          <div className="flex justify-between items-center">
          <MedicalCardsTitle title='Prescribed Medications'/>
          <Button type="primary" size="small" onClick={handleNavigate}>View All</Button>
          </div>
        {loading ? (
          <div className="flex justify-center items-center h-[120px]">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex flex-col max-h-[120px] overflow-auto mt-1">
            {data?.length === 0 ? (
              <p>No medication added.</p>
            ) : (
              data?.map((item) => (
                <PrescriptionItem key={item.id} prescriptions={item?.prescriptions} showButton={false} showInActive={false} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
  
}

export default PatientMedication;
