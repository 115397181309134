import { Button, Modal, Space, Image } from 'antd'
import React from 'react'
import FormatDate from '../../../../components/FormatDate/FormatDate'
import { useNavigate } from 'react-router-dom'

const ViewRegisterPatient = ({open, onCancel, data}) => {
    const navigate = useNavigate()

    const handlePatient = () => {
        navigate(`/main/charts/patient/${data?.data?.id}/`);
      };

  return (
    <Modal
    open={open}
    onCancel={onCancel}
    footer={null}
    maskClosable={false}
    className="w-[400px] mt-[5rem]"
    closeIcon={
      <Button size="small" className="app-close-icon" shape="round">
        Close
      </Button>
    }
  >
    <Space size="large">
      <div>
        <Image
          width={180}
          height={180}
          src={data?.data?.profile_picture}
          fallback="https://via.placeholder.com/150"
        />
      </div>
      <div>
        <Space direction="vertical" size="small">
          <p>
            <b> MRN:</b>
            <span className="ml-1">{data?.data?.mrn_number}</span>
          </p>
          <p>
            <b>Name:</b>
            <span className="ml-1">
              {data?.data?.first_name} {data?.data?.middle_name}{" "}
              {data?.data?.last_name}
            </span>
          </p>
          <p>
            <b> Email:</b>
            <span className="ml-1">{data?.data?.patient_email}</span>
          </p>
          <p>
            <b> Phone:</b>
            <span className="ml-1">{data?.data?.patient_phone}</span>
          </p>
          <p>
            <b> DOB :</b>
            <span className="ml-1">
              <FormatDate date={data?.data?.date_of_birth} />
            </span>
          </p>
          <p>
            <b>Gender:</b>
            <span className="ml-1">{data?.data?.gender}</span>
          </p>
        </Space>
      </div>
    </Space>
    <div className="flex justify-end">
      <Button
        className="ml-2"
        type="primary"
        size="small"
        onClick={handlePatient}
      >
        View Patient
      </Button>
    </div>
  </Modal>
  )
}

export default ViewRegisterPatient
