import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";

const EmergencyContact = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false)
  const { patientBasicInfo, getPatientData, loading } = useStateGlobalContext();  

  const { emergency_first_name, emergency_last_name, emergency_phone_number,  emergency_relationship, emergency_notes } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        const { emergency_first_name, emergency_last_name, emergency_phone_number, emergency_relationship, emergency_notes } =
          patientBasicInfo;

        form.setFieldsValue({
          emergency_first_name,
          emergency_last_name,
          emergency_phone_number,
          emergency_relationship,
          emergency_notes,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = ()=>{
    setEdit(true)
  }

  const onEditCancel = ()=>{
    setEdit(false)
  }

  const handleUpdate = async (values)=>{
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id); 
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderFormItem = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={12}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
      <h1 className="text-[1.125rem] font-bold mb-4">Emergency Contact</h1>
      {edit ? <Button onClick={onEditCancel}>Close</Button> : <Button onClick={onEdit}>Edit</Button> }
      </div>
      { edit ? (
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem(
            "First Name",
            "emergency_first_name",
            <Input placeholder="First Name" />
          )}
          {renderFormItem(
            "Last Name",
            "emergency_last_name",
            <Input placeholder="Last Name" />
          )}
        </Row>

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem("Phone", "emergency_phone_number", <Input placeholder="Phone" />)}
          {renderFormItem(
            "Relationship With Patient",
            "emergency_relationship",
            <Input placeholder="Relationship With Patient" />
          )}
        </Row>
        <Form.Item label="Notes" name="emergency_notes">
          <Input.TextArea placeholder="notes" maxLength={300} showCount />
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={onEditCancel}>Cancel Changes</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Save Changes
          </Button>
        </div>
      </Form>
      ) : (
      <div className="view-wrapper">
          <p>First Name: {emergency_first_name}</p>
          <p>Last Name: {emergency_last_name}</p>
          <p>Phone: {emergency_phone_number}</p>
          <p>Relationship With Patient: {emergency_relationship}</p>
          <p className="notes">Notes: {emergency_notes}</p>
      </div>
      )}
    </div>
  );
};

export default EmergencyContact;
