import React, { useEffect, useState } from "react";
import { Form, Button, Input } from "antd";
import { doGet, doPatch, doPost } from "../../../../../API/apis";
import { QuestionCircleFilled } from "@ant-design/icons";
import Notification from "../../../../../components/Notification/Notification";

const { Item } = Form;

const MonthlyInvoiceInfo = () => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  const showEdit = () => {
    setEdit(!edit);
  };

  const handleEmail = async (values) => {
    const data = {
      email: values?.email,
    };
    try {
      const url = invoiceDetails?.id
        ? `/administration/account_owner_email_for_invoice/update/${invoiceDetails?.id}/`
        : "/administration/account_owner_email_for_invoice/add/";
      const response = invoiceDetails?.id
        ? await doPatch(url, data)
        : await doPost(url, data);
      if (response.status === 201 || response.status === 200) {
        Notification.success(
          response.status === 200
            ? "Record Updated Successfully"
            : "Record Added Successfully"
        );
        setEdit(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getClientEmail = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        "/administration/account_owner_email_for_invoice/get/"
      );
      if (response.status === 200) {
        setInvoiceDetails(response.data[0]);
        form.setFieldsValue({
          email: response.data[0]?.email,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientEmail();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="font-semibold text-md">Invoicing Email <QuestionCircleFilled title="Please provide the email address of your accountant to which you prefer to receive the monthly invoice"/></p>
        <Button onClick={showEdit}>{edit ? "Cancel" : "Edit"}</Button>
      </div>
      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleEmail}>
          <Item
            label="Accountant Email"
            name="email"
            rules={[
              { required: true, message: "Please Enter Your Email!" },
              { type: "email", message: "Please Enter a valid Email!" },
            ]}
          >
            <Input />
          </Item>
          <Button type="primary" htmlType="submit">
            {invoiceDetails?.id ? "Update" : "Submit"}
          </Button>
        </Form>
      ) : (
        <div className="view-wrapper">
          <div>
            <p>Accountant Email: {invoiceDetails?.email}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default MonthlyInvoiceInfo;
