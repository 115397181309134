import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Avatar, List, Modal } from "antd";
import { doGet, doPatch, doPost, doDelete } from "../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const API_URL = "/patient/message/";
const COMMENT_URL = `${API_URL}view/`;
const UPDATE_URL = `${API_URL}update/`;
const CREATE_URL = `${API_URL}create/`;

const SUCCESS_MESSAGES = {
  update: "Message is Updated Successfully",
  add: "Message is Added Successfully",
};

const DoctorComments = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [comments, setComments] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setShowMessage(false);
    setEdit(false);
    setEditRecord(null);
  };

  const openMessage = (item) => {
    setSelectedItem(item);
    setShowMessage(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteMessage(record?.id);
      },
    });
  };

  const deleteMessage = async (record) => {
    try {
      const response = await doDelete(
        `/patient/message/delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Outstanding Bill deleted successfully");
        getComments();
        onCancel();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (item) => {
    setShowMessage(false);
    setEditRecord(item?.id);
    setEdit(true);
    setVisible(true);
    form.setFieldsValue({
      subject: item?.subject,
      message: item?.message,
    });
  };

  const handleComments = async (values) => {
    const data = {
      subject: values.subject,
      message: values.message,
    };
    try {
      const response = edit
        ? await doPatch(`${UPDATE_URL}${id}/${editRecord}/`, data)
        : await doPost(`${CREATE_URL}${id}/`, data);

      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success(SUCCESS_MESSAGES.update)
          : Notification.success(SUCCESS_MESSAGES.add);
        getComments();
        onCancel();
      }
    } catch (error) {
      console.error("Error:", error);
      Notification.error("An error occurred. Please try again.");
    }
  };

  const getComments = async () => {
    setLoading(true);
    try {
      const response = await doGet(`${COMMENT_URL}${id}/`);
      if (response.status === 200) {
        setComments(response?.data?.reverse());
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  const renderListItem = (item, index) => {
    if (
      searchTerm &&
      !item.created_by.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return null;
    }

    return (
      <List.Item
        key={index}
        onClick={() => openMessage(item)}
        className="cursor-pointer"
      >
        <List.Item.Meta
          className="mr-4"
          avatar={
            <Avatar
              shape="circle"
              style={{
                backgroundColor: "#f56a00",
              }}
            >
              {item?.created_by?.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={
            <p>
              <span>Subject: {item?.subject} </span>
            </p>
          }
          description={
            <p>
              <span className="text-gray-400 font-thin">
                Added By: {item?.created_by} -
              </span>
              <span className="mr-2 text-gray-400 font-thin"> <FormatDateTime date={item?.date_time} type="datetime"/>
              </span>
            </p>
          }
        />
      </List.Item>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-bold">Provider Comments </h1>
        <div className="flex">
          <Input
            placeholder="Search by provider name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-2"
          />
          {role !== "nurse" && (
            <Button type="primary" onClick={openModal}>
              New Message
            </Button>
          )}
        </div>
      </div>

      <Modal
        title={edit ? "Update Comment" : "New Comment"}
        open={visible}
        width={900}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        onCancel={onCancel}
      >
        <Form layout="vertical" form={form} onFinish={handleComments}>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                message: "Please Enter the Subject",
              },
            ]}
          >
            <Input placeholder="Subject" maxLength={100} />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: "Please Enter the Message",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Message..."
              maxLength={5000}
              showCount
              rows={6}
            />
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              {edit ? "Update" : "Send"}
            </Button>
          </div>

          <p className="mt-2 text-gray-400">
            *This is a One Way Communication with your Patients
          </p>
        </Form>
      </Modal>

      <Modal
        title="Message"
        open={showMessage}
        footer={null}
        onCancel={onCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <p>
          <span className="font-medium">Provider: </span>{" "}
          {selectedItem?.created_by}
        </p>
        <p>
          <span className="font-medium">Date: </span>
          <FormatDateTime date={selectedItem?.date_time} type="datetime"/>
        </p>
        <p>
          <span className="font-medium">Subject: </span> {selectedItem?.subject}
        </p>
        <p>
          <span className="font-medium">Provider's Comment: </span>
          {selectedItem?.message}
        </p>
        {role !== 'nurse' && (
          <div className="flex justify-between mt-2">
            <Button
              className="mr-2 danger__button"
              onClick={() => handleDelete(selectedItem)}
            >
              Delete
            </Button>
            <Button type="primary" onClick={() => handleEdit(selectedItem)}>
              Edit
            </Button>
          </div>
        )}
      </Modal>

      {comments !== null ? (
        <List
          loading={loading}
          className="max-h-[300px] overflow-auto mt-4"
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={renderListItem}
        />
      ) : (
        <p>No comments available.</p>
      )}

      <p className="text-gray-400 mt-20 text-center">
        *This is a One Way Communication with your Patients.
      </p>
      <p className="text-gray-400 text-center">
        **Always ask your Patients to call the Office directly or in case of
        Emergency, call local authorities.
      </p>
    </div>
  );
};

export default DoctorComments;
