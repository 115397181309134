import React from "react";
import { Menu, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Outlet, useNavigate } from "react-router-dom";
import Title from "../../../components/Title/Title";

const items = [
  { label: "Dashboard", key: "" },
  { label: "Inventory List", key: "inventory-list" },
];

const PatientTabs = () => {
  const navigate = useNavigate();
  document.title = "Inventory | American EMR";

  return (
    <>
      <Title title="Inventory Management" />
      <Layout style={{ backgroundColor: "transparent" }}>
        <Menu
          onClick={({ key }) => navigate(key)}
          mode="horizontal"
          defaultSelectedKeys={[""]}
          items={items}
          className="summary-menu mb-4 bg-white font-medium text-[16px] tracking-[0.2px]"
        />
        <Content
          className="layout"
          style={{
            backgroundColor: "white",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};

export default PatientTabs;
