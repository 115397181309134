import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  AutoComplete,
  Row,
  Col,
} from "antd";
import { useParams } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import { useSearchICD_Codes } from "../../../../../API/apiUtils";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const ICD10Codes = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [icd10_visible, setICD10_Visible] = useState(false);
  const { options, handleSearchICD10Codes } = useSearchICD_Codes();


  const disabledDate = (current) => {
    return current && current > new Date();
  };



  const onSelect = (value, option) => {
    const selectedICD10 = options.find((item) => item.value === value);
    if (selectedICD10) {
      form.setFieldsValue({
        code: selectedICD10?.value,
        description: selectedICD10?.description, 
      });
    }
  };

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setICD10_Visible(false);
    setTimeout(() => {
      setEditMode(false);
      form.resetFields();
    }, 300);
  };

  const handleText = (record) => {
    setSelectedItem(record);
    setICD10_Visible(true);
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({
      date: dayjs(record?.date),
      description: record?.description,
      code: record?.code,
    });
    setVisible(true);
  };


  const handleICD10Codes = async (values) => {
    const data = {
      date: values.date,
      description: values?.description,
      code: values?.code,
    };
    try {
      const response = editMode
        ? await doPatch(
            `/patient/update-icd-10-codes/${id}/${selectedItem.id}/`,
            data
          )
        : await doPost(`/patient/add-icd-10-codes/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        editMode
          ? Notification.success("ICD-10 code updated successfully")
          : Notification.success("ICD-10 code added successfully");
        getPatientICD10codes();
        handleCancel();
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPatientICD10codes = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-icd-10-codes/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteICD10Codes = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteICD10CodesConfirm(record);
      },
    });
  };

  const deleteICD10CodesConfirm = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-icd-10-codes/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("ICD-10 code deleted successfully");
        getPatientICD10codes();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPatientICD10codes();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Recent Diagnosis" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul>
              {data && data?.map((item, index) => (
                <li
                  key={item.id}
                  className="cursor-pointer"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <div className="flex">
                    <span className="text-[#1890ff]">{index + 1}.</span>
                    <div className="flex flex-col ml-1">
                      <span
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleText(item);
                        }}
                        title={item?.description}
                      >
                        {item?.description?.slice(0, 40)}
                        {item?.description?.length > 40 ? "..." : ""}
                      </span>
                    </div>
                    <div className="flex items-center ml-3">
                      {hoveredItemId === item.id && (
                        <>
                          <EditOutlined
                            className="text-[#1890ff] mr-2"
                            onClick={() => handleEdit(item)}
                          />
                          <DeleteOutlined
                            className="text-[#1890ff]"
                            onClick={() => deleteICD10Codes(item.id)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
              {data && data?.length === 0 && (
                <div className="flex justify-center items-center">
                  <span className="text-slate-600 text-[13px] font-medium">
                    No Diagnosis Added
                  </span>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Diagnosis"
        open={visible}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        maskClosable={false}
        width={800}
      >
        <Form
          layout="vertical"
          name="icd-codes"
          form={form}
          onFinish={handleICD10Codes}
        >
          <Form.Item label="Search Diagnosis Code" name={'search_icd_10_codes'}>
            <AutoComplete
              options={options}
              allowClear
              onSelect={onSelect}
              onSearch={handleSearchICD10Codes}
              placeholder="Enter ICD-10 description"
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <BaseDatePicker
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ICD-10 Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter ICD-10 description",
                  },
                ]}
              >
                <Input placeholder="Description"/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="ICD-10 Code" name='code'>
                <Input placeholder="code"/>
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Diagnosis History"
        open={icd10_visible}
        onCancel={handleCancel}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Diagnosis Date:</span>
              <span className="ml-2">
                <FormatDate date={selectedItem?.date}/>
              </span>
            </p>
            <p>
              <span className="font-medium">Diagnosis Code:</span>
              <span className="ml-2">{selectedItem?.code}</span>
            </p>
            <p>
              <span className="font-medium">Diagnosis Description:</span>
              <span className="ml-2">{selectedItem?.description}</span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem?.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime date={selectedItem?.date_time} type="datetime"/>
              </span>
            </p>
          </>
        )}
      </Modal>
    </div> 
  );
};

export default ICD10Codes;
