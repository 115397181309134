import React, { useEffect, useState } from "react";
import { Card, Col, Avatar, Tag, Table} from "antd";
import { doGet} from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { useOutletContext } from "react-router-dom";
import StaffRecord from "../../../../components/StaffRecord/StaffRecord";
import Notification from "../../../../components/Notification/Notification";

const column = [
  {
    key: 1,
    title: "Active Staff",
    width: "30%",
    dataIndex: "name",
    render: (text, record) => (
      <div className="flex gap-4">
        <Avatar
          shape="circle"
          style={{
            backgroundColor: "#f56a00",
          }}
        >
          {text?.charAt(0).toUpperCase()}
        </Avatar>
        <div>
          <p className="font-bold text-black">{text}</p>
          <p className="role">
            {record?.availability?.schedule?.role === "doctor"
              ? "Provider"
              : record?.availability?.schedule?.role === "clinic"
              ? "Owner"
              : record?.availability?.schedule?.role === "manager"
              ? "Admin"
              : "Nurse"}
          </p>
        </div>
      </div>
    ),
  },
  {
    key: 2,
    title: "Today's Availability",
    align: "center",
    width: "30%",
    render: (text, record) => (
      <div className="flex justify-evenly">
        <Tag
          className="rounded-lg"
          color={record?.availability?.schedule?.status ? "#87d068" : "#f87171"}
        >
          {record?.availability?.schedule?.status ? "Available" : "Not Available"}
        </Tag>
        <p>
          {record?.availability?.schedule?.start_time &&
          record?.availability?.schedule?.end_time ? (
            <>
              {record.availability.schedule.start_time} to {record.availability.schedule.end_time}
            </>
          ) : (
            "No Time Added"
          )}
        </p>
      </div>
    ),
  },  
  {
    key: 4,
    title: "Notes",
    width: "25%",
    align: "center",
    ellipsis: true,
    render: (_, record) => <span>{record?.availability?.schedule?.note}</span>,
  },
];

const ClinicStaff = () => {
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [staffAvailability, setStaffAvailability] = useState(null);
  const { getUserInfo } = useStateGlobalContext();
  const [showStaffDetails, setShowStaffDetails] = useState({
    show: false,
    data: null
  })
  const [officeDateFormat, officeTimeFormat, officeTimezone] = useOutletContext()

  const currentDayOptions = { weekday: 'long', timeZone: officeTimezone || "US/Central" };
  const currentDay = currentDate?.toLocaleString('en-US', currentDayOptions);

  const getStaffAvailability = async () => {
    setLoading(true);
    try {
      const response = await doGet("/settings/staff-availability/");
      if (response.status === 200) {
        setStaffAvailability(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showStaffInfo = async (record) => {
    setLoading(true)
    setShowStaffDetails({
      show: true,
    });
  
    try {
      const response = await doGet(`/settings/settings-detail/${record}/?q=False`); 
      if (response.status === 200) {
        setShowStaffDetails({
          show: true,
          data: response.data,
        });
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
      console.error("Error fetching staff details:", error);
    } finally{
      setLoading(false)
    }
  };
  
  const hideStaffInfo = ()=>{
    setShowStaffDetails({
      show: false,
      data: null
    })
  }

  useEffect(() => {
    getStaffAvailability();
    getUserInfo()
  }, []);

  return (
    <>
    <Col xl={12} md={12} sm={24}>
      <Card className="mt-4 shadow-sm">
        <div className="flex justify-between items-center mb-2">
        <h1 className="font-bold text-lg">Staff Schedule</h1>
        <h3> Today is {currentDay}</h3>
        </div>
        <Table
          size="small"
          columns={column}
          dataSource={staffAvailability?.staff}
          pagination={false}
          loading={loading}
          onRow={(record) => ({
            onClick: () => showStaffInfo(record?.availability?.schedule?.id),
          })}
          rowKey="id"
        />
      </Card>
    </Col>

    <StaffRecord show={showStaffDetails?.show} onClose={hideStaffInfo} data={showStaffDetails?.data} loading={loading}/>
    </>
  );
};

export default ClinicStaff;
