import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload,  Spin } from "antd";
import { PlusOutlined, InboxOutlined, DeleteOutlined , EditOutlined} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { doDelete, doGet, doPatch, doPost } from "../../../../../API/apis";
import MedicalCardsTitle from "../MedicalCardsTitle";
import ViewPatientFiles from "../../PatientFiles/component/ViewPatientFiles";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";

const { Dragger } = Upload;
const MAX_FILE_SIZE = 5 * 1024 * 1024;

const headers = {
  "Content-Type": "multipart/form-data",
};

function AdvanceDirectives() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileLoading, setFileLoading] = useState(true);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [view, setView] = useState({
    viewState: false,
    viewData: null,
  });

  const openModal = () => {
    setVisible(true);
  };

  const closeView = () => {
    setView({
      viewState: false,
      viewData: null,
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setEdit(false)
    form.resetFields();
    setFileList([]);
  };

  const handleView = async (record) => {
    await handleFile(record?.uploaded_file_encrypted_name);
    setView({
      ...view,
      viewState: true,
      viewData: record,
    });
  };

  const handleEdit = (record)=>{
    setEdit(true)
    setVisible(true)
    setEditRecord(record)
    form.setFieldsValue({
      file_name: record?.file_name,
      description: record?.description
    })
  }

  const handleFile = async (record) => {
    try {
      const response = await doGet(`/upload/media/retrieve/${record}/`);
      if (response.status === 200) {
        const fileContent = response.data;
        setFileData(fileContent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false);
    }
  };

  const handleAdvanceDirectives = async (values) => {
    setLoading(true);
    let data = {}
    let formData = new FormData();
    if(!edit){
      formData.append("file_name", values.file_name);
      formData.append("description", values.description);
      fileList.forEach((file) => {
        formData.append("file", file);
      }); 
    }else{
      data = {
        file_name: values?.file_name,
        description: values?.description
      }
    }

    try {
      const url = edit
      ? `/patient/update-advance-directives/${id}/${editRecord?.id}/`
      : `/patient/advance-directives/${id}/`
      const response = await (edit
        ? doPatch(url, !edit ? formData: data, !edit && headers)
        : doPost(url, !edit ? formData: data, !edit && headers));

      if (response.status === 201 || response.status === 200) {
        edit ? Notification.success("Patient File(s) Updated Successfully"):  Notification.success("Patient File(s) Uploaded Successfully");
        getAdvanceDirective();
        handleCancel();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAdvanceDirective = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-advance-directives/${id}/`);
      if (response.status === 200) {
        setData(response?.data);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteUploadedFile(record);
      },
    });
  };

  const deleteUploadedFile = async (record) => {
    setLoading(true);
    try {
      const response = await doDelete(
        `/patient/delete-advance-directives/${id}/${record.id}/`
      );
      if (response.status === 200) {
        Notification.success("File Deleted Successfully");
        getAdvanceDirective();
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      Notification.error("File size exceeds the maximum limit (5MB)");
      return false;
    }
  
    if (fileList.length === 1) {
      Notification.warning("You can only upload one file.");
      return false;
    }
  
    setFileList([file]); 
    return false;
  };
  

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  useEffect(() => {
    getAdvanceDirective();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px] max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Advance Directives" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        <div className="flex flex-col max-h-[130px] overflow-auto">
          {loading ? (
            <div className="flex justify-center items-center">
              <Spin size="small" />
            </div>
          ) : (
            <div className="flex max-h-[130px]  overflow-auto mt-1">
              <ul>
                {data &&
                  data?.map((item, index) => (
                    <li
                      key={item.id}
                      className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                      onMouseEnter={() => setHoveredItemId(item.id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                    >
                      <span
                        onClick={() => {
                          handleView(item);
                        }}
                      >
                        {index + 1}. {item?.file_name?.slice(0, 26)}
                        {item.file_name?.length > 26 ? "..." : ""}
                      </span>
                      {hoveredItemId === item.id && (
                        <div className="flex items-center ml-3">
                          <EditOutlined
                            className="text-[#1890ff] mr-2"
                            onClick={() => {
                              handleEdit(item);
                            }}
                          />
                          <DeleteOutlined
                            className="text-[#1890ff]"
                            onClick={() => {
                              handleDelete(item);
                            }}
                          />
                        </div>
                      )}
                    </li>
                  ))}
                {data.length === 0 && (
                  <li className="flex justify-between items-center">
                    <span>No Files Uploaded</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      <Modal
        title={edit ? "Update Advance Directives" : "Advance Directives"}
        open={visible}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          name="advance_directives_form"
          onFinish={handleAdvanceDirectives}
          form={form}
        >
          <Form.Item
            label="File Name"
            name="file_name"
            rules={[
              {
                required: true,
                message: "Please Enter File Name",
              },
            ]}
          >
            <Input placeholder="File Name" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              placeholder="Enter Description"
              maxLength={500}
              showCount
            />
          </Form.Item>
          {!edit && (
            <Form.Item
              name="file"
              label="File"
              rules={[
                {
                  required: true,
                  message: "Please Select any file",
                },
              ]}
            >
              <Dragger
                fileList={fileList}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                accept=".pdf, .jpg, .jpeg, .png"
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className="upload__icon" />
                </p>
                <p className="ant-upload-text">
                  Click or drag pdf, jpg, jpeg files to this area to upload
                </p>
                <p className="ant-upload-hint">Maximum size: 5MB.</p>
              </Dragger>
            </Form.Item>
          )}

          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {edit ? "Update": "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <ViewPatientFiles
        visible={view?.viewState}
        onClose={closeView}
        data={view?.viewData}
        fileData={fileData}
        loading={fileLoading}
      />
    </div>
  );
}

export default AdvanceDirectives;
