import React, { useEffect, useState } from "react";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import LabOrders from "./LabOrders";
import { Button, Spin } from "antd";
import MedicalCardsTitle from '../MedicalCardsTitle'

function OrderedLabs() {
  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { orderedLabs, getLabOrders } = useStateGlobalContext();

  const handleViewOrderedLabs = ()=>{
    navigate(`/main/charts/patient/${id}/labs&imaging`)
  }

  useEffect(() => {
    setLoading(true)
    getLabOrders(id);
    setLoading(false)
  }, [id]);
 
  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px] max-h-[180px]">
        <div className="flex justify-between items-center">
          <MedicalCardsTitle title='Ordered Labs'/>
          <Button size="small" type="primary" onClick={handleViewOrderedLabs}>View All</Button>
          </div>
        <div className="flex flex-col max-h-[120px] overflow-auto mt-1">
          {loading ? (
            <div className="flex justify-center items-center h-[130px]">
              <Spin />
            </div>
          ) : orderedLabs?.length === 0 ? (
            <p>No Labs added.</p>
          ) : (
            orderedLabs?.map((item) => (
              <LabOrders key={item.id} labOrders={item?.lab_name} orderType={item?.lab_id}/>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderedLabs;
