import { AutoComplete } from "antd";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../API/apiUtils";


function Searchbar({ search_width, navigate_to }) {
  const navigate = useNavigate();

  const onSelect = (value) => {
    const selectedMed = options.find((item) => item.value === value);
    navigate_to && navigate(`/main/charts/patient/${selectedMed?.key}/`);
  };
 
  const { nameOptions: options, handleSearch } = useSearch();



  return (
    <AutoComplete
      options={options}
      allowClear={true}
      onSelect={onSelect}
      onSearch={handleSearch}
      placeholder="Search Patient"
      style={{ width: search_width }}
    />
  );
}

export default Searchbar;
