import React, { useEffect, useState } from "react";
import { ConfigProvider, Tabs } from "antd";
import PrimaryInsurance from "./InsuranceTypes/PrimaryInsurance"
import SecondaryInsurance from './InsuranceTypes/SecondaryInsurance'
import TertiaryInsurance from './InsuranceTypes/TertiaryInsurance'
import AutoInsurance from './InsuranceTypes/AutoInsurance'
import OtherInsurance from './InsuranceTypes/OtherInsurance'
// import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

const TabPane = [
  {
    key: "1",
    label: "Primary Insurance",
    content: <PrimaryInsurance/>,
  },
  {
    key: "2",
    label: "Secondary Insurance",
    content: <SecondaryInsurance/>,
  },
  {
    key: "3",
    label: "Tertiary Insurance",
    content: <TertiaryInsurance/>,
  },
  {
    key: "4",
    label: "Auto Insurance",
    content: <AutoInsurance/>,
  },
  {
    key: "5",
    label: "Other Insurance",
    content: <OtherInsurance/>,
  },
];

function Insurance() {
//   const { profile, getProfile } = useStateGlobalContext();

//   useEffect(() => {
//     getProfile();
//   }, []);


  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key)
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              fontSize: 13,
            },
          },
        }}
      >
        <Tabs activeKey={activeKey} type="card" size="small" onTabClick={handleTabChange}>
          {TabPane.map((tab) => (
            <Tabs.TabPane key={tab.key} tab={tab.label}>
              {activeKey === tab.key && tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ConfigProvider>
    </div>
  );
}

export default Insurance;



