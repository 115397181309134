import React, { useEffect } from 'react'
import { useStateGlobalContext } from '../../../../../contexts/GlobalContext'

const BankTransferInfo = () => {
    const { bankInformation, getBankInformation } = useStateGlobalContext()
    
    useEffect(()=>{
        getBankInformation()
    },[])
    
  return (
    <>
    {bankInformation?.super_admin_payment_bank_info ? (
      <div
        dangerouslySetInnerHTML={{
          __html: bankInformation?.super_admin_payment_bank_info,
        }}
      />
    ) : (
      <p>No Information Added yet</p>
    )}
  </>
  )
}

export default BankTransferInfo