import React from 'react';

const PreferredItem = (preferredData) => {
  if (!preferredData || preferredData.length === 0) {
    return <p>No pharmacy information available.</p>;
  }

  const item = preferredData[0];
  return (
    <div>
      <p><span className='font-medium'>Name:</span> {item?.name}</p>
      <p><span className='font-medium'>Address:</span> {item?.address}</p>
      <p><span className='font-medium'>Phone:</span> {item?.phone}</p>
      <p><span className='font-medium'>Fax:</span> {item?.fax}</p>
      <p><span className='font-medium'>Email:</span> {item?.email}</p>
    </div>
  );
};

export default PreferredItem;
