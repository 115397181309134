import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Dropdown, Col, Card, Button, Tooltip, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { NumberFormatter } from "../../../../../../../utils/validator";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../../API/apis";
import CollectPayment from "./components/CollectPayment";
import ServiceInvoicePad from "./components/ServiceInvoicePad";
import CustomInvoice from "./components/CustomInvoice";
import ViewInvoice from "./components/ViewInvoice";
import showConfirmModal from "../../../../../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../../../components/FormatDate/FormatDateTime";
import { invoiceStatus } from "../../../../../../../components/GlobalComponents/GlobalComponents";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Collect Payment",
  },
  {
    key: 3,
    label: "Print",
  },
  {
    key: 4,
    label: "Cancel Invoice",
  },
];

// const invoice_status = [
//   { key: 1, label: "Paid" },
//   { key: 2, label: "unpaid" },
//   { key: 3, label: "Cancelled" },
// ];

function Invoices() {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serviceDrawer, setServiceDrawer] = useState(false);
  const [servicesData, setServicesData] = useState(null);
  const { invoices, getInvoices } = useStateGlobalContext();
  const [showCustomInvoice, setShowCustomInvoice] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [viewinvoiceData, setViewInvoiceData] = useState([]);

  const coloumn = [
    {
      key: "#",
      title: "#",
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      key: 1,
      title: "Issue Date",
      ellipsis: true,
      dataIndex: "issue_date",
      align: "center",
      width: "20%",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Service Name",
      ellipsis: true,
      align: "center",
      width: "20%",
      dataIndex: "service_name",
      render: (text, record) => (
        <Button type="link" onClick={() => handleInvoiceView(record)}>
        {text}
      </Button>
      ),
    },
    {
      key: 5,
      title: "Price",
      ellipsis: true,
      align: "center",
      width: "10%",
      dataIndex: "price",
      render: (text) => (
        <>
          <NumberFormatter value={text} options={{ style: "decimal" }} />
        </>
      ),
    },
    {
      key: 7,
      title: "Status",
      dataIndex: "status",
      width: "20%",
      ellipsis: true,
      align: "center",
      filters: invoiceStatus?.map((status) => ({
        text: status.label,
        value: status.label,
      })),
      onFilter: (value, record) =>
        record.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Paid", "unpaid"],
      render: (status) =>
        status === "Paid" ? (
          <span className="bg-[#1890ff] px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ) : status === "unpaid" ? (
          <span className="bg-gray-400 px-1 py-[2px] text-white text-xs rounded-md">
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>
        ) : (
          <span className="bg-red-500 px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ),
    },
    {
      key: 8,
      title: "Action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Paid"
            ? items.filter((item) => [1, 3, 4].includes(item.key))
            : status === "unpaid"
            ? items.filter((item) => [1, 2, 3, 4].includes(item.key))
            : status === "Cancelled"
            ? items.filter((item) => [1, 3].includes(item.key))
            : items?.filter((item) => [1, 3, 4].includes(item.key));
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handleCancelInvoice(record);
                  } else if (item.key === 2) {
                    handleView(record);
                  } else if (item.key === 1) {
                    handleInvoiceView(record);
                  } else if (item.key === 3) {
                    handlePrint(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handlePrint = (record) => {
    setServiceDrawer(true);
    setServicesData(record);
  };

  const closePrint = () => {
    setServiceDrawer(false);
  };

  const handleView = (record) => {
    setInvoice(record);
    setVisible(true);
  };

  const onClose = () => {
    setTimeout(() => {
      setVisible(false);
    }, 200);
  };

  const handleInvoiceView = (record) => {
    setViewInvoice(true);
    setViewInvoiceData(record);
  };

  const closeInvoiceView = () => {
    setViewInvoice(false);
  };

  const openCustomInvoice = () => {
    setShowCustomInvoice(true);
  };

  const closeCusomInvoice = () => {
    setShowCustomInvoice(false);
  };

  const handleCancelInvoice = (record) => {
    showConfirmModal({
      title: "Confirm Cancel?",
      icon: null,
      content: "Are you sure you want to Cancel this?",
      okText: "Cancel Invoice",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: async () => {
        try {
          const response = await doPatch(
            `/invoice/invoice-update/${id}/${record?.id}/`,
            { status: "Cancelled" }
          );
          if ((response.status = 200)) {
            Notification.success("Patient Invoice Cancelled Successfully");
            getInvoices(id);
          }
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getInvoices(id);
      setLoading(false);
    };
    fetchData(id);
  }, [id]);

  return (
    <>
      <Col xs={24} md={24} lg={12} xl={12}>
        <Card className="shadow-class">
          <div className="flex justify-between items-center mb-2">
            <h1>Services Invoices </h1>
            <Button type="primary" onClick={openCustomInvoice}>
              Add Custom Invoice
            </Button>
          </div>
          <Table
            size="small"
            loading={loading}
            dataSource={invoices}
            columns={coloumn}
            pagination={false}
          />
        </Card>
      </Col>

      <CollectPayment visible={visible} onClose={onClose} invoice={invoice} />
      <ServiceInvoicePad
        visible={serviceDrawer}
        onClose={closePrint}
        rxData={servicesData}
      />
      <CustomInvoice visible={showCustomInvoice} onClose={closeCusomInvoice} />
      <ViewInvoice
        visible={viewInvoice}
        onClose={closeInvoiceView}
        rxData={viewinvoiceData}
      />
    </>
  );
}

export default Invoices;
