import React, { useEffect, useState } from "react";
import { Select, Input, Button, Form, Card, Col } from "antd";
import { doGet, doPatch, doPost } from "../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";
const { Option } = Select;
const { Item } = Form;

const Rooms = () => {
  const [numRooms, setNumRooms] = useState(1);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false)
  const { officeSetting, getOfficeSettings } = useStateGlobalContext();


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getOfficeSettings(); 
        await fetchRoomsFromAPI(); 
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  const fetchRoomsFromAPI = async () => {
    try {
      const response = await doGet("/user/office-settings/?q=basic/");
      if (response.status === 200) {
        const roomData = response.data.exam_rooms;
        const numRooms = Object.keys(roomData).length;
        setNumRooms(numRooms);

        const formattedRooms = Object.keys(roomData).map((key, index) => ({
          id: index,
          key: key, // Store the key (label) in the room object
          name: roomData[key], // Store the value in the room object
        }));
        setRooms(formattedRooms);
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleNumRoomsChange = (value) => {
    setNumRooms(value);

    const existingRooms = [...rooms];

    if (value > existingRooms.length) {
      for (let i = existingRooms.length; i < value; i++) {
        existingRooms.push({ id: i, name: "" });
      }
    } else if (value < existingRooms.length) {
      existingRooms.splice(value);
    }

    setRooms(existingRooms);
  };

  const handleRoomNameChange = (index, value) => {
    const newRooms = [...rooms];
    newRooms[index].name = value;
    setRooms(newRooms);
  };

  const handleAddRoom = async () => {
    const roomData = {};
    rooms.forEach((room, index) => {
      roomData[`Room ${index + 1}`] = room.name;
    });

    const data = {
      exam_rooms: roomData,
    };
    try {
      const office_ID = officeSetting?.id;
      const response = office_ID
        ? await doPatch(
            "/user/office-settings/update/?q=basic/&update=true",
            data
          )
        : await doPost("/user/office-settings/?q=basic/", data);
      if (response.status === 200) {
        Notification.success("Rooms Added Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const roomInputs = rooms.map((room, index) => (
    <Item label={`Room ${index + 1}`} key={room.id}>
      <Input
        placeholder={`Room ${index + 1} name`}
        value={room.name}
        onChange={(e) => handleRoomNameChange(index, e.target.value)}
        maxLength={30}
      />
    </Item>
  ));

  return (
    <Col span={5}>
      <Card className="shadow-class mb-4" loading={loading}>
        <Form layout="vertical">
          <Form.Item label="Number of rooms">
            <Select
              value={numRooms}
              onChange={handleNumRoomsChange}
            >
              {Array.from({ length: 99 }, (_, index) => index + 1)?.map(
                (number) => (
                  <Option key={number} value={number}>
                    {number}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          {roomInputs}
        </Form>
        <Button type="primary" onClick={handleAddRoom}>
          Save
        </Button>
      </Card>
    </Col>
  );
};

export default Rooms;
