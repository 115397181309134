import React, { useEffect } from "react";
import { Menu, Layout } from "antd";
import { Outlet, useNavigate} from "react-router-dom";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";

const { Content } = Layout;

const items = [
  {
    label: "Patients", 
    key: "patient",
    role:["clinic", "manager", "nurse", "doctor"]
  },
  {
    label:'Appointments', key:"appointment-report", role:["clinic", "manager", "nurse", "doctor"]
  },
  { label: "Services", key: "services" , role:["clinic", "manager", "nurse", "doctor"] },
  { label: "Labs", key: "labs",role:["clinic", "manager", "nurse", "doctor"] },
  { label: "Inventory", key: "inventory",role:["clinic", "manager", "nurse", "doctor"] },
  {
    label: "Outstanding Bill",
    key: "outstanding-bills",
    role:["clinic", "manager", "nurse", "doctor"]
  },
  {
    label: "Credit Memo",
    key: "credit-memo",
    role:["clinic", "manager", "nurse", "doctor"]
  },
  { label: "Insurance Claims", key: "insurance-claims",role:["clinic", "manager", "nurse", "doctor"]},


  {
    label: "Super Bill",
    key: "super-bill",
    role:["clinic", "manager", "nurse", "doctor"]
  },
  {
    label: "Referring Provider",
    key: "referral",
    role:["clinic", "manager", "nurse", "doctor"]
  },
  {
    label:"Audit Reports",
    key:"audit-reports",
    role:["clinic", "manager"]
  }
];

const Reports = () => {
  const navigate = useNavigate();
  const {officeSetting, getOfficeSettings} = useStateGlobalContext()
  const {profile} = useStateGlobalContext()
  const role = profile && profile?.user_info[0]?.role;

  const filteredMenuItems = items.filter(item => item.role.includes(role));

  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 

  useEffect(()=>{
    getOfficeSettings()
  },[])

  document.title = "Reports | American EMR";
  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Menu
        items={filteredMenuItems}
        mode="horizontal"
        onClick={({ key }) => navigate(key)}
        defaultSelectedKeys={["patient-list"]}
        className="summary-menu mb-4 bg-white text-[16px] font-medium tracking-[0.2px]"
      />
      <Content
        className="layout"
        style={{
          backgroundColor: "white",
        }}
      >
        <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]}/>
      </Content>
    </Layout>
  );
};

export default Reports;
