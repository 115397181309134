import React, { useEffect } from "react";
import { Row, Col } from "antd";
import ProfileCard from "./components/ProfileCard/ProfileCard";
import ProfileNav from "./components/ProfileNav/ProfileNav";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { useParams, Outlet } from "react-router-dom";

const PatientProfile = () => {
  const {
    patientBasicInfo,
    getPatientData,
    loading,
    officeSetting,
    getOfficeSettings,
  } = useStateGlobalContext();
  const { id } = useParams();

  useEffect(() => {
    getOfficeSettings();
    getPatientData(id);
  }, [id]);

  const officeDateFormat = officeSetting && officeSetting?.date_foramt;

  return (
    <Row gutter={[30, 30]}>
      <Col xs={24} md={24} xl={8}>
        <div className="profile-wrapper shadow-class">
          <Row>
            <Col xs={24} md={12} xl={24}>
              <ProfileCard patientInfo={patientBasicInfo} loading={loading} />
            </Col>
            <Col xs={24} md={12} xl={24}>
              <ProfileNav />
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs={24} md={24} xl={16}>
        <Outlet patientInfo={patientBasicInfo} context={[officeDateFormat]} />
      </Col>
    </Row>
  );
};

export default PatientProfile;
