import React, { useEffect, useState } from 'react';
import { Row} from 'antd';
import { useParams } from 'react-router-dom';
import CardComponent from './component/CardComponent';
import { useStateGlobalContext } from '../../../../contexts/GlobalContext';
import { useStateInvoicesContext } from '../../../../contexts/InvoiceContext';
import Notification from '../../../../components/Notification/Notification';
// import { NumberFormatter } from '../../../../../utils/validator';

const InvoicesCards = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {unpaidInvoicesCount, getUnpaidInvoicesCount, visits, getTotalVisits} = useStateInvoicesContext()
  const { outstandingBillData, getOutstandingBillInfo, creditMemoInfo, getCreditMemoInfo, productData, getProductInvoices ,unpaidLabsCount,
    getUnpaidLabs} = useStateGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getCreditMemoInfo(id),
          getOutstandingBillInfo(id),
          getTotalVisits(id),
          getProductInvoices(id),
          getUnpaidInvoicesCount(id),
          getTotalVisits(id),
          getUnpaidLabs(id)

        ]);
      } catch (error) {
        Notification.error('Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);


  return (
    <Row className="flex bg-[#C4dBFA] rounded-md">
      <CardComponent title="Unpaid Services" value={unpaidInvoicesCount || 0} loading={loading} noShowCurrency={true}/>
      <CardComponent title="Unpaid Labs" value={unpaidLabsCount?.unpaid_count || 0} loading={loading} noShowCurrency={true}/>
      <CardComponent title="Total Visits" value={visits?.total_visits || 0} loading={loading} noShowCurrency={true} />
      <CardComponent title="Total Sales" value={productData?.length} loading={loading} noShowCurrency={true}/>
      <CardComponent title="Outstanding Bills" value={outstandingBillData?.total_outstanding_amount || 0} loading={loading} />
      <CardComponent title="Credit Memo" value={creditMemoInfo?.total_outstanding_amount || 0} loading={loading} />
    </Row>
  );
};

export default InvoicesCards;
