import React from "react";

const providerInfo = (provider) => {
  if (!provider) return null;
  const providerFullName = `${provider.firstname} ${provider.lastname}`;
  const {
    primary_speciality,
    secondary_speciality,
    medical_license,
    state_license,
    additional_data_1,
    additional_data_2,
    additional_data_3,
    provider_tax_id,
    taxonomy_code,
    dea_number,
    provisional_registration,
  } = provider?.medical_identifer_info?.[0] || {};

  const { work_phone } = provider?.personel_settings_info?.[0]?.contact || {};

  const renderInfo = (label, value, show) => (show ? <p>{`${label}: ${value}`}</p> : null);


  return (
    <div className="provider-info">
      <h4>Provider Information</h4>
      <p>Provider's Name: {providerFullName}</p>
      {renderInfo("Work Phone", work_phone)}
      {renderInfo("Primary Speciality", primary_speciality?.[0] , primary_speciality?.[1])}
      {renderInfo("Secondary Speciality", secondary_speciality?.[0], secondary_speciality?.[1])}
      {renderInfo("Medical License", medical_license?.[0], medical_license?.[1])}
      {renderInfo("State License", state_license?.[0], state_license?.[1])}
      {renderInfo("Provisional Registration", provisional_registration?.[0], provisional_registration?.[1])}
      {renderInfo("Provider Tax ID", provider_tax_id?.[0],  provider_tax_id?.[1])}
      {renderInfo("Taxonomy Code", taxonomy_code?.[0], taxonomy_code?.[1])}
      {renderInfo("DEA Number", dea_number?.[0], dea_number?.[1])}
      {renderInfo("Additional Data 1", additional_data_1?.[0], additional_data_1?.[0])}
      {renderInfo("Additional Data 2", additional_data_2?.[0], additional_data_2?.[1])}
      {renderInfo("Additional Data 3", additional_data_3?.[0], additional_data_3?.[1])}
    </div>
  );
};

export default providerInfo;
