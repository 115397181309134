import React from "react";

const ClinicInformation = (officeInformation) => {
  if (!officeInformation || typeof officeInformation !== "object") {
    return <p>No clinic information available.</p>;
  }

  // Helper function to check if the first index is true
  const shouldDisplay = (data) => Array.isArray(data) && data[1] === true;

  return (
    <div>
      {shouldDisplay(officeInformation.facility_name) && (
        <p>
          <span className="font-medium">Facility Name: </span>
          {officeInformation.facility_name[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.speciality_type) && (
        <p>
          <span className="font-medium">Speciality Type: </span>
          {officeInformation.speciality_type[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.facility_address) && (
        <p>
          <span className="font-medium">Facility Address: </span>
          {officeInformation.facility_address[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.city) && (
        <p>
          <span className="font-medium">City: </span>{" "}
          {officeInformation.city[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.state) && (
        <p>
          <span className="font-medium">State:</span>{" "}
          {officeInformation.state[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.country) && (
        <p>
          <span className="font-medium">Country: </span>
          {officeInformation.country[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.zipcode) && (
        <p>
          <span className="font-medium">Zip Code: </span>
          {officeInformation.zipcode[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.office_phone) && (
        <p>
          <span className="font-medium">Office Phone:</span>{" "}
          {officeInformation.office_phone[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.office_fax) && (
        <p>
          <span className="font-medium">Office Fax:</span>{" "}
          {officeInformation.office_fax[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.buisness_email) && (
        <p>
          <span className="font-medium">Business Email:</span>{" "}
          {officeInformation.buisness_email[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.additional_basic_data_1) && (
        <p>
          <span className="font-medium">Additional Data 1:</span>{" "}
          {officeInformation.additional_basic_data_1[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.additional_basic_data_2) && (
        <p>
          <span className="font-medium">Additional Data 2:</span>{" "}
          {officeInformation.additional_basic_data_2[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.additional_basic_data_3) && (
        <p>
          <span className="font-medium">Additional Data 3:</span>{" "}
          {officeInformation.additional_basic_data_3[0]}
        </p>
      )}
      {shouldDisplay(officeInformation.exam_rooms) && (
        <div>
          <p>
            <span className="font-medium">Exam Rooms:</span>
          </p>
          <ul>
            {Object.entries(officeInformation.exam_rooms).map(
              ([key, value]) => (
                <li key={key}>
                  {key}: {value}
                </li>
              )
            )}
          </ul>
        </div>
      )}
      {shouldDisplay(officeInformation.operating_hrs) && (
        <p>
          <span className="font-medium">Operating Hours:</span>{" "}
          {officeInformation.operating_hrs[0].start_time} to{" "}
          {officeInformation.operating_hrs[0].end_time}
        </p>
      )}
    </div>
  );
};

export default ClinicInformation;
