import React from 'react'
import Invoices from './component/Service/Invoices'
import LabInvoices from './component/Lab/LabInvoices'
import { Row } from 'antd'

const index = () => {
  return (
    <Row gutter={[16,16]}>
    <Invoices/>
    <LabInvoices/>
    </Row>
  )
}

export default index