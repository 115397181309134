import { Modal , Button} from 'antd'
import React from 'react'
import { formatDate, formatTime } from '../../../../../utils/DateFormat'
import FormatDateTime from '../../../../../components/FormatDate/FormatDateTime'

const ViewExternalLab = ({visible, onClose, rxData}) => {
  return (
    <Modal
     open={visible}
     onCancel={onClose}
     title={rxData?.externalUID}
     footer={null}
     closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
        <p>Added By: {rxData?.added_by}</p>
        <p>Added Date: <FormatDateTime date={rxData?.date_added} type='datetime'/></p>
        <p>Invoice ID: {rxData?.externalUID}</p>

        {
          rxData && rxData?.lab_name?.map((item, idx)=>(
                <div key={idx} className="bg-gray-200 p-2 mt-2 mb-2">
                    <p>Lab Name: {item?.lab_name}</p>
                    <p>Type: {item?.service_type}</p>
                    <p>Group: {item?.service_group}</p>
                    <p>Instructions: {item?.lab_instructions}</p>
                    <p>Diagnosis: {item?.lab_diagnosis}</p>
                    {/* <Divider/> */}
                </div>
            ))
        }
    </Modal>
  )
}

export default ViewExternalLab