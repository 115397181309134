import React, { createContext, useContext, useState } from "react";
import { doGet } from "../API/apis";
import Notification from "../components/Notification/Notification";

const ServicesContext = createContext();
export const ServicesContextProvider = ({ children }) => {
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [labs, setLabs] = useState(null)

  const handleApiError = (error) => {
    Notification.error(error);
    console.error(error);
  };

  

  const getGroup = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/services/service-group/get/`);
      if (response.status === 200) {
        setGroup(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getServiceType = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/services/service-type/get/`);
      if (response.status === 200) {
        setServiceType(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/services/get/`);
      if (response.status === 200) {
        const sortedServices = response.data.sort((a, b) => b.code - a.code);
        setServices(sortedServices);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };
  

  const getLabs = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/services/get-labs/`);
      if (response.status === 200) {
        const sortedLabs = response.data.sort((a, b) => b.code - a.code);
        setLabs(sortedLabs);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ServicesContext.Provider
      value={{
        loading,
        setLoading,
        group,
        getGroup,
        serviceType,
        getServiceType,
        services,
        getServices,
        labs,
        getLabs
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
export const useStateServicesContext = () => useContext(ServicesContext);
