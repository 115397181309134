import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Collapse } from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import FormatDate from "../../../../components/FormatDate/FormatDate";
const { Panel } = Collapse;

const HistoricalMedication = () => {
  const { id } = useParams();
  const { data, getHistoricalMedication } = useStateGlobalContext();

  useEffect(() => {
    getHistoricalMedication(id);
  }, [id]);

  const hasHistoricalMedication = data && data.length > 0;

  if (!hasHistoricalMedication) {
    return null; 
  }

  return (
    <div className="mt-3 mb-10" >
      <h1 className="font-semibold text-[16px] mb-2">Historical Prescribed Medication</h1>
      <Collapse accordion style={{maxHeight:'150px', overflowY:'scroll'}}>
        {data?.map((item, index) => (
          <Panel
            header={`${item?.historical_medication?.searched_medicine} (${
              item?.historical_medication?.end_date
                ? <FormatDate date={item?.historical_medication?.end_date}/>
                : "No Date Entered"
            })`}
            key={index}
          >
            <div>
              <p>
                <span className="font-medium">Instructions: </span>
                <span>{item?.historical_medication?.instructions}</span>
              </p>
              <p>
                <span className="font-medium">Refills: </span>
                <span>{item?.historical_medication?.refills}</span>
              </p>
              <p>
                <span className="font-medium">Dispense Quantity: </span>
                <span>{item?.historical_medication?.dispense_quantity}</span>
              </p>
              <p>
                <span className="font-medium">Start Date: </span>
                <span>
                  {item?.historical_medication?.start_date
                    ? <FormatDate date={item?.historical_medication?.start_date}/>
                    : "No Date Entered"}
                </span>
              </p>
              <p>
                <span className="font-medium">End Date: </span>
                <span>
                  {item?.historical_medication?.end_date
                    ? <FormatDate date={item?.historical_medication?.end_date}/>
                    : "No Date Entered"}
                </span>
              </p>
              <p>
                <span className="font-medium">Dispense as Written: </span>
                <span>
                  {item?.historical_medication?.dispense_as_written?.toString() ===
                  "true"
                    ? "Yes"
                    : "No"}
                </span>
              </p>
              <p>
                <span className="font-medium">Associated Diagnosis: </span>
                <span>{item?.historical_medication?.associated_diagnosis}</span>
              </p>
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default HistoricalMedication;
