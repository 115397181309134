import React from 'react';

function LabelDisplay({ title, description, className }) {
  return (
    <div className="mb-2">
      <span className="font-medium">{title}: </span>
      <span className={className}>{description}</span>
    </div>
  );
}

export default LabelDisplay;
