import { Avatar, Image } from 'antd'
import React from 'react'
import { DateComponent, TimeComponent } from '../TimeClock/TimeClock'
import { useOutletContext } from 'react-router-dom'

const WelcomeCard = ({name, title, color, logo, admin , profile_pic, work , provider_name}) => {
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
  useOutletContext()
  return (
    // <Card className="shadow-sm">
    <div className='mb-4'>
    <div className="flex items-center gap-3">
      <div>
      {work && <Avatar size={"large"} src={profile_pic && profile_pic}>
        {!profile_pic &&  provider_name[0]}
      </Avatar>}
      {admin &&  <Image src={logo} className='max-w-[50px]'/>} 
     
      </div>
      <div className="flex justify-between w-full">
        <div className="flex gap-4">
          <div style={{border:'1px solid #6b7280'}}></div>
        <div className="border-l-4 border-[#C4dBFA] mt-2">
          <p className="text-lg font-semibold text-gray-900">{title}</p>
          <p className="font-semibold text-gray-700">{name}</p>
        </div>
        </div>
        <div className="text-right">
          <p className="text-gray-700">Mon Tue Wed</p>
          <p className="text-gray-700">Today is <DateComponent timezone={officeTimezone || "US/Central"}/></p>
          <p className="text-gray-700">Time is <TimeComponent timeFormat={officeTimeFormat || "h12"} timezone={officeTimezone || "US/Central"}/> </p>
        </div>
      </div>
    </div>
    <hr className={`border-b-[10px] ${color} rounded-md mt-4 border-t-[0]`}/>
    </div>
// </Card>
  )
}

export default WelcomeCard