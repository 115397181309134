import React from 'react';
import { Select } from 'antd';
import moment from 'moment-timezone';

const { Option } = Select;

const TimezoneSelector = ({ onChange , defaultValue }) => {
  const timezones = moment?.tz?.names();

  const handleSelectChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const getTimezoneWithGMT = (timezone) => {
    const offset = moment.tz(timezone).utcOffset() / 60;
    const gmt = offset >= 0 ? `+${offset}` : offset;
    return `${timezone}   (GMT ${gmt})`;
  };

  return (
    <Select
      showSearch
      placeholder="Select Timezone"
      optionFilterProp="children"
      onChange={handleSelectChange}
      defaultValue={defaultValue} // Set the default value here
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {timezones.map((timezone) => (
        <Option key={timezone} value={timezone}>
          {getTimezoneWithGMT(timezone)}
        </Option>
      ))}
    </Select>
  );
};

export default TimezoneSelector;
