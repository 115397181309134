import { Modal, Button, Tag } from "antd";
import React, { useState } from "react";
import { NumberFormatter } from "../../../../../utils/validator";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";

function LabOrders({ labOrders, orderType, ordererLabs }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  
  const handleLabPopup = (lab) => {
    setSelectedItem(lab);
    setVisible(true);
  };

  return (
    <div>
      <ul className="list-disc">
      {labOrders?.map((lab, index) => (
        <li
          key={lab?.id || index}
          className="text-[#1890ff] cursor-pointer p-1"
          onClick={() => handleLabPopup(lab)}
        >
          {lab?.lab_name}
        </li>
      ))}
      </ul>

      <Modal
        title="Lab Details"
        open={visible}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div className="flex justify-center mb-4">
          {orderType?.includes("LAB_EX") ? (
            <Tag className="bg-green-600 text-white">External</Tag>
          ) : (
            <Tag className="bg-[#3484F0] text-white">In-House</Tag>
          )}
        </div>
        <LabelDisplay
          title="Lab"
          description={
            selectedItem?.lab_name
              ? selectedItem?.lab_name
              : selectedItem?.lab?.lab_name
          }
        />
        <LabelDisplay
          title="Instructions"
          description={selectedItem?.lab_instructions}
        />
        <LabelDisplay
          title="Diagnosis"
          description={selectedItem?.lab_diagnosis}
        />
        {selectedItem?.lab_price && (
          <LabelDisplay
            title="Price"
            description={<NumberFormatter value={selectedItem?.lab_price} />}
          />
        )}
      </Modal>
    </div>
  );
}

export default LabOrders;
