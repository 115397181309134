import { Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import "./labprescription.css";
import { ageCalculator } from "../../../../../utils/ageCalculator";
import LabImage from "../../../../../assets/lab.png";
import providerInfo from "../../../../../components/PrescriptionsPad/providerInfo";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import PreferredItem from "../../../../../components/PrescriptionsPad/PreferredItem";
import { useParams } from "react-router-dom";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";

const LabPrescription = ({ visible, rxData, officeData, ptData, onClose }) => {
  const {id} = useParams()
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(true)
  const {providerDetails , getProviderDetails ,preferredLab: preferredData,
    getPreferredLab, } = useStateGlobalContext()
    
  const fullName = `${ptData?.first_name} ${
    ptData?.middle_name ? ptData?.middle_name : ""
  } ${ptData?.last_name}`;

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  useEffect(() => {
    if (rxData?.provider_id) {
      getProviderDetails(rxData?.provider_id);
      setLoading(false)
    }
  }, [rxData?.provider_id]);

  useEffect(()=>{
    getPreferredLab(id)
  },[id])
  
  return (
    <Drawer
      open={visible}
      placement="bottom"
      height="100vh"
      title={fullName}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      onClose={onClose}
      className="prescription-drawer"
    >
      {!loading && <div className="lab-pres-print">
        <div className="lab-pres-container">
          <button onClick={handlePrint} className="guest-button">
            Print Document
          </button>
          <div className="lab-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Lab Order</h2>
            <div className="pres-header">
              <div className="clinic-info w-[33%]">
                <h4>Clinic Information</h4>
                {ClinicInformation(officeData)}
              </div>

              <div className="lab-info w-[33%]">
                <h4>Preferred Lab</h4>
               {PreferredItem(preferredData)}
                <div>
                  <img
                    src={LabImage}
                    alt="Prescription-Rx"
                    className="flex h-[90px]"
                  />
                </div>
              </div>
            </div>

            <hr className="print-divider" />
            <div className="flex justify-between">
              <div className="patient-info">
                <h4>Patient Information</h4>
                <p>
                  <span>Name: </span>
                  {fullName}
                </p>
                <p>
                  <span>DOB: <FormatDate date={ptData?.date_of_birth}/></span>             
                </p>
                <p>
                  <span>Age: </span>
                  {ageCalculator(ptData?.date_of_birth)} y/o
                </p>
                <p>
                  <span>Gender: </span>
                  {ptData?.gender}
                </p>
                <p>
                  <span>Address: </span>
                  {ptData && ptData?.current_address_line_1}
                </p>
                <p>
                  <span>Phone #: </span>
                  {ptData && ptData?.patient_phone}
                </p>
              </div>
              <div>
                <p>
                  <span className="font-medium">Lab Order Date: </span>
                  {rxData && <FormatDateTime date={rxData?.date_added} type="datetime"/>}
                </p>
              </div>
            </div>

            <hr className="print-divider" />

            {rxData?.lab_name?.map((lab, idx) => (
              <div key={lab?.key}>
                <div className="flex">
                  <div className="mr-2">{idx + 1}.</div>
                  <div className="lab-details">
                    <p>
                      <span>Lab: </span>
                      {lab?.lab_name}
                    </p>
                    <p>
                      <span>Diagnosis: </span>
                      {lab?.lab_diagnosis}
                    </p>
                    <p>
                      <span>Instructions: </span>
                      {lab?.lab_instructions}
                    </p>
                  </div>
                </div>
                <hr className="print-divider" />
              </div>
            ))}

            <div className="flex justify-between">
              {providerInfo(providerDetails)}
              <div className="flex items-end">
                <div>
                  <p>_______________________________</p>
                  <h3 className="flex justify-end">Signature</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </Drawer>
  );
};

export default LabPrescription;
