import React, { useEffect, useState } from "react";
import { Table, Card } from "antd";
import {doGet }from '../../../../API/apis'
import Notification from "../../../../components/Notification/Notification";



const OutOfStockMedicines = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    const coloumns = [
        {
          key: 1,
          title: "#",
          render: (_, record, index) => index + 1,
          align: "center",
        },
        {
          key: "sku",
          title: "SKU",
          align:'center',
          dataIndex:"sku"
        },
        {
          key: 2,
          align:'center',
          title: "Brand Name",
          dataIndex:"medicine_name"
        },
        {
          key: 3,
          align:'center',
          title: "Generic Name",
          dataIndex:"generic_name"
        },
        {
          key: 4,
          align:'center',
          title: "Category",
          dataIndex:"category"
        },
        {
          key: 5,
          align:'center',
          title: "Strength",
          dataIndex:'strength'
        },
        {
          key: 6,
          align:'center',
          title: "Manufacturer",
          dataIndex:'manufacturer'
        },
        {
          key:7,
          title:'Location',
          align:'center',
          dataIndex: 'location'
        },

      ];

      const getOutofStockProducts = async () => {
        setLoading(true);
        try {
          const response = await doGet(`/inventory/out-of-stock/`);
          if (response.status === 200) {
            setData(response?.data);
          }
        } catch (error) {
          Notification.error("Something Went Wrong");
        } finally{
          setLoading(false)
        }
      };

  useEffect(() => {
    getOutofStockProducts()
  }, []);
  return (
    <Card className="mt-4 shadow-sm" loading={loading}>
      <h1 className="font-bold text-lg mb-2">Out of Stock Inventory </h1>
      <Table size="small" loading={loading} columns={coloumns} dataSource={data} pagination={false}/>
    </Card>
  );
};

export default OutOfStockMedicines;
