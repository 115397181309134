import React from 'react';
import { Button, Dropdown, Select, Tooltip } from 'antd';
import FormatDate from '../FormatDate/FormatDate';
import { ageCalculator } from '../../utils/ageCalculator';
import { GetColumnSearchProps } from '../TableSearch/TableSearch';
import { NumberFormatter } from '../../utils/validator';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import FormatDateTime from '../FormatDate/FormatDateTime';
import { encounter_Status } from '../GlobalComponents/GlobalComponents';

const fullName = ({ first_name, middle_name, last_name }) => {
  return `${first_name} ${middle_name ? middle_name : ""} ${last_name}`;
}

const recentEncountersColumns = (navigate) => [
  {
    key: 1,
    title: "#",
    width: '5%',
    align: 'center',
    render: (_, record, index) => index + 1
  },
  {
    key: 2,
    title: "Full Name",
    align: 'center',
    dataIndex:"first_name",
    ...GetColumnSearchProps('first_name'),
    render: (_, record) => (
      <span>{fullName(record)}</span>
    )
  },
  {
    key:3,
    title:"Gender",
    align: 'center',
    dataIndex:"gender",
    width:"10%"
  },
  {
    key: 4,
    title: "Age",
    align: "center",
    ellipsis: true,
    dataIndex: "date_of_birth",
    width: "10%",
    render: (text) => (
      <Tooltip title={<FormatDate date={text} type="time" />}>
        <span>{ageCalculator(text)} y/o</span>
      </Tooltip>
    )
  },
  {
    key: 6,
    title: "Service Name",
    dataIndex: "service_name",
    ellipsis:true,
  },
  {
    key:7,
    title:"Note Type",
    dataIndex:"note_type",
    width:"30%",
    ellipsis:true,
    render: (text, record) => (
      <Button size="small" type="link"
        onClick={() => {
          navigate(`/main/charts/patient/${record?.patient_id}/visits`);
        }}
      >{text}</Button>
    )
  },
  {
    key: 8,
    title: "Status",
    dataIndex: "status",
    align: "center",
    filters: encounter_Status.map((text) => ({
      text: text.label,
      value: text.label,
    })),
    onFilter: (value, record) =>
      record?.status?.toLowerCase().includes(value.toLowerCase()),
    defaultFilteredValue: ["Draft", "Signed"], // Set default filters
    render: (status) =>
      status === "Draft" ? (
        <span className="bg-gray-400 px-1 py-[2px] text-white text-xs rounded-md">
          {status}
        </span>
      ) : status === "Signed" ? (
        <span className="bg-[#3484F0] px-1 py-[2px] text-white text-xs rounded-md">
          {status}
        </span>
      ) : (
        <span className="bg-red-500 px-1 py-[2px] text-white text-xs rounded-md">
          {status}
        </span>
      ),
  },
];

const RecentPatientsColumns = (navigate)=>[
    {
      key : 1,
      title:'#',
      align:'center',
      width:'25%',
      render : (_, record, index)=> index + 1
    },
    {
      key: 2,
      title: "Full Name",
      dataIndex:"first_name",
      ...GetColumnSearchProps('first_name'),
      render : (_, record)=>(
        <Button size='small' type='link' onClick={()=>
          navigate(`/main/charts/patient/${record?.id}/`)
        }>{fullName(record)}</Button>
      )
    },
    {
      key: 3,
      title: "Age",
      align: "center",
      ellipsis: true,
      dataIndex: "date_of_birth", 
      width: "20%",
      render: (text) =>(
          <Tooltip title={<FormatDate date={text} type="time" />}>
          <span>{ageCalculator(text)} y/o</span>
        </Tooltip>
        ) 
    },
  ];

  const getServiceListColumns = ({ handleEdit, showDeleteConfirm }) => [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Service Name",
      dataIndex: "service_names",
      key: "service_names",
      ellipsis: true,
      ...GetColumnSearchProps("service_names"),
    },
    {
      title: "Service Type",
      dataIndex: "service_type",
      key: "service_type",
      ellipsis: true,
      ...GetColumnSearchProps("service_type"),
    },
    {
      title: "Service Group",
      dataIndex: "service_group_name",
      key: "service_group_name",
      ellipsis: true,
      ...GetColumnSearchProps("service_group_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      ellipsis: true,
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      align: "center",
      render: (_, record) => (
        <div>
          <EditOutlined
            className="mr-5 text-[#1890ff]"
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            className="text-[#F44336]"
            onClick={() => showDeleteConfirm(record.id)}
          />
        </div>
      ),
    },
  ];

  const getLabsListColumn =({ handleEdit, showDeleteConfirm }) => [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Lab Name",
      dataIndex: "service_names",
      key: "service_names",
      ellipsis: true,
      ...GetColumnSearchProps("service_names"),
    },
    {
      title: "Service Type",
      dataIndex: "service_type",
      key: "service_type",
      ellipsis: true,
      ...GetColumnSearchProps("service_type"),
    },
    {
      title: "Service Group",
      dataIndex: "service_group_name",
      key: "service_group_name",
      ellipsis: true,
      ...GetColumnSearchProps("service_group_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      ellipsis: true,
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      align: "center",
      render: (_, record) => (
        <div>
          <EditOutlined
            className="mr-5 text-[#1890ff]"
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            className="text-[#F44336]"
            onClick={() => showDeleteConfirm(record.id)}
          />
        </div>
      ),
    },
  ];

  const getGroupColumn =({handleEdit, handleDelete}) =>[
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Group Name",
      dataIndex: "group",
      key: "group",
      width: "80%",
      ...GetColumnSearchProps("group"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div>
          <EditOutlined
            className="mr-2 text-[#1890ff]"
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            className="text-[#F44336]"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const getServiceTypeColumn = ({handleEdit, handleDelete}) => [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Service Type",
      dataIndex: "service_type",
      key: "service_type",
      width: "80%",
      ...GetColumnSearchProps("service_type"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align:'center',
      render: (_, record) => (
        <div>
          <EditOutlined
            className="mr-2 text-[#1890ff]"
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            className="text-[#F44336]"
            onClick={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];

  const getWaitingRoomColumn = ({handleProviderChange, handleRoomChange, handleCheckout, handleWaitingRoomStatus,  waitingRoomStatus, navigate, Option, providers, rooms})=> [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (_, __, index) => index + 1,
      align: "center",
    },
    {
      key: 1,
      title: "Check in Date",
      ellipsis: true,
      width:'8%',
      align:'center',
      dataIndex: "checkin_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Patient Name",
      ellipsis: true,
      dataIndex: "patient",
      ...GetColumnSearchProps('patient'),
      render : (_, record)=>(
        <Button size="small" type="link"
        onClick={() => {
          navigate(`/main/charts/patient/${record?.patient_id}/`);
        }}
        >{record?.patient}</Button>
      )
    },
    {
      key: 3,
      title: "Age",
      ellipsis: true,
      align: "center",
      dataIndex: "dob",
      width: "5%",
      render: (text) => (
        <span title={<FormatDate date={text}/>}>{ageCalculator(text)} y/o</span>
      ),
    },
    {
      key: 4,
      title: "Reason for Visit",
      ellipsis: true,
      dataIndex: "reason_of_visit",
    },
    {
      key: 5,
      title: "Service Name",
      ellipsis: true,
      dataIndex: "service_name",
      ...GetColumnSearchProps('service_name')
    },
    {
      key: 6,
      title: "Provider",
      // ellipsis: true,
      align:'center',
      width:'15%',
      dataIndex: "provider",
      render: (_, record) => (
        <Select
        style={{ width: 150 }}
          onChange={(value) => handleProviderChange(value, record)}
          value={record?.provider}
        >
          {providers?.map((provider) => (
            <Option key={provider?.id} value={`${provider?.name}`}>
              {provider?.name}
            </Option>
          ))}
        </Select>
      ),
      filters: providers?.map((provider) => ({
        text: provider?.name,
        value: `${provider?.name}`,
      })),
      onFilter: (value, record) => record?.provider === value,
    },
    {
      key: 7,
      title: "Room",
      ellipsis: true,
      align:'center',
      dataIndex: "room",
      render: (_, record) => (
        <Select
          style={{ width: 120 }}
          onChange={(value) => handleRoomChange(value, record)}
          value={record.room}
        >
          {Object?.keys(rooms)?.map((room) => (
            <Option key={room} value={room}>
              {rooms[room]}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      key: 8,
      title: "Status",
      ellipsis: true,
      align: "center",
      dataIndex: "status",
      width:'12%',
      render: (text, record) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "In Waiting Room":
            bgColor = "bg-blue-400";
            textColor = "text-white";
            break;
          case "In Exam Room":
            bgColor = "bg-blue-700";
            textColor = "text-white";
            break;
          case "Ready for Provider":
            bgColor = "bg-green-400";
            textColor = "text-white";
            break;
          case "With Provider":
            bgColor = "bg-green-600";
            textColor = "text-white";
            break;
          case "Ready for Lab Work":
            bgColor = "bg-red-400";
            textColor = "text-white";
            break;
          case "Ready for Imaging":
            bgColor = "bg-yellow-400";
            textColor = "text-white";
            break;
          case "Ready for Check Out":
            bgColor = "bg-gray-400";
            textColor = "text-white";
            break;
          case "Checked Out":
            bgColor = "bg-gray-200";
            textColor = "text-gray-500";
            break;
          default:
            bgColor = "bg-gray-200";
        }

        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
            title={<FormatDateTime date={record?.status_updated_on} type="datetime"/>}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: 9,
      title: "Action",
      align: "center",
      fixed: "right",
      width: "5%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: waitingRoomStatus?.map((item) => ({
              ...item,
              onClick: () => {
                if (
                  item.key === 1 ||
                  item.key === 2 ||
                  item.key === 3 ||
                  item.key === 4 ||
                  item.key === 5 ||
                  item.key === 6 ||
                  item.key === 7
                ) {
                  handleWaitingRoomStatus(record, item.key);
                } else if (item.key === 8) {
                  handleCheckout(record, item.key);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];


  const getGeneralNotesColumn = ({ encounter_Status, role, items, handleDelete, handleViewNotes, openModal, cancelGeneralNotes, handlePrint }) => [
    {
      title: "#",
      dataIndex: "service_type",
      render: (_, record, index) => index + 1,
      align: "center",
      width: "5%",
      ellipsis: true,
    },
    {
      title: "Service Title",
      dataIndex: "service_type",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Reason for Visit",
      dataIndex: "reason",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      width: "12%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      align: "center",
      ellipsis: true,
      render: (text) => <FormatDateTime date={text} type="date" />,
    },
    {
      title: "Time",
      dataIndex: "date_time",
      key: "time",
      align: "center",
      ellipsis: true,
      render: (text) => <FormatDateTime date={text} type="time" />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: 1,
      align: "center",
      filters: encounter_Status.map((text) => ({
        text: text.label,
        value: text.label,
      })),
      onFilter: (value, record) =>
        record?.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Draft", "Signed"],
      render: (status) =>
        status === "Draft" ? (
          <span className="bg-gray-400 px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ) : status === "Signed" ? (
          <span className="bg-[#3484F0] px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ) : (
          <span className="bg-red-500 px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ),
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "8%",
      render: (text, record) => {
        const { status } = record;
        let availableItems;
        if (status === "Cancelled") {
          availableItems = items.filter((item) => ![2,5, 6].includes(item.key));
        } else if (role === "nurse") {
          availableItems =
            status === "Draft"
              ? items.filter((item) => [1, 2, 5].includes(item.key))
              : items.filter((item) => [1, 3].includes(item.key));
        } else {
          availableItems =
            status === "Draft"
              ? items.filter((item) => [1, 2, 5].includes(item.key))
              : items.filter((item) => [1, 3, 6].includes(item.key));
        }
  
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 5) {
                    handleDelete(record.id);
                  } else if (item.key === 1) {
                    handleViewNotes(record);
                  } else if (item.key === 2) {
                    openModal(record, true);
                  } else if (item.key === 6) {
                    cancelGeneralNotes(record);
                  } else if (item.key === 3) {
                    handlePrint(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  
  const getInventoryColumns = ({ role, handleEdit, showDeleteConfirm }) => [
    {
      title: "#",
      width: "4%",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      width: "7%",
      align: "center",
      ...GetColumnSearchProps("sku"),
    },
    {
      title: "Brand Name",
      dataIndex: "medicine_name",
      align: "left",
      width: "10%",
      ellipsis: true,
      ...GetColumnSearchProps("medicine_name"),
    },
    {
      title: "Generic Name",
      dataIndex: "generic_name",
      align: "center",
      ellipsis: true,
      ...GetColumnSearchProps("generic_name"),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      align: "center",
      ellipsis: true,
      ...GetColumnSearchProps("manufacturer"),
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Strength",
      dataIndex: "strength",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      ellipsis: true,
      ...GetColumnSearchProps("location"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
      ellipsis: true,
      render: (text) =>
        text > 2 && text < 5 ? (
          <span className="bg-yellow-200 px-3 py-[4px] font-medium text-xs rounded-md">
            {text}
          </span>
        ) : text < 2 ? (
          <span className="bg-red-300 px-3 py-[4px] font-medium text-xs rounded-md">
            {text}
          </span>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => {
        const dateA = new Date(a.expiry_date);
        const dateB = new Date(b.expiry_date);
        return dateA - dateB;
      },
      render: (text) => {
        let backgroundColorClass = "";
        if (text !== null) {
          const expiryDate = text !== null ? new Date(text) : "";
          const today = new Date();
          const nextMonth = new Date();
          nextMonth.setDate(today.getDate() + 30);
  
          if (expiryDate < today) {
            backgroundColorClass = "bg-red-300";
          } else if (expiryDate < nextMonth) {
            backgroundColorClass = "bg-yellow-200";
          } else {
            backgroundColorClass = "bg-transparent";
          }
        }
  
        return (
          <span className={`px-3 py-[4px] rounded ${backgroundColorClass}`}>
            {text && <FormatDate date={text} />}
          </span>
        );
      },
    },
    {
      title: "MSRP",
      dataIndex: "MSRP",
      align: "center",
      ellipsis: true,
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      align: "center",
      ellipsis: true,
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      title: "Date Added",
      dataIndex: "date_time",
      align: "center",
      ellipsis: true,
      render: (text) => text && <FormatDate date={text} />,
    },
    ...(role !== "nurse" && role !== "doctor"
      ? [
          {
            title: "Action",
            width: "6%",
            align: "center",
            fixed: "right",
            render: (record) => (
              <span>
                <EditOutlined
                  className="mr-2 text-[#1890ff]"
                  onClick={() => handleEdit(record)}
                />
                <DeleteOutlined
                  className="text-red-500"
                  onClick={() => showDeleteConfirm(record?.id)}
                />
              </span>
            ),
          },
        ]
      : []),
  ];

export {recentEncountersColumns, RecentPatientsColumns , getServiceListColumns, getLabsListColumn, getGroupColumn, getServiceTypeColumn , getWaitingRoomColumn , getGeneralNotesColumn, getInventoryColumns};
 