import { Button, Card, Col, Dropdown, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { NumberFormatter } from "../../../../utils/validator";
import { EllipsisOutlined } from "@ant-design/icons";
import { doPatch } from "../../../../API/apis";
import GuestCheckoutDrawer from "../../../../components/GuestCheckout/GuestCheckout";
import PrescriptionPad from "../GuestCheckout/PrescriptionPad";
import GuestCheckoutView from "../../../../components/GuestCheckOutView/GuestCheckoutView";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";


const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Cancel Invoice",
  },
  {
    key: 4,
    label: "Print",
  },
];

const GuestCheckout = ({role}) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rxData, setRxData] = useState(null);
  

  const {
    guestInvoicesData,
    getGuestInvoices,
    officeSetting,
  } = useStateGlobalContext();
  const [clinicInfo, setClinicInfo] = useState({
    clinic_name: "",
    address_line_1: "",
    clinic_phone_number: "",
    clinic_office_phone: "",
    clinic_fax: "",
    additional_data_1: "",
    additional_data_2: "",
    additional_data_3: "",
  });

  const openDrawer = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleView = (record) => {
    setView(true);
    setViewRecord(record);
  };

  const closeView = () => {
    setView(false);
    setViewRecord(null);
  };

  const handlePrint = (record) => {
    setDrawerOpen(true);
    setRxData(record);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const coloumn = [
    {
      key: "#",
      title: "#",
      width: "5%",
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      key: 1,
      title: "Issue Date",
      dataIndex: "issue_date",
      width: "10%",
      align: "center",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Invoice ID #",
      dataIndex: "invoice_id",
      ellipsis: true,
      width: "15%",
      align: "center",
    },
    {
      key: 3,
      title: "Name",
      dataIndex: "patient_name",
      ellipsis: true,
      width: "15%",
    },
    {
      key: 4,
      title: "Price",
      width: "15%",
      align: "center",
      ellipsis: true,
      dataIndex: "price",
      width: "10%",
      render: (text, record) =>
        record?.status === "Cancelled" ? (
          <span className="text-red-600">
            <NumberFormatter value={text} />
          </span>
        ) : (
          <NumberFormatter value={text} />
        ),
    },
    {
      key: 5,
      title: "Status",
      dataIndex: "status",
      width: "15%",
      align: "center",
      render: (text) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "paid":
            bgColor = "bg-green-200";
            textColor = "text-green-500";
            break;
          case "Cancelled":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          default:
            bgColor = "bg-green-200";
            textColor = "text-green-500";
        }
        return (
          <span
            className={`px-3 py-1 ${textColor} ${bgColor} font-semibold text-xs rounded-md`}
          >
            {text === "paid" ? "Paid" : text}
          </span>
        );
      },
    },
    {
      key: 6,
      title: "Action",
      fixed: "right",
      align: "center",
      width: "8%",
      render: (text, record) => {
        const { status } = record;
        const availableItems =
          status === "Cancelled"
            ? items.filter((item) => [1, 3, 4].includes(item.key))
            : items.filter((item) => [1, 2, 3, 4].includes(item.key));

        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handlePrint(record);
                  } else if (item.key === 2) {
                    handleStatus(record);
                  } else if (item.key === 1) {
                    handleView(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handleStatus = (record) => {
    Modal.confirm({
      title: "Confirm Cancel?",
      icon: null,
      content: "Are you sure you want to cancel this?",
      okText: "Cancel Invoice",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleCancelInvoice(record);
      },
    });
  };

  const handleCancelInvoice = async (record) => {
    let data = {
      status: "Cancelled",
    };
    const response = await doPatch(
      `/inventory/update-status-guest/${record.id}/`,
      data
    );
    if (response.status === 200) {
      Notification.success("Invoice Cancelled Successfully");
      getGuestInvoices();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getGuestInvoices()]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (officeSetting) {
      setClinicInfo({
        clinic_name: officeSetting?.facility_name[1]
          ? officeSetting?.facility_name[0]
          : "",
        address_line_1: officeSetting?.facility_address[1]
          ? officeSetting?.facility_address[0]
          : "",
        clinic_phone_number: "",
      });
    }
  }, []);

  return (
    <>
      <Col xs={24} md={24} lg={role === "doctor" || role === "nurse" ? 24 : 24} xl={role === "doctor" || role === "nurse" ? 24 : 24}>
        <Card className="mt-4 mb-4 shadow-class" loading={loading}>
          <div className="flex justify-between mb-2">
            <h1>Recent Guest Invoices</h1>
            <Button type="primary" onClick={openDrawer}>
              Create Invoice
            </Button>
          </div>
          <Table
            size="small"
            columns={coloumn}
            dataSource={guestInvoicesData}
            scroll={{
              y: 370,
            }}
            pagination={false}
          />
        </Card>
      </Col>
      <GuestCheckoutDrawer
        visible={visible}
        onClose={handleCancel}
        // clinic_information={clinicInfo}
      />
      <PrescriptionPad
        visible={drawerOpen}
        onClose={handleCloseDrawer}
        rxData={rxData}
        clinic_information={officeSetting}
      />
      <GuestCheckoutView visible={view} data={viewRecord} onClose={closeView} />
    </>
  );
};

export default GuestCheckout;
