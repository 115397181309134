import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useStateGlobalContext } from "../../contexts/GlobalContext";

const AppointmentDatePicker = ({ value, onChange, disabledDate }) => {
  const { officeSetting } = useStateGlobalContext();
  

  let dateFormat = "YYYY/MM/DD"; // Default format
  if (officeSetting) {
    if (officeSetting.date_format === 'en-US') {
      dateFormat = "MM/DD/YYYY";
    } else if (officeSetting.date_format === 'en-GB') {
      dateFormat = 'DD/MM/YYYY';
    }
  }

  return (
    <DatePicker
      value={value ? dayjs(value) : null}
    //   onChange={(date, dateString) => onChange(date ? date.format("YYYY-MM-DD") : null)}
      onChange={onChange}
      disabledDate={disabledDate}
      format={dateFormat}
      placeholder={dateFormat}
    />
  );
};

export default AppointmentDatePicker;
