const FacilityCode =[
    {
     "code": "01",
     "service_name": "Pharmacy",
     "description": "A facility or location where drugs and other medically related items and services are sold, dispensed, or otherwise provided directly to patients.\n(Effective October 1, 2003)\n(Revised, effective October 1, 2005)"
    },
    {
     "code": "02",
     "service_name": "Telehealth Provided Other than in\nPatient’s Home",
     "description": "The location where health services and health related services are provided or received, through telecommunication technology. Patient is not located in their home when receiving health services or health related services through telecommunication technology. (Effective January 1, 2017)\n(Description change effective January 1, 2022,\nand applicable for Medicare April 1, 2022.)"
    },
    {
     "code": "03",
     "service_name": "School",
     "description": "A facility whose primary purpose is education. (Effective January 1, 2003)"
    },
    {
     "code": "04",
     "service_name": "Homeless Shelter",
     "description": "A facility or location whose primary purpose is to provide temporary housing to homeless individuals (e.g., emergency shelters, individual or family shelters).\n(Effective January 1, 2003)"
    },
    {
     "code": "05",
     "service_name": "Indian Health Service Free- standing Facility",
     "description": "A facility or location, owned and operated by the Indian Health Service, which provides diagnostic, therapeutic (surgical and non- surgical), and rehabilitation services to American Indians and Alaska Natives who do not require hospitalization.\n(Effective January 1, 2003)"
    },
    {
     "code": "06",
     "service_name": "Indian Health Service Provider- based Facility",
     "description": "A facility or location, owned and operated by the Indian Health Service, which provides diagnostic, therapeutic (surgical and non- surgical), and rehabilitation services rendered by, or under the supervision of, physicians to American Indians and Alaska Natives admitted as inpatients or outpatients.\n(Effective January 1, 2003)"
    },
    {
     "code": "07",
     "service_name": "Tribal 638 Free- standing Facility",
     "description": "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services to tribal members who do not require hospitalization.\n(Effective January 1, 2003)"
    },
    {
     "code": "08",
     "service_name": "Tribal 638 Provider-based Facility",
     "description": "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services to tribal members admitted as inpatients or outpatients.\n(Effective January 1, 2003)"
    },
    {
     "code": "09",
     "service_name": "Prison\/Correctional Facility",
     "description": "A prison, jail, reformatory, work farm, detention center, or any other similar facility maintained by either Federal, State or local authorities for the purpose of confinement or rehabilitation of adult or juvenile criminal offenders.\n(Effective July 1, 2006)"
    },
    {
     "code": "10",
     "service_name": "Telehealth Provided in Patient’s Home",
     "description": "The location where health services and health related services are provided or received, through telecommunication technology. Patient is located in their home (which is a location other than a hospital or other facility where the patient receives care in a private residence) when receiving health services or health related services through telecommunication technology.\n(This code is effective January 1, 2022, and available to Medicare April 1, 2022.)"
    },
    {
     "code": "11",
     "service_name": "Office",
     "description": "Location, other than a hospital, skilled nursing facility (SNF), military treatment facility, community health center, State or local public health clinic, or intermediate care facility (ICF), where the health professional routinely provides\nhealth examinations, diagnosis, and treatment of illness or injury on an ambulatory basis."
    },
    {
     "code": "12",
     "service_name": "Home",
     "description": "Location, other than a hospital or other facility, where the patient receives care in a private residence."
    },
    {
     "code": "13",
     "service_name": "Assisted Living Facility",
     "description": "Congregate residential facility with self- contained living units providing assessment of each resident's needs and on-site support 24 hours a day, 7 days a week, with the capacity to deliver or arrange for services including some health care and other services.\n(Effective October 1, 2003)"
    },
    {
     "code": "14",
     "service_name": "Group Home",
     "description": "A residence, with shared living areas, where clients receive supervision and other services such as social and\/or behavioral services, custodial service, and minimal services (e.g., medication administration).\n(Effective October 1, 2003)\n(Revised, effective April 1, 2004)"
    },
    {
     "code": "15",
     "service_name": "Mobile Unit",
     "description": "A facility\/unit that moves from place-to-place equipped to provide preventive, screening, diagnostic, and\/or treatment services. (Effective January 1, 2003)"
    },
    {
     "code": "16",
     "service_name": "Temporary Lodging",
     "description": "A short-term accommodation such as a hotel, camp ground, hostel, cruise ship or resort where the patient receives care, and which is not identified by any other POS code.\n(Effective January 1, 2008)"
    },
    {
     "code": "17",
     "service_name": "Walk-in Retail Health Clinic",
     "description": "A walk-in health clinic, other than an office, urgent care facility, pharmacy or independent clinic and not described by any other Place of Service code, that is located within a retail operation and provides, on an ambulatory basis, preventive and primary care services.\n(This code is available for use immediately with a final effective date of May 1, 2010)"
    },
    {
     "code": "18",
     "service_name": "Place of Employment- Worksite",
     "description": "A location, not described by any other POS code, owned or operated by a public or private entity where the patient is employed, and where a health professional provides on-going or episodic occupational medical, therapeutic or rehabilitative services to the individual.\n(This code is available for use effective January 1, 2013 but no later than May 1, 2013)"
    },
    {
     "code": "19",
     "service_name": "Off Campus- Outpatient Hospital",
     "description": "A portion of an off-campus hospital provider based department which provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services to sick or injured persons who do not require hospitalization or institutionalization. (Effective January 1, 2016)"
    },
    {
     "code": "20",
     "service_name": "Urgent Care Facility",
     "description": "Location, distinct from a hospital emergency room, an office, or a clinic, whose purpose is to diagnose and treat illness or injury for unscheduled, ambulatory patients seeking immediate medical attention.\n(Effective January 1, 2003)"
    },
    {
     "code": "21",
     "service_name": "Inpatient Hospital",
     "description": "A facility, other than psychiatric, which primarily provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services by, or under, the supervision of physicians to patients admitted for a variety of\nmedical conditions."
    },
    {
     "code": "22",
     "service_name": "On Campus- Outpatient Hospital",
     "description": "A portion of a hospital’s main campus which provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services to sick or injured persons who do not require\nhospitalization or institutionalization. (Description change effective January 1, 2016)"
    },
    {
     "code": "23",
     "service_name": "Emergency Room – Hospital",
     "description": "A portion of a hospital where emergency diagnosis and treatment of illness or injury is provided."
    },
    {
     "code": "24",
     "service_name": "Ambulatory Surgical Center",
     "description": "A freestanding facility, other than a physician's office, where surgical and diagnostic services are provided on an ambulatory basis."
    },
    {
     "code": "25",
     "service_name": "Birthing Center",
     "description": "A facility, other than a hospital's maternity facilities or a physician's office, which provides a setting for labor, delivery, and immediate\npost-partum care as well as immediate care of new born infants."
    },
    {
     "code": "26",
     "service_name": "Military Treatment Facility",
     "description": "A medical facility operated by one or more of the Uniformed Services. Military Treatment Facility (MTF) also refers to certain former\nU.S. Public Health Service (USPHS) facilities\nnow designated as Uniformed Service Treatment Facilities (USTF)."
    },
    {
     "code": "27",
     "service_name": "Outreach Site\/ Street",
     "description": "A non-permanent location on the street or found environment, not described by any other POS code, where health professionals provide preventive, screening, diagnostic, and\/or treatment services to unsheltered homeless\nindividuals. (Effective October 1, 2023)"
    },
    {
     "code": "31",
     "service_name": "Skilled Nursing Facility",
     "description": "A facility which primarily provides inpatient skilled nursing care and related services to patients who require medical, nursing, or\nrehabilitative services but does not provide the level of care or treatment available in a hospital."
    },
    {
     "code": "32",
     "service_name": "Nursing Facility",
     "description": "A facility which primarily provides to residents skilled nursing care and related services for the rehabilitation of injured, disabled, or sick persons, or, on a regular basis, health-related care services above the level of custodial care to\nother than individuals with intellectual disabilities."
    },
    {
     "code": "33",
     "service_name": "Custodial Care Facility",
     "description": "A facility which provides room, board and other personal assistance services, generally on a long-term basis, and which does not include a medical component."
    },
    {
     "code": "34",
     "service_name": "Hospice",
     "description": "A facility, other than a patient's home, in which palliative and supportive care for terminally ill\npatients and their families are provided."
    },
    {
     "code": "41",
     "service_name": "Ambulance – Land",
     "description": "A land vehicle specifically designed, equipped and staffed for lifesaving and transporting the sick or injured."
    },
    {
     "code": "42",
     "service_name": "Ambulance – Air or Water",
     "description": "An air or water vehicle specifically designed, equipped and staffed for lifesaving and transporting the sick or injured."
    },
    {
     "code": "49",
     "service_name": "Independent Clinic",
     "description": "A location, not part of a hospital and not described by any other Place of Service code, that is organized and operated to provide preventive, diagnostic, therapeutic, rehabilitative, or palliative services to outpatients only.\n(Effective October 1, 2003)"
    },
    {
     "code": "50",
     "service_name": "Federally Qualified Health Center",
     "description": "A facility located in a medically underserved area that provides Medicare beneficiaries preventive primary medical care under the general direction of a physician."
    },
    {
     "code": "51",
     "service_name": "Inpatient Psychiatric Facility",
     "description": "A facility that provides inpatient psychiatric services for the diagnosis and treatment of\nmental illness on a 24-hour basis, by or under the supervision of a physician."
    },
    {
     "code": "52",
     "service_name": "Psychiatric Facility – Partial Hospitalization",
     "description": "A facility for the diagnosis and treatment of mental illness that provides a planned therapeutic program for patients who do not require full time hospitalization, but who need broader programs than are possible from outpatient visits to a hospital-based or hospital- affiliated facility."
    },
    {
     "code": "53",
     "service_name": "Community Mental Health Center",
     "description": "A facility that provides the following services: outpatient services, including specialized outpatient services for children, the elderly, individuals who are chronically ill, and residents of the CMHC's mental health services area who have been discharged from inpatient treatment at a mental health facility; 24 hour a day emergency care services; day treatment, other partial hospitalization services, or psychosocial rehabilitation services; screening for patients being considered for admission to State mental health facilities to determine the appropriateness of such admission; and consultation and education services."
    },
    {
     "code": "54",
     "service_name": "Intermediate Care Facility\/Individuals with Intellectual Disabilities",
     "description": "A facility which primarily provides health- related care and services above the level of custodial care to individuals but does not provide the level of care or treatment available in a hospital or SNF."
    },
    {
     "code": "55",
     "service_name": "Residential Substance Abuse Treatment Facility",
     "description": "A facility which provides treatment for substance (alcohol and drug) abuse to live-in residents who do not require acute medical care. Services include individual and group therapy and counseling, family counseling, laboratory\ntests, drugs and supplies, psychological testing, and room and board."
    },
    {
     "code": "56",
     "service_name": "Psychiatric Residential Treatment Center",
     "description": "A facility or distinct part of a facility for psychiatric care which provides a total 24-hour therapeutically planned and professionally staffed group living and learning environment."
    },
    {
     "code": "57",
     "service_name": "Non-residential Substance Abuse Treatment Facility",
     "description": "A location which provides treatment for substance (alcohol and drug) abuse on an ambulatory basis. Services include individual and group therapy and counseling, family counseling, laboratory tests, drugs and supplies, and psychological testing.\n(Effective October 1, 2003)"
    },
    {
     "code": "58",
     "service_name": "Non-residential Opioid Treatment Facility",
     "description": "A location that provides treatment for opioid use disorder on an ambulatory basis. Services include methadone and other forms of Medication Assisted Treatment (MAT). (Effective January 1, 2020)"
    },
    {
     "code": "60",
     "service_name": "Mass Immunization Center",
     "description": "A location where providers administer pneumococcal pneumonia and influenza virus vaccinations and submit these services as electronic media claims, paper claims, or using the roster billing method. This generally takes place in a mass immunization setting, such as, a\npublic health center, pharmacy, or mall but may include a physician office setting."
    },
    {
     "code": "61",
     "service_name": "Comprehensive Inpatient Rehabilitation Facility",
     "description": "A facility that provides comprehensive rehabilitation services under the supervision of a physician to inpatients with physical disabilities. Services include physical therapy, occupational therapy, speech pathology, social or\npsychological services, and orthotics and prosthetics services."
    },
    {
     "code": "62",
     "service_name": "Comprehensive Outpatient Rehabilitation Facility",
     "description": "A facility that provides comprehensive rehabilitation services under the supervision of a physician to outpatients with physical disabilities. Services include physical therapy, occupational therapy, and speech pathology services."
    },
    {
     "code": "65",
     "service_name": "End-Stage Renal Disease Treatment Facility",
     "description": "A facility other than a hospital, which provides dialysis treatment, maintenance, and\/or training to patients or caregivers on an ambulatory or home-care basis."
    },
    {
     "code": "66",
     "service_name": "Programs of All- Inclusive Care for the Elderly (PACE) Center",
     "description": "A facility or location providing comprehensive medical and social services as part of the Programs of All-Inclusive Care for the Elderly (PACE). This includes, but is not limited to, primary care; social work services; restorative therapies, including physical and occupational therapy; personal care and supportive services; nutritional counseling; recreational therapy; and meals when the individual is enrolled in PACE.\n(Effective August 1, 2024)"
    },
    {
     "code": "71",
     "service_name": "Public Health Clinic",
     "description": "A facility maintained by either State or local health departments that provides ambulatory\nprimary medical care under the general direction of a physician."
    },
    {
     "code": "72",
     "service_name": "Rural Health Clinic",
     "description": "A certified facility which is located in a rural medically underserved area that provides ambulatory primary medical care under the general direction of a physician."
    },
    {
     "code": "81",
     "service_name": "Independent Laboratory",
     "description": "A laboratory certified to perform diagnostic\nand\/or clinical tests independent of an institution or a physician's office."
    },
    {
     "code": "99",
     "service_name": "Other Place of Service",
     "description": "Other place of service not identified above."
    }
   ]

   export default FacilityCode