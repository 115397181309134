import React from "react";
import { NumberFormatter } from "../../../../../../../utils/validator";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";

const InvoiceFooter = ({ 
  className = "text-right float-right", 
  subTotal, 
  discount, 
  discountAmount, 
  tax, 
  grandTotal 
}) => {
  const { officeSetting } = useStateGlobalContext();

  return (
    <div className={`invoice-footer w-[40%] ${className}  border-t border-gray-300 pt-4 mt-4`}>
      <div className="flex justify-between py-1">
        <span className="text-gray-600">Sub Total:</span>
        <span className={`${className} font-semibold`}><NumberFormatter value={subTotal} /></span>
      </div>
      <div className="flex justify-between py-1">
        <span className="text-gray-600">Discount {discount}%:</span>
        <span className={`${className} font-semibold`}><NumberFormatter value={discountAmount} /></span>
      </div>
      <div className="flex justify-between py-1">
        <span className="text-gray-600">Tax {officeSetting?.local_tax_rate}%:</span>
        <span className={`${className} font-semibold`}><NumberFormatter value={tax} /></span>
      </div>
      <div className="flex justify-between py-2 border-t border-gray-300 mt-2">
        <span className="text-lg font-bold text-gray-800">Grand Total:</span>
        <span className={`${className} text-lg font-bold text-gray-800`}><NumberFormatter value={grandTotal} /></span>
      </div>
    </div>
  );
};

export default InvoiceFooter;
