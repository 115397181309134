import {
  AutoComplete,
  Button,
  Form,
  Modal,
  Input,
  Row,
  Col,
} from "antd";
import React, { useEffect } from "react";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import { doPatch, doPost } from "../../../../../API/apis";
import { useSearchMedicines } from "../../../../../API/apiUtils";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";

const SUCCESS_MESSAGES = {
  UPDATE: "Medication History updated successfully",
  ADD: "Medication History added successfully",
  DELETE: "Medication History Deleted Successfully",
};

const CurrentMedicationModal = ({ open, onCancel, edit, editRecord }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { searchMedicine: options, handleSearchMedicines } = useSearchMedicines();
  const { getCurrentMedications } = useStateGlobalContext();

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      form.resetFields();
    }, 300); 
  };

  useEffect(() => {
    form.setFieldsValue({
      medicine_name: editRecord?.medicine_name,
      start_date: editRecord?.start_date,
      end_date: editRecord?.end_date,
      notes: editRecord?.notes,
    });
  }, [edit, editRecord]);

  const onSelect = (value) => {
    form.setFieldsValue({ medicine_name: value });
  };

  const handleDateChange = (field, date) => {
    form.setFieldsValue({ [field]: date });
  };

  const handleCurrentMedication = async (values) => {
    const data = {
      medicine_name: values.medicine_name,
      start_date: values.start_date,
      end_date: values.end_date || null,
      notes: values.notes,
    };
    try {
      const response = edit
        ? await doPatch(
            `/patient/update-current-medications/${id}/${editRecord?.id}/`,
            data
          )
        : await doPost(`/patient/add-current-medications/${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        Notification.success(edit ? SUCCESS_MESSAGES.UPDATE : SUCCESS_MESSAGES.ADD);
        getCurrentMedications(id);
        handleCancel()
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  return (
    <Modal
      title={edit ? "Update Current Medication" : "Current Medications"}
      open={open}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width={550}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form
        layout="vertical"
        name="current-medications"
        form={form}
        onFinish={handleCurrentMedication}
      >
        <Form.Item
          label="Search Medicine"
          name="medicine_name"
          tooltip="B = Brand and G = Generic"
          rules={[
            {
              required: true,
              message: "Please Enter Medicine Name First",
            },
          ]}
        >
          <AutoComplete
            options={options}
            allowClear
            onSelect={onSelect}
            onSearch={handleSearchMedicines}
            placeholder="Search by brand name or add custom"
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="start_date" label="Start Date">
              <BaseDatePicker
                value={form.getFieldValue("start_date")}
                onChange={(date) => handleDateChange("start_date", date)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="end_date"
              label="End Date"
              tooltip="Leave Blank for Active Medication"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue("start_date");
                    if (!value || !startDate || value >= startDate) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("End Date cannot be before than Start Date")
                    );
                  },
                }),
              ]}
            >
              <BaseDatePicker
                value={form.getFieldValue("end_date")}
                onChange={(date) => handleDateChange("end_date", date)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} maxLength={500} showCount />
        </Form.Item>
        <div className="flex justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" className="ml-2">
            {edit ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CurrentMedicationModal;
