import { Button, Card, Table, Dropdown, Tooltip} from "antd";
import React, { useEffect, useState } from "react";
import ReferalDrawer from "./ReferalDrawer";
import ReferralReport from "./ReferralReport/ReferralReport";
import { doPatch } from "../../../../API/apis";
import {  useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import ViewReferralNotes from "./ReferralReport/ViewReferralNotes";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../components/FormatDate/FormatDate";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Print",
  },
  {
    key: 3,
    label: "Cancel",
  },
];

const encounter_Status = [
  { key: 1, label: "Signed" },
  { key: 2, label: "Cancelled" },
];

const Referal = () => {
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [viewReportData, setViewReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [referralReportData, setReferralReportData] = useState([]);
  const { referralData, getReferral, profile } = useStateGlobalContext();
  

  const role = profile && profile?.user_info[0]?.role;

  const coloumn = [
    {
      key: 1,
      title: "#",
      width: "5%",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: "referral_id",
      title: "Referral ID",
      dataIndex: "referral_id",
      width: "20%",
    },
    {
      key: 2,
      title: "Date",
      align: "center",
      dataIndex: "date_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      key: 3,
      title: "Reason",
      dataIndex: "reason_for_referral",
    },
    {
      key: 4,
      title: "Referral From",
      align: "center",
      dataIndex: "referral_from_name",
    },
    {
      key: 5,
      title: "Referral To",
      align: "center",
      dataIndex: "referral_to_full_name",
    },
    {
      key: 6,
      title: "Speciality",
      align: "center",
      dataIndex: "referral_to_speciality",
    },
    {
      key: 7,
      title: "Expiry Date",
      align: "center",
      dataIndex: "end_date",
      render: (text) =>
        text && <FormatDate date={text}/>,
    },
    {
      key: 8,
      title: "Status",
      align: "center",
      dataIndex: "status",
      filters: encounter_Status.map((text) => ({
        text: text.label,
        value: text.label,
      })),
      onFilter: (value, record) =>
        record?.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Signed"],
      render: (status) =>
        status === "Signed" ? (
          <span className="bg-[#3484F0] px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ) : (
          <span className="bg-red-500 px-1 py-[2px] text-white text-xs rounded-md">
            {status}
          </span>
        ),
    },
    {
      title: "Action",
      key: 9,
      width: "7%",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const availableItems = items.filter((item) => {
          if (item.key === 3 && record.status === "Cancelled") {
            return false;
          }
          return role !== "nurse" || item.key !== 3;
        });

        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleViewReport(record);
                  } else if (item.key === 2) {
                    handlePrintReport(record);
                  } else if (item.key === 3) {
                    handleCancel(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const openDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setEdit(false);
    setReferralReportData(null);
  };

  const handleViewReport = (record) => {
    setViewReport(true);
    setViewReportData(record);
  };

  const closeViewReport = () => {
    setViewReport(false);
    setViewReportData([]);
  };

  const handlePrintReport = (record) => {
    setShowReport(true);
    setReferralReportData(record);
  };

  const hideReport = () => {
    setShowReport(false);
  };

  const handleCancel = (record) => {
    showConfirmModal({
      title: "Confirm Cancel?",
      icon: null,
      content: "Are you sure you want to Cancel this?",
      okText: "Cancel",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        cancelReferral(record);
      },
    });
  };

  const cancelReferral = async (record) => {
    const data = {
      status: "Cancelled",
    };
    try {
      const response = await doPatch(
        `/referral/referral-update/${id}/${record?.id}/`,
        data
      );
      if (response.status === 200) {
        Notification.success("Patient Referral Cancelled successfully");
        getReferral(id);
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getReferral(id);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <Card>
      <div className="flex justify-between items-center">
        <h1>All Referrals</h1>
        <div>
          {/* <Button type='primary' onClick={viewReport}>View Report</Button> */}
          {role !== "nurse" && (
            <Button className="ml-2" type="primary" onClick={openDrawer}>
              Create Referral
            </Button>
          )}
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={referralData}
        size="small"
        className="mt-2"
        columns={coloumn}
        pagination={false}
      />
      <ReferalDrawer
        visible={visible}
        onClose={onClose}
        edit={edit}
        editData={referralReportData}
      />
      <ViewReferralNotes
        open={viewReport}
        onClose={closeViewReport}
        data={viewReportData}
      />
      <ReferralReport
        visible={showReport}
        onClose={hideReport}
        data={referralReportData}
      />
    </Card>
  );
};

export default Referal;
