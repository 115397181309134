import React, { useEffect, useState } from "react";
import { Input, Button, Form, Row, Col,  Select, Divider } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import { doPatch } from "../../../../../../API/apis";
import Loader from "../../../../../../components/Loader/Loader";
import BaseDatePicker from "../../../../../../components/BaseDatePicker/BaseDatePicker";
import dayjs from 'dayjs';
import Notification from "../../../../../../components/Notification/Notification";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
const { Option } = Select;

const FormItem = Form.Item;

const BasicInformation = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const { patientBasicInfo, getPatientData} = useStateGlobalContext();


  const {
    first_name,
    middle_name,
    last_name,
    gender,
    date_of_birth,
    sexual_orientation,
    marital_status,
    other_Martial_Status,
    employment,
    guardian_first_name,
    guardian_middle_name,
    guardian_last_name,
    guardian_phone_number,
    guardian_email,
    basic_information_notes,
  } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        const {
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          gender,
          sexual_orientation,
          marital_status,
          other_Martial_Status,
          employment,
          guardian_first_name,
          guardian_middle_name,
          guardian_last_name,
          guardian_phone_number,
          guardian_email,
          basic_information_notes,
        } = patientBasicInfo;

        form.setFieldsValue({
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          gender,
          sexual_orientation,
          marital_status:
            marital_status === "other" ? other_Martial_Status : marital_status,
          employment,
          guardian_first_name,
          guardian_middle_name,
          guardian_last_name,
          guardian_phone_number,
          guardian_email,
          basic_information_notes,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = () => {
    setEdit(true);
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const handleUpdate = async (values)=>{
    const date_of_birth = values?.date_of_birth && dayjs(values?.date_of_birth).format("YYYY-MM-DD");

    try {
      const response = await doPatch(`/patient/update-info/${id}/`, {...values,date_of_birth });
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id); 
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderFormItem = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={8}>
      <FormItem label={label} name={name}>
        {inputComponent}
      </FormItem>
    </Col>
  );

  const renderFormItem1 = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={12}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (!patientBasicInfo) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
      <h1 className="text-[1.125rem] font-bold mb-4">Basic Information</h1>
      {edit ? <Button onClick={onEditCancel}>Close</Button> : <Button onClick={onEdit}>Edit</Button> }
      </div>

      { edit ? (
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <h1 className="font-medium mb-4">Patient </h1>
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem(
            "First Name",
            "first_name",
            <Input placeholder="First Name" maxLength={30}/>,
          )}
          {renderFormItem(
            "Middle Name",
            "middle_name",
            <Input placeholder="Middle Name" maxLength={30}/>
          )}
          {renderFormItem(
            "Last Name",
            "last_name",
            <Input placeholder="Last Name" maxLength={30}/>
          )}
        </Row>

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col span={6} xs={24} md={12} lg={6}>
          <FormItem
              name="date_of_birth"
              label="DOB"
              rules={[
                {
                  required: true,
                  message: "Enter date of birth",
                },
              ]}
            >
              <BaseDatePicker/>
            </FormItem>
          </Col>
          <Col span={6} xs={24} md={12} lg={6}>
          <Form.Item label="Gender" name="gender">
              <Select defaultValue="male">
                <Option value="Male"> Male </Option>
                <Option value="Female"> Female </Option>
                <Option value="unknown"> Unknown </Option>
                <Option value="Decline to Specify"> Decline to Specify </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} xs={24} md={12} lg={6}>
          <Form.Item label="Sexual Orientation" name="sexual_orientation">
              <Select placeholder="Choose Sexual Orientation">
                <Option value="Heterosexual"> Heterosexual (straight) </Option>
                <Option value="Gay"> Gay </Option>
                <Option value="Lesbian"> Lesbian </Option>
                <Option value="Bisexual"> Bisexual </Option>
                <Option value="Transgender"> Transgender </Option>
                <Option value="Unknown"> Unknown </Option>
                <Option value="Decline to Specify"> Decline to Specify </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col xs={24} md={12} lg={12}>
            <Form.Item label="Marital Status" name="marital_status">
              <Select placeholder='Choose Marital Status'>
                <Option value="Single"> Single </Option>
                <Option value="Married"> Married </Option>
                <Option value="Widowed"> Widowed </Option>
                <Option value="Divorced"> Divorced </Option>
                <Option value="Choose Not to Disclose"> Choose not to Disclose </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item label="Employement Status" name="employment">
              <Select placeholder='Chooe Employement Status'>
                <Option value="Employed"> Employed </Option>
                <Option value="Unemployed"> Unemployed </Option>
                <Option value="Student"> Student </Option>
                <Option value="Other"> Other </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <h1 className="font-medium mb-4">Legal Guardian </h1>
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem(
            "First Name",
            "guardian_first_name",
            <Input placeholder="First Name" maxLength={30}/>
          )}
          {renderFormItem(
            "Middle Name",
            "guardian_middle_name",
            <Input placeholder="Middle Name" maxLength={30}/>
          )}
          {renderFormItem(
            "Last Name",
            "guardian_last_name",
            <Input placeholder="Last Name" maxLength={30}/>
          )}
        </Row>

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem1(
            "Guardian Phone",
            "guardian_phone_number",
            <Input placeholder="Guardian Phone" maxLength={30}/>
          )}
          {renderFormItem1(
            "Guardian Email",
            "guardian_email",
            <Input placeholder="Guardian Email" maxLength={30}/>
          )}
        </Row>

        <Form.Item label="Notes" name="basic_information_notes">
          <Input.TextArea placeholder="Notes..." maxLength={300} showCount/>
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={onEditCancel}>Cancel</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Save Changes
          </Button>
        </div>
      </Form> 
      ) : (
        <div className="view-wrapper">
            <div>
            <h1 className="font-medium mb-2">Patient </h1>
            <p>First Name: {first_name}</p>
            <p>Middle Name: {middle_name}</p>
            <p>Last Name: {last_name}</p>
            {/* <p>DOB: {birthDateFormat(date_of_birth, officeDateFormat)}</p> */}
            <p>DOB: <FormatDate date={date_of_birth}/></p>
            <p>Gender: {gender}</p>
            <p>Sexual Orientation: {sexual_orientation}</p>
            <p>Marital Status: {marital_status === "other" ? other_Martial_Status : marital_status}</p>
            <p>Employement Status: {employment}</p>
            <p className="notes">Notes: {basic_information_notes}</p>
            </div>
            <Divider/>
            <div className="mt-4">
            <h1 className="font-medium mb-2">Legal Guardian </h1>
            <p>First Name: {guardian_first_name}</p>
            <p>Middle Name: {guardian_middle_name}</p>
            <p>Last Name: {guardian_last_name}</p>
            <p>Phone: {guardian_phone_number}</p>
            <p>Email: {guardian_email}</p>
            </div>


        </div>
      )}
    </div>
  );
};

export default BasicInformation;
