import { Row } from 'antd'
import React from 'react'
import Rooms from './Rooms'
import Discounts from './Discounts'

const index = () => {
  return (
    <Row gutter={[16,16]}>
        <Rooms/>
        <Discounts/>
    </Row>
  )
}

export default index