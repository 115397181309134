import { Button, Divider, Drawer } from "antd";
import React, { useRef, useEffect, useState } from "react";
import "./referralreport.css";
import { useReactToPrint } from "react-to-print";
import {
  birthDateFormat,
  formatDate,
  formatTime,
} from "../../../../../utils/DateFormat";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const ReferralReport = ({ visible, onClose, data }) => {
  const contentRef = useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    officeSetting,
    getOfficeSettings,
    patientBasicInfo,
    getPatientData,
    patientAllergies,
    getAllergies,
  } = useStateGlobalContext();

  const {
    current_address_line_1,
    current_address_line_2,
    current_city,
    current_state,
    current_country,
  } = patientBasicInfo || {};

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const DisplayAllergies = ({ allergies }) => {
    const displayedAllergies = allergies?.map(
      (allergy) => allergy?.allergy_value
    );

    return <span>{displayedAllergies?.join(", ")}</span>;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getOfficeSettings(),
          getPatientData(id),
          getAllergies(id),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <Drawer
      title="Referral Report"
      open={visible}
      onClose={onClose}
      placement="bottom"
      height="100vh"
      footer={null}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer mb-10"
    >
      <div className="reports-print mb-10">
        <div className="referral-report-container">
          <Button type="primary" onClick={handlePrint}>
            Print Document
          </Button>
          <div className="referral-report-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Referral ID: {data?.referral_id} </h2>
            <h2 className="text-center mt-4 mb-4">Status: {data?.status === 'Cancelled' ? <span className="font-medium text-red-500">{data.status}</span>: data?.status}</h2>
            <h2 className="text-center">Referring From</h2>
            <div className="flex justify-between">
              <div className="w-[33%]">
                <h3>Office Info</h3>
                <p>{officeSetting?.facility_name[1]}</p>
                <p>Phone: {officeSetting?.office_phone[0]}</p>
                <p>Fax: {officeSetting?.office_fax[0]}</p>
                <p>Website: {officeSetting?.website}</p>
                {officeSetting?.facility_address[1] && (
                  <p>Address: {officeSetting?.facility_address[0]}</p>
                )}
              </div>
              <div className="w-[33%]">
                <h3>Provider Info</h3>
                <p>Name: {data?.referral_from_name}</p>
                <p>Speciality: {data?.referral_from_speciality}</p>
                <p>Phone: {data?.referral_from_phone}</p>
                <p>Fax: {data?.referral_from_fax}</p>
                <p>Email: {data?.referral_from_email}</p>
              </div>
            </div>
            <Divider
              style={{ border: "1px solid gray" }}
              className="referral-divider"
            />
            <div className="flex justify-between">
              <p>
                Referral Date: <FormatDateTime date={data?.date_time} type="datetime"/>
              </p>
              <p>Start Date: {data?.start_date &&  <FormatDate date={data?.start_date}/>}</p>
              <p>Expiry Date: {data?.end_date && <FormatDate date={data?.end_date}/>}</p>
              <p>Authoriztion Code: {data?.auth_code}</p>
            </div>
            <Divider
              style={{ border: "1px solid gray" }}
              className="referral-divider"
            />

            <h2 className="text-center">Patient Demographics</h2>
            <div>
              <div className="flex justify-between">
                <p>
                  <span className="font-medium">Full Name: </span>{" "}
                  {patientBasicInfo?.first_name}{" "}
                  {patientBasicInfo?.middle_name &&
                    patientBasicInfo?.middle_name}{" "}
                  {patientBasicInfo?.last_name}
                </p>
                <p>
                  <span className="font-medium">Gender: </span>
                  {patientBasicInfo?.sex === "other"
                    ? patientBasicInfo?.other_Gender
                    : patientBasicInfo?.gender}
                </p>
                <p>
                  <span className="font-medium">DOB: </span>{" "}
                  <FormatDate date={patientBasicInfo?.date_of_birth}/>
                </p>
              </div>
              <p>
                <span className="font-medium">Full Address: </span>
                {`${current_address_line_1 || ""} ${current_address_line_2 ? `, ${current_address_line_2}` : ""} ${current_city ? `, ${current_city}` : ""} ${current_state ? `, ${current_state}` : ""} ${current_country ? `, ${current_country}` : ""}`}
              </p>
              <div className="flex justify-between">
                <p>
                  <span className="font-medium">Phone Number: </span>
                  {patientBasicInfo?.patient_phone}
                </p>
                <p>
                  <span className="font-medium">
                    Email: {patientBasicInfo?.patient_email}
                  </span>
                </p>
              </div>
              <p>
                <span className="font-medium">Allergies: </span>
                <span className="text-red-600 font-medium">
                  <DisplayAllergies allergies={patientAllergies} />{" "}
                </span>
              </p>
            </div>
            <Divider
              style={{ border: "1px solid gray" }}
              className="referral-divider"
            />

            <h2 className="text-center">Patient Insurance Information</h2>
            <div>
              <div className="flex justify-between">
                <p>Type: {data?.insurance_type}</p>
                <p>Company: {data?.insurance_company}</p>
                <p>Plan: {data?.insurance_plan}</p>
              </div>
              <div className="flex justify-between">
                <p>Group #: {data?.insurance_group}</p>
                <p>Policy #: {data?.insurance_policy}</p>
              </div>
            </div>

            <Divider
              style={{ border: "1px solid gray" }}
              className="referral-divider"
            />

            <h2 className="text-center">Referring To</h2>
            <div>
              <div className="flex justify-between">
                <p>Full Name: {data?.referral_to_full_name}</p>
                <p>Speciality: {data?.referral_to_speciality}</p>
              </div>
              <div className="flex justify-between">
                <p>Phone: {data?.referral_to_phone}</p>
                <p>Fax: {data?.referral_to_fax}</p>
              </div>

              <p>Direct Email: {data?.referral_to_email}</p>
              <p>Full Address: {data?.referral_to_address}</p>
              <p>Operating Hours: {data?.referral_to_operating_hours}</p>
            </div>
          </div>
          <Divider
            style={{ border: "1px solid gray" }}
            className="referral-divider"
          />

          <h2 className="text-center">Clinical Notes</h2>
          <p>Referral For Reason: {data?.reason_for_referral}</p>
          <p>
            Patient diagnosis ICD -10 Codes: {data?.patient_diagnostic_code}
          </p>
          <p>Clinical Notes: {data?.instructions}</p>
        </div>
      </div>
    </Drawer>
  );
};

export default ReferralReport;
