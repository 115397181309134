import React, { useEffect, useState } from "react";
import { Card, Row, Col, Select, Tooltip } from "antd";
import { doGet } from "../../../../API/apis";

const { Option } = Select;

const DashboardCard = ({
  title,
  totalNumber,
  monthlyData,
  loading,
  onChange,
  tooltipText,
}) => {
  const [selectedOption, setSelectedOption] = useState("today");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <Col xl={4} sm={12}>
      <Card hoverable className="shadow-sm" loading={loading}>
        <div className="flex justify-between place-items-center">
          <Tooltip title={tooltipText}>
            <p className="font-medium text-sm">{title} </p>
          </Tooltip>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            // className="w-[100px]"
          >
            <Option value="today">Today</Option>
            <Option value="month">Month</Option>
          </Select>
        </div>

        <h1 className="font-bold text-xl leading-4">
          {selectedOption === "month" && monthlyData
            ? monthlyData
            : totalNumber}
        </h1>
      </Card>
    </Col>
  );
};

const DashboardCards = () => {
  const [loading, setLoading] = useState(false);
  const [TotalPatients, setTotalPatients] = useState(null);
  const [TotalAppointments, setTotalAppointments] = useState(null);
  const [TotalLabsAndImages, setTotalLabsAndImages] = useState(null);
  const [TotalProductInvoice, setTotalProductsInvoice] = useState(null);
  const [servicesCount, setServicesCount] = useState(null);
  const fetchData = async (endpoint, setData) => {
    setLoading(true);
    try {
      const response = await doGet(endpoint);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData("/reports/patient-detail/", setTotalPatients);
    fetchData("/reports/appointments-card/", setTotalAppointments);
    fetchData("/reports/labs-images-detail/", setTotalLabsAndImages);
    fetchData("/reports/product-invoices-widgets/", setTotalProductsInvoice);
    fetchData("/reports/patient-invoices-widgets/", setServicesCount);
  }, []);

  const handleCardChange = (value) => {};

  return (
    <Row gutter={[16, 16]} className="mt-2">
      <DashboardCard
        title="Patients"
        loading={loading}
        totalNumber={TotalPatients?.today_patients}
        monthlyData={TotalPatients?.monthly_patients}
        onChange={() => handleCardChange()}
        tooltipText="Total number for New Patients registered Today or this Month"
      />
      <DashboardCard
        title="Appointments"
        loading={loading}
        totalNumber={TotalAppointments?.today_appointments_count}
        monthlyData={TotalAppointments?.monthly_appointments_count}
        onChange={handleCardChange}
        tooltipText="Total number for New Appointments created Today or this Month"
      />

      <DashboardCard
        title="Services"
        loading={loading}
        totalNumber={servicesCount?.today_invoices_count}
        monthlyData={servicesCount?.monthly_invoices_count}
        onChange={handleCardChange}
        tooltipText="Total number for New Services offered Today or this Month"
      />
      <DashboardCard
        title="Inhouse Labs"
        loading={loading}
        totalNumber={TotalLabsAndImages?.today_inhouse_labs_count}
        monthlyData={TotalLabsAndImages?.monthly_inhouse_labs_count}
        onChange={handleCardChange}
        tooltipText="Total number for In-house Labs ordered Today or this Month"
      />
      <DashboardCard
        title="External Labs"
        loading={loading}
        totalNumber={TotalLabsAndImages?.today_external_labs_count}
        monthlyData={TotalLabsAndImages?.monthly_external_labs_count}
        onChange={handleCardChange}
        tooltipText="Total number for External Labs ordered Today or this Month"
      />
      <DashboardCard
        title="Products"
        loading={loading}
        totalNumber={TotalProductInvoice?.today_invoices_count}
        monthlyData={TotalProductInvoice?.monthly_invoices_count}
        onChange={handleCardChange}
        tooltipText="Total number of product invoices by registered patients"
      />
    </Row>
  );
};

export default DashboardCards;
