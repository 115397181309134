import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Loader from "../../../../components/Loader/Loader";

const TotalPatientCharts = ({ data, loading }) => {
  const [chartSeries, setChartSeries] = useState([]);
  const [maxYAxis, setMaxYAxis] = useState(0);
  const [chartCategories, setChartCategories] = useState([]);

  useEffect(() => {
    if (data) {
      const seriesData = Object.values(data);
      const maxValue = Math.max(...seriesData) + 20;

      setMaxYAxis(maxValue);
      setChartSeries([{ data: seriesData }]);

      const categories = Object.keys(data).map((date) => {
        // Extract year and month
        const [year, month] = date.split("-");
        return `${month.substring(0, 3)} ${year}`; 
      });
      setChartCategories(categories);
    }
  }, [data]);

  const options = {
    chart: {
    
      type: "bar",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartCategories,
    },
    yaxis: {
      title: {
        text: "Patients",
      },
      max: maxYAxis,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },

    },
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ReactApexChart options={options} series={chartSeries} type="bar" height={320} />
  );
};

export default TotalPatientCharts;