import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InsuranceForm from './component/InsuranceForm';
import { useStateGlobalContext } from '../../../../../../contexts/GlobalContext';
import Loader from '../../../../../../components/Loader/Loader'; // Import your loader component

function SecondaryInsurance() {
  const { id } = useParams();
  const { insuranceData, getInsuranceData } = useStateGlobalContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getInsuranceData(id, 'secondary');
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <InsuranceForm
      insuranceType="secondary"
      id={id}
      insuranceData ={insuranceData}
      getInsuranceData={getInsuranceData}
    />
  );
}

export default SecondaryInsurance;
