import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Checkbox,
  Button,
  Tooltip,
  Form,
  Input,
  Row,
  Col,
  Select,
} from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { doGet, doPost, doPatch } from "../../../../API/apis";
import FacilityCode from "./Datasets/FacilityCode";
import Loader from "../../../../components/Loader/Loader";
import Notification from "../../../../components/Notification/Notification";

const { Option } = Select;

document.title = "Billing Information | American EMR";

function BillingInformation() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(false);
  const [billingData, setBillingData] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(FacilityCode);

  const handleCancel = () => {
    getBillingInformation();
    setModified(false);
  };

  const onFinish = async (values) => {
    const {
      facility_code,
      facility_code_visible,
      facility_registration_number,
      facility_registration_num_visible,
      billing_provider_npi,
      billing_provider_npi_visible,
      tax_id,
      tax_id_visible,
      taxonomy,
      taxonomy_visible,
      clia_number,
      clia_number_visible,
      additional_billing_data_1,
      additional_billing_data_1_visible,
      additional_billing_data_2,
      additional_billing_data_2_visible,
      additional_billing_data_3,
      additional_billing_data_3_visible,
    } = values;
    const data = {
      facilty_code: [facility_code, facility_code_visible || false],
      facilty_registration_number: [
        facility_registration_number,
        facility_registration_num_visible || false,
        billing_provider_npi,
        billing_provider_npi_visible || false,
      ],
      tax_id: [tax_id, tax_id_visible],
      taxonomy: [taxonomy, taxonomy_visible || false],
      clia_number: [clia_number, clia_number_visible || false],
      additional_billing_data_1: [
        additional_billing_data_1,
        additional_billing_data_1_visible || false,
      ],
      additional_billing_data_2: [
        additional_billing_data_2,
        additional_billing_data_2_visible || false,
      ],
      additional_billing_data_3: [
        additional_billing_data_3,
        additional_billing_data_3_visible || false,
      ],
    };

    try {
      const billingInfo = billingData;
      const endpoint = billingInfo
        ? `/user/office-settings/?q=billing/&update=true`
        : `/user/office-settings/?q=billing/`;
      const response = await (billingInfo ? doPatch : doPost)(endpoint, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = modified
          ? "Billing Information Updated Successfull"
          : "Billing Information Saved Successfully";
        Notification.success(successMessage);
        setModified(false);
        getBillingInformation();
      }
    } catch (err) {
      Notification.error("Something went wrong");
      setLoading(false);
    }
  };

  const getBillingInformation = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/user/office-settings/?q=billing/`);
      if (response.status === 200) {
        const {
          facilty_code,
          facilty_registration_number,
          tax_id,
          taxonomy,
          clia_number,
          additional_billing_data_1,
          additional_billing_data_2,
          additional_billing_data_3,
        } = response?.data;
        setBillingData(response?.data);
        form.setFieldsValue({
          facility_code: facilty_code[0],
          facility_code_visible: facilty_code[1],
          facility_registration_number: facilty_registration_number[0],
          facility_registration_num_visible: facilty_registration_number[1],
          billing_provider_npi: facilty_registration_number[2],
          billing_provider_npi_visible: facilty_registration_number[3],
          tax_id: tax_id[0],
          tax_id_visible: tax_id[1],
          taxonomy: taxonomy[0],
          taxonomy_visible: taxonomy[1],
          clia_number: clia_number[0],
          clia_number_visible: clia_number[1],
          additional_billing_data_1: additional_billing_data_1[0],
          additional_billing_data_1_visible: additional_billing_data_1[1],
          additional_billing_data_2: additional_billing_data_2[0],
          additional_billing_data_2_visible: additional_billing_data_2[1],
          additional_billing_data_3: additional_billing_data_3[0],
          additional_billing_data_3_visible: additional_billing_data_3[1],
        });
      }
    } catch (error) {
      console.log("error", error);
      Notification.error("Something went wrong while fetching");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useCallback((searchText) => {
    const filteredOptions = FacilityCode.filter(
      (option) =>
        option?.service_name
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        option?.description?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  }, []);

  useEffect(() => {
    getBillingInformation();
  }, []);

  const handleFormChange = useCallback(() => {
    setModified(true);
  }, []);

  const memoizedFilteredOptions = useMemo(
    () => filteredOptions,
    [filteredOptions]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form
        layout="vertical"
        style={{ maxWidth: 550 }}
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="facility_code" label="Facility Code / POS">
              <Select
                placeholder="Select a facility"
                showSearch
                onSearch={handleSearch}
                filterOption={false}
              >
                {memoizedFilteredOptions?.map((option) => (
                  <Option key={option.code} value={option.code}>
                    <span title={option.description}>{option.code} - {option.service_name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <p className="text-[#1890ff] flex items-center align-middle ml-[-3rem]">
              Visible
              <Tooltip title="The checked values will be printed on superbill, prescriptions and lab orders">
                <QuestionCircleFilled className="ml-2 text-[#1890ff]" />
              </Tooltip>
            </p>
            <Form.Item name="facility_code_visible" valuePropName="checked">
              <Checkbox className="mt-3" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="facility_registration_number"
              label="Facility Registration Number"
            >
              <Input
                size="medium"
                placeholder="Enter Facility Registration number"
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="facility_registration_num_visible"
              valuePropName="checked"
            >
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="billing_provider_npi"
              label="Office NPI (Billing Provider)"
            >
              <Input
                size="medium"
                placeholder="Enter Billing Provider NPI"
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="billing_provider_npi_visible"
              valuePropName="checked"
            >
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="clia_number" label="CLIA Number">
              <Input
                size="medium"
                placeholder="Enter CLIA Number"
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="clia_number_visible" valuePropName="checked">
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="tax_id" label="Tax ID">
              <Input size="medium" placeholder="Enter Tax ID" maxLength={30} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tax_id_visible" valuePropName="checked">
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="taxonomy" label="Taxonomy">
              <Input
                size="medium"
                placeholder="Enter Taxonomy"
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="taxonomy_visible" valuePropName="checked">
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="additional_billing_data_1"
              label="Additional Billing Data 1"
            >
              <Input
                placeholder="Enter Additional Billing Data 1"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="additional_billing_data_1_visible"
              valuePropName="checked"
            >
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="additional_billing_data_2"
              label="Additional Billing Data 2"
            >
              <Input
                placeholder="Enter Additional Billing Data 2"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="additional_billing_data_2_visible"
              valuePropName="checked"
            >
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="additional_billing_data_3"
              label="Additional Billing Data 3"
            >
              <Input
                placeholder="Enter Additional Billing Data 3"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="additional_billing_data_3_visible"
              valuePropName="checked"
            >
              <Checkbox className="mt-9" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>

        <div className="mb-10">
          <Button className="mr-2" onClick={handleCancel}>
            {modified ? "Cancel Changes" : "Cancel"}
          </Button>
          <Button type="primary" htmlType="submit">
            {modified ? "Save Changes" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default BillingInformation;
