import React from "react";
import {  Menu, Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";

const { Content } = Layout;

const items = [
  { label: "Templates", key: "" },
  { label: "Referring Providers", key: "referring-providers" },
];

document.title = "Templates | American EMR";

const Templates =()=> {
  
  const navigate = useNavigate();
  const { officeSetting } = useStateGlobalContext()


  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 


  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Menu
        onClick={({ key }) => navigate(key)}
        mode="horizontal"
        defaultSelectedKeys={[""]}
        items={items}
        className="summary-menu mb-4 bg-white text-[16px] font-medium tracking-[0.2px]"
      />
      <Content
        className="layout"
        style={{
          backgroundColor: "white",
        }}
      >
        <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
      </Content>
    </Layout>
  );
}

export default Templates;
 