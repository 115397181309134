import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal,  Spin, Row, Col } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { doPost, doGet, doPatch } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { phoneValidator, emailValidator } from "../../../../../utils/validator";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const { TextArea } = Input;

const PrefferedPharmacy = () =>{
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editPharmcy, setEditPharmacy] = useState(null);

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  //edit preffered Pharmacy
  const handleEdit = (data) => () => {
    setEditPharmacy(data[0]?.id);
    form.setFieldsValue({
      pharmacy_name: data[0]?.name,
      address: data[0]?.address,
      phone_number: data[0]?.phone,
      fax_number: data[0]?.fax,
      email: data[0]?.email,
      preffered_pharmacy_notes: data[0].pref_pharma_notes,
    });
    setVisible(true);
  };

  //post preferred pharmacy
  const handlePreferredPharmacy = async (values) => {
    const data = {
      name: values.pharmacy_name,
      address: values.address,
      phone: values.phone_number,
      fax: values.fax_number,
      email: values.email,
      pref_pharma_notes: values.preffered_pharmacy_notes,
    };
    try {
      const response = editPharmcy
        ? await doPatch(
            `/patient/update-prefered-pharmacies/${id}/${editPharmcy}/`,
            data
          )
        : await doPost(`/patient/add-prefered-pharmacies/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        editPharmcy
          ? Notification.success("Preffered Pharmacy Updated Successfully")
          : Notification.success("Pharmacy Added Successfully");
        setVisible(false);
        form.resetFields();
        getPreferredPharmacy();
      }
    } catch (error) {
      setloading(false);
    }
  };

  //get preferred pharmacy
  const getPreferredPharmacy = async () => {
    setloading(true);
    try {
      const response = await doGet(`/patient/get-prefered-pharmacies/${id}/`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getPreferredPharmacy();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Preferred Pharmacy" />
          {data.length === 0 ? (
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={openModal}
            >
              Add <PlusOutlined />
            </Button>
          ) : (
            <Button
              size="small"
              type="default"
              shape="round"
              onClick={handleEdit(data)}
            >
              Edit <EditOutlined />
            </Button>
          )}
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-[130px]">
            <Spin />
          </div>
        ) : (
          <div className="flex flex-col max-h-[130px]  overflow-auto mt-1">
            <span>
              <span className="font-medium">Pharmacy Name: </span>
              {data[0]?.name}
            </span>
            <span>
              <span className="font-medium">Address: </span> {data[0]?.address}
            </span>
            <span>
              <span className="font-medium">Phone: </span> {data[0]?.phone}
            </span>
            <span>
              <span className="font-medium">Fax Number: </span> {data[0]?.fax}
            </span>
            <span>
              <span className="font-medium">Email: </span>
              {data[0]?.email}
            </span>

            <span>
              <span className="font-medium">Notes: </span>
              {data[0]?.pref_pharma_notes}
            </span>

            <span>
              <span className="font-medium">Added By: </span>
              {data[0]?.added_by}
            </span>
            <span>
              <span className="font-medium">Date: </span>
              {data && <FormatDateTime type="datetime" date={data[0]?.date_time}/>}
              <span>
              </span>
            </span>
          </div>
        )}
      </div>

      <Modal
        title={editPharmcy ? "Update Pharmacy Details" : "Add Pharmacy Details"}
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        footer={null}
      >
        <Form
          layout="vertical"
          name="preffered-pharmacy"
          form={form}
          onFinish={handlePreferredPharmacy}
        >
          <Form.Item
            name="pharmacy_name"
            label="Pharmacy Name"
            rules={[
              {
                required: true,
                message: "Please Enter Pharmacy Name!",
              },
            ]}
          >
            <Input placeholder="Pharmacy Name" size="medium" maxLength={200} />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <TextArea
              placeholder="Address"
              rows={3}
              maxLength={300}
              showCount
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[
                  {
                    validator: phoneValidator,
                  },
                ]}
              >
                <Input
                  placeholder="Phone Number"
                  size="medium"
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="fax_number" label="Fax Number">
                <Input placeholder="Fax Number" size="medium" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                validator: emailValidator,
              },
            ]}
          >
            <Input placeholder="Email" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item name="preffered_pharmacy_notes" label="Notes">
            <TextArea placeholder="Notes" rows={3} maxLength={300} showCount />
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              {editPharmcy ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default PrefferedPharmacy;
