import React from "react";
import {
  UserOutlined,
  DollarOutlined,
  PhoneOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "../ProfileCard/profilecard.css";

const navItems = [
  {
    id: 1,
    name: "Basic Information",
    icon: <UserOutlined />,
    color: "primary",
    href: "basic-information",
  },
  {
    id: 2,
    name: "Contact Information",
    icon: <PhoneOutlined />,
    color: "success",
    href: "contact-information",
  },
  {
    id: 3,
    name: "Emergency Contact",
    icon: <PhoneOutlined />,
    color: "error",
    href: "emergency-contact",
  },
  {
    id: 4,
    name: "Address",
    icon: < PhoneOutlined/>,
    color: "warning",
    href: "address",
  },
  {
    id: 5,
    name: "Demographics",
    icon: <UserOutlined/>,
    color: "warning",
    href: "demographics",
  },
  {
    id: 6,
    name: "Referral",
    icon: <UserOutlined/>,
    color: "warning",
    href: "referral",
  },
  {
    id: 7,
    name: "Identification Cards",
    icon: <IdcardOutlined/>,
    color: "warning",
    href: "identification-cards",
  },
  {
    id: 8,
    name: "Profile and ID Picture",
    icon: <UserOutlined />,
    color: "warning",
    href: "upload-pictures",
  },
  {
    id: 9,
    name: "Payment Services",
    icon: <DollarOutlined />,
    color: "warning",
    href: "payment-services",
  },
];

const ProfileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="profile-nav-wrapper">
      {navItems.map((item) => (
        <Button
          className={`flex items-center mb-4 profile-nav-item ${
            location.pathname.includes(`/profile/${item.href}`) ? "active" : ""
          }`}
          key={item.id}
          type="text"
          onClick={() => navigate(item.href)}
        >
          {item.icon}
          <span className="nav-text">{item.name}</span>
        </Button>
      ))}
    </div>
  );
};

export default ProfileNav;
