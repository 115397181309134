import React, { useState, useEffect } from "react";
import {  formattedTime } from "../../utils/DateFormat";
import { useStateGlobalContext } from "../../contexts/GlobalContext";

export const DateComponent = ({  timezone, header }) => {
  const currentDate = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: timezone || 'UTC' };
  const formattedDate = currentDate && currentDate?.toLocaleString('en-US', options);

  return (
    <>
      <span className={header ? "font-bold text-xl" : "font-semibold"} >{formattedDate}</span>
    </>
  );
};



export const TimeComponent = ({timeFormat, timezone}) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <span>{formattedTime(currentDateTime, timeFormat, timezone)}</span>;
};

const TimeClock = () => {
  const { officeSetting, getOfficeSettings } = useStateGlobalContext();
  const officeDateFormat = officeSetting && officeSetting?.date_format || 'MM/DD/YYYY'
  const officeTimeFormat = officeSetting && officeSetting?.time_format || 'h12'
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 

  useEffect(()=>{
    getOfficeSettings()
  },[])
  return (
    <div className="flex justify-between bg-gray-300 p-3 rounded-xl">
      <div>
        <p className="font-semibold text-lg">Current Time: <TimeComponent timeFormat={officeTimeFormat} timezone = {officeTimezone}/></p>
      </div>
      <div>
        <p className="font-semibold text-lg">Today's Date: <DateComponent dateFormat={officeDateFormat} timezone={officeTimezone}/></p>
      </div>
    </div>
  );
};

export default TimeClock;
