import { Button } from 'antd'
import React from 'react'

const BasicButton = ({loading, saving, title, type="primary", className="ml-2"}) => {
    const buttonText = loading ? (saving ? "Saving..." : "Updating...") : title;
  return (
    <Button loading={loading} htmlType="submit" type={type} className={className}>
        {buttonText}
    </Button>
  )
}

export default BasicButton
