import React from 'react'
import {Tabs} from 'antd'
import Credentials from "./components/Credentials";
import DoctorComments from "./components/DoctorComments";

const items = [
  { label: "Provider  Comments", key: "1", children: <DoctorComments/> },
  { label: "Credentials", key: "2", children: <Credentials/> },
];


const PatientPortal = () => {
  return (
    <Tabs
    type='card'
     items={items}
    />
  )
}

export default PatientPortal