import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Divider } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";

const Referral = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false)
  const { patientBasicInfo, getPatientData, loading } = useStateGlobalContext();

  const {
    referal_full_name,
    referal_email,
    referal_phone,
    referal_speciality,
    referal_fax,
    referal_organization_name,
    referal_group_name,
    wdyhau,
    referal_notes,
  } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {

        form.setFieldsValue({
          referal_full_name,
          referal_email,
          referal_phone,
          referal_speciality,
          referal_fax,
          referal_organization_name,
          referal_group_name,
          wdyhau,
          referal_notes,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = ()=>{
    setEdit(true)
  }

  const onEditCancel = ()=>{
    setEdit(false)
  }

  const handleUpdate = async (values)=>{
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id); 
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  }


  const renderFormItem = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={12}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  const renderFormItem1 = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={8}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
      <h1 className="text-[1.125rem] font-bold mb-4">Referral Information</h1>
      {edit ? <Button onClick={onEditCancel}>Close</Button> : <Button onClick={onEdit}>Edit</Button> }
      </div>

      { edit ? (
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem(
            "Full Name",
            "referal_full_name",
            <Input placeholder="Full Name" />
          )}
          {renderFormItem(
            "Specialty",
            "referal_speciality",
            <Input placeholder="specialty" />
          )}
        </Row>

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem1(
            "Phone",
            "referal_phone",
            <Input placeholder="Phone" />
          )}
          {renderFormItem1(
            "Email",
            "referal_email",
            <Input placeholder="Email" />
          )}
          {renderFormItem1("Fax", "doctor_fax", <Input placeholder="Fax" />)}
        </Row>

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          {renderFormItem1(
            "Organization",
            "referal_organization_name",
            <Input placeholder="Organization" />
          )}
          {renderFormItem1("Group", "referal_group_name", <Input placeholder="Group" />)}
          {renderFormItem1(
            "Where did you hear about us?",
            "wdyhau",
            <Input placeholder="Where did you hear about us?" />
          )}
        </Row>

        <Form.Item label="Notes" name="referal_notes">
          <Input.TextArea placeholder="Notes..." maxLength={300} showCount/>
        </Form.Item>

        <div className="flex justify-end">
          <Button onClick={onEditCancel}>Cancel Changes</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Save Changes
          </Button>
        </div>
      </Form>
      ) : (
      <div className="view-wrapper">
          <p>Full Name: {referal_full_name}</p>
          <p>Specialty: {referal_speciality}</p>
          <p>Phone: {referal_phone}</p>
          <p>Email: {referal_email}</p>
          <p>Fax: {referal_fax}</p>

          <Divider/>
          
          <p>Organization: {referal_organization_name}</p>
          <p>Group: {referal_group_name}</p>
          <p>Where did you hear about us?: {wdyhau}</p>
          <p className="notes">Notes: {referal_notes}</p>
      </div>
      )}
    </div>
  );
};

export default Referral;
