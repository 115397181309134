import React, { useState } from "react";
import { Button, Form, Input, Col, Row} from "antd";
import { doPost } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { validateDecimalNumber } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";

function BMICalculator({ onSave, onCancel, metric }) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [bmi, setBMI] = useState(null);
  const [isBMICalculated, setIsBMICalculated] = useState(false);

 

  const handleCancel = ()=>{
    onCancel()
    form.resetFields()
    setBMI(null)
  }


  const calculateBMI = () => {
    const height = form.getFieldValue("height");
    const weight = form.getFieldValue("weight");
    let calc_bmi;
    if (metric) {
      calc_bmi = weight / ((height / 100) * (height / 100));
    } else {
      calc_bmi = (weight / (height * height)) * 703;
    }
    const calc_bmi_upto_two_decimal = calc_bmi.toFixed(2);
    setBMI(calc_bmi_upto_two_decimal);
    form.setFieldsValue({ calc_bmi_upto_two_decimal }); // Set the calculated BMI value in the form field
    setIsBMICalculated(true);
  };

  //Add BMI
  const handleBMISave = async (values) => {
    const { height, weight } = values;
    const data = {
      BMI_height: height,
      BMI_weight: weight,
      BMI: bmi,
    };
    try {
      const response = await doPost(
        `/patient/add-bmi/${id}/`,
        data,
      );
      if (response.status === 201) {
        Notification.success("BMI Data Added Successfully");
        onSave();
        form.resetFields();
        setBMI(null);
      } 
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleBMISave}>
        <Row gutter={[16,16]}>
          <Col span={12}>
            <Form.Item
              name="height"
              label={`${metric ? "Height (cm)" : "Height (in)"}`}
              rules={[
                { required: true, message: "Please Enter height!" },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Height" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              label={`${metric ? "Weight (kg)" : "Weight (lb)"}`}
              rules={[
                { required: true, message: "Please Enter weight!" },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Weight" />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex items-center">
          <Button type="primary" onClick={calculateBMI}>
            Calculate
          </Button>
          <p className="ml-2 font-semibold">
            {bmi !== null && isNaN(bmi) === false ? `BMI : ${bmi}` : null}
          </p>
        </div>
        <div className="flex justify-end">
          <Button onClick={handleCancel}> Cancel </Button>
          <Button
            type={!bmi ? "default" : "primary"}
            htmlType="submit"
            className="ml-2"
            disabled={bmi === 0 || isNaN(bmi) || isBMICalculated === false}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default BMICalculator;
