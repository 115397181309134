import React, { useState } from "react";
import { Select } from "antd";
import currencies from "../Datasets/Currencies";

const { Option } = Select;

const CurrencySelector = ({ onChange, value }) => {
  const [options, setOptions] = useState(currencies);

  const handleSearch = (value) => {
    const filteredOptions = currencies.filter(
      (currency) =>
        currency?.currency.toLowerCase().includes(value.toLowerCase()) ||
        currency?.alphabeticCode.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  return (
    <Select
      showSearch
      placeholder="Search Currency"
      onSearch={handleSearch}
      onChange={onChange}
      value={value}
      filterOption={false}
    >
      {options.map((currency) => (
        <Option key={currency.alphabeticCode} value={currency.alphabeticCode}>
          {currency.currency} - {currency.alphabeticCode}
        </Option>
      ))}
    </Select>
  );
};

export default CurrencySelector;
