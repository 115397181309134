import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Row, Col } from "antd";
import dayjs from "dayjs";
import BaseDatePicker from "../../../../../../../components/BaseDatePicker/BaseDatePicker";
import Loader from "../../../../../../../components/Loader/Loader";
import InsuranceView from "./InsuranceView";
import {
  emailValidator,
  faxValidator,
  phoneValidation,
  validatePercentage,
  validatePrice,
} from "../../../../../../../utils/validator";
import { doPatch, doPost } from "../../../../../../../API/apis";
import Notification from "../../../../../../../components/Notification/Notification";

function InsuranceForm({ id,insuranceData, insuranceType, getInsuranceData }) {
  // const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModified, setIsModified] = useState(false);
  // const { insuranceData, getInsuranceData } = useStateGlobalContext();

  const onEdit = () => {
    setEdit(true);
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const handleFormChange = () => {
    setIsModified(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInsuranceSubmit = async (values) => {
    const data = {
      insurance_type: insuranceType,
      insurance_company: {
        insurance_company_name: values.insurance_company_name,
        insurance_company_address: values.insurance_company_address,
        insurance_company_email: values.insurance_company_email,
        insurance_company_phone: values.insurance_company_phone,
        insurance_company_fax: values.insurance_company_fax,
        insurance_company_notes: values.insurance_company_notes,
      },
      insured_person_info: {
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        insured_id: values.insured_id,
        insured_group: values.insured_group,
        insurance_plan: values.insurance_plan,
        insurance_policy: values.insurance_policy,
        relationship: values.relationship,
        insurer_notes: values.insurer_notes,
        effective_date: values.effective_date,
        expiration_date: values.expiration_date,
      },
      payment_info: {
        copay_amount: values.copay_amount,
        copay_percentage: values.copay_percentage,
        deductible_amount: values.deductible_amount,
        deductible_percentage: values.deductible_percentage,
        prior_authorization_number: values.prior_authorization_number,
        payment_info_notes: values.payment_info_notes,
      },
    };
    try {
      const insurance_id = insuranceData[0]?.id;
      const endpoint = insurance_id
        ? `/invoice/insurance-update/${id}/${insurance_id}/`
        : `/invoice/insurance-add/${id}/`;
      const response = await (insurance_id ? doPatch : doPost)(endpoint, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = isModified
          ? `${
              insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1)
            } Insurance Updated Successfully`
          : `${
              insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1)
            } Insurance Information Saved Successfully`;
        setEdit(false);
        Notification.success(successMessage);
        setIsModified(false);
        getInsuranceData(id, insuranceType);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  const handleCancel = () => {
    getInsuranceData(id, insuranceType);
  };

  useEffect(() => {
    getInsuranceData(id, insuranceType);
  }, [id, insuranceType]);

  useEffect(() => {
    if (insuranceData?.length > 0) {
      form.setFieldsValue({
        insurance_company_name:
          insuranceData[0]?.insurance_company?.insurance_company_name,
        insurance_company_address:
          insuranceData[0]?.insurance_company?.insurance_company_address,
        insurance_company_email:
          insuranceData[0]?.insurance_company?.insurance_company_email,
        insurance_company_phone:
          insuranceData[0]?.insurance_company?.insurance_company_phone,
        insurance_company_fax:
          insuranceData[0]?.insurance_company?.insurance_company_fax,
        insurance_company_notes:
          insuranceData[0]?.insurance_company?.insurance_company_notes,

        first_name: insuranceData[0]?.insured_person_info?.first_name,
        middle_name: insuranceData[0]?.insured_person_info?.middle_name,
        last_name: insuranceData[0]?.insured_person_info?.last_name,
        insured_id: insuranceData[0]?.insured_person_info?.insured_id,
        insured_group: insuranceData[0]?.insured_person_info?.insured_group,
        insurance_plan: insuranceData[0]?.insured_person_info?.insurance_plan,
        insurance_policy:
          insuranceData[0]?.insured_person_info?.insurance_policy,
        relationship: insuranceData[0]?.insured_person_info?.relationship,
        insurer_notes: insuranceData[0]?.insured_person_info?.insurer_notes,
        effective_date: insuranceData[0]?.insured_person_info?.effective_date
          ? dayjs(insuranceData[0]?.insured_person_info?.effective_date)
          : "",
        expiration_date: insuranceData[0]?.insured_person_info?.expiration_date
          ? dayjs(insuranceData[0]?.insured_person_info?.expiration_date)
          : "",

        copay_amount: insuranceData[0]?.payment_info?.copay_amount,
        copay_percentage: insuranceData[0]?.payment_info?.copay_percentage,
        deductible_amount: insuranceData[0]?.payment_info?.deductible_amount,
        deductible_percentage:
          insuranceData[0]?.payment_info?.deductible_percentage,
        prior_authorization_number:
          insuranceData[0]?.payment_info?.prior_authorization_number,
        payment_info_notes: insuranceData[0]?.payment_info?.payment_info_notes,
      });
    }
  }, [insuranceData]);

  const isExpirationDatePast = (expirationDate) => {
    return dayjs().isAfter(dayjs(expirationDate));
  };

  const renderFormItem = (label, name, inputComponent, isRequired) => (
    <Col span={6} xs={24} md={12} lg={6}>
      <Form.Item
        label={label}
        name={name}
        rules={
          isRequired
            ? [{ required: true, message: `Please enter ${label}` }]
            : []
        }
      >
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (loading) return <Loader />;

  return (
    <Card>
      <div className="flex justify-between">
        <h1 className="text-[1.125rem] font-bold mb-4">
          {insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1)}{" "}
          Insurance
        </h1>
        {edit ? (
          <Button onClick={onEditCancel}>Close</Button>
        ) : (
          <Button onClick={onEdit}>Edit</Button>
        )}
      </div>
      {edit ? (
        <Form
          layout="vertical"
          form={form}
          onFinish={handleInsuranceSubmit}
          onFieldsChange={handleFormChange}
          className="w-full"
        >
          <Row gutter={[16, 16]}>
            <Col xl={24} md={24} sm={24}>
              <h1 className="font-medium">
                Insurance Company{" "}
                {insuranceData?.length > 0 &&
                  isExpirationDatePast(
                    insuranceData[0]?.insured_person_info?.expiration_date
                  ) && (
                    <span className="text-red-500">(Insurance is expired)</span>
                  )}
              </h1>
              <Row gutter={[16, 16]}>
                <Col xl={6} md={12} sm={24}>
                  <Form.Item
                    name="insurance_company_name"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Company Name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Insurance Company Name"
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>

                <Col xl={6} md={12} sm={24}>
                  <Form.Item
                    name="insurance_company_email"
                    label="Email"
                    rules={[
                      {
                        validator: emailValidator,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Insurance Company Email"
                      maxLength={200}
                    />
                  </Form.Item>
                </Col>

                <Col xl={6} md={12} sm={24}>
                  <Form.Item
                    name="insurance_company_phone"
                    label="Phone"
                    rules={[
                      {
                        validator: phoneValidation,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Insurance Company Phone Number"
                      maxLength={15}
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} md={12} sm={24}>
                  <Form.Item
                    name="insurance_company_fax"
                    label="Fax"
                    rules={[
                      {
                        validator: faxValidator,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Insurance Company Fax"
                      maxLength={20}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xl={12} md={12} sm={24}>
                  <Form.Item name="insurance_company_address" label="Address">
                    <Input.TextArea
                      placeholder="Enter Insurance Company Address"
                      maxLength={300}
                      showCount
                    />
                  </Form.Item>
                </Col>

                <Col xl={12} md={12} sm={24}>
                  <Form.Item name="insurance_company_notes" label="Notes">
                    <Input.TextArea
                      placeholder="Enter Notes"
                      maxLength={300}
                      showCount
                    />
                  </Form.Item>
                </Col>
              </Row>

              <h1 className="font-medium">Primary Account Holder</h1>
              <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
                {renderFormItem(
                  "First Name",
                  "first_name",
                  <Input placeholder="First Name" />,
                  true
                )}
                {renderFormItem(
                  "Middle Name",
                  "middle_name",
                  <Input placeholder="Middle Name" />
                )}
                {renderFormItem(
                  "Last Name",
                  "last_name",
                  <Input placeholder="Last Name" />,
                  true
                )}
                <Col span={6} xs={24} md={12} lg={6}>
                  <Form.Item name="relationship" label="Relationship">
                    <Input
                      placeholder="Relationship with Patient"
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
                {renderFormItem(
                  "Insured Member's ID",
                  "insured_id",
                  <Input placeholder="Insured Member's ID" maxLength={30} />,
                  true
                )}
                <Col span={6} xs={24} md={12} lg={6}>
                  <Form.Item
                    name="insurance_policy"
                    label="Insurance Policy #"
                  >
                    <Input placeholder="Insurance Policy" maxLength={30} />
                  </Form.Item>
                </Col>
                {renderFormItem(
                  "Insured Group",
                  "insured_group",
                  <Input placeholder="Insured Group" maxLength={30} />
                )}
                {renderFormItem(
                  "Insurance Plan",
                  "insurance_plan",
                  <Input placeholder="Insurance Plan" maxLength={30} />
                )}
              </Row>


              <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item name="insurer_notes" label="Notes">
                    <Input.TextArea
                      placeholder="Enter Notes"
                      maxLength={300}
                      showCount
                    />
                  </Form.Item>
                </Col>
                <Col>
                <Col xs={24} sm={24} md={12} lg={24}>
                <div className="flex">
                <Form.Item name="effective_date" label="Effective Date">
                  <BaseDatePicker onChange={handleDateChange} />
                </Form.Item>
                <Form.Item
                  name="expiration_date"
                  className="ml-4"
                  label="Expiration Date"
                >
                  <BaseDatePicker onChange={handleDateChange} />
                </Form.Item>
                </div>
                </Col>
              </Col>
              </Row>
            </Col>
          </Row>

          <h1 className="font-medium">Payment Info</h1>
          <Row gutter={[16, 16]}>
            <Col xl={6} md={12} sm={12}>
              <Form.Item
                name="copay_amount"
                label="Copay Amount"
                rules={[
                  {
                    validator: validatePrice,
                  },
                ]}
              >
                <Input placeholder="Enter Copay Amount" maxLength={10} />
              </Form.Item>
            </Col>

            <Col xl={6} md={12} sm={12}>
              <Form.Item
                name="copay_percentage"
                label="Copay %"
                rules={[
                  {
                    validator: validatePercentage,
                  },
                ]}
              >
                <Input placeholder="Enter Copay Percentage" maxLength={10} />
              </Form.Item>
            </Col>
            <Col xl={6} md={12} sm={12}>
              <Form.Item
                name="deductible_amount"
                label="Total Deductible"
                rules={[
                  {
                    validator: validatePrice,
                  },
                ]}
              >
                <Input placeholder="Enter deductible Amount" maxLength={10} />
              </Form.Item>
            </Col>

            <Col xl={6} md={12} sm={12}>
              <Form.Item
                name="deductible_percentage"
                label="Deductible Amount Met"
              >
                <Input
                  placeholder="Enter Deductible Amount Met"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12} xs={12} md={12} lg={12}>
              <Form.Item name="payment_info_notes" label="Notes">
                <Input.TextArea
                  placeholder="Enter Notes"
                  maxLength={300}
                  showCount
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={12} md={12} lg={12}>
              <Form.Item
                name="prior_authorization_number"
                label="Prior Authorization Number"
              >
                <Input
                  placeholder="Enter Prior Authorizaton Number"
                  maxLength={30}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex justify-end mt-4">
            <Button onClick={handleCancel}>
              {isModified ? "Cancel Changes" : "Cancel"}
            </Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              {isModified ? "Save Changes" : "Save"}
            </Button>
          </div>
        </Form>
      ) : (
        <InsuranceView data={insuranceData} />
      )}
    </Card>
  );
}

export default InsuranceForm;
