import {
  Button,
  Drawer,
  Form,
  Input,
  Row,
  Col,
  Table,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { doPost, doGet, doPatch, doDelete } from "../../../API/apis";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { formatDate } from "../../../utils/DateFormat";
import { PriceFormatter, validateDecimalNumber, validateNumber } from "../../../utils/validator";
import { NumberFormatter } from "../../../utils/validator";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import BaseDatePicker from "../../../components/BaseDatePicker/BaseDatePicker";
import { GetColumnSearchProps } from "../../../components/TableSearch/TableSearch";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import Notification from "../../../components/Notification/Notification";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../components/FormatDate/FormatDate";
import { getInventoryColumns } from "../../../components/TableColumns/TableColumns";



function InventoryList() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null);
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onCancel = () => {
    setVisible(false);
    setTimeout(() => {
      setEdit(false);
      form.resetFields();
    }, 200);
  };
  const showDrawer = () => setVisible(true);

  const handleEdit = (record) => {
    setEdit(true);
    setVisible(true);
    setSelectedItem(record);
    form.setFieldsValue({
      sku: record?.sku,
      medicine_name: record?.medicine_name,
      generic_name: record?.generic_name,
      category: record?.category,
      manufacturer: record?.manufacturer,
      shelf: record?.location,
      quantity: record?.quantity,
      expiry_date: record?.expiry_date && dayjs(record?.expiry_date),
      msrp: record?.MSRP,
      original_price: record?.sale_price,
      strength: record?.strength,
      notes: record?.notes,
    });
  };

  const showDeleteConfirm = (id) => {
   showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await doDelete(`/inventory/delete/${id}/`);
      if (response.status === 200) {
        Notification.success("Product Deleted Successfully");
        getProducts();
      }
    } catch (error) {
      console.error(error);
    } 
  };

  const handleInventory = async (values) => {
    const data = {
      sku: values.sku,
      medicine_name: values.medicine_name,
      generic_name: values.generic_name,
      category: values.category,
      manufacturer: values.manufacturer,
      location: values.shelf,
      quantity: values.quantity,
      expiry_date:  values.expiry_date && dayjs(values.expiry_date).format("YYYY-MM-DD"),
      MSRP: PriceFormatter(values.msrp),
      sale_price: PriceFormatter(values.original_price),
      strength: values.strength,
      notes: values.notes,
    };
    try {
      const response = edit
        ? await doPatch(`/inventory/update/${selectedItem?.id}/`, data)
        : await doPost(`/inventory/add/`, data);
      if (response.status === 201 || response.status === 200) {
        const successMessage = edit
          ? "Product Updated Successfully"
          : "Product Added Successfully";
        Notification.success(successMessage);
        getProducts();
        onCancel();
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  const getProducts = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/inventory/get/`);
      if (response.status === 200) {
        const sortedTasks = response.data.sort(
          (a, b) => new Date(b.date_time) - new Date(a.date_time)
        );
        setData(sortedTasks);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false)
    }
  };

  const columns = getInventoryColumns({
    role,
    handleEdit,
    showDeleteConfirm,
  });

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Card>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-lg font-bold">Inventory List</h1>
        <div>
        <Button className="mr-2">
          <CSVLink data={data} filename={"Inventory.csv"} className="csv-link">
            Download Report
          </CSVLink>
        </Button>
        <Button type="primary" onClick={showDrawer}>
          Add Product
        </Button>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1500,
        }}
        size="small"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          total: data?.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />

      <Drawer
        title={edit ? "Update Product" : "Add Product"}
        width={420}
        onClose={onCancel}
        open={visible}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="drawer-close-icon" shape="round">Close</Button>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleInventory}>
          <Form.Item name="sku" label="SKU">
            <Input placeholder="SKU" maxLength={10} />
          </Form.Item>
          <Form.Item
            name="medicine_name"
            label="Brand Name"
            rules={[{ required: true, message: "Please Enter Brand Name" }]}
          >
            <Input placeholder="Brand Name" maxLength={100} />
          </Form.Item>
          <Form.Item name="generic_name" label="Generic Name">
            <Input placeholder="Generic Name" maxLength={100} />
          </Form.Item>
          <Form.Item name="manufacturer" label="Manufacturer">
            <Input placeholder="Manufacturer" maxLength={50} />
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Input placeholder="Category" maxLength={30} />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="strength" label="Strength">
                <Input placeholder="Strength" maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="shelf" label="Location">
                <Input placeholder="Location" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[{ required: true, message: "Please Enter Quantity" }, 
                  {validator: validateNumber}
                ]}
              >
                <Input placeholder="Quantity" maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="expiry_date" label="Expiration Date">
                <BaseDatePicker
                  value={edit ? selectedItem?.implanted_date : selectedDate}
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="msrp"
                label="MSRP"
                rules={[
                  {
                    validator: validateDecimalNumber,
                  },
                ]}
              >
                <Input placeholder="MSRP" maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="original_price"
                label="Sale Price"
                rules={[
                  { required: true, message: "Please enter Price" },
                  {
                    validator: validateDecimalNumber,
                  },
                ]}
              >
                <Input placeholder="Sale Price" maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea placeholder="Notes" maxLength={300} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button className="mr-2" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {edit ? "Update" : "Add Product"}
            </Button>
          </div>
        </Form>
      </Drawer>
    </Card>
  );
}

export default InventoryList;
