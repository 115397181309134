import axios from "axios";
import React, {useState} from "react";
import backgroundImage from "../../../assets/login-bg-image.png";
import logo from "../../../assets/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, Spin } from "antd";
import { validatePassword } from "../../../utils/validator";
import Notification from "../../../components/Notification/Notification";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { username, token } = useParams();
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log(username, token, "HILLO")
    setLoading(true);
    const result = axios
      .post(`${process.env.REACT_APP_API_URL}/user/reset-password/confirm/${username}/${token}/`, values)
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          const token = res.data.access;
          localStorage.setItem("token", token);
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Notification.error(err.response.data.detail);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Side */}
      <div
        className="lg:w-1/2 bg-gray-200 flex items-center justify-center xs:hidden md:block"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute top-6 left-6">
          <img src={logo} alt="Logo" className="w-[200px]" />
        </div>
      </div>

      {/* Right Side */}
      <div className="lg:w-1/2 flex items-center justify-center">
        <Form
          layout="vertical"
          className="w-11/12 lg:w-1/2"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h1 className="text-3xl mb-3">Reset Password</h1>
          <Form.Item
            label="Password"
            name="new_password"
            rules={[
              { required: true, message: "Please input your password!" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password placeholder="Please enter your Password here" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            hasFeedback
            dependencies={["new_password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <Button
            size="large"
            className="bg-[#3484F0] text-[#fff] w-full mb-2"
            type="primary"
            htmlType="submit"
            disabled={loading}
          >
            {loading ? <Spin /> : "Reset Password"}
          </Button>
          <div className="flex justify-between">
            <p className="text-center">
              Don't have an account?{" "}
              <Link to="/signup" className="text-[#3484F0]">
                Sign up
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
