import React, { useEffect, useState } from "react";
import { Form, Button, message, Card, Select, Row, Col, Tag } from "antd";
import { doGet, doPatch } from "../../../../API/apis";
import { formatDate } from "../../../../utils/DateFormat";
import PaymentMethodTabs from "./PaymentMethodTabs";
import { QuestionCircleFilled } from "@ant-design/icons";
import HelpfullInfo from "./Datasets/HelpfullInfo";
import Notification from "../../../../components/Notification/Notification";

const paymentMethod = (method) => {
  if (method === "bank_transfer") {
    return "Bank Transfer";
  } else if (method === "credit_card") {
    return "Credit Card";
  } else if (method === "monthly_invoice") {
    return "Monthly Invoice";
  } else {
    return "No Payment Method Selected";
  }
};

document.title = "Account Payment Status | American EMR";

const AccountPayment = () => {
  const [form3] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const getPaymentStatus = async () => {
    setLoading(true);
    try {
      const response = await doGet("/user/list/status/");
      if (response.status === 200) {
        setPaymentDetails(response.data);
        form3.setFieldsValue({
          preferred_payment_method: response?.data?.payment_method,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePaymentPrefferedMethod = (value) => {
    setSelectedPaymentMethod(value);
  };

  const preferredPaymentMethod = async () => {
    const data = {
      payment_method: selectedPaymentMethod,
    };
    try {
      const response = await doPatch("/user/add/payment-method/", data);
      if (response.status === 200) {
        Notification.success("Payment Method Selected Successfully");
        getPaymentStatus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={12} lg={12}>
        <Card className="bg-white rounded-lg shadow-md" loading={loading}>
          <Card>
            <p className="text-lg font-semibold mb-4">Payment Information</p>
            <p className="mb-4">
              Your Current Balance is{" "}
              <span className="font-bold">
                ${paymentDetails && paymentDetails?.payment_due?.payment_due}{" "}
                USD
              </span>
              . Your Practice has{" "}
              <span className="font-bold">
                {paymentDetails &&
                  paymentDetails?.payment_due?.active_count + 1}{" "}
                users{" "}
                <QuestionCircleFilled
                  title="This is based on how many users they are adding: +5 for new and -5 for
        deleting user. The basic plan costs $45 plus $5 per user."
                  size="small"
                />
              </span>{" "}
            </p>
            <p className="mb-4">
              <span className="font-bold">Account Status:</span>{" "}
              {paymentDetails && paymentDetails?.status === "unpaid" ? (
                <Tag color="#9ca3af">Unpaid</Tag>
              ) : (
                <Tag color="#18900f">Paid</Tag>
              )}
            </p>
            <p className="mb-4">
              <span className="font-bold">Last Payment Date: </span>{" "}
              {paymentDetails?.date_time &&
                formatDate(paymentDetails?.date_time)}
            </p>
            <p className="mb-4">
              <span className="font-bold">Notes:</span>{" "}
              {paymentDetails && paymentDetails?.notes}
            </p>
            <p className="mb-4">
              <span className="font-bold">Payment Method: </span>{" "}
              {paymentMethod(paymentDetails?.payment_method)}
            </p>
            <Form
              layout="vertical"
              onFinish={preferredPaymentMethod}
              form={form3}
            >
              <Form.Item
                label="Preferred Payment Method"
                name="preferred_payment_method"
              >
                <Select
                  onChange={handleChangePaymentPrefferedMethod}
                  placeholder="Choose Preferred Payment Method"
                  style={{ maxWidth: "200px" }}
                >
                  <Select.Option value="credit_card">Credit Card</Select.Option>
                  <Select.Option value="bank_transfer">
                    Bank Transfer
                  </Select.Option>
                  <Select.Option value="monthly_invoice">
                    Monthly Invoice
                  </Select.Option>
                </Select>
              </Form.Item>

              <div className="mb-4">
                <Button type="primary" htmlType="submit">
                  Save Payment Type
                </Button>
              </div>
            </Form>
          </Card>

          <Card className="mt-4">
            <PaymentMethodTabs />
          </Card>
        </Card>
      </Col>

      <Col sm={24} md={12} lg={12}>
        <HelpfullInfo />
      </Col>
    </Row>
  );
};

export default AccountPayment;
