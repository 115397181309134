import React from "react";
import { NumberFormatter } from "../../../../../utils/validator";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function ViewCreditMemo({ creditMemoData }) {
  return (
    <div>
      <p>
        <span className="font-medium">Added By: </span>
        {creditMemoData?.added_by}
      </p>
      <p>
        <span className="font-medium">Date Added: </span>
        <FormatDateTime date={creditMemoData?.date_time} type="datetime"/>
      </p>

      <div className="mt-3 bg-slate-100 p-3">
        <p>
          <span className="font-medium">Invoice ID: </span>
          {creditMemoData?.invoice_id}
        </p>
        <p>
          <span className="font-medium">Description: </span>
          {creditMemoData?.description}
        </p>
        <p>
          <span className="font-medium">Credit Amount: </span>

          <NumberFormatter
            value={creditMemoData?.credit_amount}
            options={{ style: "decimal" }}
          />
        </p>
      </div>
      <p>
        <span className="font-medium">Status: </span>
        {creditMemoData?.status}
      </p>
      <p>
        <span className="font-medium">Redeemed By: </span>
        {creditMemoData?.redeemed_by}
      </p>
      <p>
        <span className="font-medium">Action Date: </span>
        {creditMemoData?.redeemed_by_datetime && <FormatDateTime date={creditMemoData?.redeemed_by_datetime} type="datetime"/>}
      </p>
    </div>
  );
}

export default ViewCreditMemo;
