import { useStateGlobalContext } from "../contexts/GlobalContext";

function Currency() {
  const { officeSetting } = useStateGlobalContext();
  let currency = officeSetting && officeSetting?.local_currency;

  return <>{currency}</>;
}

export default Currency;
