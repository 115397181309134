import React, { useState } from "react";
import { Form, Input, Button, Card, Table, Radio } from "antd";
import { doGet } from "../../../../../API/apis";
import { dateFormat, formatDate } from "../../../../../utils/DateFormat";
import { CSVLink } from "react-csv";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const { Group: RadioGroup } = Radio;

const columns = [
  {
    key: "1",
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    key: "patient_mrn",
    title: "MRN",
    dataIndex: "mrn_number",
  },
  {
    key: "2",
    title: "First Name",
    dataIndex: "first_name",
    ellipsis: true,
  },
  {
    key: "3",
    title: "Middle Name",
    dataIndex: "middle_name",
    ellipsis: true,
  },
  {
    key: "4",
    title: "Last Name",
    dataIndex: "last_name",
    ellipsis: true,
  },
  {
    key: "5",
    title: "DOB",
    dataIndex: "date_of_birth",
    ellipsis: true,
    render: (text) => <FormatDate date={text}/>,
  },
  {
    key: "6",
    title: "Gender",
    ellipsis: true,
    dataIndex: "gender",
  },
  {
    key: "7",
    title: "Marital Status",
    ellipsis: true,
    dataIndex: "marital_status",
  },
  {
    key: "8",
    title: "Legal Guardian",
    dataIndex: "basic_information",
    ellipsis: true,
    render: (item) => (
      <span>
        {item?.guardian_First_Name} {item?.guardian_Middle_Name}{" "}
        {item?.guardian_Last_Name}{" "}
      </span>
    ),
  },
  {
    key: "9",
    title: "Mobile",
    ellipsis: true,
    dataIndex: "patient_phone",
  },
  {
    key: "10",
    title: "Home",
    ellipsis: true,
    dataIndex: "patient_home",
  },
  {
    key: "11",
    title: "Work",
    ellipsis: true,
    dataIndex: "patient_work",
  },
  {
    key: "12",
    title: "Email",
    ellipsis: true,
    dataIndex: "patient_email",
  },
  {
    key: "19",
    title: "Note",
    ellipsis: true,
    dataIndex: "address_Note",
  },
];

const transformHealthRecord = (records) => {
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => {
    return {
      ID: record.id,
      MRN: record.mrn_number,
      FirstName: record?.first_name,
      MiddleName: record?.middle_name,
      LastName: record?.last_name,
      DOB: dateFormat(record?.date_of_birth),
      Gender: record?.gender,
      MaritalStatus: record?.marital_status || "",
      LegalGuardian:
        `${record?.guardian_first_Name} ${record?.guardian_middle_Name} ${record?.guardian_last_Name}` ||
        "",
      Mobile: record?.patient_phone || "",
      Home: record?.patient_home || "",
      Work: record?.patient_work || "",
      Email: record?.patient_email || "",
      Note: record?.address_note_line_1 || "",
    };
  });
};

const PatientHealthRecord = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("allergies");

  const handleSearch = async () => {
    const searchParam = form.getFieldValue(searchCategory);
    setLoading(true);

    try {
      const response = await doGet(
        `/reports/report-search/?search_on=${searchCategory}&search_param=${searchParam}`
      );
      if (response.status === 200) {
        setData(response.data.patients);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // socialHistory , vaccinationHistory , icd
  return (
    <>
      <h1 className="text-lg font-bold">Patient Health Record</h1>
      <Card className="shadow-class mb-4 mt-4">
        <h1> Search By Category </h1>
        <RadioGroup
          onChange={(e) => setSearchCategory(e.target.value)} 
          value={searchCategory}
          buttonStyle="solid"
          className="mb-2 mt-2"
        >
          <Radio.Button value="allergies">Allergies</Radio.Button>
          <Radio.Button value="socialHistory">Social History</Radio.Button>
          <Radio.Button value="vaccinationHistory">
            Vaccination History
          </Radio.Button>
          <Radio.Button value="icd">Diagnosis</Radio.Button>
          {/* Add more Radio buttons for other search categories */}
        </RadioGroup>
        <Form layout="vertical" form={form}>
          <div className="flex items-center">
            <Form.Item
              name={searchCategory}
              label={
                searchCategory === "allergies"
                  ? "Allergies"
                  : searchCategory === "socialHistory"
                  ? "Social History"
                  : searchCategory === "vaccinationHistory"
                  ? "Vaccination History"
                  : "Diagnosis"
              }
            >
              <Input
                allowClear={true}
                placeholder={`Search ${
                  searchCategory === "allergies"
                  ? "Allergies"
                  : searchCategory === "socialHistory"
                  ? "Social History"
                  : searchCategory === "vaccinationHistory"
                  ? "Vaccination History"
                  : "Diagnosis"
              }
                }`}
              />
            </Form.Item>
            <Button className="ml-2 mt-2" type="primary" onClick={handleSearch}>
              Submit
            </Button>
            <Button
              className="ml-2 mt-2"
              onClick={() => {
                form.resetFields();
                setData([]);
              }}
            >
              Reset
            </Button>
          </div>
        </Form>
      </Card>

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {data?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformHealthRecord(data)}
                // headers={headersCSV}
                filename={"PatientHealthRecord.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        {data && (
          <Table
            size="small"
            scroll={{
              x: 1000,
            }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        )}
      </Card>
    </>
  );
};

export default PatientHealthRecord;
